<div class="body-wrapper" *ngIf="print.loaded" [ngClass]="Direction" style='padding-top: 0;'>
    <header class="header">

        <div class="justify-between items-center" [innerHtml]="altHeader" *ngIf="altHeader && showHeader"></div>
        <div class="justify-between items-center" style="display: inline-flex;justify-content: space-between;width: 100%;margin-bottom: 5px;">
            <section style="font-size: medium;width: 70%;">
                <table>
                    <tr>
                        <td><strong><img height="20px" width="20px" src="../../../assets/icons/phone.svg"></strong> 09-7441155 </td>
                        <td> <strong><img height="20px" width="20px" src="../../../assets/icons/whatsapp.svg"></strong> 054-6105269</td>
                        <td><strong><img height="20px" width="20px" src="../../../assets/icons/fax.svg"></strong>  09-7440544</td>

                    </tr>
                    <tr>
                        <td> <strong><img height="20px" width="20px" src="../../../assets/icons/email.svg"></strong> contact@maromdoc.co.il </td>
                        <td> <strong><img height="20px" width="20px" src="../../../assets/icons/location.svg"></strong>דרך השרון 8 כפר סבא (בית מריק) </td>
                    </tr>
                    <tr>
                    </tr>
                </table>
            </section>
            <img [src]="! print.env['haslogo'] ? '../../assets/images/logo.png' : '/api/logo'" alt="Logo" class="header-logo"  *ngIf="print.env && ! altHeader && print.env['haslogo']">
        </div>
        <div class="justify-between items-center" style="margin-bottom: 5px;">
            <h1 class="header-title">{{'exam.type.Cardio.' + print.exam.ExamType | translate }}</h1>
        </div>
        <div class="header-details">
            <div style="display: inline-block; padding-left: 1em;padding-right: 1em">{{(print.isForm ? 'general.date' :'general.ExaminationDate')| translate}}:&nbsp;<strong style='direction: ltr;'>{{print.exam.CreationTime | date:"dd.MM.yyyy HH:mm"}}</strong></div>
            <div style="display: inline-block">{{'general.Printing_Date' | translate}}:&nbsp;<strong style='direction: ltr;'>{{now| date:"dd.MM.yyyy HH:mm"}}</strong></div>
        </div>
    </header>
    <main class="main">
        <section class="section" *ngIf="print.Pat">
            <h4 class="text-20 text-gold">{{'cardio.Details' | translate}}</h4>

            <div style="width:100%">
                <div>
                    <table><tr>
                        <td>
                            <strong>{{'general.Patient.first_name' | translate}}: </strong> {{print.Pat.First}}
                        </td>
                        <td>
                            <strong>{{'general.Patient.last_name' | translate}}: </strong> {{print.Pat.Last}}
                        </td>
                        <td>
                            <strong>{{'general.Patient.ID' | translate}}: </strong> {{print.Pat.ID}}
                        </td>
                        <td *ngIf="print.cardioGeneral && print.cardioGeneral.HMO">
                            <strong>קופת חולים</strong> {{'general.HMOs.'+print.cardioGeneral.HMO | translate}}
                            <!-- <strong>גורם מפנה: </strong> {{print.summary[0].HMO}} -->
                        </td>
                        <td *ngIf="print.Pat.Mobile">
                            <strong>{{'general.Patient.mobile' | translate}}: </strong> {{print.Pat.Mobile}}
                        </td>
                        <td *ngIf="print.Pat.Sex">
                            <strong>מגדר: </strong> {{print.Pat.Sex == 'M'? 'גבר': 'אישה' }}
                        </td>
                    </tr>
                    <tr *ngIf="print.Pat.Height || print.Pat.Weight || print.Pat.Birth">
                        <td *ngIf="print.Pat.Birth">
                            <strong>{{'general.Patient.birth_date' | translate}}: </strong> {{print.Pat.Birth| date:"dd.MM.yyyy"}}
                        </td>
                        <td *ngIf="print.Pat.Birth">
                            <strong>{{'general.Patient.Age' | translate}}: </strong> {{print.Pat.getAgeMo().split(" ")[0]}}
                        </td>
                        <td *ngIf="print.Pat.Height">
                            <strong>גובה: </strong> {{print.Pat.Height}}cm
                        </td>
                        <td *ngIf="print.Pat.Weight">
                            <strong>מישקל: </strong> {{print.Pat.Weight}}kg
                        </td>
                        <td *ngIf="print.Pat.Weight && print.Pat.Height" style="display: inline-flex;">
                            <strong style="margin-inline-end: 0.5em;">BMI: </strong> {{getBMI()}}
                        </td>
                        <td *ngIf="print.Pat.Weight && print.Pat.Height" style="display: inline-flex;">
                            <strong style="margin-inline-end: 0.5em;">BSA: </strong> {{getBSA()}}m²
                        </td>
                    </tr>
                    </table>
                    <hr *ngIf="print.cardioGeneral && (print.cardioGeneral.MedialHistory || print.cardioGeneral.Purpose || print.cardioGeneral.HeartRate || print.cardioGeneral.Indication || print.cardioGeneral.Procedure || print.cardioGeneral.Risk || print.cardioGeneral.Medications || print.cardioGeneral.ECG || print.cardioGeneral.Protocol)">
                    <table *ngIf="print.cardioGeneral && (print.cardioGeneral.HeartRate || print.cardioGeneral.Indication || print.cardioGeneral.Procedure || print.cardioGeneral.Risk || print.cardioGeneral.Medications || print.cardioGeneral.ECG || print.cardioGeneral.Protocol)">
                        <tr>
                            <td *ngIf="print.cardioGeneral.HeartRate">
                                <strong>{{'cardio.General.HeartRate' | translate}}: </strong> {{print.cardioGeneral.HeartRate}}bpm
                            </td>
                            <td *ngIf="print.cardioGeneral.bpSystolic">
                                <strong>{{'cardio.General.bpSystolic' | translate}}: </strong> {{print.cardioGeneral.bpSystolic}}/{{print.cardioGeneral.bpDiastolic}}mmHg
                            </td>
                            <td *ngIf="print.cardioGeneral.Indication">
                                <strong>{{'cardio.General.Indication' | translate}}: </strong> {{print.cardioGeneral.Indication}}
                            </td>
                            <td *ngIf="print.cardioGeneral.Procedure">
                                <strong>{{'cardio.General.Procedure' | translate}}: </strong> {{print.cardioGeneral.Procedure}}
                            </td>
                        </tr>
                    </table>
                    <div class="section-text" *ngIf="print.cardioGeneral && print.cardioGeneral.MedicalHistory" style="margin-top: 1em;">
                        <p class="comment"><strong>{{'pregnancy.anamnesis' | translate}}: </strong>{{print.cardioGeneral.MedicalHistory}}</p>
                    </div>
                    <div class="section-text" *ngIf="print.cardioGeneral && print.cardioGeneral.Purpose">
                        <p class="comment"><strong>{{'pregnancy.Purpose_of_Exam' | translate}}:</strong> {{print.cardioGeneral.Purpose}}</p>
                    </div>       
                    <div class="section-text" *ngIf="print.cardioGeneral && print.cardioGeneral.Risk" style="margin-top: 1em;">
                        <p class="comment"><strong>{{'cardio.Risk' | translate}}: </strong>{{print.cardioGeneral.Risk}}</p>
                    </div>
                    <div class="section-text" *ngIf="print.cardioGeneral && print.cardioGeneral.Medications" style="margin-top: 1em;">
                        <p class="comment"><strong>{{'cardio.Medications' | translate}}: </strong>{{print.cardioGeneral.Medications}}</p>
                    </div>
                    <div class="section-text" *ngIf="print.cardioGeneral && print.cardioGeneral.ECG" style="margin-top: 1em;">
                        <p class="comment"><strong>{{'cardio.ECG' | translate}}: </strong>{{print.cardioGeneral.ECG}}</p>
                    </div>
                    <div class="section-text" *ngIf="print.cardioGeneral && print.cardioGeneral.Protocol" style="margin-top: 1em;">
                        <p class="comment"><strong>{{'cardio.Protocol' | translate}}: </strong>{{print.cardioGeneral.Protocol}}</p>
                    </div>
                    <table>                        <tr>
                        <td *ngIf="print.cardioGeneral && print.cardioGeneral.Quality"><strong>{{'cardio.General.Quality.title' | translate}}: </strong>{{'cardio.General.Quality.'+print.cardioGeneral.Quality | translate}}</td>
                        <td *ngIf="print.summary && print.cardioGeneral && print.cardioGeneral.ReferingDoc"><strong>{{'general.Patient.referring_doctor' | translate}}:</strong> {{print.cardioGeneral.ReferingDoc}}</td>
                    </tr>
                </table>
                </div>
            </div>
        </section>
        <section class="section t-flowmeas" *ngIf="print.flowmeas && print.exam.ExamType =='Duplex Carotid'">
            <h4 class="text-20 text-gold">{{'cardio.Findings.flowmeas.title' | translate}}</h4>
            <table>
                <tr>
                    <td style="border: none;"><div><img src="../../../assets/images/leftcarotid.jpg"></div></td>
                    <td width="40%;border: none;">
                        <table>
                            <tr>
                                <td colspan="2"><div><span>ימין</span></div></td>
                                <td rowspan="2"></td>
                                <td colspan="2"><div><span>שמאל</span></div></td>
                            </tr>
                            <tr>
                                <td><div><span>EDV</span></div></td>
                                <td><div><span>PSV</span></div></td>
                                <td><div><span>EDV</span></div></td>
                                <td><div><span>PSV</span></div></td>
            
                            </tr>
                            <tr *ngFor="let i of FLOWMEAS">
                                <td>{{(getDb(i)+'EDVRIGHT' == 'ICA/CCAEDVRIGHT' ? '-' : print.flowmeas[getDb(i)+'EDVRIGHT'])}}</td>
                                <td>{{print.flowmeas[getDb(i)+'PSVRIGHT'] }}</td>
                                <td>{{i}}</td>
                                <td>{{(getDb(i)+'EDVLEFT' == 'ICA/CCAEDVLEFT' ? '-' : print.flowmeas[getDb(i)+'EDVLEFT'])}}</td>
                                <td>{{print.flowmeas[getDb(i)+'PSVLEFT']}}</td>
                            </tr>
                            <tr>
                                <td colspan="2">{{print.flowmeas['IMTRIGHT']}}</td>
                                <td>IMT</td>
                                <td colspan="2">{{print.flowmeas['IMTLEFT']}}</td>
                            </tr>
                        </table>
                    </td>
                    <td style="border: none;"><div><img src="../../../assets/images/leftcarotid.jpg"></div></td>

                </tr>
            </table>
        </section>
        <section class="section" *ngIf="print.cardio2D && print.exam.ExamType != 'Stress' && print.exam.ExamType !='Duplex Carotid'">
            <h4 class="text-20 text-gold">{{'cardio.2D.title' | translate}}</h4>
            <table>
                <tr>
                    <td *ngIf="print.cardio2D.LVDD || print.cardio2D.LVSD || print.cardio2D.IVSDiastole|| print.cardio2D.Apex || print.cardio2D.ProximalSeptum || print.cardio2D.LVPW || print.cardio2D.LVEF || print.cardio2D.LVFS || print.cardio2D.IVSP" style="width: fit-content; vertical-align: top;padding-left: 5px !important;">
                        <table class="formatted">
                            <tr><td class="formatted-title">{{'cardio.2D.LeftVentrical.title' | translate}} </td></tr>
                            <tr *ngIf="print.cardio2D.LVDD">
                                <td style="padding-left: 5px;">
                                    <strong>{{'cardio.2D.LeftVentrical.LVDD' | translate}}: </strong> {{print.cardio2D.LVDD  | number:'1.0-1'}}mm
                                </td>
                                <td style="padding-left: 5px;">
                                    <span style="font-size: 10px;">(42-54mm)</span>
                                </td>
                            </tr>
                            <tr *ngIf="print.cardio2D.LVSD">
                                <td style="padding-left: 5px;">
                                    <strong>{{'cardio.2D.LeftVentrical.LVSD' | translate}}: </strong> {{print.cardio2D.LVSD | number:'1.0-1'}}mm
                                </td>
                                <td style="padding-left: 5px;">
                                    <span style="font-size: 10px;">(25-40mm)</span>
                                </td>
                            </tr>
                            <tr *ngIf="print.cardio2D.IVSP">
                                <td style="padding-left: 5px;">
                                    <strong style="margin-inline-end: 0.5em;">IVSP/LVSPW: </strong> {{print.cardio2D.IVSP | number:'1.0-1'}}
                                </td>
                                <td style="padding-left: 5px;">
                                    <span style="font-size: 10px;">(6-11mm)</span>
                                </td>
                            </tr>
                            <tr *ngIf="print.cardio2D.ProximalSeptum">
                                <td style="padding-left: 5px;">
                                    <strong>{{'cardio.2D.LeftVentrical.ProximalSeptum' | translate}}: </strong> {{print.cardio2D.ProximalSeptum | number:'1.0-1'}}mm
                                </td>
                            </tr>
                            <tr *ngIf="print.cardio2D.IVSDiastole">
                                <td style="padding-left: 5px;">
                                    <strong>{{'cardio.2D.LeftVentrical.IVSDiastole' | translate}}: </strong> {{print.cardio2D.IVSDiastole | number:'1.0-1'}}mm
                                </td>
                            </tr>
                            <tr *ngIf="print.cardio2D.LVPW">
                                <td style="padding-left: 5px;">
                                    <strong>{{'cardio.2D.LeftVentrical.LVPW' | translate}}: </strong> {{print.cardio2D.LVPW | number:'1.0-1'}}mm
                                </td>
                            </tr>
                            <tr *ngIf="print.cardio2D.Apex">
                                <td style="padding-left: 5px;">
                                    <strong>{{'cardio.2D.LeftVentrical.Apex' | translate}}: </strong> {{print.cardio2D.Apex | number:'1.0-1'}}
                                </td>
                            </tr>
                            <tr *ngIf="print.cardio2D.LVEF">
                                <td style="padding-left: 5px;">
                                    <strong>{{'cardio.2D.LeftVentrical.LVEF' | translate}}: </strong> {{print.cardio2D.LVEF}}%  
                                </td>
                            </tr>
                            <tr *ngIf="print.cardio2D.LVM">
                                <td style="padding-left: 5px;">
                                    <strong>{{'cardio.2D.LeftVentrical.LVM' | translate}}: </strong> {{print.cardio2D.LVM | number:'1.0-1'}}gm
                                </td>
                            </tr>
                            <tr *ngIf="print.cardio2D.LVMI">
                                <td style="padding-left: 5px;">
                                    <strong>{{'cardio.2D.LeftVentrical.LVMI' | translate}}: </strong> {{print.cardio2D.LVMI | number:'1.0-1'}}gm/m²
                                </td>
                            </tr>
                        </table>
                    </td>
                    <td style="width: fit-content; vertical-align: top;padding-left: 5px;" >
                        <table class="formatted" *ngIf="print.cardio2D.AorticRoot || print.cardio2D.AscendingAorta">
                            <tr class="formatted-title"><td>{{'cardio.2D.Aorta.title' | translate}} </td></tr>
                            <tr *ngIf="print.cardio2D.AorticRoot">
                                <td style="padding-left: 5px;">
                                    <strong>{{'cardio.2D.Aorta.AorticRoot' | translate}}: </strong> {{print.cardio2D.AorticRoot | number:'1.0-1'}}mm
                                </td>
                                <td style="padding-left: 5px;">
                                    <span style="font-size: 10px;">(23-30mm)</span>
                                </td>
                            </tr>
                            <tr *ngIf="print.cardio2D.AscendingAorta">
                                <td style="padding-left: 5px;">
                                    <strong>{{'cardio.2D.Aorta.AscendingAorta' | translate}}: </strong> {{print.cardio2D.AscendingAorta | number:'1.0-1'}}mm
                                </td>
                                <td style="padding-left: 5px;">
                                    <span style="font-size: 10px;">(27-34mm)</span>
                                </td>
                            </tr>
                            <tr *ngIf="print.cardio2D.SinusAorta">
                                <td style="padding-left: 5px;">
                                    <strong>{{'cardio.2D.Aorta.SinusAorta' | translate}}: </strong> {{print.cardio2D.SinusAorta | number:'1.0-1'}}mm
                                </td>
                                <td style="padding-left: 5px;">
                                    <span style="font-size: 10px;">(30-37mm)</span>
                                </td>
                            </tr>
                        </table>
                        <table class="formatted" *ngIf="print.cardio2D.RA || print.cardio2D.RAAS || print.cardio2D.RAESV">
                            <tr class="formatted-title"><td>{{'cardio.2D.RightAtrium.title' | translate}} </td></tr>
                            <tr *ngIf="print.cardio2D.RA">
                                <td style="padding-left: 5px;">
                                    <strong>{{'cardio.2D.RightAtrium.RA' | translate}}: </strong> {{print.cardio2D.RA | number:'1.0-1'}}mm
                                </td>
                            </tr>
                            <tr *ngIf="print.cardio2D.RAAS">
                                <td style="padding-left: 5px;">
                                    <strong>{{'cardio.2D.RightAtrium.RAAS' | translate}}: </strong> {{print.cardio2D.RAAS | number:'1.0-1'}}mm²
                                </td>
                            </tr>
                            <tr *ngIf="print.cardio2D.RAAESV">
                                <td style="padding-left: 5px;">
                                    <strong>{{'cardio.2D.RightAtrium.RAAESV' | translate}}: </strong> {{print.cardio2D.RAAESV | number:'1.0-1'}}mm³
                                </td>
                            </tr>
                        </table>
                    </td>
                    <td style="width: fit-content; vertical-align: top;">
                        <table class="formatted" *ngIf="print.cardio2D.LAAP || print.cardio2D.LASI || print.cardio2D.lArea || print.cardio2D.lAVol || print.cardio2D.lAVol2">
                            <tr class="formatted-title"><td>{{'cardio.2D.LeftAtrium.title' | translate}}</td></tr>
                            <tr *ngIf="print.cardio2D.LAAP">
                                <td style="padding-left: 5px;">
                                    <strong>{{'cardio.2D.LeftAtrium.LAAP' | translate}}: </strong> {{print.cardio2D.LAAP | number:'1.0-1'}}mm
                                </td>
                                <td style="padding-left: 5px;">
                                    <span style="font-size: 10px;">(27-40mm)</span>
                                </td>
                            </tr>
                            <tr *ngIf="print.cardio2D.LASI">
                                <td style="padding-left: 5px;">
                                    <strong>{{'cardio.2D.LeftAtrium.LASI' | translate}}: </strong> {{print.cardio2D.LASI | number:'1.0-1'}}mm
                                </td>
                            </tr>
                            <tr *ngIf="print.cardio2D.lArea">
                                <td style="padding-left: 5px;">
                                    <strong>{{'cardio.2D.LeftAtrium.lArea' | translate}}: </strong> {{print.cardio2D.lArea | number:'1.0-1'}}cm²
                                </td>
                                <td style="padding-left: 5px;">
                                    <span style="font-size: 10px;">(20cm²)</span>
                                </td>
                            </tr>
                            <tr *ngIf="print.cardio2D.lAVol">
                                <td style="padding-left: 5px;">
                                    <strong style="margin-inline-end: 0.5em;">{{'cardio.2D.LeftAtrium.lAVol' | translate}}: </strong> {{print.cardio2D.lAVol | number:'1.0-1'}}cc
                                </td>
                                <td style="padding-left: 5px;">
                                    <span style="font-size: 10px;">(13-34cc)</span>
                                </td>
                            </tr>
                            <tr *ngIf="print.cardio2D.lAVol2">
                                <td style="display: flex;padding-left: 5px;">
                                    <strong style="margin-inline-end: 0.5em;">{{'cardio.2D.LeftAtrium.lAVol2' | translate}}: </strong> {{print.cardio2D.lAVol2 | number:'1.0-1'}}cc
                                </td>
                            </tr>
                        </table>
                        <table class="formatted" *ngIf="print.cardio2D.RVdis || print.cardio2D.TAPSE">
                            <tr class="formatted-title"><td>{{'cardio.2D.RightVentrical.title' | translate}}</td></tr>
                            <tr *ngIf="print.cardio2D.RVdis">
                                <td>
                                    <strong>{{'cardio.2D.RightVentrical.RVdis' | translate}}: </strong> {{print.cardio2D.RVdis | number:'1.0-1'}}mm
                                </td>
                            </tr>
                            <tr *ngIf="print.cardio2D.TAPSE">
                                <td style="display: flex;">
                                    <strong style="margin-inline-end: 0.5em;">{{'cardio.2D.RightVentrical.TAPSE' | translate}}: </strong> {{print.cardio2D.TAPSE | number:'1.0-1'}}
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <span>*טווח הערכים משתנים בהתאם לגיל, מגדר ושטח גוף</span>
                </tr>
            </table>
        </section>

        <section class="section doppler" *ngIf="print.cardioDoppler">
            <h4 class="text-20 text-gold">{{'cardio.Valves' | translate}}</h4>
            <div class="section-inner mb-20 px-16" *ngIf="print.cardioDoppler && dopplerHas('Mitral')" style='width: 99%'>
                <div class="formatted-title">{{'cardio.Mitral' | translate}}</div>
                <span *ngIf="print.cardioDoppler['MitralPV']"> <strong>{{'doppler.peakVelocity' | translate}} </strong> {{print.cardioDoppler['MitralPV'] | number:'1.0-1'}}m/s</span>
                <span *ngIf="print.cardioDoppler['MitralRFV']"> <strong>{{'doppler.meanVelocity' | translate}} </strong> {{print.cardioDoppler['MitralRFV'] | number:'1.0-1'}}m/s</span>
                <span *ngIf="print.cardioDoppler['MitralPG']"> <strong>{{'doppler.maxGradient' | translate}} </strong> {{print.cardioDoppler['MitralPG'] | number:'1.0-1'}}mm/Hg</span>
                <span *ngIf="print.cardioDoppler['MitralMG']"> <strong>{{'doppler.meanGradient' | translate}} </strong> {{print.cardioDoppler['MitralMG'] | number:'1.0-1'}}mm/Hg</span>
                <span *ngIf="print.cardioDoppler['MitralVA']"> <strong>{{'doppler.valveArea' | translate}} </strong> {{print.cardioDoppler['MitralVA'] | number:'1.0-1'}}cm²</span>
                <span *ngIf="print.cardioDoppler['PISA']" style="display: inline-flex;"><strong style="margin-inline-end: 0.5em;">PISA </strong> {{print.cardioDoppler['PISA'] | number:'1.0-1'}}</span>
                <span *ngIf="print.cardioDoppler['VENACONTRACTA']" style="display: flex;"> <strong style="margin-inline-end: 0.5em;">VENA CONTRACTA</strong> {{print.cardioDoppler['VENACONTRACTA'] | number:'1.0-1'}}</span>
            </div>
            <div class="section-inner mb-20 px-16" *ngIf="print.cardioDoppler && dopplerHas('Tricuspid')" style='width: 99%'>
                <div class="formatted-title">{{'cardio.Tricuspid' | translate}}</div>
                <span *ngIf="print.cardioDoppler['TricuspidPV']"> <strong>{{'doppler.peakVelocity' | translate}} </strong> {{print.cardioDoppler['TricuspidPV'] | number:'1.0-1'}}m/s</span>
                <span *ngIf="print.cardioDoppler['TricuspidRFV']"> <strong>{{'doppler.meanVelocity' | translate}} </strong> {{print.cardioDoppler['TricuspidRFV'] | number:'1.0-1'}}m/s</span>
                <span *ngIf="print.cardioDoppler['TricuspidPG']"> <strong>{{'doppler.maxGradient' | translate}} </strong> {{print.cardioDoppler['TricuspidPG'] | number:'1.0-1'}}mm/Hg</span>
                <span *ngIf="print.cardioDoppler['TricuspidMG']"> <strong>{{'doppler.meanGradient' | translate}} </strong> {{print.cardioDoppler['TricuspidMG'] | number:'1.0-1'}}mm/Hg</span>
                <span *ngIf="print.cardioDoppler['TricuspidVA']"> <strong>{{'doppler.valveArea' | translate}} </strong> {{print.cardioDoppler['TricuspidVA'] | number:'1.0-1'}}cm²</span>
                <span *ngIf="print.cardioDoppler['RAP']" style="display: inline-flex;"><strong style="margin-inline-end: 0.5em;">RAP </strong> {{print.cardioDoppler['RAP'] | number:'1.0-1'}}</span>
                <span *ngIf="print.cardioDoppler['TricuspidEPP']"> <strong>{{'cardio.TricuspidEPP' | translate}} </strong> {{print.cardioDoppler['TricuspidEPP'] | number:'1.0-1'}}mm/Hg</span>
            </div>
            <div class="section-inner mb-20 px-16" *ngIf="print.cardioDoppler && dopplerHas('Aortic')" style='width: 99%'>
                <div class="formatted-title">{{'cardio.Aortic' | translate}}</div>
                <span *ngIf="print.cardioDoppler['AorticPV']"> <strong>{{'doppler.peakVelocity' | translate}} </strong> {{print.cardioDoppler['AorticPV'] | number:'1.0-1'}}m/s</span>
                <span *ngIf="print.cardioDoppler['AorticRFV']"> <strong>{{'doppler.meanVelocity' | translate}} </strong> {{print.cardioDoppler['AorticRFV'] | number:'1.0-1'}}m/s</span>
                <span *ngIf="print.cardioDoppler['AorticPG']"> <strong>{{'doppler.maxGradient' | translate}} </strong> {{print.cardioDoppler['AorticPG'] | number:'1.0-1'}}mm/Hg</span>
                <span *ngIf="print.cardioDoppler['AorticMG']"> <strong>{{'doppler.meanGradient' | translate}} </strong> {{print.cardioDoppler['AorticMG'] | number:'1.0-1'}}mm/Hg</span>
                <span *ngIf="print.cardioDoppler['AorticVA']"> <strong>{{'doppler.valveArea' | translate}} </strong> {{print.cardioDoppler['AorticVA'] | number:'1.0-1'}}cm²</span>
            </div>
            <div class="section-inner mb-20 px-16" *ngIf="print.cardioDoppler && dopplerHas('Pulmonic')" style='width: 99%'>
                <div class="formatted-title">{{'cardio.Pulmonic' | translate}}</div>
                <span *ngIf="print.cardioDoppler['PulmonicPV']"> <strong>{{'doppler.peakVelocity' | translate}} </strong> {{print.cardioDoppler['PulmonicPV'] | number:'1.0-1'}}m/s</span>
                <span *ngIf="print.cardioDoppler['PulmonicRFV']"> <strong>{{'doppler.meanVelocity' | translate}} </strong> {{print.cardioDoppler['PulmonicRFV'] | number:'1.0-1'}}m/s</span>
                <span *ngIf="print.cardioDoppler['PulmonicPG']"> <strong>{{'doppler.maxGradient' | translate}} </strong> {{print.cardioDoppler['PulmonicPG'] | number:'1.0-1'}}mm/Hg</span>
                <span *ngIf="print.cardioDoppler['PulmonicMG']"> <strong>{{'doppler.meanGradient' | translate}} </strong> {{print.cardioDoppler['PulmonicMG'] | number:'1.0-1'}}mm/Hg</span>
                <span *ngIf="print.cardioDoppler['PulmonicVA']"> <strong>{{'doppler.valveArea' | translate}} </strong> {{print.cardioDoppler['PulmonicVA'] | number:'1.0-1'}}cm²</span>
            </div>
        </section>

        <section class="section doppler" *ngIf="print.cardioTissue">
            <h4 class="text-20 text-gold">{{'cardio.FunctionalHeart' | translate}}</h4>
            <section class="section" *ngIf="print.cardioTissue['RightVentrical']">
                <div class="formatted-title" style="width: 100%;">{{'cardio.RightVentrical' | translate}}</div>
                <div class="comment">{{print.cardioTissue['RightVentrical']}}</div>
            </section>
            <section class="section" *ngIf="print.cardioTissue['LeftVentrical']">
                <div class="formatted-title" style="width: 100%;">{{'cardio.LeftVentrical' | translate}}</div>
                <div class="comment">{{print.cardioTissue['LeftVentrical']}}</div>
            </section>
            <div class="section-inner mb-20 px-16" *ngIf="print.cardioTissue && (print.cardioTissue.LVOTPV || print.cardioTissue.LVOTRFV || print.cardioTissue.LVOTPG || print.cardioTissue.LVOTMG || print.cardioTissue.LVOTVA || print.cardioTissue.LVOTDiam || print.cardioTissue.LVOTTrace || print.cardioTissue.LVOTEnv || print.cardioTissue.LVOTVTI || print.cardioTissue.HR || print.cardioTissue.LVSV || print.cardioTissue.LVCO)" style='width: 99%'>
                <div class="formatted-title">{{'cardio.LVOT' | translate}}</div>
                <span *ngIf="print.cardioTissue['LVOTPV']"><strong>{{'doppler.peakVelocity' | translate}} </strong> {{print.cardioTissue['LVOTPV'] | number:'1.0-1'}}m/s</span>
                <span *ngIf="print.cardioTissue['LVOTRFV']"><strong>{{'doppler.meanVelocity' | translate}} </strong> {{print.cardioTissue['LVOTRFV'] | number:'1.0-1'}}m/s</span>
                <span *ngIf="print.cardioTissue['LVOTPG']"><strong>{{'doppler.maxGradient' | translate}} </strong> {{print.cardioTissue['LVOTPG'] | number:'1.0-1'}}mm/Hg</span>
                <span *ngIf="print.cardioTissue['LVOTMG']"><strong>{{'doppler.meanGradient' | translate}} </strong> {{print.cardioTissue['LVOTMG'] | number:'1.0-1'}}mm/Hg</span>
                <span *ngIf="print.cardioTissue['LVOTVA']"><strong>{{'doppler.valveArea' | translate}} </strong> {{print.cardioTissue['LVOTVA'] | number:'1.0-1'}}cm²</span>
                <span *ngIf="print.cardioTissue['LVOTDiam']" style="display: inline-flex;"> <strong style="margin-inline-end: 0.5em;">Diameter </strong> {{print.cardioTissue['LVOTDiam'] | number:'1.0-1'}}mm</span>
                <span *ngIf="print.cardioTissue['LVOTTrace']" style="display: inline-flex;"> <strong style="margin-inline-end: 0.5em;">Trace </strong> {{print.cardioTissue['LVOTTrace'] | number:'1.0-1'}}</span>
                <span *ngIf="print.cardioTissue['LVOTEnv']" style="display: inline-flex;"> <strong style="margin-inline-end: 0.5em;">Envelope Time </strong> {{print.cardioTissue['LVOTEnv'] | number:'1.0-1'}}s</span>
                <span *ngIf="print.cardioTissue['LVOTVTI']" style="display: inline-flex;"> <strong style="margin-inline-end: 0.5em;">Velocity Time Integ </strong> {{print.cardioTissue['LVOTVTI'] | number:'1.0-1'}}</span>
                <span *ngIf="print.cardioTissue['HR']"><strong>{{'cardio.General.HeartRate' | translate}} </strong> {{print.cardioTissue['HR'] | number:'1.0-1'}}bpm</span>
                <span *ngIf="print.cardioTissue['LVSV']" style="display: inline-flex;"> <strong style="margin-inline-end: 0.5em;">Stroke Vol. </strong> {{print.cardioTissue['LVSV'] | number:'1.0-1'}}ml</span>
                <span *ngIf="print.cardioTissue['LVCO']" style="display: inline-flex;"> <strong style="margin-inline-end: 0.5em;">LVCO </strong> {{print.cardioTissue['LVCO'] | number:'1.0-1'}}l</span>
            </div>
            <div class="section-inner mb-20 px-16" *ngIf="print.cardioTissue.Ewave || print.cardioTissue.Awave || print.cardioTissue.EA || print.cardioTissue.DecT" style='width: 99%;direction: ltr;text-align: left;'>
                <div class="formatted-title">LV Filling</div>
                <span *ngIf="print.cardioTissue.Ewave" style="width: 25%;"> <strong>Peak E-wave </strong> {{print.cardioTissue.Ewave | number:'1.0-1'}}</span>
                <span *ngIf="print.cardioTissue.Awave" style="width: 25%;"> <strong>Peak A-wave </strong> {{print.cardioTissue.Awave | number:'1.0-1'}}</span>
                <span *ngIf="print.cardioTissue.EA" style="width: 25%;"> <strong>E/A ratio </strong> {{print.cardioTissue.EA | number:'1.0-1'}}</span>
                <span *ngIf="print.cardioTissue.DecT" style="width: 25%;"> <strong>Deceleration time </strong> {{print.cardioTissue.DecT | number:'1.0-1'}}ms</span>
            </div>
            <div class="section-inner mb-20 px-16" *ngIf="print.cardioTissue.Elat || print.cardioTissue.Eelat || print.cardioTissue.Slat" style='width: 99%;direction: ltr;text-align: left;'>
                <div class="formatted-title">Lateral</div>
                <span *ngIf="print.cardioTissue.Slat" style="width: 25%;"> <strong>S' Velocity </strong> {{print.cardioTissue.Slat | number:'1.0-1'}}</span>
                <span *ngIf="print.cardioTissue.Elat" style="width: 25%;"> <strong>E' Velocity </strong> {{print.cardioTissue.Elat | number:'1.0-1'}}</span>
                <span *ngIf="print.cardioTissue.Eelat" style="width: 25%;"> <strong>E/e' ratio </strong> {{print.cardioTissue.Eelat | number:'1.0-1'}}</span>
            </div>
            <div class="section-inner mb-20 px-16" *ngIf="print.cardioTissue.Esep || print.cardioTissue.Sesep || print.cardioTissue.Ssep" style='width: 99%;direction: ltr;text-align: left;'>
                <div class="formatted-title">Septal</div>
                <span *ngIf="print.cardioTissue.Ssep" style="width: 25%;"> <strong>S' Velocity </strong> {{print.cardioTissue.Ssep | number:'1.0-1'}}</span>
                <span *ngIf="print.cardioTissue.Esep" style="width: 25%;"> <strong>E' Velocity </strong> {{print.cardioTissue.Esep | number:'1.0-1'}}</span>
                <span *ngIf="print.cardioTissue.Eesep" style="width: 25%;"> <strong>E/e' ratio </strong> {{print.cardioTissue.Eesep | number:'1.0-1'}}</span>
            </div>
            <h4 class="text-20 text-gold" *ngIf="print.exam.ExamType !='TTE Adults' && (print.cardioTissue.EwaveAfter || print.cardioTissue.AwaveAfter || print.cardioTissue.EAAfter || print.cardioTissue.DecTAfter)">{{'cardio.StressEcho.AfterStress' | translate}}</h4>
            <div class="section-inner mb-20 px-16" *ngIf="print.cardioTissue.EwaveAfter || print.cardioTissue.AwaveAfter || print.cardioTissue.EAAfter || print.cardioTissue.DecTAfter" style='width: 99%;direction: ltr;text-align: left;'>
                <div class="formatted-title">LV Filling</div>
                <span *ngIf="print.cardioTissue.EwaveAfter" style="width: 25%;"> <strong>Peak E-wave </strong> {{print.cardioTissue.EwaveAfter | number:'1.0-1'}}</span>
                <span *ngIf="print.cardioTissue.AwaveAfter" style="width: 25%;"> <strong>Peak A-wave </strong> {{print.cardioTissue.AwaveAfter | number:'1.0-1'}}</span>
                <span *ngIf="print.cardioTissue.EAAfter" style="width: 25%;"> <strong>E/A ratio </strong> {{print.cardioTissue.EAAfter | number:'1.0-1'}}</span>
                <span *ngIf="print.cardioTissue.DecTAfter" style="width: 25%;"> <strong>Deceleration time </strong> {{print.cardioTissue.DecTAfter | number:'1.0-1'}}ms</span>
            </div>
            <div class="section-inner mb-20 px-16" *ngIf="print.cardioTissue.ElatAfter || print.cardioTissue.EelatAfter || print.cardioTissue.SlatAfter" style='width: 99%;direction: ltr;text-align: left;'>
                <div class="formatted-title">Lateral</div>
                <span *ngIf="print.cardioTissue.Slat" style="width: 25%;"> <strong>S' Velocity </strong> {{print.cardioTissue.SlatAfter | number:'1.0-1'}}</span>
                <span *ngIf="print.cardioTissue.Elat" style="width: 25%;"> <strong>E' Velocity </strong> {{print.cardioTissue.ElatAfter | number:'1.0-1'}}</span>
                <span *ngIf="print.cardioTissue.Eelat" style="width: 25%;"> <strong>E/e' ratio </strong> {{print.cardioTissue.EelatAfter | number:'1.0-1'}}</span>
            </div>
            <div class="section-inner mb-20 px-16" *ngIf="print.cardioTissue.EsepAfter || print.cardioTissue.SesepAfter || print.cardioTissue.SsepAfter" style='width: 99%;direction: ltr;text-align: left;'>
                <div class="formatted-title">Septal</div>
                <span *ngIf="print.cardioTissue.SsepAfter" style="width: 25%;"> <strong>S' Velocity </strong> {{print.cardioTissue.SsepAfter | number:'1.0-1'}}</span>
                <span *ngIf="print.cardioTissue.EsepAfter" style="width: 25%;"> <strong>E' Velocity </strong> {{print.cardioTissue.EsepAfter | number:'1.0-1'}}</span>
                <span *ngIf="print.cardioTissue.EesepAfter" style="width: 25%;"> <strong>E/e' ratio </strong> {{print.cardioTissue.EesepAfter | number:'1.0-1'}}</span>
            </div>
        </section>
        <section class="section" *ngIf="print.exam.ExamType != 'Duplex Carotid' && print.cardioFindings && ((print.exam.ExamType =='Stress-Echo' && print.cardioFindings.Medication) ||print.cardioFindings.Response || print.cardioFindings.Arythmia || print.cardioFindings.Conduction || print.cardioFindings.ST || print.cardioFindings.EndReason || print.cardioFindings.PulseRate || print.cardioFindings.PulseStatus || print.cardioFindings.Duration || (print.cardioFindings.Stage > 0) || print.cardioFindings.METs || print.cardioFindings.HeartRate || print.cardioFindings.MaxExpectedHeartRate || print.cardioFindings.MaxHeartRate || print.cardioFindings.Precentage || (print.cardioFindings.bpSystolicRest && print.cardioFindings.bpDiastolicRest) || (print.cardioFindings.bpSystolic && print.cardioFindings.bpDiastolic) || print.cardioFindings.Response || print.cardioFindings.Arythmia || print.cardioFindings.Conduction || print.cardioFindings.ST || print.cardioFindings.EndReason || print.cardioFindings.PulseRate)">
            <h4 class="text-20 text-gold" *ngIf="print.exam.ExamType == 'Stress-Echo'">{{'cardio.StressEcho.title' | translate}}</h4>
            <table class="formatted" style="font-size: 14px;">
                <tr><td class="formatted-title" style="margin-bottom: 0;height: min-content;" *ngIf="print.exam.ExamType != 'TTE Adults'">{{'cardio.StressEcho.CourseOfExam' | translate}}</td></tr>
                <tr *ngIf="print.cardioFindings.ECG || (print.cardioFindings.bpSystolicRest && print.cardioFindings.bpDiastolicRest) || (print.cardioFindings.bpSystolic && print.cardioFindings.bpDiastolic)">
                    <td class="flex"  *ngIf="print.cardioFindings.bpSystolicRest && print.cardioFindings.bpDiastolicRest">
                        <div style="width: fit-content;margin-inline-end: 0.5em;"><strong>{{'cardio.Findings.RestingBP' | translate}}: </strong> </div>
                        <div style="direction: ltr;width: fit-content;">{{print.cardioFindings.bpSystolicRest}}/{{print.cardioFindings.bpDiastolicRest}} mmHg</div>
                    </td>
                    <td class="flex"  *ngIf="print.cardioFindings.bpSystolic && print.cardioFindings.bpDiastolic">
                        <div style="width: fit-content;margin-inline-end: 0.5em;"><strong>{{'cardio.Findings.MaxBP' | translate}}: </strong> </div>
                        <div style="direction: ltr;width: fit-content;">{{print.cardioFindings.bpSystolic}}/{{print.cardioFindings.bpDiastolic}} mmHg</div>
                    </td>
                </tr>
                <tr *ngIf="(print.cardioFindings.Protocol || print.cardioFindings.Duration || (print.cardioFindings.Stage > 0 )|| print.cardioFindings.METs) && print.exam.ExamType != 'Duplex Carotid'">
                    <td class="flex" *ngIf="print.cardioFindings.Protocol && print.exam.ExamType != 'TTE Adults' && print.exam.ExamType != 'Duplex Carotid'">
                        <strong style="margin-inline-end: 0.5em;">{{'cardio.Findings.Protocol' | translate}}: </strong> {{print.cardioFindings.Protocol}}
                    </td>
                    <td class="flex" *ngIf="print.cardioFindings.Duration">
                        <strong style="margin-inline-end: 0.5em;">{{'cardio.Findings.Duration' | translate}}: </strong> {{print.cardioFindings.Duration }} {{'cardio.Findings.DurationMin' | translate}}
                    </td>
                    <td class="flex" *ngIf="print.cardioFindings.Stage > 0">
                        <strong style="margin-inline-end: 0.5em;">{{'cardio.Findings.Stage' | translate}}: </strong> {{print.cardioFindings.Stage }}
                    </td>
                    <!-- <td class="flex" *ngIf="print.cardioFindings.METs">
                        <strong style="margin-inline-end: 0.5em;">{{(print.cardioFindings.Protocol == 'Bicycle' ? 'cardio.Findings.WATTs' :'cardio.Findings.METs') | translate}}:</strong> {{print.cardioFindings.METs  | number:'1.0-1'}}
                    </td> -->
                </tr>
                <tr *ngIf="(print.cardioFindings.MaxExpectedHeartRate && print.exam.ExamType != 'TTE Adults' && print.exam.ExamType != 'Duplex Carotid' ) || print.cardioFindings.HeartRate || print.cardioFindings.MaxHeartRate|| print.cardioFindings.MaxExpectedHeartRate || print.cardioFindings.Duration || (print.cardioFindings.Precentage && print.exam.ExamType != 'TTE Adults' )">
                    <td class="flex">
                        <div>
                            <span style="margin: 0 5px;"> {{'cardio.Findings.MaxHeartRate' | translate}} <strong> {{print.cardioFindings.MaxHeartRate | number:'1.0-0'}}bpm, </strong> </span>
                            <span> {{'cardio.Findings.MaxExpectedHeartRate' | translate}} <strong> {{print.cardioFindings.MaxExpectedHeartRate | number:'1.0-0'}}bpm. </strong> </span>
                            <span> {{'cardio.Findings.Duration' | translate}} <strong> {{print.cardioFindings.Duration }} {{'cardio.Findings.DurationMin' | translate}}, </strong> </span>
                            <span> {{(print.cardioFindings.Protocol == 'Bicycle' ? 'cardio.Findings.WATTs' :'cardio.Findings.METs') | translate}}: <strong> {{print.cardioFindings.METs  | number:'1.0-1'}} </strong> </span>
                        </div>
                    </td>
                </tr>
                <tr *ngIf="(print.cardioFindings.MaxExpectedHeartRate && print.exam.ExamType != 'TTE Adults' && print.exam.ExamType != 'Duplex Carotid' ) || print.cardioFindings.HeartRate || print.cardioFindings.MaxHeartRate || (print.cardioFindings.Precentage && print.exam.ExamType != 'TTE Adults' )">
                    <!-- <td class="flex"  *ngIf="print.cardioFindings.MaxExpectedHeartRate && print.exam.ExamType != 'TTE Adults'  && print.exam.ExamType != 'Duplex Carotid'">
                        <div style="width: fit-content;margin-inline-end: 0.5em;"><strong>{{'cardio.Findings.MaxExpectedHeartRate' | translate}}: </strong></div>
                        <div style="direction: ltr;width: fit-content;">{{print.cardioFindings.MaxExpectedHeartRate | number:'1.0-0' }} bpm</div>
                    </td> -->
                    <td class="flex"  *ngIf="print.cardioFindings.HeartRate">
                        <div style="width: fit-content;margin-inline-end: 0.5em;"><strong>{{'cardio.Findings.HeartRate' | translate}}: </strong> </div>
                        <div style="direction: ltr;width: fit-content;">{{print.cardioFindings.HeartRate | number:'1.0-0' }} bpm</div>
                    </td>
                    <!-- <td class="flex"  *ngIf="print.cardioFindings.MaxHeartRate">
                        <div style="width: fit-content;margin-inline-end: 0.5em;"><strong>{{'cardio.Findings.MaxHeartRate' | translate}}: </strong> </div>
                        <div style="direction: ltr;width: fit-content;">{{print.cardioFindings.MaxHeartRate | number:'1.0-0'}} bpm</div>
                    </td> -->
                    <td class="flex"  *ngIf="print.cardioFindings.Precentage && print.exam.ExamType != 'TTE Adults' ">
                        <div style="width: fit-content;margin-inline-end: 0.5em;"><strong>{{'cardio.Findings.Precentage' | translate}}: </strong> </div>
                        <div style="direction: ltr;width: fit-content;">{{print.cardioFindings.Precentage }}%</div>
                    </td>
                </tr>

                <tr *ngIf="print.exam.ExamType == 'Duplex Carotid' && print.cardioFindings">
                    <td>CAA LEFT</td>
                </tr>

                <tr style="margin-top: 1em;" *ngIf="print.cardioFindings.ECG || (print.cardioFindings.bpSystolicRest && print.cardioFindings.bpDiastolicRest) || (print.cardioFindings.bpSystolic && print.cardioFindings.bpDiastolic)">
                    <td class="flex">
                        <div class="section-text" *ngIf="print.cardioFindings.ECG">
                            <p class="comment"><strong>{{'cardio.ECG' | translate}}: </strong>{{print.cardioFindings.ECG}}</p>
                        </div>
                    </td>
                </tr>
                <tr class="comment" *ngIf="(print.exam.ExamType =='Stress-Echo' && print.cardioFindings.Medication)">
                    <div class="section-text" *ngIf="print.cardioFindings.Medication" style="margin-top: 1em;">
                        <p class="comment"><strong>{{'cardio.Medications' | translate}}: </strong> {{print.cardioFindings.Medication}}</p>
                    </div>
                </tr>
                <tr>
                    <div class="section-text" *ngIf="print.cardioFindings.Response">
                        <p class="comment"><strong>{{'cardio.Findings.Response' | translate}}: </strong> {{print.cardioFindings.Response}}</p>
                    </div>
                </tr>
                <tr>
                    <div class="section-text" *ngIf="print.cardioFindings.Arythmia">
                        <p class="comment"><strong>{{'cardio.Findings.Arythmia' | translate}}: </strong> {{print.cardioFindings.Arythmia}}</p>
                    </div>
                </tr>
                <tr>
                    <div class="section-text" *ngIf="print.cardioFindings.Conduction">
                        <p class="comment"><strong>{{'cardio.Findings.Conduction' | translate}}: </strong> {{print.cardioFindings.Conduction}}</p>
                    </div>
                </tr>
                <tr>
                    <div class="section-text" *ngIf="print.cardioFindings.ST">
                        <p class="comment"><strong>{{'cardio.Findings.ST' | translate}}: </strong> {{print.cardioFindings.ST}}</p>
                    </div>
                </tr>
                <tr>
                    <div class="section-text" *ngIf="print.cardioFindings.EndReason" style="margin-top: 1em;">
                        <p class="comment"><strong>{{'cardio.Findings.EndReason' | translate}}: </strong> {{print.cardioFindings.EndReason}}</p>
                    </div>
                </tr>
                <tr *ngIf="print.cardioFindings.PulseStatus || print.cardioFindings.PulseRate">
                    <td *ngIf="print.cardioFindings.PulseStatus"><strong>{{'cardio.Findings.PulseRate' | translate}}: </strong></td>
                    <td *ngIf="print.cardioFindings.PulseStatus">{{print.cardioFindings.PulseStatus}}</td>
                    <td style="direction: ltr;" *ngIf="print.cardioFindings.PulseStatus == 'יש לציין טכיקרדיה במנוחה' && print.cardioFindings.PulseRate">{{print.cardioFindings.PulseRate}} bpm</td>
                </tr>

            </table>

        </section>


        <section class="section" *ngIf="print.exam.ClosedDate || (print.summary && print.summary[0]  && (print.summary[0].AnomaliesStress || print.summary[0].Anomalies ||print.summary[0].ReferingDoc || print.summary[0].Quality || print.summary[0].AnomaliesList  || print.summary[0].Recomm || print.summary[0].Repeat1 || print.summary[0].FollowUpExam || print.summary[0].Diagnosis|| print.summary[0].Assessments || print.summary[0].Summary || (print.summary[0].LVEF && (print.cardio2D && print.cardio2D.LVEF)) ))">
            <h4 class="text-20 text-gold">{{'cardio.summary.title' | translate}}</h4>
            <section class="section" *ngIf="print.flowmeas && print.exam.ExamType=='Duplex Carotid'">
                <h4 class="text-20 text-gold">{{'cardio.Findings.title' | translate}}</h4>
                <table>
                    <tr height="100%" width="100%" style="vertical-align: top;">
                        <td width="45%" height="100%">
                            <section class="section" *ngIf="print.flowmeas.CCARight || print.flowmeas.ICARight  || print.flowmeas.ECARight || print.flowmeas.VARight">
                                <h4><strong>{{'cardio.Findings.flowmeas.Right' | translate}}</strong></h4>
                                <ng-container *ngFor="let item of ['CCA','ICA','ECA','VA']">
                                    <section class="section" *ngIf="print.flowmeas[item+'Right']">
                                        <span style="margin-inline-end: 5px;white-space: pre-wrap;unicode-bidi: plaintext;" ><strong>{{'cardio.Findings.flowmeas.'+item | translate}}:</strong></span>{{print.flowmeas[item+'Right']}}
                                    </section>
                                </ng-container>
                            </section>
                        </td>
                        <td width="45%" height="100%">
                            <section class="section"*ngIf="print.flowmeas.CCALeft || print.flowmeas.ICALeft || print.flowmeas.ECALeft || print.flowmeas.VALeft">
                                <h4><strong>{{'cardio.Findings.flowmeas.Left' | translate}}</strong></h4>
                                <ng-container *ngFor="let item of ['CCA','ICA','ECA','VA']">
                                    <section class="section" *ngIf="print.flowmeas[item+'Left']">
                                        <span style="margin-inline-end: 5px;white-space: pre-wrap;unicode-bidi: plaintext;"><strong>{{'cardio.Findings.flowmeas.'+item | translate}}:</strong></span>{{print.flowmeas[item+'Left']}}
                                    </section>
                                </ng-container>
                            </section>
                        </td>

                    </tr>
    
                </table>
            </section>
            <!-- <strong>{{'cardio.Findings.flowmeas.'+print.flowmeas['CCALeft']}}</strong>{{print.flowmeas['CCALeft']}} -->
            <table *ngIf="(print.summary && print.summary[0]) && ((print.summary && print.summary[0] && print.summary[0].LVEF) || (print.cardio2D && print.cardio2D.LVEF))">
                <tr *ngIf="print.summary && (print.summary[0].LVEF || (print.cardio2D && print.cardio2D.LVEF))">
                    <td class="w-1/2"><strong>{{'cardio.summary.LVEF.title' | translate}}: </strong></td> <td *ngIf="print.cardio2D && print.cardio2D.LVEF">{{'cardio.summary.LVEF.meas' | translate}}: {{print.cardio2D.LVEF}}%</td> <td>{{'cardio.summary.LVEF.estimated' | translate}}: {{print.summary[0].LVEF}}%</td>
                </tr>
            </table>
            <section class="section" *ngIf="print.summary && print.summary[0]  && print.summary[0].AnomaliesList && print.summary[0].AnomaliesList.length > 0">
                <div *ngIf="print.summary[0].AnomaliesList.length > 0" class="comment">
                    <ng-container *ngFor="let a of print.summary[0].AnomaliesList">{{a[1]}}<br/></ng-container>
                    <br>
                </div>
            </section>
            <section class="section comment" *ngIf="print.summary && print.summary[0] && print.summary[0].Anomalies"><strong *ngIf="print.exam.ExamType == 'Stress-Echo'">{{'cardio.summary.RestingEcho' | translate}}</strong><br/>  {{print.summary[0].Anomalies}}</section>
            <section class="section comment" *ngIf="print.summary && print.summary[0] && print.summary[0].AnomaliesStress &&  print.exam.ExamType == 'Stress-Echo' "><strong>{{'cardio.summary.StressEcho' | translate}} </strong><br/> {{print.summary[0].AnomaliesStress}}</section>
            <section class="section comment" *ngIf="print.summary && print.summary[0] && print.summary[0].Diagnosis">
                <div *ngIf="print.summary && print.summary[0] && print.summary[0].Diagnosis" class="comment" style='width: 100%;margin-top: 1em;'><strong>{{'cardio.Diagnosis' | translate}}:</strong> {{print.summary[0].Diagnosis}}</div>
            </section>
            <div *ngIf="print.summary &&  print.summary[0] && print.summary[0].Assessments" class="comment" style='width: 100%;margin-top: 1em;'><strong>{{'cardio.Assessments' | translate}}:</strong> {{print.summary[0].Assessments}}</div>
            <div *ngIf="print.summary &&  print.summary[0] && print.summary[0].Summary" class="comment" style='width: 100%;margin-top: 1em;'><strong>{{'cardio.Summary' | translate}}:</strong> {{print.summary[0].Summary}}</div>
            <section class="section" *ngIf="print.summary &&  print.summary[0] && print.summary[0].Recomm">
                <div *ngIf="print.summary &&  print.summary[0] && print.summary[0].Recomm" class="comment" style='width: 100%;margin-top: 1em;'><strong>{{'cardio.Recomm' | translate}}:</strong> {{print.summary[0].Recomm}}</div>
            </section>
            
            <div style="margin-top:1em; display: block;" *ngIf="print.exam.ClosedDate">
                <table *ngIf="print.signers ||(print.cardioFindings && print.cardioFindings.OtherStaff)">
                    <tr *ngIf="print.cardioFindings && print.cardioFindings.OtherStaff">
                        <td>
                            <strong>{{'cardio.summary.medicalTech' | translate}}: </strong> {{print.cardioFindings.OtherStaff}}
                        </td>
                    </tr>
                    <tr *ngFor="let s of print.signers">
                        <td *ngIf="s.Membership == 'tech'">
                            <strong>{{'cardio.summary.examTech' | translate}}: </strong> {{s.OpenerName }}
                        </td>
                    </tr>
                </table>
                <strong>{{'cardio.summary.signExam' | translate}} </strong>{{print.exam.TerminationRemarks}} {{print.exam.license ? 'מס רישיון רופא '  : ''}} {{print.exam.license ? print.exam.license : ''}}  ב {{print.exam.ClosedDate | date:"dd.MM.yyyy HH:mm"}}
            </div>
        </section>
        <section class="section" style="direction: rtl;"  *ngIf="print.exam.ExamType == 'TTE Adults'">
            <h4 style="text-align: right;">נבדק/ת יקר/ה</h4>
            <ul style="padding: 0 2em;text-align: right;">
                <li *ngIf="print.exam.ExamType == 'TTE Adults'">קריאת המידע הרפואי בדו"ח זה ללא ליווי של הרופא המטפל עלולה לגרום להבנת הכתוב באופן שגוי או חלקי. אנא פנה עם דו"ח זה לרופא המטפל אשר יסביר לך את משמעות תוצאות הבדיקה.</li>
                <li>מסמך זה הינו חסוי ומכיל מידע מוגן הרשום במאגר מידע לפי חוק הגנת הפרטיות, המוסרו שלא כדין עובר עבירה.</li>
            </ul>
        </section>


    </main>
</div>
