import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { GastroFormsService } from 'src/app/gastro-forms.service';

@Component({
  selector: 'app-patformsitem',
  templateUrl: './patformsitem.component.html',
  styleUrls: ['./patformsitem.component.scss']
})
export class PatformsitemComponent implements OnInit {
  @Input('type') type: UntypedFormControl;
  @Input('name') name;
  displayName;
  constructor(public gfs:GastroFormsService) { }

  ngOnInit() {
    this.displayName = this.gfs.getDisplay(this.name);
  }
  getClass(item):string{
    if(item !== null  && this.gfs.Form.get(this.type+'Note')  && this.gfs.Form.get(this.type+'Note').value )
      return 'type-v'
    return 'type-plus';
  }

}
