import { Component, OnInit } from '@angular/core';
import { LoginService } from '../login.service';
import {TranslateService} from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  user: string;
  pw: string;
  error: string;
  code: string;
  hidepw=true;
  typed=false;
  showLogin=false;
  currentDate = new Date();
  currentYear = this.currentDate.getFullYear();

  constructor(public loginService: LoginService, private translate: TranslateService,private http: HttpClient,) { 
    this.translate.use('en-US');//load tranlations for non prinouts - regular login
  }

  ngOnInit() {
  }

 checkLogin(){
    // this.loginService.totpVerify = true;
    if(! this.loginService.getCode)
    this.loginService.login(this.user,this.pw);
  else
    this.loginService.sendCode(this.code);
  }
  
  clean(){
    try{
      this.user = this.user.replace(/[^\x00-\x7F]+/, '')
      this.pw = this.pw.replace(/[^\x00-\x7F]+/, '')
    }
    catch{}
  }

  setClinic(index:number){
    this.loginService.setClinic(index);
  }
  getotp(){
      
      let resp = this.loginService.getTOTP(this.user,this.pw);
      resp.subscribe(async (data:any) =>{
        this.loginService.totpVerify = data;
        if(data['remote'] && data['TOTPSet']){
          try{
            const res= await this.http.get('http://localhost:8088/whereami').toPromise()
            if(res['office_server'] || res['location']){
                 this.checkLogin()
                return 
            }

          }
          catch(err){
            this.showLogin=true

          }

          
        }
        else if(data['TOTPSet']){
          this.showLogin = true;
        }else{
          this.checkLogin();
        }
      })
    

  }
  viewonly(){

  }

}
