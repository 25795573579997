<div class="body-wrapper rtl" *ngIf="loaded" style='padding-top: 0;max-width: 1500px;'>
    <header class="header">
        <div class="justify-between items-center" [innerHtml]="altHeader" *ngIf="altHeader && showHeader"
            style="width: 60%;"></div>
        <div class="justify-between items-center" *ngIf="cf.env && cf.env['haslogo'] && false">
            <img [src]="! cf.env['haslogo'] ? '../../assets/images/logo.png' : '/api/logo'" alt="Logo">
        </div>
        <div class="justify-between items-center" style="margin-bottom: 5px;">
            <h1 class="header-title">{{'exam.type.followup' | translate}}</h1>
            <img [src]="! cf.env['haslogo'] ? '../../assets/images/logo.png' : '/api/logo'" alt="Logo"
                class="header-logo" *ngIf="cf.env && ! altHeader && cf.env['haslogo']">
        </div>
        <div class="header-details">
            <div style="display: inline-block; padding-left: 1em;padding-right: 1em">
                {{'general.date' | translate}}:&nbsp;
                <strong style='direction: ltr;'>
                    {{cf.selcForm.CreationTime | date:"dd.MM.yyyy HH:mm"}}
                </strong>
            </div>
            <div style="display: inline-block">
                {{'general.Printing_Date' | translate}}:&nbsp;
                <strong style='direction: ltr;'>
                    {{now | date:"dd.MM.yyyy HH:mm"}}
                </strong>
            </div>
        </div>
    </header>
    <main class="main">
        <section class="section" *ngIf="cf.Pat">
            <h4 class="text-20 text-gold">
                {{'general.Patient.Patient_Details' | translate}}
            </h4>
            <div style="width:100%;">
                <div style="width: 100%;">
                    <div style="display: inline-flex;">
                        <table>
                            <tr>
                                <td>
                                    <dt>{{'general.Patient.first_name' | translate}}</dt>
                                    <dd>{{cf.Pat.First}}</dd>
                                </td>
                                <td>
                                    <dt>{{'general.Patient.last_name' | translate}}</dt>
                                    <dd>{{cf.Pat.Last}}</dd>
                                </td>
                                <td>
                                    <dt>{{'general.Patient.ID' | translate}}</dt>
                                    <dd>{{cf.Pat.ID}}</dd>
                                </td>
                                <td *ngIf="cf.Birth">
                                    <dt>{{'general.Patient.birth_date' | translate}}</dt>
                                    <dd>{{cf.Birth| date:"dd.MM.yyyy"}}</dd>
                                </td>
                                <td *ngIf="cf.Birth">
                                    <dt>{{'general.Patient.Age' | translate}}</dt>
                                    <dd>{{cf.Age}}</dd>
                                </td>
                                <td *ngIf="cf.Pat.Phone">
                                    <dt>{{'general.Patient.mobile' | translate}}</dt>
                                    <dd>{{cf.Pat.Phone}}</dd>
                                </td>
                                <td *ngIf="cf.Address">
                                    <dt>{{'general.Patient.address' | translate}}</dt>
                                    <dd>{{cf.Address}}</dd>
                                </td>
                            </tr>
                            <tr *ngIf="cf.selcForm.LMP">
                                <td *ngIf="cf.selcForm.LMP">
                                    <dt>{{'pregnancy.Last_Menstrual_Period.LMP' | translate}}</dt>
                                    <dd>{{cf.selcForm.LMP | date:"dd.MM.yyyy"}}</dd>
                                </td>
                                <td>
                                    <dt>{{'pregnancy.Last_Menstrual_Period.EDD' | translate}}</dt>
                                    <dd>{{cf.edd(false, cf.selcForm.LMP)}}</dd>
                                </td>
                                <td>
                                    <dt>{{'general.Patient.GA' | translate}}</dt>
                                    <dd>{{getLmpGA(cf.selcForm.LMP)}}</dd>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </section>

        <section *ngIf="cf.selcForm" style="margin-block-start: 20px;">
            <ng-container *ngIf="cf.selcForm.printAllFields">

                <table class="t-calendar formatted" *ngFor="let tri of cf.triArray;index as i"
                    style="border-collapse: collapse;border-spacing: 0 10px;margin-top: -10px;margin-bottom: 30px;break-inside: avoid-page;">

                    <tr class="formatted-title">{{'general.tri.'+tri.trianame | translate}}</tr>
                    <tr class="t-tr-calendar">
                        <td class="t-td-calendar" style="width: 5%; text-align: center; font-weight: 700;">חודש</td>
                        <td class="t-td-calendar" style="width: 22%; text-align: center; font-weight: 700;">שבוע</td>
                        <td class="t-td-calendar" style="width: 40%; text-align: center; font-weight: 700;">סוג בדיקה
                        </td>
                        <td class="t-td-calendar" style="width: 33%; text-align: center; font-weight: 700;">הערות</td>

                    </tr>
                    <tr *ngFor="let month of tri.months; index as j"
                        style="text-align-last: center; border-bottom: 1px solid grey; border-radius: 5px;">
                        <td style="width: 5%;">{{month.name}}</td>
                        <td style="width: 95%;padding-left: 0;">
                            <table class="w100" style="border-collapse: collapse;">
                                <tr class="t-tr-calendar" style="text-align: center;"
                                    *ngFor="let _ of [].constructor(month.weeks); index as i">
                                    <!-- <td class="t-td-calendar" style="width: 10%;">{{i+1}}</td> -->
                                    <td style="width: 90%;padding-left: 0;">
                                        <table class="w100 table-calendar">
                                            <tr style="border-bottom: 1px solid grey;">
                                                <!-- ========================== Month & Week Column (Start) ========================== -->
                                                <td class="t-td-calendar"
                                                    style="width: 30%;display: inline-flex;justify-content: space-around;">
                                                    <span>
                                                        {{cf.getWeek(month.name, i, tri.trianame)}}
                                                    </span>
                                                    <!-- Week Column -->
                                                    <span>
                                                        {{(cf.selcForm.LMP  ? 'general.startFrom': '') |
                                                        translate}}{{cf.getDateByWeek(cf.getWeek(month.name, i,
                                                        tri.trianame), cf.selcForm.LMP ) | date:"dd/MM/yyyy"}}
                                                    </span>
                                                </td>
                                                <!-- ========================== Month & Week Column (End) ========================== -->

                                                <!-- ========================== Exam Type Column (Start) ========================== -->
                                                <td class="t-td-calendar" style="width: 30%;">
                                                    <span
                                                        *ngIf="cf.selcForm['week'+cf.getWeek(month.name, i, tri.trianame)]">
                                                        {{(OB.includes(cf.selcForm['week'+cf.getWeek(month.name, i,
                                                        tri.trianame)]) ?
                                                        ('exam.type.OBS.'+cf.selcForm['week'+cf.getWeek(month.name, i,
                                                        tri.trianame)]) : cf.selcForm['week'+cf.getWeek(month.name, i,
                                                        tri.trianame)]) | translate}}
                                                        <!-- {{'exam.type.OBS.'+cf.selcForm['week'+cf.getWeek(month.name, i, tri.trianame)] | translate}} -->
                                                    </span>
                                                </td>
                                                <!-- ========================== Exam Type Column (End) ========================== -->

                                                <!-- ========================== Comments Column (Start) ========================== -->
                                                <td class="t-td-calendar" style="width: 40%;">
                                                    <span
                                                        *ngIf="cf.selcForm['week'+cf.getWeek(month.name, i, tri.trianame)+'Comment']">
                                                        {{cf.selcForm['week'+cf.getWeek(month.name, i,
                                                        tri.trianame)+'Comment']}}
                                                    </span>
                                                </td>
                                                <!-- ========================== Comments Column (End) ========================== -->
                                            </tr>
                                        </table>

                                    </td>
                                </tr>
                            </table>


                        </td>
                    </tr>
                    <tr>
                        <section class="section" *ngIf="cf.selcForm[tri.trianame+'Tri']">
                            <span class="comment">
                                <strong>
                                    {{'general.Comments' | translate}}:
                                </strong>
                                {{cf.selcForm[tri.trianame+'Tri']}}
                            </span>
                        </section>
                    </tr>
                </table>
            </ng-container>

            <ng-container *ngIf="!cf.selcForm.printAllFields">
                <ng-container *ngFor="let tri of cf.triArray; index as i;">

                    <table #firstTable class="t-calendar formatted"
                        *ngIf="!checkFirstTable() && i === 0" style="border-collapse: collapse; 
                    border-spacing: 0 10px; 
                    margin-top: -10px; 
                    margin-bottom: 30px; 
                    break-inside: avoid-page;">

                        <tr class="formatted-title">{{'general.tri.first' | translate}}</tr>
                        <tr class="t-tr-calendar">
                            <td class="t-td-calendar" style="width: 5%; text-align: center; font-weight: 700;">חודש</td>
                            <td class="t-td-calendar" style="width: 22%; text-align: center; font-weight: 700;">שבוע
                            </td>
                            <td class="t-td-calendar" style="width: 40%; text-align: center; font-weight: 700;">סוג
                                בדיקה
                            </td>
                            <td class="t-td-calendar" style="width: 33%; text-align: center; font-weight: 700;">הערות
                            </td>

                        </tr>
                        <tr *ngFor="let month of tri.months; index as j"
                            style="text-align-last: center; border-bottom: 1px solid grey; border-radius: 5px;">
                            <td style="width: 5%;">{{month.name}}</td>
                            <td style="width: 95%;padding-left: 0;">
                                <table class="w100" style="border-collapse: collapse;">
                                    <tr class="t-tr-calendar" style="text-align: center;"
                                        *ngFor="let _ of [].constructor(month.weeks); index as i">
                                        <td style="width: 90%;padding-left: 0;">
                                            <table class="w100 table-calendar">
                                                <tr style="border-bottom: 1px solid grey;">
                                                    <!-- ========================== Month & Week Column (Start) ========================== -->
                                                    <td class="t-td-calendar"
                                                        style="width: 30%; display: inline-flex; justify-content: space-around;">
                                                        <!-- Month Column -->
                                                        <span>
                                                            {{cf.getWeek(month.name, i, 'first')}}
                                                        </span>
                                                        <!-- Week Column -->
                                                        <span>
                                                            {{(cf.selcForm.LMP ? 'general.startFrom': '') |
                                                            translate}}{{cf.getDateByWeek(cf.getWeek(month.name, i,
                                                            'first'), cf.selcForm.LMP) | date:"dd/MM/yyyy"}}
                                                        </span>
                                                    </td>
                                                    <!-- ========================== Month & Week Column (End) ========================== -->

                                                    <!-- ========================== Exam Type Column (Start) ========================== -->
                                                    <td class="t-td-calendar" style="width: 30%;">
                                                        <span
                                                            *ngIf="cf.selcForm['week'+cf.getWeek(month.name, i, 'first')]">
                                                            {{(OB.includes(cf.selcForm['week'+cf.getWeek(month.name, i,
                                                            'first')]) ?
                                                            ('exam.type.OBS.'+cf.selcForm['week'+cf.getWeek(month.name,
                                                            i,
                                                            'first')]) : cf.selcForm['week'+cf.getWeek(month.name, i,
                                                            'first')]) | translate}}
                                                        </span>
                                                    </td>
                                                    <!-- ========================== Exam Type Column (End) ========================== -->

                                                    <!-- ========================== Comments Column (Start) ========================== -->
                                                    <td class="t-td-calendar" style="width: 40%;">
                                                        <span
                                                            *ngIf="cf.selcForm['week'+cf.getWeek(month.name, i, 'first')+'Comment']">
                                                            {{cf.selcForm['week'+cf.getWeek(month.name, i,
                                                            'first')+'Comment']}}
                                                        </span>
                                                    </td>
                                                    <!-- ========================== Comments Column (End) ========================== -->
                                                </tr>
                                            </table>

                                        </td>
                                    </tr>
                                </table>

                            </td>
                        </tr>
                        <!-- ========================== Trimester Comments Column (Start) ========================== -->
                        <tr>
                            <section class="section" *ngIf="cf.selcForm['first'+'Tri']">
                                <span class="comment">
                                    <strong>
                                        {{'general.Comments' | translate}}:
                                    </strong>
                                    {{cf.selcForm['first'+'Tri']}}
                                </span>
                            </section>
                        </tr>
                        <!-- ========================== Trimester Comments Column (End) ========================== -->
                    </table>

                    <table #secondTable class="t-calendar formatted"
                        *ngIf="!checkSecondTable() && i === 1"
                        style="border-collapse: collapse; border-spacing: 0 10px; margin-top: -10px; margin-bottom: 30px; break-inside: avoid-page;">
                        <tr class="formatted-title">{{'general.tri.second' | translate}}</tr>
                        <tr class="t-tr-calendar">
                            <td class="t-td-calendar" style="width: 5%; text-align: center; font-weight: 700;">חודש</td>
                            <td class="t-td-calendar" style="width: 22%; text-align: center; font-weight: 700;">שבוע
                            </td>
                            <td class="t-td-calendar" style="width: 40%; text-align: center; font-weight: 700;">סוג
                                בדיקה
                            </td>
                            <td class="t-td-calendar" style="width: 33%; text-align: center; font-weight: 700;">הערות
                            </td>

                        </tr>
                        <tr *ngFor="let month of tri.months; index as j"
                            style="text-align-last: center; border-bottom: 1px solid grey; border-radius: 5px;">
                            <td style="width: 5%;">{{month.name}}</td>
                            <td style="width: 95%;padding-left: 0;">
                                <table class="w100" style="border-collapse: collapse;">
                                    <tr class="t-tr-calendar" style="text-align: center;"
                                        *ngFor="let _ of [].constructor(month.weeks); index as i">
                                        <td style="width: 90%;padding-left: 0;">
                                            <table class="w100 table-calendar">
                                                <tr style="border-bottom: 1px solid grey;">
                                                    <!-- ========================== Month & Week Column (Start) ========================== -->
                                                    <td class="t-td-calendar"
                                                        style="width: 30%; display: inline-flex; justify-content: space-around;">
                                                        <span>
                                                            {{cf.getWeek(month.name, i, 'second')}}
                                                        </span>
                                                        <span>
                                                            {{(cf.selcForm.LMP ? 'general.startFrom': '') |
                                                            translate}}{{cf.getDateByWeek(cf.getWeek(month.name, i,
                                                            'second'), cf.selcForm.LMP) | date:"dd/MM/yyyy"}}
                                                        </span>
                                                    </td>
                                                    <!-- ========================== Month & Week Column (End) ========================== -->

                                                    <!-- ========================== Exam Type Column (Start) ========================== -->
                                                    <td class="t-td-calendar" style="width: 30%;">
                                                        <span
                                                            *ngIf="cf.selcForm['week'+cf.getWeek(month.name, i, 'second')]">
                                                            {{(OB.includes(cf.selcForm['week'+cf.getWeek(month.name, i,
                                                            'second')]) ?
                                                            ('exam.type.OBS.'+cf.selcForm['week'+cf.getWeek(month.name,
                                                            i,
                                                            'second')]) : cf.selcForm['week'+cf.getWeek(month.name, i,
                                                            'second')]) | translate}}
                                                        </span>
                                                    </td>
                                                    <!-- ========================== Exam Type Column (End) ========================== -->

                                                    <!-- ========================== Comments Column (Start) ========================== -->
                                                    <td class="t-td-calendar" style="width: 40%;">
                                                        <span
                                                            *ngIf="cf.selcForm['week'+cf.getWeek(month.name, i, 'second')+'Comment']">
                                                            {{cf.selcForm['week'+cf.getWeek(month.name, i,
                                                            'second')+'Comment']}}
                                                        </span>
                                                    </td>
                                                    <!-- ========================== Comments Column (End) ========================== -->
                                                </tr>
                                            </table>

                                        </td>
                                    </tr>
                                </table>

                            </td>
                        </tr>
                        <!-- ========================== Trimester Comments Column (Start) ========================== -->
                        <tr>
                            <section class="section" *ngIf="cf.selcForm['second'+'Tri']">
                                <span class="comment">
                                    <strong>
                                        {{'general.Comments' | translate}}:
                                    </strong>
                                    {{cf.selcForm['second'+'Tri']}}
                                </span>
                            </section>
                        </tr>
                        <!-- ========================== Trimester Comments Column (End) ========================== -->
                    </table>
                    <table #thirdTable class="t-calendar formatted"
                        *ngIf="!checkThirdTable() && i === 2" style="border-collapse: collapse; 
                    border-spacing: 0 10px; 
                    margin-top: -10px; 
                    margin-bottom: 30px; 
                    break-inside: avoid-page;">

                        <tr class="formatted-title">{{'general.tri.third' | translate}}</tr>
                        <tr class="t-tr-calendar">
                            <td class="t-td-calendar" style="width: 5%; text-align: center; font-weight: 700;">חודש</td>
                            <td class="t-td-calendar" style="width: 22%; text-align: center; font-weight: 700;">שבוע
                            </td>
                            <td class="t-td-calendar" style="width: 40%; text-align: center; font-weight: 700;">סוג
                                בדיקה
                            </td>
                            <td class="t-td-calendar" style="width: 33%; text-align: center; font-weight: 700;">הערות
                            </td>

                        </tr>
                        <tr *ngFor="let month of tri.months; index as j"
                            style="text-align-last: center; border-bottom: 1px solid grey; border-radius: 5px;">
                            <td style="width: 5%;">{{month.name}}</td>
                            <td style="width: 95%;padding-left: 0;">
                                <table class="w100" style="border-collapse: collapse;">
                                    <tr class="t-tr-calendar" style="text-align: center;"
                                        *ngFor="let _ of [].constructor(month.weeks); index as i">
                                        <!-- <td class="t-td-calendar" style="width: 10%;">{{i+1}}</td> -->
                                        <td style="width: 90%;padding-left: 0;">
                                            <table class="w100 table-calendar">
                                                <tr style="border-bottom: 1px solid grey;">
                                                    <!-- ========================== Month & Week Column (Start) ========================== -->
                                                    <td class="t-td-calendar"
                                                        style="width: 30%; display: inline-flex; justify-content: space-around;">
                                                        <!-- Month Column -->
                                                        <span>
                                                            {{cf.getWeek(month.name, i, 'third')}}
                                                        </span>
                                                        <!-- Week Column -->
                                                        <span>
                                                            {{(cf.selcForm.LMP ? 'general.startFrom': '') |
                                                            translate}}{{cf.getDateByWeek(cf.getWeek(month.name, i,
                                                            'third'), cf.selcForm.LMP) | date:"dd/MM/yyyy"}}
                                                        </span>
                                                    </td>
                                                    <!-- ========================== Month & Week Column (End) ========================== -->

                                                    <!-- ========================== Exam Type Column (Start) ========================== -->
                                                    <td class="t-td-calendar" style="width: 30%;">
                                                        <span
                                                            *ngIf="cf.selcForm['week'+cf.getWeek(month.name, i, 'third')]">
                                                            {{(OB.includes(cf.selcForm['week'+cf.getWeek(month.name, i,
                                                            'third')]) ?
                                                            ('exam.type.OBS.'+cf.selcForm['week'+cf.getWeek(month.name,
                                                            i,
                                                            'third')]) : cf.selcForm['week'+cf.getWeek(month.name, i,
                                                            'third')]) | translate}}

                                                            <!-- {{'exam.type.OBS.'+cf.selcForm['week'+cf.getWeek(month.name, i, tri.trianame)] | translate}} -->
                                                        </span>
                                                    </td>
                                                    <!-- ========================== Exam Type Column (End) ========================== -->

                                                    <!-- ========================== Comments Column (Start) ========================== -->
                                                    <td class="t-td-calendar" style="width: 40%;">
                                                        <span
                                                            *ngIf="cf.selcForm['week'+cf.getWeek(month.name, i, 'third')+'Comment']">
                                                            {{cf.selcForm['week'+cf.getWeek(month.name, i,
                                                            'third')+'Comment']}}
                                                        </span>
                                                    </td>
                                                    <!-- ========================== Comments Column (End) ========================== -->
                                                </tr>
                                            </table>

                                        </td>
                                    </tr>
                                </table>

                            </td>
                        </tr>
                        <!-- ========================== Trimester Comments Column (Start) ========================== -->
                        <tr>
                            <section class="section" *ngIf="cf.selcForm['third'+'Tri']">
                                <span class="comment">
                                    <strong>
                                        {{'general.Comments' | translate}}:
                                    </strong>
                                    {{cf.selcForm['third'+'Tri']}}
                                </span>
                            </section>
                        </tr>
                        <!-- ========================== Trimester Comments Column (End) ========================== -->
                    </table>
                </ng-container>
            </ng-container>
            <section class="section" *ngIf="cf.selcForm && cf.selcForm.plannerComment">
                <span class="comment">
                    <strong>
                        {{'general.PlannerComments' | translate}}:
                    </strong>
                    {{cf.selcForm.plannerComment}}
                </span>
            </section>

        </section>
        <section class="section">
            <table>
                <tr *ngFor="let signer of cf.signers;let i = index">
                    <td class="w-1/3">
                        <strong>
                            {{(signer['Title'] ? signer['Title']+ ' ': '')+ signer.Name}}
                        </strong>
                    </td>
                    <td class="w-1/3">
                        <ng-container *ngIf="signer.DrNum">
                            מ.ר.
                            <strong>
                                {{signer.DrNum}}
                            </strong>
                        </ng-container>
                        <ng-container *ngIf="signer.ExpertNum">
                            מ.ר.מ.
                            <strong>
                                {{signer.ExpertNum}}
                            </strong>
                        </ng-container>
                    </td>
                    <td class="w-1/3" *ngIf="i == 0">
                        {{'general.date' | translate}}:
                        <strong>
                            {{cf.selcForm.CreationTime | date:"dd.MM.yyyy"}}
                        </strong>
                    </td>
                </tr>
            </table>
        </section>
        <span class="finished" *ngIf="loaded"></span>

    </main>
</div>