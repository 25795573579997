<div class="t-popup popup-patientDetails">

    <div class="t-popup-close"></div>
    
    <div class="t-popup-frame">
        <div class="t-popup-body" style="direction: rtl">

            <button class="t-btn-popup-close" aria-label="close popup" (click)="closed.next()"></button>

            <h2 style="margin-right: 3em">
                תוצאת בדיקה זכאות
            </h2>
            <!-- box (end) -->

            <div class="l-flex l-flex-w t-fieldmargin patient-details" style="direction: rtl">
                <div class="w33">

                    <div class="l-flex l-flex-c t-field t-field-pad">

                        <label>
                            ת.ז.
                        </label>

                        <i class="t-sep"></i>

                    {{tz}}
                        
                    </div>
                    
                </div>
                <!-- cell ( end ) -->

                <!-- cell ( start ) -->
                <div class="w66">

                    <div class="l-flex l-flex-c t-field t-field-pad">

                        <label>
                            שם
                        </label>

                        <i class="t-sep"></i>

                    {{name}}
                        
                    </div>
                    
                </div>
                <ng-container *ngIf="show">
                    <div class="w100">
                        <label>
                        זכאות לבדיקה
                        </label>
                        <i class="t-sep"></i>
                        {{approved ? 'כן': 'לא'}}
                    </div>
                    <div class="w100" *ngIf="note">
                        {{note}}
                    </div>
                    <div class="w100" *ngIf="code">
                        {{code}}
                    </div>
                    <div class="w100" *ngIf="approved">
                        <label>
                        סכום לתשלום
                        </label>
                        <i class="t-sep"></i>
                        {{payment}}
                        ש"ח
                    </div>
                    <div class="w100" *ngIf="approved && payment && payment > 0">
                        <label>
                        אופן תשלום
                        </label>
                        <i class="t-sep"></i>
                        {{macabipay? 'חויב בהוראת קבע' : 'לגביה'}}
                    </div>
                </ng-container>

            </div>
        </div>
    </div>
</div>
