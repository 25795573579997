<div class="w100 l-flex-w summary" [formGroup]="procedureForm" *ngIf="es.ExamType =='CVS' || es.ExamType =='Amniocentesis'">

    <div class="h100 w100 l-flex-w l-flex t-boxMargin--spacebyPadding placenta-table placenta-panel">
        <div class="l-fg1 placenta-box placenta-biophsical" style="height: auto;width: fit-content;" *ngIf="es.ExamType =='CVS'">
            <div class="m-box" style="min-height: 5em;">

                <h2>
                    Indicative Way of Examination
                </h2>
    
                <div class="l-flex-c l-flex-w indicative-fieldset h100">
                    
                    <label class="t-label-top">
    
                        <input type="checkbox" formControlName="Abdominal" class="custom-check -sq"/>
                        <i class="customInput-display -sq"></i>
                        <span>
                            Abdominal
                        </span>
    
                    </label>
    
                    <label class="t-label-top">
    
                        <input type="checkbox"  formControlName="Vaginal" class="custom-check -sq"/>
                        <i class="customInput-display -sq"></i>
                        <span>
                            Vaginal
                        </span>
    
                    </label>
    
                    <label class="t-label-top">
    
                        <input type="checkbox" formControlName="Transperineal" class="custom-check -sq"/>
                        <i class="customInput-display -sq"></i>
                        <span>
                            Transperineal
                        </span>
    
                    </label>
                    
                    <label class="t-label-top">
    
                        <input type="checkbox" formControlName="Rectal" class="custom-check -sq"/>
                        <i class="customInput-display -sq"></i>
                        <span>
                            Rectal
                        </span>
    
                    </label>
                                                                                                        
                </div>
                
            </div>
        </div>
        <div class="l-fg1 placenta-box placenta-biophsical" [ngClass]="es.ExamType != 'CVS' ? 'maxW' : ''" style="height: auto;">
            <div class="m-box" [formGroup]="medS.Medication">
                <h2>Administered Medications</h2>
                    <div class="l-fg1 repeatExamination-text" style="justify-content: space-between;display: inline-table;font-size: 1.15em;width: 100%;"  >
                        <div style="width: 100%;" >
                            <app-geninput [form]="medS.Medication.get('Medication')"></app-geninput>
                            <label style="margin-inline-start: 1em;">Dose</label> 
        
                            <span class="t-highlight-glowingCircle" style="margin-block-end: 1em;">
                                <input type="number" min="1" max="40"  style='width: 2em; background: none; border: none; color: white; font-size: 1.1em' formControlName="Dose" name="Dose">
                            </span>
                            <label style="margin-inline-end: 1em;">Unit</label>
                            <select class="t-select t-field" formControlName="Unit" style="padding-right: 1em;margin-block-end: 1em;">
                                <!-- <option></option> -->
                                <option value="ml">ml</option>
                                <option value="mg">mg</option>
                                <option value="mg/kg">mg/kg</option>
                                <option value="ml/kg">ml/kg</option>
                            </select>
                            <button class="btnAdd" (click)="medS.saveNew();" [disabled]="es.isClosed">Add</button>
        
                        </div>
                    </div>
                <section style="height: auto;" *ngIf="medS.patMedication && medS.patMedication.length >0">
                    <table class="weightAss-table" style="width: 100%">
                        <tr class="weightAss-thead" style="font-weight: 700;">
                            <td class="l-td-m">#</td>
                            <td class="l-td-m">Name</td>
                            <td class="l-td-m">Dose</td>
                            <td class="l-td-m">Unit</td>
                            <td class="l-td-m"></td>
                        </tr>
                        <ng-container *ngIf="medS.patMedication">
                            <tr class="weightAss-tbody" *ngFor="let med of medS.patMedication; index as i">
                                <td class="l-td-m">{{i+1}}</td>
                                <td class="l-td-m">{{med.Medication}}</td>
                                <td class="l-td-m">{{med.Dose}}</td>
                                <td class="l-td-m">{{med.Unit}}</td>
                                <td class="l-td-m"><span class="linked-radios-duration"><img src="../../assets/icons/del.svg"
                                    matTooltip="Delete"
                                    (click)="medS.deleteMed(med.AutoIndex)"></span></td>
                            </tr>
                        </ng-container>
                    </table>
                </section>
            </div>
        </div>
        <div class="l-fg1 w100 placenta-biophsical">
            <section class="m-box">
                <h2>
                    Preparetion
                </h2>
                <div class="l-flex-w placenta-biophsical-table" style="justify-content: space-between;">
                    <div style="margin-inline-end: 1em;align-self: center;">
                        <label style="margin-inline-end: 0.5em;">Needle Size</label>
                        <select class="t-select t-field menstural-field" formControlName="Needle">
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                            <option value="21">21</option>
                        </select>
                        <label style="margin-inline-start: 0.5em;">fr</label>
                    </div>
                    <div style="place-self: center;">
                        <label style="margin-inline-end: 0.5em;">Repeat</label>
                        <select class="t-select t-field menstural-field" formControlName="Tries">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                        </select>
                        <label style="margin-inline-start: 1em;">times</label>
                    </div>
                    <div class="t-label-top">
                        <label id="amorfic_f">
                            US Guidance
                        </label>
                        <app-genradio formname="USGiudance" [formgroup]="procedureForm" [value1]="2" [value2]="0" lname="Yes" rname="No" [resetval]="-1"></app-genradio>
                    </div>
                    <label class="t-label-top" *ngIf="es.ExamType == 'CVS'" >
                        <span>Amount of villi sampeld </span>

                        <span class="t-field t-field--number">
                            <input class="t-field-text" formControlName="FluidSample" type="text">
                            <span class="t-field-desc">cc</span>
                        </span>
                    </label>
                    <div class="l-flex-w w100" *ngIf="es.ExamType == 'Amniocentesis'">
                        <label class="t-label-top" >
                            <span>Amount of fluid sampled </span>
    
                            <span class="t-field t-field--number">
                                <input class="t-field-text" formControlName="FluidSample" type="number">
                                <span class="t-field-desc">ml</span>
    
                            </span>
                        </label>
                        <label class="t-label-top">
                            <span>Type</span>
                            <select  class="t-select t-field menstural-field" formControlName="Type">
                                <option value="דם">דם</option>
                                <option value="מי שפיר">מי שפיר</option>
                            </select>
                        </label>
                        <label class="t-label-top">
                            <span>Fluid Color</span>
                            <div class="l-flex-c l-flex-w l-justify placenta-row">
                                <label class="t-label-top">
                                    <input class="custom-check" type="radio" value="צלול" formControlName="FluidColor" name="FluidColor">
                                    <i class="customInput-display"></i>
                                    <span>צלול</span>
                                </label>
                                <label class="t-label-top">
                                    <input class="custom-check" type="radio" value="עכור" formControlName="FluidColor" name="FluidColor">
                                    <i class="customInput-display"></i>
                                    <span>עכור</span>
                                </label>
                                <label class="t-label-top">
                                    <input class="custom-check" type="radio" value="בוצי" formControlName="FluidColor" name="FluidColor">
                                    <i class="customInput-display"></i>
                                    <span>בוצי</span>
                                </label>
                                <label class="t-label-top">
                                    <input class="custom-check" type="radio" value="דמי" formControlName="FluidColor" name="FluidColor">
                                    <i class="customInput-display"></i>
                                    <span>דמי</span>
                                </label>
                            </div>
                        </label>
                    </div>


                </div>
            </section>
        </div>
        <div class="l-fg1 placenta-box placenta-biophsical">
            <section class="m-box">
                <h2>Procedure preformed by</h2>
                <textarea class="scroller scroller-intro fulltext" formControlName="preformedby" style="height: 100%;"></textarea>
                <button class="t-btn-edit" *ngIf="! es.isClosed" (click)="atS.showAuto(procedureForm.get('preformedby'),'Precudere preformed by',2)">Auto
                    Text</button>
            </section>
        </div>
    </div>
</div>


<div class="w100 l-flex-w summary" *ngIf="es.ExamType == 'Follicles'" style="height: auto;">
    <div class="m-box w45" style="margin: 0;height: auto;">
        <h2>Left Ovary</h2>
        <div class="l-flex-c l-flex-w l-justify placenta-row" [formGroup]="FolliclesForm">
            <label class="t-label-top">
                <span (click)="getOvaries('X')" matTooltip="Order By X index">X</span>
                <span class="t-field t-field--number">
                    <input class="t-field-text" formControlName="X" type="text"/>
                    <span class="t-field-desc">
                        mm
                    </span>
                </span>
            </label>
            <label class="t-label-top">
                <span (click)="getOvaries('Y')" matTooltip="Order By Y index">Y</span>
                <span class="t-field t-field--number">
                    <input class="t-field-text" formControlName="Y" type="text"/>
                    <span class="t-field-desc">
                        mm
                    </span>
                </span>
            </label>            
            <label class="t-label-top">
                <span (click)="getOvaries('Z')" matTooltip="Order By Z index">Z</span>
                <span class="t-field t-field--number">
                    <input class="t-field-text" formControlName="Z" type="text"/>
                    <span class="t-field-desc">
                        mm
                    </span>
                </span>
            </label>
            <label class="t-label-top l-flex t-boxMargin menstural-fields-set">
                <button class="l-flex t-boxMargin menstural-info btnAdd" (click)="saveFolicals('Left')">Add</button>
            </label>

        </div>
        <hr *ngIf="pr.LeftOvary.length > 0">
        <table class="w100">
            <tr class="ovary-follicles-tr" *ngFor="let item of pr.LeftOvary;index as i" (dblclick)="deleteItem=item;toDelete=true">
                <label class="w5"><strong>{{i+1}})</strong></label>
                <label class="w20"><strong>X</strong>: {{item.Xindex}}mm</label>
                <label class="w20"><strong>Y</strong>: {{item.Yindex}}mm</label>
                <label class="w20"><strong>Z</strong>: {{item.Zindex}}mm</label>
                <label class="w20"><strong>AVG</strong>: {{item.AVG}}mm</label>
            </tr>
        </table>
        
    </div>
    <div class="m-box w45" style="margin-inline-start: 0.5em;height: auto;">
        <h2>Right Ovary</h2>
        <div class="l-flex-c l-flex-w l-justify placenta-row" [formGroup]="FolliclesForm">
            <label class="t-label-top">
                <span (click)="getOvaries('X')" matTooltip="Order By X index">X</span>
                <span class="t-field t-field--number">
                    <input class="t-field-text" formControlName="X" type="text"/>
                    <span class="t-field-desc">
                        mm
                    </span>
                </span>
            </label>
            <label class="t-label-top">
                <span (click)="getOvaries('Y')" matTooltip="Order By Y index">Y</span>
                <span class="t-field t-field--number">
                    <input class="t-field-text" formControlName="Y" type="text"/>
                    <span class="t-field-desc">
                        mm
                    </span>
                </span>
            </label>            
            <label class="t-label-top">
                <span (click)="getOvaries('Z')" matTooltip="Order By Z index">Z</span>
                <span class="t-field t-field--number">
                    <input class="t-field-text" formControlName="Z" type="text"/>
                    <span class="t-field-desc">
                        mm
                    </span>
                </span>
            </label>
            <label class="t-label-top  l-flex t-boxMargin menstural-fields-set">
                <button class="l-flex t-boxMargin menstural-info btnAdd" (click)="saveFolicals('Right')">Add</button>
            </label>
        </div>
        <hr *ngIf="pr.RightOvary.length > 0">
        <table  class="w100">
            <tr class="ovary-follicles-tr" *ngFor="let item of pr.RightOvary;index as i" (dblclick)="deleteItem=item;toDelete=true">
                <label class="w5"><strong>{{i+1}})</strong></label>
                <label class="w20"><strong>X</strong>: {{item.Xindex}}mm</label>
                <label class="w20"><strong>Y</strong>: {{item.Yindex}}mm</label>
                <label class="w20"><strong>Z</strong>: {{item.Zindex}}mm</label>
                <label class="w20"><strong>AVG</strong>: {{item.AVG}}mm</label>
            </tr>
        </table>
    </div>
    <div class="t-popup popup-patientDetails" *ngIf="toDelete" style='display: block'>

        <div class="t-popup-close"></div>

        <div class="t-popup-frame">
            <div class="t-popup-body">

                <button class="t-btn-popup-close" aria-label="close popup"
                    (click)="toDelete = false;readydelete = false"></button>

                <h2 style='color: #D7A95B'>
                    Delete {{deleteItem.Ovary}} ovary measurment from {{deleteItem.CreationTime | date:"HH:mm"}}?
                </h2>
                <!-- box (end) -->
                <div *ngIf="! readydelete">
                    <button (click)="readydelete = true">yes</button> <button
                        (click)="toDelete = false;readydelete = false" style='margin-left: 2em'>no</button>
                </div>
                <div *ngIf="readydelete">
                    WARNING data will we lost!<br>
                    <button (click)="pr.delete(deleteItem);toDelete = false;readydelete = false">OK</button> <button (click)="toDelete = false;readydelete = false"
                        style='margin-left: 2em'>Cancel</button>
                </div>


            </div>
        </div>

    </div>

</div>
