import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, Renderer2 } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CommerrorService } from '../commerror.service';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-totpbox',
  templateUrl: './totpbox.component.html',
  styleUrls: ['./totpbox.component.scss']
})
export class TotpboxComponent implements OnInit, OnDestroy,AfterViewInit {
  @Output() closed = new EventEmitter<null>();
  @Output() login = new EventEmitter<null>();
  @Input('uname') uname: String;
  @Input('pw') pw: String;
  totpCode=true;

  @ViewChild('1') inputElFirst: ElementRef | undefined;
  @ViewChild('2') inputElSecond: ElementRef | undefined;
  totpForm;
  imageUrl: any = {};
  needs_qr: boolean = false;
  sub = new Subscription();
  user;
  Birth;
  ID;
  Email;
  Phone;
  fillq = true;
  maxBD:Date;
  startBD:Date;
  constructor(private fb:UntypedFormBuilder, private http:HttpClient, public loginService:LoginService,private renderer: Renderer2, public ce:CommerrorService) {
    this.maxBD = new Date();
    this.maxBD.setFullYear(this.maxBD.getFullYear() - 12);
    this.startBD = new Date();
    this.startBD.setFullYear(this.startBD.getFullYear() - 22);
   }

  ngOnInit() {
    this.resetForm();
    this.http.post('/api/login/getUser', {UserName: this.uname, PW: this.pw}).subscribe((data) => {
      if(data){
        this.user = data;
        if(! data['TOTPSecret']){
          this.http.get(`/api/login/getQrCode/${data['UID']}`, { responseType: 'text' }).subscribe((data) => {
            this.imageUrl = data
          });
        }
        this.needs_qr = ! data['TOTPSecret']
        if(!data['fillQuestions']){
          this.fillq = false;
          this.totpCode = false;
        }
      }
      
    })
  }
  ngAfterViewInit(): void {
    this.inputElFirst.nativeElement.focus();
}
  resetForm(){
    this.totpForm = new UntypedFormGroup({
      totp1: new UntypedFormControl(null,[Validators.maxLength(1), Validators.max(9), Validators.min(0)] ),
      totp2: new UntypedFormControl(null,[Validators.maxLength(1), Validators.max(9), Validators.min(0)] ),
      totp3: new UntypedFormControl(null,[Validators.maxLength(1), Validators.max(9), Validators.min(0)] ),
      totp4: new UntypedFormControl(null,[Validators.maxLength(1), Validators.max(9), Validators.min(0)] ),
      totp5: new UntypedFormControl(null,[Validators.maxLength(1), Validators.max(9), Validators.min(0)] ),
      totp6: new UntypedFormControl(null,[Validators.maxLength(1), Validators.max(9), Validators.min(0)] ),
    })
  }
  submit(){
    if(this.totpCode){

      var totp = '';
      for(let key in this.totpForm.controls){
        totp += this.totpForm.get(key).value;
      }
      this.http.post('/api/login/verifyTotp',{user:this.user,totp:totp}).subscribe((data:any) => {
        this.loginService.totpVerify = data;
        if(data){
          this.login.emit();
          this.closed.emit()
        }else{
          this.ce.error("Wrong TOTP inserted")
          this.resetForm();
          this.inputElFirst.nativeElement.focus();
          }
        })
    }else{
      var days = new Date(this.Birth).getDate()
      var months = new Date(this.Birth).getMonth() +1
      var year = new Date(this.Birth).getFullYear()

      var date = year+"-"+months+"-"+days;
      this.http.post("/api/login/question" ,{birth: date, email:this.Email, ID:this.ID,phone:this.Phone, user:this.user}).subscribe((data:any)=>{
        this.loginService.totpVerify = data;
        if(data){
          this.login.emit();
          this.closed.emit()
        }else{
          if(!this.fillq)
            this.closed.emit()
          else
          this.ce.error("Wrong answers inserted")
        }
      })
      
    }
    
  }

  onDigitInput(event:any){

    let element;
    if (event.code !== 'Backspace')
         element = event.srcElement.nextElementSibling;
 
     if (event.code === 'Backspace')
         element = event.srcElement.previousElementSibling;
 
     if(element == null)
         return;
     else
         element.focus();
 }

 ngOnDestroy(): void {
   this.sub.unsubscribe()
 }

}
