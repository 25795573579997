<ng-container [formGroup]="FindingsForm">
    <div class="h100 l-flex-w t-boxMargin--spacebyPadding summary">
        <!-- cell : Measurements -->

        <div class="w100 summary-anamnesis">
            <section class="m-box">
                <h2>Course of exam</h2>
                <div class="w100 placenta-box placenta-ammiotic">
                    <!-- row (start) -->
                    <div class="l-flex-c l-flex-w l-justify h35 placenta-row">

                        <label class="t-label-top">
                            <span>
                                Duration
                            </span>
                            <span class="t-field t-field--number">
                                <input class="t-field-text" type="number" min="0" formControlName="Duration" />
                                <span class="t-field-desc">
                                    min
                                </span>
                            </span>
                        </label>

                        <label class="t-label-top">
                            <span>
                                Exam Protocol
                            </span>

                            <select formControlName="Protocol" class="t-select t-field">
                                <option value="Bruce">Bruce</option>
                                <option value="Bruce Modified">Bruce Modified</option>
                                <option value="Bicycle">Bicycle</option>

                            </select>

                        </label>

                        <label class="t-label-top">
                            <span>
                                Stage
                            </span>

                            <select formControlName="Stage" class="t-select t-field">
                                <option value="0"></option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>

                            </select>

                        </label>

                        <label class="t-label-top">
                            <span style="width: 100%">
                                Max. {{ FindingsForm.get('Protocol').value == 'Bicycle'? 'WATTs' : 'METs'}}
                            </span>
                            <span class="t-field t-field--number" style="max-width: fit-content">
                                <input class="t-field-text" type="number" min="0" formControlName="METs" />
                            </span>
                        </label>


                    </div>
                    <!-- row (end) -->
                    <!-- row (start) -->
                    <div class="l-flex-c l-flex-w l-justify h35 placenta-row">
                        <label class="t-label-top">
                            <span>
                                Heart Rate at Rest
                            </span>
                            <span class="t-field t-field--number">
                                <input class="t-field-text" type="text" min="0" formControlName="HeartRate" minlength="0" maxlength="3" />
                                <span class="t-field-desc">
                                    bpm
                                </span>
                            </span>
                        </label>

                        <label class="t-label-top">
                            <span>
                                Max Expected HR
                            </span>
                            <span class="t-field t-field--number">
                                <!-- <input class="t-field-text" type="number" min="0"
                                    formControlName="MaxExpectedHeartRate" /> -->
                                    <p class="t-field-text" style="margin: 0;">{{FindingsForm.get('MaxExpectedHeartRate').value | number: '1.0-0'}}</p>
                                <span class="t-field-desc">
                                    bpm
                                </span>
                            </span>
                        </label>
                        <label class="t-label-top">
                            <span>
                                Max HR
                            </span>
                            <span class="t-field t-field--number">
                                <input class="t-field-text" type="number" min="0" formControlName="MaxHeartRate" maxlength="3" />
                                <span class="t-field-desc">
                                    bpm
                                </span>
                            </span>
                        </label>

                        <label class="t-label-top">
                            <span>
                                % of Max Expected
                            </span>
                            <span class="t-field t-field--number">
                                <p class="t-field-text">{{FindingsForm.get('Precentage').value}}</p>
                            </span>
                        </label>
                    </div>
                    <!-- row (end) -->
                    
                    <!-- row (start) -->
                    <div class="l-flex-c l-flex-w l-just-even h35 placenta-row">
                        <label class="t-label-top" *ngIf="es.ExamType == 'Stress-Echo'">
                            <app-geninput [form]="FindingsForm.get('OtherStaff')" title="Technologies Name"></app-geninput>
                        </label>
                        <label class="t-label-top">
                            <span>
                                Blood Pressure at Rest
                            </span>
                            <span class="t-field t-field--number" style="max-width: fit-content">
                                <input class="t-field-text" type="number" maxlength="3"  min="0" formControlName="bpSystolicRest"
                                    style='width: 4em' />/<input class="t-field-text" type="number" maxlength="3"  min="0"
                                    formControlName="bpDiastolicRest" style='width: 4em'>
                                <span class="t-field-desc">
                                    mmHg
                                </span>
                            </span>
                        </label>

                        <label class="t-label-top">
                            <span>
                                Blood Pressure Peak Ex
                            </span>
                            <span class="t-field t-field--number" style="max-width: fit-content">
                                <input class="t-field-text" type="number" maxlength="3"  min="0" formControlName="bpSystolic"
                                    style='width: 4em' />/<input class="t-field-text" type="number" maxlength="3"  min="0"
                                    formControlName="bpDiastolic" style='width: 4em'>
                                <span class="t-field-desc">
                                    mmHg
                                </span>
                            </span>
                        </label>
                    </div>
                    <!-- row (end) -->

                </div>

            </section>

        </div>
        <!-- endcell : Measurements -->
        <div class="w100 summary-anamnesis" *ngIf="es.ExamType == 'Stress-Echo'" style="display: inline-flex;">
            <section class="w100 m-box">
                <h2>Medication</h2>
                <textarea class="scroller" formControlName="Medication"></textarea>
                <!-- /scroller -->

                <button class="t-btn-edit" *ngIf="! es.isClosed"
                    (click)="atS.showAuto(FindingsForm.get('Medication'),'Medication ',500)">Auto
                    Text</button>
            </section>
            <!-- <div class='w50'  *ngIf="es.ExamType == 'Stress-Echo'" >
                <div class="m-box " style="margin-inline-start: 1em;">
                <h2>
                    Resting ECG
                </h2>

                <textarea class="scroller" formControlName="ECG"></textarea>
                <button class="t-btn-edit" *ngIf="! es.isClosed"
                    (click)="atS.showAuto(FindingsForm.get('ECG'),'Resting ECG',46)">Auto Text</button>

                </div>
            </div> -->
        </div>
        <!-- cell : Measurements -->

        <div class="w100 summary-anamnesis">
            <section class="m-box" style="height: auto;">
                <h2>Qualitative Findings</h2>
                <!-- row (start) -->
                <div class="l-flex-c l-flex-w l-justify h50 placenta-row" style="margin: 0 0 1em;">
                    <div class="w49 summary-anamnesis">
                        <section class="m-box">
                            <h2>Clinic Response</h2>
                            <textarea class="scroller" formControlName="Response"></textarea>
                            <!-- /scroller -->

                            <button class="t-btn-edit" *ngIf="! es.isClosed"
                                (click)="atS.showAuto(FindingsForm.get('Response'),'Clinic Response ',506)">Auto
                                Text</button>
                        </section>
                    </div>

                    <div class="w49 summary-anamnesis">
                        <section class="m-box">
                            <h2>Arythmia and Conduction</h2>
                            <textarea class="scroller" formControlName="Arythmia"></textarea>
                            <!-- /scroller -->

                            <button class="t-btn-edit" *ngIf="! es.isClosed"
                                (click)="atS.showAuto(FindingsForm.get('Arythmia'),'Arythmia and Conduction ',507)">Auto
                                Text</button>
                        </section>
                    </div>
                </div>
                <!-- row (end) -->
                <!-- row (start) -->
                <div class="l-flex-c l-flex-w l-justify h50 placenta-row" style="margin: 1em 0;">
                    <div class="w49 summary-anamnesis">
                        <section class="m-box">
                            <h2>Reason for exam termination</h2>
                            <textarea class="scroller" formControlName="EndReason"></textarea>
                            <!-- /scroller -->

                            <button class="t-btn-edit" *ngIf="! es.isClosed"
                                (click)="atS.showAuto(FindingsForm.get('EndReason'),'Reason for exam termination ',510)">Auto
                                Text</button>
                        </section>
                    </div>

                    <div class="w49 summary-anamnesis">
                        <section class="m-box">
                            <h2>ST Tract</h2>
                            <textarea class="scroller" formControlName="ST"></textarea>
                            <!-- /scroller -->

                            <button class="t-btn-edit" *ngIf="! es.isClosed"
                                (click)="atS.showAuto(FindingsForm.get('ST'),'ST ',509)">Auto
                                Text</button>
                        </section>
                    </div>
                </div>
                <!-- row (end) -->
                <!-- row (start) -->
                <div class="l-flex-c l-flex-w l-justify h50 placenta-row" style="margin: 1em 0;">
                    <div class="w49 summary-anamnesis">
                        <section class="m-box">
                            <h2>Resting ECG</h2>
                            <textarea class="scroller" formControlName="ECG"></textarea>
                            <!-- /scroller -->

                            <button class="t-btn-edit" *ngIf="! es.isClosed"
                                (click)="atS.showAuto(FindingsForm.get('ECG'),'Resting ECG ',508)">Auto
                                Text</button>
                        </section>
                    </div>

                    <div class="w49 summary-anamnesis">
                        <label class="t-label-top">
                            <span>
                                Pulse Rate 2 min after study end
                            </span>
                            <select formControlName="PulseStatus" class="t-select t-field">
                                <option value="0"></option>
                                <option value="תקין">תקין</option>
                                <option value="ממושך">ממושך</option>
                                <option value="יש לציין טכיקרדיה במנוחה">יש לציין טכיקרדיה במנוחה</option>
                            </select>
                            <span class="t-field t-field--number" *ngIf="FindingsForm.get('PulseStatus').value == 'יש לציין טכיקרדיה במנוחה'">
                                <input class="t-field-text" type="number" min="0" formControlName="PulseRate" />
                                <span class="t-field-desc">
                                    bpm
                                </span>
                            </span>
                        </label>
                    </div>
                </div>
                <!-- row (end) -->
            </section>

        </div>
        <!-- cell : Measurements -->

    </div>
</ng-container>