import { Injectable } from '@angular/core';
import { UntypedFormBuilder,UntypedFormGroup} from '@angular/forms';
import {PatientsService} from './patients.service';
import {PregnancyService} from './pregnancy.service';
import {ExamsService} from './exams.service';
import {MeasgrowthService} from './measgrowth.service';
import { HttpClient } from '@angular/common/http';
import { debounceTime, distinctUntilChanged} from 'rxjs/operators';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class NtService {
  NTForm: UntypedFormGroup;
  AgeEdd:string;
  freezerAge: string;
  donorAge: string;
  debounce= 500;
  loaded=false;
  updated$: Subject<void> = new Subject<void>();

  constructor(private fb:UntypedFormBuilder, private pats: PatientsService, private pregS: PregnancyService, private es: ExamsService,private ms: MeasgrowthService,private http: HttpClient) {
    this.resetForm();
   }

  resetForm(){
    this.NTForm = this.fb.group({
      YolkSac:[''],
      NasalBone:[''],
      Heart:[''],
      FHR:[''],
      Conception: [''],
      Comments:[''],
      OtherDonorBD:[''],
      EggDonationDate:[''],
      PrintConception:['']
    });
  }
  public calcRiskAge(yearstr:string):number{
    try{let yearprt = yearstr.split('y');
    let years = parseInt(yearprt[0]);
    let months = parseInt(yearprt[1].replace('m','')) ;
    if(years < 20  || years >= 46)
      return null;
    return this.riskAge[years -20][months+1]}
    catch{return null;}
  }

  public calcRiskCRL(riskAge: number,CRL: number,NT:number):number{
    if(NT < 0.6 || NT > 5 || CRL < 40 || CRL > 84)
      return null;
    let row = Math.floor(NT * 10) - 6;
    let col = Math.floor(CRL) - 40;
    try{return Math.round(riskAge / this.riskCRL[row][col]);}
    catch{return null;}
  }

  getEDDAge(){
    if(!this.pats.getPat().Birth){
      this.AgeEdd=null;
      return null;
    }
    let edd = this.pregS.edd(true);
    if(edd != ""){
      this.AgeEdd = this.pats.ageAtEDD(<Date>edd,true);
      return this.AgeEdd;
    }
    return null;
  }

  freezeAge(date = this.NTForm.get('EggDonationDate').value){
    if(! date || date == '' || !this.pats.getPat().Birth){
      this.freezerAge=null;
      return null;
    }
    let egg =Date.parse(this.NTForm.get('EggDonationDate').value);
    let eggage= new Date(egg);
    this.freezerAge = this.pats.ageAtEDD(eggage,true,9);
    return this.freezerAge;
  }

  getDonorAge(){
    if(!this.NTForm.get('EggDonationDate').value || !this.NTForm.get("OtherDonorBD").value || this.NTForm.get("OtherDonorBD").value == '' || this.NTForm.get('EggDonationDate').value==''){
      this.donorAge=null;
      return null;
    }
    let agedays= 9*30 + (Date.parse(this.NTForm.get('EggDonationDate').value) - Date.parse(this.NTForm.get("OtherDonorBD").value))/(24*60*60*1000);
    let years = Math.floor(agedays/365.25);
    let mo = Math.floor((agedays - years*365.25)/30);
    this.donorAge=years+"y"+mo+"m";
    return this.donorAge;
  }

  fillForm(){
    let f = this.http.get("/api/get",{params:{'table':'nt',fetus: this.es.getFetus().toString(),ExamID: this.es.examID.toString()}});
    f.subscribe((data)=>{
      if(! data.hasOwnProperty("empty")){
        for (let key in data) {
          if(this.NTForm.get(key)){
            if(data[key]!== null)
              this.NTForm.get(key).setValue(data[key],{emitEvent: false});//null ruins formcontrol
            else{
              if( this.NTForm.get(key).value != '')
                this.NTForm.get(key).setValue('',{emitEvent: false});
            }
             
          }
        }
      }
      if(! data.hasOwnProperty("empty")){
        if(data['Conception'] !='Natural' && this.es.ps.pregForm.get('NaturalPregnancy').value == 1){
          this.NTForm.get("Conception").setValue("Natural"); 
        }
        
        if(data['NT']){
          this.ms.measref['NT'].value=data['NT'];
        }
        if(data['CRL']){
          this.ms.measref['CRL'].value=data['CRL'];
          if(data['CRL_GGfig'])
            this.ms.measref['CRL'].GGfig = data['CRL_GGfig'];
          if(data['CRL_GAfig'])
          this.ms.measref['CRL'].GAfig = data['CRL_GAfig'];
          this.ms.measref['CRL'].calcGATable();
          this.ms.measref['CRL'].calcGGTable(this.es.examGA());
          this.ms.measref['CRL'].setSDColor();
        }
        if(data['GS']){
          this.ms.measref['GS'].value=data['GS'];
          this.ms.measref['GS'].calcGATable();
          this.ms.measref['GS'].setSDColor();
        }
      }
      for (let key in this.NTForm.controls) {
        if(this.es.isClosed){
          this.NTForm.get(key).disable();
        }
        else
          this.NTForm.controls[key].valueChanges.pipe(debounceTime(this.debounce), distinctUntilChanged()).subscribe(data=>{
            this.saveVals(key);
        }); 
      }
      this.loaded = true;
      this.updated$.next();
    }, (error)=>{
      console.log(error);
    });
    return f;
  }

  getAge(){
    switch(this.NTForm.get('Conception').value){
      case "Natural": case null: case '':
        return this.AgeEdd;
      case "Egg Freezing":
        return this.freezerAge;
      case "Egg Donation":
        return this.donorAge;
    }
  }

  saveVals(key:string){
    let p = this.pats.getPat();
    let val = this.NTForm.get(key).value;
    if((key == "EggDonationDate" || key == "OtherDonorBD") && this.NTForm.get(key).value)
      val = this.NTForm.get(key).value.format('YYYY-MM-DD');

    let values = {
      'table': 'nt',
      'indexvalue': this.es.examID,
      'PID': p.PID,
      'ID': p.ID,
      'CreationTime': this.es.CreationTime,
      'field': key,
      'value': val,
      'fetus':  this.es.getFetus().toString(),
      Preg: p.PID > 0 ? this.pregS.pregID : 0 //for defaults - no preg
    };
    
    this.http.post('/api/save',values).subscribe((data)=>{//later on add observer for saves/errors
      console.log(data);
    }, (error)=> {// do this part later
      console.log(error);
    });
  }

  //for printing
  loadData(data){
    for(let key in this.NTForm.controls){
      if(data[key])
        this.NTForm.get(key).setValue(data[key],{emitEvent:false});
    }
    this.getEDDAge();
    this.freezeAge();
    this.getDonorAge();
    
  }

  inRange(age = this.pats.getAgeMo()){
    if(age){
      var year = parseInt(age.split("y")[0])
      if(year >= 46 || year <20)
        return false;
      else
        return true;
    }
  }
  getAgeOver(age = this.pats.getAgeMo()){
    if(age){
      var year = parseInt(age.split("y")[0])
      if(year >= 46)
        return true;
      else
        return false;
    }
  }
  
  private riskAge= [
    [20,1529,1527,1525,1524,1522,1521,1519,1517,1515,1514,1512,1510  ],
    [21,1508,1506,1504,1502,1500,1498,1495,1493,1491,1489,1486,1484  ],
    [22,1481,1479,1476,1474,1471,1468,1465,1462,1460,1457,1454,1450  ],
    [23,1447,1444,1441,1438,1434,1431,1427,1423,1420,1416,1412,1408  ],
    [24,1404,1400,1396,1392,1388,1384,1379,1375,1370,1366,1361,1356  ],
    [25,1351,1346,1341,1336,1331,1326,1320,1315,1309,1304,1298,1292  ],
    [26,1286,1280,1274,1268,1262,1255,1249,1243,1236,1239,1223,1216  ],
    [27,1209,1202,1195,1187,1180,1173,1165,1158,1150,1143,1135,1127  ],
    [28,1119,1111,1101,1095,1087,1078,1070,1062,1053,1045,1036,1027  ],
    [29,1019,1010,1001,992,983,974,965,956,947,938,928,919  ],
    [30,910,900,891,882,872,863,853,844,835,825,816,806  ],
    [31,797,787,778,768,759,749,740,730,721,711,702,693  ],
    [32,683,674,665,655,646,637,628,619,610,601,592,583  ],
    [33,575,566,557,548,540,531,523,515,506,498,490,482  ],
    [34,474,466,458,451,443,435,428,420,413,406,399,391  ],
    [35,384,378,371,364,357,351,334,338,332,325,319,313  ],
    [36,307,301,296,290,284,279,273,268,263,257,252,247  ],
    [37,242,237,233,228,223,219,214,210,206,201,197,193  ],
    [38,189,185,181,177,174,170,166,163,159,156,153,149  ],
    [39,146,143,140,137,134,131,128,126,123,120,117,115  ],
    [40,112,110,110,105,103,100,98,96,94,92,90,88  ],
    [41,86,86,86,80,80,80,75,75,75,70,70,70  ],
    [42,65,65,65,61,61,61,57,57,57,53,53,53  ],
    [43,49,49,49,46,46,46,43,43,43,40,40,40  ],
    [44,37,37,37,35,35,35,32,32,32,30,30,30  ],
    [45,28,28,28,26,26,26,24,24,24,23,23,23  ]
  ];

  private riskCRL = [
    [0.16,0.16,0.16,0.16,0.16,0.16,0.16,0.16,0.16,0.16,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15  ],
    [0.16,0.16,0.16,0.16,0.16,0.16,0.16,0.16,0.16,0.16,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15  ],
    [0.16,0.16,0.16,0.16,0.16,0.16,0.16,0.16,0.16,0.16,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15  ],
    [0.16,0.16,0.16,0.16,0.16,0.16,0.16,0.16,0.16,0.16,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15  ],
    [0.16,0.16,0.16,0.16,0.16,0.16,0.16,0.16,0.16,0.16,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15  ],
    [0.19,0.19,0.19,0.19,0.19,0.17,0.17,0.17,0.17,0.17,0.16,0.16,0.16,0.16,0.16,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15  ],
    [0.22,0.22,0.22,0.22,0.22,0.19,0.19,0.19,0.19,0.19,0.17,0.17,0.17,0.17,0.17,0.16,0.16,0.16,0.16,0.16,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15  ],
    [0.22,0.22,0.22,0.22,0.22,0.22,0.22,0.22,0.22,0.22,0.19,0.19,0.19,0.19,0.19,0.17,0.17,0.17,0.17,0.17,0.16,0.16,0.16,0.16,0.16,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15  ],
    [0.27,0.27,0.27,0.27,0.27,0.27,0.27,0.27,0.27,0.27,0.22,0.22,0.22,0.22,0.22,0.19,0.19,0.19,0.19,0.19,0.18,0.18,0.18,0.18,0.18,0.16,0.16,0.16,0.16,0.16,0.16,0.16,0.16,0.16,0.16,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15,0.15  ],
    [0.36,0.36,0.36,0.36,0.36,0.34,0.34,0.34,0.34,0.34,0.27,0.27,0.27,0.27,0.27,0.22,0.22,0.22,0.22,0.22,0.2,0.2,0.2,0.2,0.2,0.18,0.18,0.18,0.18,0.18,0.16,0.16,0.16,0.16,0.16,0.16,0.16,0.16,0.16,0.16,0.15,0.15,0.15,0.15,0.15  ],
    [0.48,0.48,0.48,0.48,0.48,0.45,0.45,0.45,0.45,0.45,0.33,0.33,0.33,0.33,0.33,0.27,0.27,0.27,0.27,0.27,0.22,0.22,0.22,0.22,0.22,0.2,0.2,0.2,0.2,0.2,0.18,0.18,0.18,0.18,0.18,0.17,0.17,0.17,0.17,0.17,0.16,0.16,0.16,0.16,0.16  ],
    [0.65,0.65,0.65,0.65,0.65,0.6,0.6,0.6,0.6,0.6,0.42,0.42,0.42,0.42,0.42,0.32,0.32,0.32,0.32,0.32,0.26,0.26,0.26,0.26,0.26,0.22,0.22,0.22,0.22,0.22,0.2,0.2,0.2,0.2,0.2,0.18,0.18,0.18,0.18,0.18,0.17,0.17,0.17,0.17,0.17  ],
    [0.93,0.93,0.93,0.93,0.93,0.79,0.79,0.79,0.79,0.79,0.56,0.56,0.56,0.56,0.56,0.4,0.4,0.4,0.4,0.4,0.32,0.32,0.32,0.32,0.32,0.26,0.26,0.26,0.26,0.26,0.22,0.22,0.22,0.22,0.22,0.2,0.2,0.2,0.2,0.2,0.18,0.18,0.18,0.18,0.18  ],
    [1.3,1.3,1.3,1.3,1.3,1.1,1.1,1.1,1.1,1.1,0.73,0.73,0.73,0.73,0.73,0.52,0.52,0.52,0.52,0.52,0.39,0.39,0.39,0.39,0.39,0.31,0.31,0.31,0.31,0.31,0.26,0.26,0.26,0.26,0.26,0.22,0.22,0.22,0.22,0.22,0.2,0.2,0.2,0.2,0.2  ],
    [1.83,1.83,1.83,1.83,1.83,1.54,1.54,1.54,1.54,1.54,0.97,0.97,0.97,0.97,0.97,0.65,0.65,0.65,0.65,0.65,0.48,0.48,0.48,0.48,0.48,0.38,0.38,0.38,0.38,0.38,0.3,0.3,0.3,0.3,0.3,0.25,0.25,0.25,0.25,0.25,0.22,0.22,0.22,0.22,0.22  ],
    [2.72,2.72,2.72,2.72,2.72,2.18,2.18,2.18,2.18,2.18,1.3,1.3,1.3,1.3,1.3,0.85,0.85,0.85,0.85,0.85,0.6,0.6,0.6,0.6,0.6,0.46,0.46,0.46,0.46,0.46,0.36,0.36,0.36,0.36,0.36,0.3,0.3,0.3,0.3,0.3,0.25,0.25,0.25,0.25,0.25  ],
    [3.86,3.86,3.86,3.86,3.86,2.97,2.97,2.97,2.97,2.97,1.76,1.76,1.76,1.76,1.76,1.14,1.14,1.14,1.14,1.14,0.79,0.79,0.79,0.79,0.79,0.56,0.56,0.56,0.56,0.56,0.43,0.43,0.43,0.43,0.43,0.35,0.35,0.35,0.35,0.35,0.29,0.29,0.29,0.29,0.29  ],
    [5.49,5.49,5.49,5.49,5.49,4.21,4.21,4.21,4.21,4.21,2.38,2.38,2.38,2.38,2.38,1.48,1.48,1.48,1.48,1.48,1,1,1,1,1,0.7,0.7,0.7,0.7,0.7,0.54,0.54,0.54,0.54,0.54,0.42,0.42,0.42,0.42,0.42,0.34,0.34,0.34,0.34,0.34  ],
    [8.24,8.24,8.24,8.24,8.24,6,6,6,6,6,3.24,3.24,3.24,3.24,3.24,2,2,2,2,2,1.3,1.3,1.3,1.3,1.3,0.89,0.89,0.89,0.89,0.89,0.65,0.65,0.65,0.65,0.65,0.6,0.6,0.6,0.6,0.6,0.4,0.4,0.4,0.4,0.4  ],
    [12,12,12,12,12,8.2,8.2,8.2,8.2,8.2,4.59,4.59,4.59,4.59,4.59,2.72,2.72,2.72,2.72,2.72,1.68,1.68,1.68,1.68,1.68,1.14,1.14,1.14,1.14,1.14,0.9,0.9,0.9,0.9,0.9,0.63,0.63,0.63,0.63,0.63,0.48,0.48,0.48,0.48,0.48  ],
    [17,17,17,17,17,12,12,12,12,12,6.3,6.3,6.3,6.3,6.3,3.53,3.53,3.53,3.53,3.53,2.28,2.28,2.28,2.28,2.28,1.48,1.48,1.48,1.48,1.48,1,1,1,1,1,0.76,0.76,0.76,0.76,0.76,0.58,0.58,0.58,0.58,0.58  ],
    [26,26,26,26,26,17,17,17,17,17,8.6,8.6,8.6,8.6,8.6,4.8,4.8,4.8,4.8,4.8,2.97,2.97,2.97,2.97,2.97,1.92,1.92,1.92,1.92,1.92,1.3,1.3,1.3,1.3,1.3,0.93,0.93,0.93,0.93,0.93,0.7,0.7,0.7,0.7,0.7  ],
    [38,38,38,38,38,24,24,24,24,24,12,12,12,12,12,6.6,6.6,6.6,6.6,6.6,3.86,3.86,3.86,3.86,3.86,2.49,2.49,2.49,2.49,2.49,1.68,1.68,1.68,1.68,1.68,1.14,1.14,1.14,1.14,1.14,0.85,0.85,0.85,0.85,0.85  ],
    [54,54,54,54,54,34,34,34,34,34,17,17,17,17,17,8.6,8.6,8.6,8.6,8.6,5,5,5,5,5,3.24,3.24,3.24,3.24,3.24,2.1,2.1,2.1,2.1,2.1,1.48,1.48,1.48,1.48,1.48,1.05,1.05,1.05,1.05,1.05  ],
    [75,75,75,75,75,49,49,49,49,49,23,23,23,23,23,12,12,12,12,12,6.9,6.9,6.9,6.9,6.9,4.03,4.03,4.03,4.03,4.03,2.72,2.72,2.72,2.72,2.72,1.83,1.83,1.83,1.83,1.83,1.3,1.3,1.3,1.3,1.3  ],
    [75,75,75,75,75,67,67,67,67,67,31,31,31,31,31,17,17,17,17,17,9,9,9,9,9,5.25,5.25,5.25,5.25,5.25,3.38,3.38,3.38,3.38,3.38,2.28,2.28,2.28,2.28,2.28,1.61,1.61,1.61,1.61,1.61  ],
    [75,75,75,75,75,75,75,75,75,75,45,45,45,45,45,22,22,22,22,22,12,12,12,12,12,6.9,6.9,6.9,6.9,6.9,4.4,4.4,4.4,4.4,4.4,2.84,2.84,2.84,2.84,2.84,2,2,2,2,2  ],
    [75,75,75,75,75,75,75,75,75,75,61,61,61,61,61,30,30,30,30,30,16,16,16,16,16,9.1,9.1,9.1,9.1,9.1,5.49,5.49,5.49,5.49,5.49,3.69,3.69,3.69,3.69,3.69,2.49,2.49,2.49,2.49,2.49  ],
    [75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,41,41,41,41,41,22,22,22,22,22,12,12,12,12,12,7.2,7.2,7.2,7.2,7.2,4.59,4.59,4.59,4.59,4.59,3.1,3.1,3.1,3.1,3.1  ],
    [75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,54,54,54,54,54,29,29,29,29,29,16,16,16,16,16,9.1,9.1,9.1,9.1,9.1,5.7,5.7,5.7,5.7,5.7,3.86,3.86,3.86,3.86,3.86  ],
    [75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,38,38,38,38,38,21,21,21,21,21,12,12,12,12,12,7.2,7.2,7.2,7.2,7.2,4.8,4.8,4.8,4.8,4.8  ],
    [75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,49,49,49,49,49,27,27,27,27,27,16,16,16,16,16,9.5,9.5,9.5,9.5,9.5,6,6,6,6,6  ],
    [75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,67,67,67,67,67,36,36,36,36,36,20,20,20,20,20,12,12,12,12,12,7.5,7.5,7.5,7.5,7.5  ],
    [75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,45,45,45,45,45,26,26,26,26,26,15,15,15,15,15,9.5,9.5,9.5,9.5,9.5  ],
    [75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,59,59,59,59,59,33,33,33,33,33,19,19,19,19,19,12,12,12,12,12  ],
    [75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,43,43,43,43,43,25,25,25,25,25,15,15,15,15,15  ],
    [75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,54,54,54,54,54,31,31,31,31,31,19,19,19,19,19  ],
    [75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,70,70,70,70,70,39,39,39,39,39,24,24,24,24,24  ],
    [75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,51,51,51,51,51,30,30,30,30,30  ],
    [75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,64,64,64,64,64,38,38,38,38,38  ],
    [75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,47,47,47,47,47  ],
    [75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,59,59,59,59,59  ],
    [75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,73,73,73,73,73  ],
    [75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75  ],
    [75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75  ]
  ];
}
