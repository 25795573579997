<div class="t-popup-body" style="overflow: auto;"  [formGroup]="gfs.Form">
    <button class="t-btn-popup-close" aria-label="close popup" (click)="close(true)"></button>
    <div class="formHeader">
        <h2 style="align-self: center;">
            Admission Form
        </h2>
        <span (click)="printForm()" class="icon-print" style="margin-inline-end: 1.5em;" aria-label="Print" matTooltip="Print"><img src="../../../assets/icons/print.svg"></span>
    </div>
    <section class="w100" style="direction: rtl;justify-content: space-evenly;display: flex;">
        <span> בדיקות לביצוע:</span>
        <span>קולונוסקופיה <input type="checkbox" formControlName="Colonoscopy"></span>
        <span>גסטרוסקופיה <input type="checkbox" formControlName="Gastroscopy"></span>
        <span>סיגמואידוסקופיה <input type="checkbox" formControlName="Sigmoidoscopy"></span>

    </section>
    <div class="m-box" style="direction: rtl;margin: 1em 0;justify-content: space-between;" >
        <h2>מדדים</h2>
        <div style="display: flex">
            <div class="w20" style="display: flex;margin-top: 0; margin: 0.3em;">
                <span style="margin-left: 0.3em;align-self: center;">חום</span> 
                <span class="t-field t-field--number" style="direction: ltr;">
                    <input class="t-field-text" type="number" min="0" id="Temp"
                        formControlName="Temp" />
                    <span class="t-field-desc">
                        *C
                    </span>
                </span>
            </div>
            <div class="w20" style="display: flex; margin: 0.3em;">
                <span style="margin-left: 0.3em;align-self: center;">דופק</span> 
                <span class="t-field t-field--number" style="direction: ltr;">
                    <input class="t-field-text" type="number" min="0" id="Pulse"
                        formControlName="Pulse" />
                    <span class="t-field-desc">
                        bpm
                    </span>
                </span>
            </div>                
            <div class="w20" style="display: flex; margin: 0.3em;">
                <span style="margin-left: 5px;align-self: center;">לחץ דם</span>
                    <!-- <span>
                        לחץ דם
                    </span>
                    <span class="t-field t-field--number" style="max-width: fit-content">
                        <input class="t-field-text" type="number" min="0" formControlName="bpSystolic" style='width: 4em'/>/<input class="t-field-text" type="number" min="0" formControlName="bpDyastolic" style='width: 4em'>
                        <span class="t-field-desc">
                           mmHg
                    </span> -->
                <span class="t-field t-field--number" style="direction: ltr;">
                    <input class="t-field-text" type="number" min="0" formControlName="bpSystolic" style='width: 3em;text-align: center;text-align-last: center;'/>/<input class="t-field-text" type="number" min="0" formControlName="bpDyastolic" style='width: 3em;text-align: center;text-align-last: center;'>
                    <span class="t-field-desc" style="padding: 0.3em;">
                        mmHg
                    </span>
                </span>
            </div>
            <div class="w20" style="display: flex; margin: 0.3em;">
                <span style="margin-left: 0.3em;align-self: center;">סיטורציה</span> 
                <span class="t-field t-field--number" style="direction: ltr;min-width: 6.31em;">
                    <input class="t-field-text" type="number" min="0" formControlName="Saturation" style='text-align: center;text-align-last: center;'/>
                    <span class="t-field-desc" style="padding: 0.3em;">
                        %
                    </span>
                </span>
            </div>
            <div class="w20" style="display: flex; margin: 0.3em;">
                <span style="margin-left: 0.3em;align-self: center;">שעת הבדיקה</span> 
                <span class="t-field t-field--number" style="direction: ltr;">
                    <input class="t-field-text" type="time" id="CheckTime"
                        formControlName="CheckTime" />
                </span>
            </div>
        </div>
    </div>
    <div class="m-box" style="direction: rtl;margin: 1em 0;;">
        <h2>הרגלים</h2>
        <div style="z-index: 7;">
            <div  style="display: flex;z-index: 1000;">
                <app-patformsitem type="Smokes" name="Smokes" style="margin-top: 0;width:16%;margin: 0.5em;text-align: center;"></app-patformsitem>
                <app-patformsitem type="Alcohol" name="Alcohol" style="width: 16%;margin: 0.5em;text-align: center;"></app-patformsitem>
                <app-patformsitem type="Drugs" name="Drugs" style="width: 16%;margin: 0.5em;text-align: center;"></app-patformsitem>
                <app-patformsitem type="Sleep" name="Sleep" style="width: 16%;margin: 0.5em;text-align: center;"></app-patformsitem>
                <app-patformsitem type="Allergies" name="Allergies" style="width: 16%;margin: 0.5em;text-align: center;"></app-patformsitem>
                <app-patformsitem type="DrugsAllergies" name="DrugsAllergies" style="width: 16%;margin: 0.5em;text-align: center;"></app-patformsitem>
            </div>
        </div>
    </div>
    <div class="m-box" style="direction: rtl;margin: 1em 0;" >
        <h2>הכנת המטופל</h2>
        <div style="z-index: 6;">
            <div style="display: flex;">
                <app-patformsitem type="Nursing" name="Nursing" style="margin-top: 0;width:16%;margin: 0.5em;text-align: center;"></app-patformsitem>
                <app-patformsitem type="OSA" name="OSA" style="margin-top: 0;width:16%;margin: 0.5em;text-align: center;"></app-patformsitem>
                <app-patformsitem type="DAC" name="DAC" style="margin-top: 0;width:16%;margin: 0.5em;text-align: center;"></app-patformsitem>
                <app-patformsitem type="Dentures" name="Dentures" style="margin-top: 0;width:16%;margin: 0.5em;text-align: center;"></app-patformsitem>
                <app-patformsitem type="Escorted" name="Escorted" style="margin-top: 0;width:16%;margin: 0.5em;text-align: center;"></app-patformsitem>
                <app-patformsitem type="Guardian" name="Guardian" style="margin-top: 0;width:16%;margin: 0.5em;text-align: center;"></app-patformsitem>
            </div>
        </div>
    </div>
    <div class="m-box" style="direction: rtl;margin: 1em 0;z-index: inherit;" >
        <h2>מחלות</h2>
        <div style="z-index: 5;">
            <div style="display: flex;padding: 0.3em;">
                <app-patformsitem type="Hypertension" name="Hypertension" style="margin-top: 0;width:20%;margin: 0.5em;text-align: center;"></app-patformsitem>
                <app-patformsitem type="RespiratoryDiseases" name="RespiratoryDiseases" style="margin-top: 0;width:20%;margin: 0.5em;text-align: center;"></app-patformsitem>
                <app-patformsitem type="Arrhythmias" name="Arrhythmias" style="margin-top: 0;width:20%;margin: 0.5em;text-align: center;"></app-patformsitem>
                <app-patformsitem type="Diabetes" name="Diabetes" style="margin-top: 0;width:20%;margin: 0.5em;text-align: center;"></app-patformsitem>
                <app-patformsitem type="Hepatitis" name="Hepatitis" style="margin-top: 0;width:20%;margin: 0.5em;text-align: center;"></app-patformsitem>
            </div>
            <div style="display: flex;padding: 0.3em;">
                <app-patformsitem type="Cancer" name="Cancer" style="margin-top: 0;width:20%;margin: 0.5em;text-align: center;"></app-patformsitem>
                <app-patformsitem type="Radiation" name="Radiation" style="margin-top: 0;width:20%;margin: 0.5em;text-align: center;"></app-patformsitem>
                <app-patformsitem type="Cramps" name="Cramps" style="margin-top: 0;width:20%;margin: 0.5em;text-align: center;"></app-patformsitem>
                <app-patformsitem type="Psychiatric" name="Psychiatric" style="margin-top: 0;width:20%;margin: 0.5em;text-align: center;"></app-patformsitem>
                <app-patformsitem type="Nervous" name="Nervous" style="margin-top: 0;width:20%;margin: 0.5em;text-align: center;"></app-patformsitem>
            </div>
        </div>
    </div>
    <div style="display: flex;justify-content: space-between;">
        <div class="w40" style="direction: rtl;margin: 0;height: fit-content;" >
            <app-patformsitem type="PreMedication" name="PreMedication" style="margin-top: 0;width:20%;margin: 0.5em;text-align: center;"></app-patformsitem>
        </div>
        <div class="w40" style="direction: rtl;margin-left: 0.5em;height: fit-content;" >
            <app-patformsitem type="Surgeries" name="Surgeries" style="margin-top: 0;width:20%;margin: 0.5em;text-align: center;"></app-patformsitem>
        </div>
    </div>
    <div class="m-box" style="direction: rtl;margin: 1em 0;" >
        <h2>מצב היום</h2>
        <div style="z-index: 4;">
            <div style="display: flex;justify-content: space-evenly;">
                <app-patformsitem type="Medication" name="Medication" style="margin-top: 0;width:20%;margin: 0.5em;text-align: center;"></app-patformsitem>
                <app-patformsitem type="BloodThinners" name="BloodThinners" style="margin-top: 0;width:20%;margin: 0.5em;text-align: center;"></app-patformsitem>
                <app-patformsitem type="Fast" name="Fast" style="margin-top: 0;width:20%;margin: 0.5em;text-align: center;"></app-patformsitem>
            </div>
        </div>
    </div>
</div>
