import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ExamsService } from './exams.service';
import { PatientsService } from './patients.service';

@Injectable({
  providedIn: 'root'
})
export class PathologyService {

  pathologyForm: UntypedFormGroup;
  pathologies:any =[];

  constructor(private fb:UntypedFormBuilder, private http:HttpClient, private es:ExamsService, public pat:PatientsService) {
    this.resetForm();
    if(es.isClosed){
      this.pathologyForm.disable({emitEvent:false})
    }
   }

   resetForm(){
     this.pathologyForm = this.fb.group({
      Type:[''],
      Comments:[''],
     })
   }

   getPathologies(){
     this.pathologies = [];
     this.http.get(`/api/gastro/getPathology/${this.es.examID}`).subscribe((data) => {
       this.pathologies = data;
     })
   }

   save(){
    this.http.post(`/api/gastro/savePathology/${this.es.examID}` ,{pathology: this.pathologyForm.value, pat: this.pat.getPat()}).subscribe((data) => {
      this.getPathologies();
      this.pathologyForm.reset()
    })
    
   }
   delete(path){
     if(this.es.isClosed)
      return;
    this.http.post(`/api/gastro/deletePathology`, {pathology: path}).subscribe((data) => {
      this.getPathologies();
    })
    
  }

}
