import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {LoginService} from '../app/login.service';
import {ExamsService } from '../app/exams.service';
import {TimerService} from './timer.service';

declare var Peer: any;

@Injectable({
  providedIn: 'root'
})
export class VideoClientService {

  ClientPeer: any;
  Stream;
  GotStream: Subject<null>;
  RTCid;
  DataConn;
  Recording = false;

  constructor(private http: HttpClient, private ls: LoginService, private es: ExamsService, private ts: TimerService) { 
    
    this.GotStream = new Subject();
    this.SetStream = this.SetStream.bind(this);
    this.OpenData = this.OpenData.bind(this);
    this.SignalCall =  this.SignalCall.bind(this);
    this.Answer = this.Answer.bind(this);
  }

  async ConnectServer(){
    if(! this.ClientPeer || ! this.ClientPeer.open){
      this.ClientPeer = new Peer(this.ls.PeerServ);
      this.ClientPeer.on('open',this.OpenData)
      this.ClientPeer.on('call', this.Answer);
    }
    else
      this.OpenData(this.ClientPeer.id);
   
  }

  OpenData(id){
    this.RTCid = id;
    this.http.get('/api/getarchiverid').subscribe((data)=>{
      try{
        //first send this id and then 
        this.DataConn = this.ClientPeer.connect(data['archiver']);
        this.DataConn.on('open',this.SignalCall)
        this.DataConn.on('error', (err)=>{
          console.log(err)
        });
      }
      catch(e){
        console.log(e);
      }
    },(err)=>{});

  }

  SignalCall(){
    this.DataConn.send(JSON.stringify({id:this.RTCid,cmd: 'callme'}));
  }

  StartRecording(){
    this.DataConn.send(JSON.stringify({
      cmd: 'startrecord',
      ExamID: this.es.examID,
      id: this.RTCid
    }));
    this.Recording = true;
    this.ts.startTimer();
  }

  StopRecording(){
    if(this.Recording){
      this.DataConn.send(JSON.stringify({
        cmd: 'stoprecord',
        ExamID: this.es.examID,
        id: this.RTCid
      }));
      this.Recording = false;
      this.ts.stopTimer();
    }
  }

  SetStream(stream){
    console.log('got remote stream');
    this.Stream = stream;
    this.GotStream.next();
  }

  Answer(call){
    call.on('stream', this.SetStream);
    call.answer();
  }

}
