import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormArray,
} from "@angular/forms";
import { ExamsService } from "./exams.service";
import { PatientsService } from "./patients.service";
import { Patient } from "./patients/patient";

@Injectable({
  providedIn: "root",
})
export class InboxTransferService {
  // Inbox Form:
  inboxForm: UntypedFormGroup;

  // Time:
  hours: string[] = [];
  minutes: string[] = [];

  // Data:
  availableClinics: any;
  incorrectResults: any;
  correctResults: any;
  movingDataResult: any;

  // Server Responses:
  successMessage: string = "";
  toggleSuccessMessage: boolean = false;
  toggleErrorMessage1: boolean = false;
  errorMessage1: string = "";
  toggleErrorMessage2: boolean = false;
  errorMessage2: string = "";
  toggleDataTransferMessage: boolean = false;
  dataTransferMessage: string = "";
  toggleValidation: boolean = false;

  // Parsed Dates:
  parsedFromIncorrectDate: string;
  parsedToIncorrectDate: string;
  parsedFromCorrectDate: string;
  parsedToCorrectDate: string;
  parsedMoveFromCorrectDate: string;
  parsedMoveToCorrectDate: string;

  revert: boolean = false;

  inboxConnectors: string[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    public examService: ExamsService,
    public patientsService: PatientsService,
    private http: HttpClient
  ) {
    for (let i = 1; i <= 23; i++) {
      this.hours.push(i.toString().padStart(2, "0"));
    }

    for (let i = 0; i <= 59; i++) {
      this.minutes.push(i.toString().padStart(2, "0"));
    }

    this.http.get(`/api/support/clinics`).subscribe((result) => {
      this.availableClinics = result;
    });

    this.resetForm();
  }

  resetForm() {
    this.inboxForm = this.fb.group({
      inboxClinic: [""],
      examClinic: [""],
      patientId: [""],
      fromIncorrectDate: [""],
      fromIncorrectHour: ["01"],
      fromIncorrectMinute: ["00"],
      fromCorrectDate: [""],
      fromCorrectHour: ["01"],
      fromCorrectMinute: ["00"],
      toIncorrectDate: [""],
      toIncorrectHour: ["01"],
      toIncorrectMinute: ["00"],
      toCorrectDate: [""],
      toCorrectHour: ["01"],
      toCorrectMinute: ["00"],
      dateToMoveFrom: [""],
      toMoveFromCorrectHour: ["01"],
      toMoveFromCorrectMinute: ["00"],
      dateToMoveTo: [""],
      toMoveToCorrectHour: ["01"],
      toMoveToCorrectMinute: ["00"],
      parsedMoveFromCorrectDate: [""],
      parsedMoveToCorrectDate: [""],
      selectedInbox: [""],
      selectedExam: [""],
      documentPath: [""],
    });
  }

  onDateChange(date: Date, type: string) {
    let parsedDate = this.examService.parseDate(date);

    switch (type) {
      case "incorrectFromDate":
        this.parsedFromIncorrectDate = parsedDate;
        break;
      case "incorrectToDate":
        this.parsedToIncorrectDate = parsedDate;
        break;
      case "correctFromDate":
        this.parsedFromCorrectDate = parsedDate;
        break;
      case "correctToDate":
        this.parsedToCorrectDate = parsedDate;
        break;
      case "moveFromDate":
        this.parsedMoveFromCorrectDate = parsedDate;
        break;
      case "moveToDate":
        this.parsedMoveToCorrectDate = parsedDate;
        break;
      default:
        break;
    }
  }

  onConnectorChange(event: Event, checkboxName: string): void {
    const checkboxValue = (event.target as HTMLInputElement).value;
    this.inboxForm.get(`${checkboxName}`).setValue(checkboxValue);
  }

  onConnctorSelect(checked: boolean, connector: string) {
    if (checked) {
      this.inboxConnectors.push(connector);
    } else {
      this.inboxConnectors = this.inboxConnectors.filter(
        (conn) => conn !== connector
      );
    }
  }

  onClinicSelect() {
    this.inboxConnectors = [];
  }

  searchInbox0Media() {
    const fromFullTime = `${this.inboxForm.value.fromIncorrectHour}:${this.inboxForm.value.fromIncorrectMinute}`;
    const incorrectFromExamFullDate = `${this.parsedFromIncorrectDate} ${fromFullTime}`;

    const toFullTime = `${this.inboxForm.value.toIncorrectHour}:${this.inboxForm.value.toIncorrectMinute}`;
    const incorrectToExamFullDate = `${this.parsedToIncorrectDate} ${toFullTime}`;

    const data = {
      connectorList: this.inboxConnectors,
      fromCreationDate: incorrectFromExamFullDate,
      toCreationDate: incorrectToExamFullDate,
    };

    this.http.post(`/api/support/search-inbox0`, data).subscribe(
      (result: HttpResponse<any>) => {
        this.incorrectResults = result;
        this.errorMessage1 = "";
        this.toggleErrorMessage1 = false;
        this.inboxForm.value.selectedExam = null;
        this.inboxForm.value.selectedInbox = null;
        this.dataTransferMessage = "";
        this.toggleDataTransferMessage = false;
      },
      (error) => {
        if (error.status === 404) {
          this.errorMessage1 = error.error.message;
          this.toggleErrorMessage1 = true;
          this.incorrectResults = null;
        } else {
          console.error(error);
        }
      }
    );
  }

  searchExam() {
    const fromFullTime = `${this.inboxForm.value.fromCorrectHour}:${this.inboxForm.value.fromCorrectMinute}`;
    const correctFromExamFullDate = `${this.parsedFromCorrectDate} ${fromFullTime}`;

    const toFullTime = `${this.inboxForm.value.toCorrectHour}:${this.inboxForm.value.toCorrectMinute}`;
    const correctToExamFullDate = `${this.parsedToCorrectDate} ${toFullTime}`;

    const data = {
      patientId: this.inboxForm.value.patientId,
      fromCreationDate: correctFromExamFullDate,
      GID: this.inboxForm.value.examClinic.GID,
      toCreationDate: correctToExamFullDate,
    };

    this.http.post(`/api/support`, data).subscribe(
      (result: HttpResponse<any>) => {
        this.correctResults = result;
        this.toggleErrorMessage2 = false;
        this.errorMessage2 = "";
        this.inboxForm.value.selectedExam = null;
        this.inboxForm.value.selectedInbox = null;
        this.dataTransferMessage = "";
        this.toggleDataTransferMessage = false;
      },
      (error) => {
        if (error.status === 404) {
          this.errorMessage2 = error.error.message;
          this.toggleErrorMessage2 = true;
          this.correctResults = null;
        } else {
          this.errorMessage2 = "error.message";
          this.toggleErrorMessage2 = true;
          console.error(error);
        }
      }
    );
  }

  moveInbox0() {
    const doctorUID = this.inboxForm.get("selectedExam").value?.doctorUID;
    const GID = this.inboxForm.get("selectedExam").value?.GID;
    const examId = this.inboxForm.get("selectedExam").value?.AutoIndex;
    const Connector = this.inboxForm.get("selectedInbox").value?.Connector;
    const inboxId = this.inboxForm.get("selectedInbox").value?.inboxID;
    const patientId = this.inboxForm.get("patientId").value;

    const data = {
      doctorUID,
      GID,
      examId,
      Connector,
      inboxId,
      patientId,
    };

    this.http.put(`/api/support/move-inbox0`, data).subscribe(
      (result: HttpResponse<any>) => {
        this.movingDataResult = result;
        this.dataTransferMessage = "";
        this.toggleDataTransferMessage = false;
        this.successMessage = `Data has successfully been transferred`;
        this.toggleSuccessMessage = true;
        this.revert = true;
      },
      (error) => {
        if (error.status === 404) {
          this.dataTransferMessage = error.error.message;
          this.toggleDataTransferMessage = true;
        } else {
          this.dataTransferMessage = "Transfer Failed";
          this.toggleDataTransferMessage = true;
          console.error(error);
        }
      }
    );
  }

  revertDataMove() {
    console.log("revert function or something");
    const doctorUID = this.inboxForm.get("selectedExam").value?.doctorUID;
    const GID = this.inboxForm.get("selectedExam").value?.GID;
    const examId = this.inboxForm.get("selectedExam").value?.AutoIndex;
    const Connector = this.inboxForm.get("selectedInbox").value?.Connector;
    const inboxId = this.inboxForm.get("selectedInbox").value?.inboxID;

    const data = {
      doctorUID,
      GID,
      examId,
      Connector,
      inboxId,
    };

    this.http.put(`/api/support/revert-inbox0`, data).subscribe(
      (result: HttpResponse<any>) => {
        this.movingDataResult = result;
        this.dataTransferMessage = "";
        this.toggleDataTransferMessage = false;
        this.successMessage = `Data has successfully been reverted back`;
        this.toggleSuccessMessage = true;
        this.inboxForm.get("selectedInbox").setValue(null);
        this.inboxForm.get("selectedExam").setValue(null);
        this.revert = false;
      },
      (error) => {
        if (error.status === 404) {
          this.dataTransferMessage = error.error.message;
          this.toggleDataTransferMessage = true;
        } else {
          this.dataTransferMessage = "Transfer Failed";
          this.toggleDataTransferMessage = true;
          console.error(error);
        }
      }
    );
  }

  finishTransfer() {
    const selectedExam = this.inboxForm.get("selectedExam").value;
    const patientName = `${selectedExam?.First + " " + selectedExam?.Last}`;
    const examId = this.inboxForm.get("selectedExam").value?.AutoIndex;
    const patientId = this.inboxForm.get("selectedExam").value?.ID;
    const GID = this.inboxForm.get("selectedExam").value?.GID;
    const documentPath = this.inboxForm.get("documentPath").value;
    const inboxId = this.inboxForm.get("selectedInbox").value?.inboxID;
    const PID = this.inboxForm.get("selectedExam").value?.PID;

    const data = {
      examId,
      patientName,
      patientId,
      GID,
      documentPath,
      isInbox: true,
      inboxId,
      PID,
    };

    this.http.post(`/api/support/inbox-finish`, data).subscribe(
      (result: HttpResponse<any>) => {
        this.dataTransferMessage = "";
        this.toggleDataTransferMessage = false;
        this.successMessage = `Transfer Complete`;
        this.toggleSuccessMessage = true;
        this.toggleValidation = false;
        this.clearAllFields();
      },
      (error) => {
        if (error.status === 404) {
          this.dataTransferMessage = error.error.message;
          this.toggleDataTransferMessage = true;
        } else {
          this.dataTransferMessage = "Finish Transfer Failed";
          this.toggleDataTransferMessage = true;
          console.error(error);
        }
      }
    );
  }

  // Navigate to the selected exam:
  goToExamDetails(exam: Patient): void {
    this.patientsService.setPat(exam);
    this.examService.goExamAdvancedSearch(exam, true, true); // (exam, viewonly, suppprtCenter)
  }

  // Stops the parent div's click event:
  handleRadioClick(event: Event): void {
    event.stopPropagation();
  }

  updateDateToSelected(event: any): void {
    this.inboxForm.value.dateToMoveFrom = event?.CreationTime;
    this.inboxForm.value.dateToMoveTo = event?.CreationTime;
    this.parsedMoveFromCorrectDate = this.examService.parseDate(
      this.inboxForm.value.dateToMoveFrom
    );
    this.parsedMoveToCorrectDate = this.examService.parseDate(
      this.inboxForm.value.dateToMoveTo
    );
  }

  disableMoveData(): boolean {
    const selectedExam = this.inboxForm.get("selectedExam").value;
    const selectedInbox = this.inboxForm.get("selectedInbox").value;
    const revertToggle = this.revert;

    return !(selectedExam && selectedInbox && !revertToggle);
  }

  disableSearchInbox(): boolean {
    const selectedClinic = this.inboxForm.get("inboxClinic").value;
    const fromInboxDate = this.inboxForm.get("fromIncorrectDate").value;
    const fromInboxHour = this.inboxForm.get("fromIncorrectHour").value;
    const fromInboxMinute = this.inboxForm.get("fromIncorrectMinute").value;
    const toInboxDate = this.inboxForm.get("toIncorrectDate").value;
    const toInboxHour = this.inboxForm.get("toIncorrectHour").value;
    const toInboxMinute = this.inboxForm.get("toIncorrectMinute").value;
    const selectedConnectors = this.inboxConnectors;

    return !(
      selectedClinic &&
      fromInboxDate &&
      fromInboxHour &&
      fromInboxMinute &&
      toInboxDate &&
      toInboxHour &&
      toInboxMinute &&
      selectedConnectors.length > 0
    );
  }

  disableSearchExam(): boolean {
    const patientId = this.inboxForm.get("patientId").value;
    const selectedCinic = this.inboxForm.get("examClinic").value;
    const fromExamDate = this.inboxForm.get("fromCorrectDate").value;
    const fromExamHour = this.inboxForm.get("fromCorrectHour").value;
    const fromExamMinute = this.inboxForm.get("fromCorrectMinute").value;
    const toExamDate = this.inboxForm.get("toCorrectDate").value;
    const toExamHour = this.inboxForm.get("toCorrectHour").value;
    const toExamMinute = this.inboxForm.get("toCorrectMinute").value;

    return !(
      patientId &&
      selectedCinic &&
      fromExamDate &&
      fromExamHour &&
      fromExamMinute &&
      toExamDate &&
      toExamHour &&
      toExamMinute
    );
  }

  disableFinishTransfer(): boolean {
    const movingResult = this.movingDataResult;

    return !movingResult;
  }

  clearAllFields(): void {
    this.correctResults = null;
    this.incorrectResults = null;
    this.movingDataResult = null;
    this.errorMessage1 = "";
    this.toggleErrorMessage1 = false;
    this.errorMessage2 = "";
    this.toggleErrorMessage2 = false;
    this.dataTransferMessage = "";
    this.toggleDataTransferMessage = false;
    this.revert = false;
    this.resetForm();
  }

  toggleValidationMsg() {
    const documentPath = this.inboxForm.get("documentPath").value;
    if (!documentPath) {
      this.toggleValidation = true;
    } else {
      this.toggleValidation = false;
      this.finishTransfer();
    }
  }
}
