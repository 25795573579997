<label>{{title}}</label>
<mat-form-field style="margin-top: 0;" [ngClass]="ls.isCardio ? 'marg' :''" (click)="load()">
    <input #inputDiagnosis type="text"
            id="inputDiagnosis"
           matInput
           class="t-field t-select" 
           style="height: 2em;padding: 0 0 0 1em;"
           [formControl]="search"
           [matAutocomplete]="auto">
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"  >
        <mat-option *ngIf="!atS.options.includes(search.value)" [value]="search.value" (onSelectionChange)="saveNew(search.value, true)" >{{search.value}}</mat-option>
      <div *ngFor="let option of atS.filteredOptions | async;" style="display: flex;">                          
        <!-- <mat-option style="width:100%"  [value]="option.value" (onSelectionChange)="gs.saveDiagnosis(option);reset();blur()"> -->
        <mat-option style="width:100%" (contextmenu)="onRightClick($event, option)"  [value]="option" (onSelectionChange)="saveNew(option, false)">
            {{option}}
        </mat-option>
      </div>
    </mat-autocomplete>
</mat-form-field>
