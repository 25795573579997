import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from "@angular/forms";
import { AutotextService } from "../autotext.service";
import { ExamsService } from "../exams.service";
import { PatientsService } from "../patients.service";
import { AngularEditorConfig, UploadResponse } from "@kolkov/angular-editor";
import { Observable, Subscription } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { LoginService } from "../login.service";
import { TranslateService } from "@ngx-translate/core";

export interface Template {
  TemplateID: string;
  UID: string;
  Title: string;
  TemplateContent: string;
}

@Component({
  selector: "app-consultation",
  templateUrl: "./consultation.component.html",
  styleUrls: ["./consultation.component.scss"],
})
export class ConsultationComponent implements OnInit {
  @ViewChild("Title") Title: ElementRef;
  @ViewChild("titleValue") titleValue: ElementRef;
  showEdit = false;
  showPopup = false;
  inputValue = "";
  templates: Template[] = [];
  selectedTemplate = false;
  consultation: UntypedFormGroup;
  input1: string;
  input2: string;
  editorConfig: AngularEditorConfig = {
    editable: true,
    height: "50vh",
    toolbarHiddenButtons: [
      ["subscript", "superscript", "strikeThrough"],
      [
        "backgroundColor",
        "customClasses",
        "link",
        "unlink",
        "insertImage",
        "insertVideo",
        "removeFormat",
        "toggleEditorMode",
      ],
    ],
  };

  atSTitleConsultationLetter  = this.translate.instant("GUI.Letters.Consultation Letter");

  constructor(
    private patService: PatientsService,
    private http: HttpClient,
    public atS: AutotextService,
    public es: ExamsService,
    private fb: UntypedFormBuilder,
    public ls: LoginService,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.consultation = this.fb.group({
      FormContent: [""],
      selectedTemplate: [""],
      Title: new UntypedFormControl("", Validators.minLength(2)),
    });
    this.es.fillForm(this.consultation, "Forms", false, true);

    this.http.get("/api/templates").subscribe((data) => {
      this.templates = data as Template[];
    });
  }
  createTemplate(titleValue: string) {
    const TemplateContent = this.consultation.get("FormContent").value;
    const Title = titleValue;

    if (!TemplateContent || !Title) {
      return;
    }

    const body = { TemplateContent, Title };
    this.http.post("/api/templates", body).subscribe((data) => {
      this.templates = data as Template[];
      this.titleValue.nativeElement.value = "";
    });
  }

  checkTemplate(titleValue: string) {
    if (!titleValue || !this.consultation.get("FormContent").value) {
      return true;
    }
    return false;
  }

  deleteTemplate(templateId: string) {
    if (!templateId) return;
    this.http.delete(`/api/templates/${templateId}`).subscribe((data) => {
      this.templates = data as Template[];
      this.consultation.get('selectedTemplate').setValue("",{emitEvent:false})
    });
  }
  editTemplate(templateId: string) {
    if (!templateId) return;
    const TemplateContent = this.consultation.get("FormContent").value;
    const [selectedTemplate] = this.templates.filter(
      (template) => template.TemplateID == templateId
    );
    const currentTitle = selectedTemplate.Title;
    const newTitle = this.titleValue.nativeElement.value;
    const Title = newTitle || currentTitle;
    const body = { Title, TemplateContent };
    this.http.put(`/api/templates/${templateId}`, body).subscribe((data) => {
      this.templates = data as Template[];
      this.titleValue.nativeElement.value = "";
      this.showPopup = false;
    });
  }
  setTemplate(templateId: string) {
    if (!templateId) return;
    this.selectedTemplate = true;
    let [selectedTemplate] = this.templates.filter(
      (template) => template.TemplateID == templateId
    );

    this.consultation
      .get("FormContent")
      .setValue(selectedTemplate.TemplateContent);
      let title=selectedTemplate.Title
      this.consultation.get('selectedTemplate').setValue(title,{emitEvent:false})

      if (!this.consultation.get("Title").value) {
        this.es.shouldTranslate$.next(false);
        this.consultation.get("Title").setValue(title);
        this.es.changeTitle(this.consultation.get("Title").value, true);
      } else if (this.templates.filter((template) => template.Title === this.consultation.get("Title").value).length) {
        this.consultation.get("Title").setValue(title);
        this.es.changeTitle(this.consultation.get("Title").value, true);
      }

  }
  checkTextChanged(templateId: string, title?: string) {
    if (templateId) {
      const [currentTemplate] = this.templates.filter(
        (template) => templateId == template.TemplateID
      );
      if (
        currentTemplate.TemplateContent !==
        this.consultation.get("FormContent").value
      ) {
        return (this.showEdit = true);
      }
      if (currentTemplate.Title != title && title) {
        return (this.showEdit = true);
      }
    }
    this.showEdit = false;
  }

  changeTitle() {
    this.es.shouldTranslate$.next(true);

    if (!this.consultation.get("Title").value) {
      const templateTitle = this.consultation.get("selectedTemplate").value;
      this.consultation.get("Title").setValue(templateTitle);
    }

    this.es.changeTitle(this.consultation.get("Title").value, true);
  }
}
