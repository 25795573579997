<!-- ========================== intro (start) ========================== -->

<main class="l-deashboard summary" [formGroup]="summaryForm">
    <div class="h100 t-boxMargin" style='padding-right: 0.87em; padding-left: 0.87em;position: relative;' *ngIf="es.ExamType !='Stress' && es.ExamType !='Duplex Carotid'">
        <div class="l-flex-c l-flex-w" style="position: relative;bottom: -1em;right: 3em;float: right;width: fit-content;" *ngIf="es.ExamType !='Duplex Carotid'">

            <label class="t-label-top">
                <span>
                    Measured LVEF
                </span>
                <span class="t-field t-field--number">
                    <input class="t-field-text" type="number" min="0" formControlName="LVEFP" />
                </span>
            </label>
            <label class="t-label-top">
                <span>
                    Estimated LVEF
                </span>
                <span class="t-field t-field--number">
                    <input class="t-field-text" type="text" formControlName="LVEF" />
                </span>
            </label>
            <button class="t-btn-edit" *ngIf="! es.isClosed" (click)="atS.showAuto(summaryForm.get('LVEF'),'Estimated LVEF',es.ExamType =='TTE Adults' ? 63 :(es.ExamType =='Stress-Echo' ? 62 : 64))" style="right: -3em;top: -2em;">Auto Text</button>
            <button class="t-btn-edit" *ngIf="! es.isClosed" (click)="addFindings()" style="margin-right: 3em;top: -2em;">Load Findings</button>
            <button class="t-btn-edit" *ngIf="! es.isClosed && lastFindings" (click)="addLastFindings()" style="margin-right: 12em;top: -2em;">Load pre Findings</button>
        </div>
        <!-- row (end) -->



        <!-- cell : recommendations -->
        <app-diagnoses group="summary" [form]="summaryForm.get('DiagnosisCodes')" [text]="summaryForm.get('Anomalies')" [textStress]="summaryForm.get('AnomaliesStress')"
            class="w100 summary-boxHeight summary-extradocs" autoid="51" autoidstress="407" [textonly]="true">
        </app-diagnoses>
    </div>
    <!-- row : Summary in Stress exam -->

    <div class="h100 t-boxMargin" *ngIf="es.ExamType == 'Duplex Carotid'" style='padding-right: 0.87em; padding-left: 0.87em;position: relative;'>
        <app-cardio-flow-meas></app-cardio-flow-meas>
    </div>
    <div class="h100 l-flex-w t-boxMargin--spacebyPadding" *ngIf="es.ExamType =='Stress'">
        <div class="w50 summary-boxHeight summary-indicative" style="height: 10em;">
            <section class="m-box" style="height: 100%;">

                <h2>
                    Functional Assessments
                </h2>
                <textarea class="scroller" formControlName="Assessments"></textarea>
                <button class="t-btn-edit" *ngIf="! es.isClosed"
                    (click)="atS.showAuto(summaryForm.get('Assessments'),'Functional Assessments',512)">Auto
                    Text</button>

            </section>
        </div>
        <div class="w50 summary-boxHeight summary-indicative" style="height: 10em;">
            <section class="m-box" style="height: 100%;">

                <h2>
                    Summary
                </h2>
                <textarea class="scroller" formControlName="Summary"></textarea>
                <button class="t-btn-edit" *ngIf="! es.isClosed"
                    (click)="atS.showAuto(summaryForm.get('Summary'),'Summary',511)">Auto Text</button>

            </section>
        </div>
    </div>
    <!-- endrow : Summary in Stress exam -->


    <div class="h100 l-flex-w t-boxMargin--spacebyPadding" style='margin-top: 2em'>
        <div class='w50'>
            <section class="m-box">

                <h2>
                    Diagnosis
                </h2>

                <textarea class="scroller" formControlName="Diagnosis"></textarea>
                <button class="t-btn-edit" *ngIf="! es.isClosed && lastDiagnosis" (click)="addLastDiagnosis()"
                    style="margin-right: 6em">Previous Diagnosis</button>
                <button class="t-btn-edit" *ngIf="! es.isClosed"
                    (click)="atS.showAuto(summaryForm.get('Diagnosis'),'Diagnosis',es.ExamType =='TTE Adults' ? 43 :(es.ExamType =='Stress-Echo' ? 42 : 44))">Auto Text</button>


            </section>
        </div>

        <div class="w50 summary-boxHeight summary-indicative">
            <section class="m-box" style="height: 100%;">

                <h2>
                    Recommendations
                </h2>
                <textarea class="scroller" formControlName="Recomm"></textarea>
                <button class="t-btn-edit" *ngIf="! es.isClosed && lastRecommendations" (click)="addLastRecomm()"
                    style="margin-right: 6em">Previous Recommendations</button>
                <button class="t-btn-edit" *ngIf="! es.isClosed"
                    (click)="atS.showAuto(summaryForm.get('Recomm'),'Recommendations',es.ExamType =='TTE Adults' ? 53 :(es.ExamType =='Stress-Echo' ? 52 : 54))">Auto Text</button>

            </section>
        </div>

        <!-- cell : indicative way of examination -->

        <div class="w50 summary-boxHeight summary-indicative"
            *ngIf="! ls.ishospital && summaryForm.get('HMO').value == 'Leumit' && ls.hasLeumit">
            <section class="m-box">

                <h2>
                    PRN & Treatment Code
                </h2>
                <div class="l-flex-c l-flex-w indicative-fieldset h100">
                    <label class="t-label-top">
                        <span>
                            Payer Reference Num
                        </span>
                        <span class="t-field t-field--number">
                            <input class="t-field-text" type="number" formControlName="PRN" (keyup)="checklength()"
                                [ngClass]="prnerr? 'is-invalid':''" />
                            <div class="t-validation-message">
                                <span>{{prnerr}}</span>
                            </div>
                        </span>
                    </label>

                    <label class="t-label-top">
                        <span>
                            Code of Treatment
                        </span>
                        <span class="t-field t-field--number">
                            <input class="t-field-text" type="number" formControlName="TreatmentCode"
                                [ngClass]="trerr? 'is-invalid':''" (keyup)="checklength()" />
                            <div class="t-validation-message">
                                <span>Invalid Treament Code</span>
                            </div>
                        </span>
                    </label>

                    <button class="save_patieont" (click)="checkPRN()" *ngIf="ls.PRNcheck && checkleumit">
                        Check Validity
                    </button>
                    <div *ngIf="ls.PRNcheck && checkleumit" style="margin-left:auto">Invalid PRNs will not be saved
                    </div>
                </div>

            </section>

        </div>
        <!-- cell : Summary -->

        <!-- endcell : Summary -->

    </div>
    
    <div class="h100 l-flex-w t-boxMargin--spacebyPadding " style='padding-bottom: 1em;place-content: center;' >
            
        <div [ngClass]="es.ExamType != 'Stress-Echo' ? 'signexam' : 'w50' ">
            <div class="m-box" [ngClass]="es.ExamType != 'Stress-Echo' ? 'w50' : ''" *ngIf="ls.userType == 'doctor'">
                <h2>
                    Sign Exam
                </h2>
                <div class="l-flex-c l-flex-w indicative-fieldset h100">
                    <button class="save_patieont" *ngIf="showSign()" (click)="showlock=true">Sign Exam as
                        {{ls.getUserName()}}</button>
                    <div *ngIf="showlock && ! es.isClosed">Are you sure? This will lock the exam at midnight.
                        <span (click)="signClose()" style='margin-left: 2em; cursor: pointer;'>Yes</span>
                        <span (click)="showlock=false" style='margin-left: 2em; cursor: pointer;'>Cancel</span>
                    </div>
                    <span *ngIf="signed">Signed on {{signed | date:'d-M-y H:mm:ss'}} by {{signer}}</span>
                </div>
            </div>
        </div>
    </div>

</main>