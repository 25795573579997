<!-- ========================== Footer (Start) ========================== -->
<footer class="l-footer">
  <ul style="display: flex; align-items: center;" class="footer-menu">
    <button style="display: flex; margin-right: 0.6em" *ngIf="loginService.userType === 'artisanAdmin'" mat-icon-button
      [matMenuTriggerFor]="aboveMenu">
      <mat-icon> more_vert </mat-icon>
    </button>

    <mat-menu #aboveMenu="matMenu" yPosition="above">
      <button class="menu-buttons" (click)="goReportsLeumit()" mat-menu-item>
        Report Leumit Admin
      </button>

      <button class="menu-buttons" [matMenuTriggerFor]="supportcenter" mat-menu-item>
        Support Center
      </button>
    </mat-menu>

    <mat-menu #supportcenter="matMenu">
      <button mat-menu-item [matMenuTriggerFor]="mediahistory" class="inner-menu-buttons" (click)="goMediaTransfer()">
        Multimedia Transfer
      </button>
      <button mat-menu-item [matMenuTriggerFor]="inboxhistory" class="inner-menu-buttons" (click)="goInboxTransfer()">
        Inbox 0 Transfer
      </button>
    </mat-menu>

    <mat-menu #mediahistory="matMenu">
      <button class="inner-menu-buttons" mat-menu-item (click)="goMediaTransferHistory()">
        Media History
      </button>
    </mat-menu>

    <mat-menu #inboxhistory="matMenu">
      <button class="inner-menu-buttons" mat-menu-item (click)="goInboxTransferHistory()">
        Inbox History
      </button>
    </mat-menu>

    <div style="display: flex; align-items: center">
      <li *ngIf="!disableDataCenter()" (click)="goAdvancedSearch()" class="footer-menu-item">
        <button [disabled]="disableDataCenter()" class="footer-menu-link" style="font-weight: bolder; color: #015a6c">
          AWEB Data Center
        </button>
      </li>

      <li class="footer-menu-item">
        <a class="footer-menu-link">
          {{'GUI.Buttons.Support Center' | translate}}
        </a>
      </li>

      <li class="footer-menu-item">
        <a class="footer-menu-link">
          {{'GUI.Buttons.Terms of Use' | translate}}
        </a>
      </li>
    </div>
  </ul>

  <div class="footer-credit">© 2006-{{ currentYear }} Artisan Sonics, Inc</div>
</footer>
<!-- ========================== Footer (End) ========================== -->