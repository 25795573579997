import { Component, OnInit,Output, EventEmitter,Input } from '@angular/core';
import { GalleryService } from '../gallery.service';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-gallerybox',
  templateUrl: './gallerybox.component.html',
  styleUrls: ['./gallerybox.component.scss']
})
export class GalleryboxComponent implements OnInit {
  @Output() closed = new EventEmitter<null>();
  @Output() closeall = new EventEmitter<null>();
  @Input('inbox') inbox:number;
  constructor(public gs:GalleryService, public ls:LoginService) { }

  ngOnInit() {
  }

}
