import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../login.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  constructor(public router: Router, private ls: LoginService, private http: HttpClient) {
    this.ls.loginMissing();
  }

  ngOnInit(): void {
  }

  redirectAdministration(type) {
    this.http.post('/api/administration', {type}).subscribe((data: string) => {
      window.location.href = data;
    })
  }
}
