import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ExamsService } from '../exams.service';
import { LoginService } from '../login.service';
import { StatsService } from '../stats.service';

@Component({
  selector: 'app-printreport',
  templateUrl: './printreport.component.html',
  styleUrls: ['./printreport.component.scss']
})
export class PrintreportComponent implements OnInit {
  examNames = ['TTE Adults','Duplex Carotid','Stress', 'Stress-Echo'];

  examsList;
  now:Date;
  Direction='ltr';
  typeOfPrint = 'simple'
  showCharts = false;
  loaded=false;
  hasImages = false;
  imPerPage = 2;
  mct = 0;
  altHeader:SafeHtml;
  showHeader = true;
  showHistory = true;
  sub: Subscription;
  start;
  end;
  code;
  type;
  constructor(private http:HttpClient,public sts:StatsService,public route: ActivatedRoute, public translate: TranslateService, public ls:LoginService, public es:ExamsService) {
    this.now=new Date();

   }

  ngOnInit() {
    this.translate.use('he-IL');
    this.Direction='rtl';
    let code = this.route.snapshot.params['code'];
    var params = this.route.snapshot.params;
    this.start = new Date(this.route.snapshot.params.start);
    this.end = new Date(this.route.snapshot.params.end);
    this.type = this.route.snapshot.params.type;
    if(this.route.snapshot.params.code){
      this.loadData(code)
    }
    this.sub = this.es.reportSearch(this.start, this.end, this.route.snapshot.params.type, this.route.snapshot.params.hmo, this.ls.doctor).subscribe((data) => {
      this.examsList = data;
      this.loaded = true;
      
    })

  }

  filterExams(exams, type){
    if(!exams)
      return []
    return exams.filter((i) => i.ExamType == type)
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

  loadData(code){
    this.code = code;
    this.http.post('/api/print/resurection', {code:code}).subscribe((data) => {
      this.es.reportSearch(this.start, this.end, this.type, this.route.snapshot.params.hmo, this.ls.doctor).subscribe((data) => {
        this.examsList = data;
        this.loaded = true;
      })
    })
  }

}
