import { AfterViewInit, Component, OnDestroy, OnInit,SecurityContext } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {Patient} from '../../app/patients/patient';
import {PrintService} from '../print.service';
import {PregnancyService} from '../pregnancy.service';
import {MeasgrowthService} from '../measgrowth.service';
import {DopplerService} from '../doppler.service';
import {Anatomies} from '../anatomy/anatomy';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Subscription } from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import { DomSanitizer,SafeHtml} from '@angular/platform-browser';
import {NtService} from '../nt.service';
import { ExamsService } from '../exams.service';
import { FetalechoService } from '../fetalecho.service';
import { LoginService } from '../login.service';
import { UltrasoundMeasurementsService } from './../ultrasound-measurements.service';



@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss']
})
export class PrintComponent implements OnInit, OnDestroy {

  examID:number;
  PID:number;
  now:Date;
  measures:string[];

  scores= [['AFI', 'Amniotic Fluid'],['Tonus', 'Tonus'],['Move','Movements'],['Breath','Breathing']];
  keys:string[];
  Direction='ltr';
  typeOfPrint = 'simple'
  chartData:ChartDataSets[][][] = [];
  i:number=0;
  cervicalMeasures;
  showCharts = false;
  // showMeas = true;
  loaded=false;
  hasImages = false;
  imPerPage = 2;
  mct = 0;
  altHeader:SafeHtml;
  showHeader = true;
  showHistory = true;
  sub: Subscription;
  haveTitle:boolean = false;
  titleArray = ["Early & NT","1st", "NT",'1st & NT', "Early", "2nd/3rd", "Weight Assessment",'Tech Exam', "Late",'Third Scan', "Specialized", "Fetal Echo", "GYN", "Consultation Letter", "Amniocentesis", "CVS","Pediatric Echo"]
  console=console


  constructor(private route: ActivatedRoute, public print:PrintService, public preg: PregnancyService, public meas: MeasgrowthService, public dop: DopplerService,private translate: TranslateService,private sanitizer:DomSanitizer, public nt: NtService, public es:ExamsService, public ls:LoginService,public us: UltrasoundMeasurementsService) { 
    this.now=new Date();
    this.translate.use(this.route.snapshot.params.LANG);
  }

  async ngOnInit() {
    if(this.route.snapshot.params.LANG == 'he-IL' || this.route.snapshot.params.LANG == 'ar-JO'){
      this.Direction='rtl';
    }
    this.typeOfPrint = this.route.snapshot.params.style
    if(this.route.snapshot.params.charts == 'y'){
      this.print.chartsWaited.subscribe(()=>{
        this.loaded=true;
        
      });
    }
    else
      this.loaded=true;
    let code = this.route.snapshot.params['code'];
    this.examID = this.route.snapshot.params.examid;
    this.PID= this.route.snapshot.params.PID;
    if(this.route.snapshot.params["images"] == "y"){
      this.hasImages = true;
      this.imPerPage = this.route.snapshot.params["perpage"];
    }
    if(this.route.snapshot.params["perpage"] == "All"){
      this.hasImages = true;
      this.imPerPage = 2;
      this.print.allImages = true;
    }
    else
      this.print.allImages = false;
    if(this.route.snapshot.params.hasOwnProperty('showheader'))
      this.showHeader = this.route.snapshot.params['showheader'] == 'y';
    this.print.loadExam(this.examID,this.PID, this.hasImages,code);
    this.print.loadMsg.subscribe(()=> {
      if(this.route.snapshot.params.charts == 'y' && this.print.exam.ExamType != 'NT' && this.print.exam.ExamType!= '1st')
        this.showCharts=true;
      if(this.print.env['htmlheader']){
        this.altHeader=this.sanitizer.bypassSecurityTrustHtml(this.print.env['htmlheader']);
      }      
      this.keys=Object.keys(this.meas.measref);
      this.keys.splice(this.keys.indexOf('EFW 2'), 1); 
    });
    let measures = this.meas.getNames();
    
    let sub: Subscription;
    
    /*if(this.meas.historyloaded && this.meas.dataloaded)
      this.getChartData();
    else{
      sub = this.meas.loaded.subscribe(()=>{
        this.getChartData();
      });
    }*/

  
  }

  hasScore(placenta){
    for(let s of this.scores){
      if(placenta['Score'+s[0]] > -1)
        return true;
    }
    return false;
  }

  
  lineChartOptions: ChartOptions  = { responsive: false,scales: {
    xAxes: [{
        type: 'linear',
        position: 'bottom',
        //scaleLabel: { display: true, labelString: 'weeks' }
    }],
    yAxes: [{
      type: 'linear',
    //  scaleLabel: { display: true, labelString: 'mm' }
    }],
    
  },
  aspectRatio: 1,
   
  };

  weightChartOptions: ChartOptions  = { responsive: true,scales: {
    xAxes: [{
        type: 'linear',
        position: 'bottom',
      //  scaleLabel: { display: true, labelString: 'weeks' }
    }],
    yAxes: [{
      type: 'linear',
     // scaleLabel: { display: true, labelString: 'gm' }
    }],
    
  },
  aspectRatio: 1
  };

  getName(name:string):string{
    if(name.indexOf("EFW") > -1){
      return "EFW by "+ this.meas.getMeas(name).WeightType;
    }
    return name;
  }

  ngOnDestroy(){
    this.sub.unsubscribe()
  }
  



}
