import { HttpClient } from "@angular/common/http";
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FormBuilder } from "@angular/forms";
import { Subject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-print-consent",
  templateUrl: "./print-consent.component.html",
  styleUrls: ["./print-consent.component.scss"],
})
export class PrintConsentComponent implements OnInit {
  @ViewChild("canvas", { static: true }) canvas: ElementRef<HTMLCanvasElement>;
  @ViewChild("canvasPat", { static: true })
  canvasPat: ElementRef<HTMLCanvasElement>;
  @ViewChild("canvasApp", { static: false })
  canvasApp: ElementRef<HTMLCanvasElement>;
  @ViewChild("canvasDoc", { static: true })
  canvasDoc: ElementRef<HTMLCanvasElement>;

  AutoIndex;
  concentForm;
  type;
  ctx: CanvasRenderingContext2D;
  ctxPat: CanvasRenderingContext2D;
  ctxApp: CanvasRenderingContext2D;
  typecanvas;
  changeCanvas$ = new Subject();

  OBEXAMS = [
    "1st",
    "NT",
    "Early",
    "2nd/3rd",
    "Weight Assessment",
    "Late",
    "Third Scan",
    "Specialized",
    "Fetal Echo",
  ];

  constructor(
    private route: ActivatedRoute,
    public http: HttpClient,
    private fb: FormBuilder,
    public translate: TranslateService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.translate.use("he-IL");
    this.AutoIndex = this.route.snapshot.params["AutoIndex"];
    this.resetForm();
    this.loadData();
   
  }

  loadData() {
    this.http.get(`/api/consent/data/${this.AutoIndex}`).subscribe((data) => {
      for (let value in data[0]) {
        this.concentForm.controls[value].setValue(data[0][value]);
      }
      if (this.concentForm && this.concentForm.get('patSign').value) {
        this.loadSign("patSign");
      }
      if (this.concentForm && this.concentForm.get('patSignApproved').value) {
        this.loadSign("patSignApproved");
      }
      if (this.concentForm && this.concentForm.get('appSign').value) {
        this.changeDetector.detectChanges();
        this.loadSign("appSign");
      }
      if (this.concentForm && this.concentForm.get('docSign').value) {
        this.loadSign("docSign");
      }
      console.log(this.concentForm);
      
      const body = {
        AutoIndex: this.AutoIndex,
        value: 1,
      }
      this.http.post(`/api/consent/update-printed`,body).subscribe((data) => {})
    });
  }

  resetForm() {
    this.concentForm = this.fb.group({
      AutoIndex: [""],
      PID: [""],
      UID: [""],
      GID: [""],
      PregID: [""],
      ExamID: [""],
      Opener: [""],
      Deleted: [""],
      DeletedBy: [""],
      ID: [""],
      First: [""],
      Last: [""],
      Phone: [""],
      CreationTime: [""],
      refferalDoc: [""],
      ExamType: [""],
      weekNum: [""],
      preformed: [""],
      docReason: [""],
      approved: [""],
      translated: [""],
      transName: [""],
      transRel: [""],
      appRel: [""],
      appName: [""],
      patSign: [""],
      patSignApproved: [""],
      appSign: [""],
      docSign: [""],
      printed: [''],
    });
  }

  setCanvas(type) {
    switch (type) {
      case "canvas":
        this.type = type;
        this.ctx = this.canvas.nativeElement.getContext("2d");
        this.typecanvas = this.canvas;
        this.changeCanvas$.next({ canvas: this.typecanvas, ctx: this.ctx });

        break;
      case "canvasPat":
        this.type = type;
        this.ctx = this.canvasPat.nativeElement.getContext("2d");
        this.typecanvas = this.canvasPat;
        this.changeCanvas$.next({ canvas: this.typecanvas, ctx: this.ctx });

        break;
      case "canvasApp":
        this.type = type;
        this.ctx = this.canvasApp.nativeElement.getContext("2d");
        this.typecanvas = this.canvasApp;
        this.changeCanvas$.next({ canvas: this.typecanvas, ctx: this.ctx });

        break;
      case "canvasDoc":
        this.type = type;
        this.ctx = this.canvasDoc.nativeElement.getContext("2d");
        this.typecanvas = this.canvasDoc;
        this.changeCanvas$.next({ canvas: this.typecanvas, ctx: this.ctx });
        break;
    }
  }

  ngAfterViewInit() {
    
  }

  loadSign(type = null) {
    switch (type) {
      case "patSign":
        var ctx = this.canvas.nativeElement.getContext("2d");
        const imgpatSign = document.createElement("img");
        imgpatSign.onload = function () {
          ctx.drawImage(imgpatSign, 0, 0); // Or at whatever offset you like
        };
        imgpatSign.src = this.concentForm.get("patSign").value;
        break;
      case "patSignApproved":
        var ctx = this.canvasPat.nativeElement.getContext("2d");
        const imgpatSignApproved = document.createElement("img");
        imgpatSignApproved.onload = function () {
          ctx.drawImage(imgpatSignApproved, 0, 0); // Or at whatever offset you like
        };
        imgpatSignApproved.src = this.concentForm.get("patSignApproved").value;
        break;
      case "appSign":
        var ctx = this.canvasApp.nativeElement.getContext("2d");
        const imgappSign = document.createElement("img");
        imgappSign.onload = function () {
          ctx.drawImage(imgappSign, 0, 0); // Or at whatever offset you like
        };
        imgappSign.src = this.concentForm.get("appSign").value;
        break;
      case "docSign":
        var ctx = this.canvasDoc.nativeElement.getContext("2d");
        const imgdocSign = document.createElement("img");
        imgdocSign.onload = function () {
          ctx.drawImage(imgdocSign, 0, 0); // Or at whatever offset you like
        };
        imgdocSign.src = this.concentForm.get("docSign").value;
        break;

      default:
        break;
    }
  }

}
