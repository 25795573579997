<!-- =============== Popup (Start) ================== -->
<div *ngIf="inboxTransferService.toggleSuccessMessage" class="server-popup">
  <div class="server-popup-body">
    <button (click)="togglePopup()" class="server-popup-close" aria-label="close popup"></button>
    {{ inboxTransferService.successMessage }}
  </div>
</div>
<!-- =============== Popup (End) ================== -->

<div class="wrapper">
  <header class="l-header">
    <div class="on-mobile header-logo">
      <img src="assets/icons/logo.svg" alt="Artisan Sonics" />
    </div>
    <div class="l-header-panel" style="
        justify-content: space-between;
        background-color: #dcb16b;
        color: #015a6c;
      ">
      <h3>Support Center: Inbox 0 M/M Transfer</h3>
      <div style="display: flex; flex-direction: row" class="buttons-container">
        <a class="header-menu-link icon-confirm" style="cursor: pointer" aria-label="Close Inbox Transfer "
          (click)="exit()" matTooltip="Close Inbox Transfer and Open AWL"></a>
        <button style="border: 2px solid white" matTooltip="Clear" (click)="inboxTransferService.clearAllFields()"
          class="clear-btn" type="button">
          Clear
        </button>
      </div>
    </div>
  </header>

  <!-- =============== Search Inputs (Start) ================== -->
  <div [formGroup]="inboxTransferService.inboxForm" style="display: flex; flex-direction: row">
    <!-- =============== Incorrect Data Inputs (Start) =============== -->
    <div class="search-wrapper">
      <h4>Search For Media In Inbox 0:</h4>

      <label class="t-label-top" style="padding: 0.5em">
        <span> Clinic: </span>
        <select formControlName="inboxClinic" class="t-select t-field" (change)="inboxTransferService.onClinicSelect()">
          <option *ngFor="let clinic of inboxTransferService.availableClinics" [ngValue]="clinic">
            {{ clinic.ClinicTitle }}
          </option>
        </select>
      </label>

      <ng-container *ngIf="inboxTransferService.inboxForm.get('inboxClinic') as connectors">
        <label class="t-label-top">
          Connector: &nbsp;
        </label>
        <ng-container *ngFor="let connector of connectors.value?.connectors">
          <label class="t-label-top">
            {{ connector }}
            <input type="checkbox" class="custom-check" [value]="connector"
              (change)="inboxTransferService.onConnctorSelect($event.target.checked, connector)">
            <i class="customInput-display radio-print"></i>
          </label>
        </ng-container>
      </ng-container>

      <!-- =============== From Date Input (Start) =============== -->
      <label class="t-label-top" style="padding: 0.5em">
        <span> From: </span>

        <span>
          <input [matDatepicker]="incorrectDate" formControlName="fromIncorrectDate"
            (ngModelChange)="inboxTransferService.onDateChange($event, 'incorrectFromDate')"
            class="t-field field--date mmenstural-field" (click)="incorrectDate.open()" />
          <mat-datepicker #incorrectDate></mat-datepicker>
        </span>

        <span> Time: </span>

        <select formControlName="fromIncorrectHour" class="t-select t-field">
          <option [value]=""></option>
          <option *ngFor="let hour of inboxTransferService.hours" [value]="hour">
            {{ hour }}
          </option>
        </select>
        &nbsp; : &nbsp;
        <select formControlName="fromIncorrectMinute" class="t-select t-field">
          <option [value]=""></option>
          <option *ngFor="let minute of inboxTransferService.minutes" [value]="minute">
            {{ minute }}
          </option>
        </select>
      </label>
      <!-- =============== From Date Input (End) =============== -->

      <!-- =============== To Date Input (Start) =============== -->
      <label class="t-label-top" style="padding: 0.5em">
        <span> To: </span>

        <span>
          <input [matDatepicker]="incorrectToDate" formControlName="toIncorrectDate"
            (ngModelChange)="inboxTransferService.onDateChange($event, 'incorrectToDate')"
            class="t-field field--date mmenstural-field" (click)="incorrectToDate.open()" />
          <mat-datepicker #incorrectToDate></mat-datepicker>
        </span>

        <span> Time: </span>

        <select formControlName="toIncorrectHour" class="t-select t-field">
          <option [value]=""></option>
          <option *ngFor="let hour of inboxTransferService.hours" [value]="hour">
            {{ hour }}
          </option>
        </select>
        &nbsp; : &nbsp;
        <select formControlName="toIncorrectMinute" class="t-select t-field">
          <option [value]=""></option>
          <option *ngFor="let minute of inboxTransferService.minutes" [value]="minute">
            {{ minute }}
          </option>
        </select>
      </label>
      <!-- =============== To Date Input (End) =============== -->

      <button matTooltip="Search" class="advanced-search-btn" type="button"
        (click)="inboxTransferService.searchInbox0Media()" [disabled]="inboxTransferService.disableSearchInbox()">
        Search
      </button>
    </div>
    <!-- =============== Incorrect Data Inputs (End) =============== -->

    <!-- =============== Correct Data Inputs (Start) =============== -->
    <div class="search-wrapper">
      <h4>Exam To Move Data To:</h4>
      <label class="t-label-top" style="padding: 0.5em">
        <span> Clinic: </span>
        <select formControlName="examClinic" class="t-select t-field">
          <option *ngFor="let clinic of inboxTransferService.availableClinics" [ngValue]="clinic">
            {{ clinic.ClinicTitle }}
          </option>
        </select>
      </label>

      <label class="t-label-top" style="padding: 0.5em">
        <span> Patient ID: &nbsp; </span>
        <span class="t-field t-field--number">
          <input formControlName="patientId" class="t-field-text number-input" type="text" />
        </span>
      </label>
      <!-- =============== From Date Input (Start) =============== -->

      <label class="t-label-top" style="padding: 0.5em">
        <span> From: </span>
        <span>
          <input [matDatepicker]="correctDate" formControlName="fromCorrectDate"
            (ngModelChange)="inboxTransferService.onDateChange($event, 'correctFromDate')"
            class="t-field field--date mmenstural-field" (click)="correctDate.open()" />

          <mat-datepicker #correctDate></mat-datepicker>
        </span>

        <span> Time: </span>
        <select formControlName="fromCorrectHour" class="t-select t-field">
          <option [value]=""></option>
          <option *ngFor="let hour of inboxTransferService.hours" [value]="hour">
            {{ hour }}
          </option>
        </select>
        &nbsp; : &nbsp;
        <select formControlName="fromCorrectMinute" class="t-select t-field">
          <option [value]=""></option>
          <option *ngFor="let minute of inboxTransferService.minutes" [value]="minute">
            {{ minute }}
          </option>
        </select>
      </label>
      <!-- =============== From Date Input (End) =============== -->

      <!-- =============== To Date Input (Start) =============== -->
      <label class="t-label-top" style="padding: 0.5em">
        <span> To: </span>
        <span>
          <input [matDatepicker]="correctToDate" formControlName="toCorrectDate"
            (ngModelChange)="inboxTransferService.onDateChange($event, 'correctToDate')"
            class="t-field field--date mmenstural-field" (click)="correctToDate.open()" />
          <mat-datepicker #correctToDate></mat-datepicker>
        </span>

        <span> Time: </span>
        <select formControlName="toCorrectHour" class="t-select t-field">
          <option [value]=""></option>
          <option *ngFor="let hour of inboxTransferService.hours" [value]="hour">
            {{ hour }}
          </option>
        </select>
        &nbsp; : &nbsp;
        <select formControlName="toCorrectMinute" class="t-select t-field">
          <option [value]=""></option>
          <option *ngFor="let minute of inboxTransferService.minutes" [value]="minute">
            {{ minute }}
          </option>
        </select>
      </label>
      <!-- =============== To Date Input (End) =============== -->

      <button matTooltip="Search" class="advanced-search-btn" type="button" (click)="inboxTransferService.searchExam()"
        [disabled]="inboxTransferService.disableSearchExam()">
        Search
      </button>
    </div>
    <!-- =============== Correct Data Inputs (End) =============== -->
  </div>
  <!-- =============== Search Inputs (End) ================== -->

  <!-- =============== Search Results (Start) ================== -->
  <div [formGroup]="inboxTransferService.inboxForm" style="display: flex; flex-direction: row">

    <!-- ======= Inbox 0 Search (Start) ======= -->
    <main class="w50" style="padding: 1em">
      <ng-container *ngIf="inboxTransferService.incorrectResults">
        <h4>Inbox Results:</h4>
        <div *ngFor="let media of inboxTransferService.incorrectResults">
          <div class="l-flex patient-item">
            <div class="w100 patient-item-link">
              <div class="l-table h100">
                <span class="l-td-m patient-item-cell patient-name-first" style="width: auto">
                  <strong>
                    Inbox ID: {{ media.inboxID }}
                  </strong>
                </span>
                <span class="l-td-m patient-item-cell patient-name-first" style="width: auto">
                  <strong>
                    Conn: {{ media.Connector }}
                  </strong>
                </span>
                <span class="l-td-m patient-item-cell patient-name-first" style="width: auto">
                  <strong>
                    Pics: {{ media.PicturesCount }}
                  </strong>
                </span>
                <span class="l-td-m patient-item-cell patient-name-first" style="width: auto">
                  <strong>
                    Clips: {{ media.ClipsCount }}
                  </strong>
                </span>
                <span class="l-td-m patient-item-cell patient-name-first" style="width: auto">
                  <strong>
                    HTML: {{ media.HTMLCount }}
                  </strong>
                </span>
                <span class="l-td-m patient-item-cell patient-id" style="width: auto">
                  <strong>
                    Full: {{ media.FullCount }}
                  </strong>
                </span>
                <span class="l-td-m patient-item-cell patient-id" style="width: auto">
                  <strong>
                    Pat ID: {{ media.PatientID }}
                  </strong>
                </span>
                <span class="l-td-m patient-item-cell patient-id" style="width: auto">
                  <strong>
                    Total: {{ media.Total }}
                  </strong>
                </span>

                <span class="l-td-m patient-item-cell-check" style="width: 3.3em; padding: 0;"
                  (click)="inboxTransferService.handleRadioClick($event)">
                  <label class="t-label-top"
                    (dblclick)="inboxTransferService.inboxForm.get('selectedInbox').setValue(null)">
                    <input type="radio" [formControl]="inboxTransferService.inboxForm.get('selectedInbox')"
                      (ngModelChange)="inboxTransferService.updateDateToSelected($event)" [value]="media"
                      name="incorrectExam" class="custom-check -sq" style="height: 0;
                      width: 0;" />
                    <i class="customInput-display -sq"></i>
                  </label>

                </span>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div *ngIf="inboxTransferService.toggleErrorMessage1" class="not-found-error">
        <h3 style="text-align: center">
          {{ inboxTransferService.errorMessage1 }}
        </h3>
      </div>
    </main>
    <!-- ======= Inbox 0 Search (End) ======= -->

    <main class="w50" style="padding: 1em">
      <ng-container *ngIf="inboxTransferService.correctResults">
        <h4>Exam Results To Move Data To:</h4>

        <div *ngFor="let exam of inboxTransferService.correctResults"
          (click)="inboxTransferService.goToExamDetails(exam)">
          <div class="l-flex patient-item" style="cursor: pointer;">
            <div class="w100 patient-item-link">
              <div class="l-table h100">
                <div matTooltip="Click to View Exam">
                  <span class="l-td-m patient-item-cell patient-name-first" style="width: auto">
                    <strong>{{ exam.First }} {{ exam.Last }}</strong>
                  </span>
                  <span class="l-td-m patient-item-cell patient-name-first" style="width: auto">
                    <strong> Patient ID: {{ exam.ID }} </strong>
                  </span>
                  <span class="l-td-m patient-item-cell patient-id" style="width: auto">
                    <strong>
                      Exam Date: {{ exam.CreationTime | date : "d/M/yy, HH:mm" }}
                    </strong>
                  </span>
                  <span class="l-td-m patient-item-cell patient-id" style="width: auto">
                    <strong> Exam ID: {{ exam.AutoIndex }} </strong>
                  </span>
                  <span class="l-td-m patient-item-cell patient-id" style="width: auto">
                    <strong> Media Count: {{ exam.MediaCount }} </strong>
                  </span>
                </div>
                <span class="l-td-m patient-item-cell-check" style="width: 3.3em; padding: 0;"
                  (click)="inboxTransferService.handleRadioClick($event)">
                  <label class="t-label-top"
                    (dblclick)="inboxTransferService.inboxForm.get('selectedExam').setValue(null)">
                    <input type="radio" [formControl]="inboxTransferService.inboxForm.get('selectedExam')"
                      [value]="exam" name="correctExam" class="custom-check -sq" style="height: 0;
                      width: 0;" />
                    <i class="customInput-display -sq"></i>
                  </label>
                </span>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div *ngIf="inboxTransferService.toggleErrorMessage2" class="not-found-error">
        <h3 style="text-align: center">
          {{ inboxTransferService.errorMessage2 }}
        </h3>
      </div>
    </main>
  </div>
  <!-- =============== Search Results (End) ================== -->

  <!-- =============== Moving Section (Start) =============== -->
  <div [formGroup]="inboxTransferService.inboxForm" style="display: grid; align-items: center; justify-content: center">
    <div *ngIf="inboxTransferService.toggleDataTransferMessage" class="not-found-error">
      <h3 style="text-align: center">
        {{ inboxTransferService.dataTransferMessage }}
      </h3>
    </div>

    <button matTooltip="Move Data" class="advanced-search-btn" type="button"
      [disabled]="inboxTransferService.disableMoveData()" (click)="inboxTransferService.moveInbox0()">
      Move Data
    </button>
  </div>
  <!-- =============== Moving Section (End) =============== -->

  <!-- =============== Moving Result (Start) =============== -->
  <div *ngIf="inboxTransferService.movingDataResult"
    style="display: grid; align-items: center; justify-content: center">
    <main style="padding: 1em">
      <h4>
        {{
        inboxTransferService.revert
        ? "Updated New Data:"
        : "Reverted To Original Data:"
        }}
      </h4>
      <table>
        <thead>
          <tr>
            <th>Patient Name</th>
            <th>Patient ID</th>
            <th>Exam ID</th>
            <th>Inbox ID</th>
            <th>Doctor UID</th>
            <th>Date</th>
            <th>File Type</th>
          </tr>
        </thead>
        <tbody matTooltip="Click to View Exam" *ngIf="inboxTransferService.correctResults as exam"
          (click)="inboxTransferService.goToExamDetails(exam[0])">
          <tr *ngFor="let movingResult of inboxTransferService.movingDataResult">
            <td>{{ movingResult.First + " " + movingResult.Last }}</td>
            <td>{{ movingResult.ID }}</td>
            <td>{{ movingResult.examId }}</td>
            <td>{{ movingResult.inboxID }}</td>
            <td>{{ movingResult.UID }}</td>
            <td>{{ movingResult.date | date: "d/M/yy, HH:mm" }}</td>
            <td>{{ movingResult.file_type }}</td>
          </tr>
        </tbody>
      </table>
    </main>
  </div>
  <!-- =============== Moving Result (End) =============== -->

  <!-- =============== Finish Section (Start) =============== -->
  <div [formGroup]="inboxTransferService.inboxForm" style="display: grid; align-items: center; justify-content: center">
    <label class="t-label-top" style="padding: 0.5em">
      <span> Document Path: &nbsp; </span>
      <span class="t-field t-field--number number-input">
        <input class="t-field-text number-input" formControlName="documentPath" type="text" />
      </span>
    </label>

    <div style="align-items: center; justify-content: center">
      <button matTooltip="Revert Data Move" class="advanced-search-btn" type="button"
        [disabled]="!inboxTransferService.revert" (click)="inboxTransferService.revertDataMove()">
        Revert Data Move
      </button>
      <button matTooltip="Finish Transfer" class="advanced-search-btn" type="button"
        (click)="inboxTransferService.toggleValidationMsg()" [disabled]="inboxTransferService.disableFinishTransfer()">
        Finish Transfer
      </button>
    </div>

    <div *ngIf="inboxTransferService.toggleValidation" class="t-validation-wrap">
      <button class="t-menu-item is-invalid"></button>

      <div class="t-validation-message" style="width: 20em; left: 1em; font-size: 1.5em; margin: -7.3em 12em">
        <span style="border-radius: 8px">
          <p style="margin-bottom: 1em">
            You are about to complete the transfer without saving the Transfer
            Document files by the user.
          </p>
          <div style="
                display: flex;
                justify-content: space-between;
                margin-left: 1vw;
                margin-right: 1vw;
              ">
            <button class="t-menu-item" (click)="inboxTransferService.finishTransfer()">
              Yes
            </button>
            <button class="t-menu-item" (click)="inboxTransferService.toggleValidation = false">
              No
            </button>
          </div>
        </span>
      </div>
    </div>
  </div>
  <!-- =============== Finish Section (End) =============== -->

  <aside class="l-sidebar l-flex l-column">
    <img class="off-mobile sidebar-logo" src="../../assets/icons/logo.svg" alt="Artisan Sonics" />

    <div class="userPanel"></div>
    <nav class="mainNav l-fg1 scroller"></nav>

    <app-docpanel></app-docpanel>
  </aside>

  <app-bottombar></app-bottombar>
</div>