<div class="l-flex-w summary" [formGroup]="gs.gastroForm">
    <section class="section detail-row">
        <span style="align-self: center;width: 20%;" matTooltip="Double Click To Reset" (dblclick)="gs.gastroForm.get(item.db).setValue(-1);gs.gastroForm.get(item.db+'Comment').setValue(null)">{{item.name}}</span>
        <div class="anatomy-twin-radios" style="width: 40%;" (dblclick)="gs.gastroForm.get(item.db).setValue(-1);gs.gastroForm.get(item.db+'Comment').setValue(null)">
                    
            <label class="observe">
    
                <input type="radio" class="custom-check customInput"
                    [formControlName]="item.db" [name]="item.db" [value]="0"/>
                <i class="customInput-display"></i>
                <span>
                    Normal
                </span>
            </label>
            <label class="not-observe">

                <input type="radio" [formControlName]="item.db" [name]="item.db"
                    class="custom-check customInput" [value]="1"/>
                <i class="customInput-display"></i>
                <span>
                    Abnormal
                </span>

            </label>
        </div>
        <div style="width: 40%;margin-left: 0.5em;border-left: 1px solid #348596;">
            <!-- <div class="summary m-box"> -->
                <textarea class="fulltext" style="width: 95%;float: right;padding: 0.37em;border-radius: 15px;border-color: #015a6c;height: 100%;" [formControlName]="item.db+'Comment'" matTooltip="Comment"></textarea>
                <!-- <button class="t-btn-edit" *ngIf="! es.isClosed"
                (click)="atS.showAuto(gs.gastroForm.get(item.db+'Comment'),item.name +'Comments',132+id)">Auto Text</button> -->
            <!-- </div> -->
        </div>
        <div style="position: relative;">
            <button class="t-btn-edit" style="width: max-content" *ngIf="! es.isClosed"
                (click)="atS.showAuto(gs.gastroForm.get(item.db+'Comment'),item.name +' Comments',132+id)">Auto Text</button>
        </div>
    </section>
</div>
