import { Component, EventEmitter, OnInit, Output, OnDestroy } from '@angular/core';
import {CommerrorService} from '../commerror.service';
import { Router } from '@angular/router';
import { LoginService } from '../login.service';
import { Subject, Subscription } from 'rxjs';
import { ExamsService } from '../exams.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-commerror',
  templateUrl: './commerror.component.html',
  styleUrls: ['./commerror.component.scss']
})
export class CommerrorComponent implements OnInit, OnDestroy {
  sub = new Subscription()
  constructor(
    public ce: CommerrorService,
    private router: Router,
    public ls: LoginService,
    public examsServices: ExamsService,
    private http: HttpClient
  ) {}

  ngOnInit() {
  }

  close(save = null){
    if(save){
      if(this.ce.concentForm){
        this.ce.saveConcent$.next(true);
      } else if (this.ce.alreadyHasExam) {
        this.examsServices.continueExam(true); 

      } else {
        this.ce.saveCalendar$.next(true);
      }

    }else{
      if(this.ce.concentForm) {
        this.ce.saveConcent$.next(false);
      } else if (this.ce.alreadyHasExam) {
        this.examsServices.continueExam(false); 
      } else {
        this.ce.saveCalendar$.next(false);
      }
    }
    this.ce.resetErr();
  }

  cardioErr(){
    this.ce.resetErr();
    if(this.ce.isCardio)
      this.ce.ecnext$.next()
  }

  login(data = null){
    this.ce.isCardio = false;
    if(data){
      this.ce.resetErr();
      this.ls.contLogin = true;
    } else {
      // this.ls.clearCookies();
      this.ce.resetErr();
      this.router.navigate(['/']);
    }
  }

  refreshSession() {
    return this.http.get('/api/refresh-session').subscribe((data: any) => {
      this.ce.resetErr();
      // this.ls.updateSessionExpiration(data.newExpirationDate);
      // this.ls.refreshTimer();
    });
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe()
    }
  }

}
