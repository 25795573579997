import { Component, OnInit,OnDestroy } from '@angular/core';
import { UntypedFormBuilder,UntypedFormGroup,FormControl } from '@angular/forms';
import {ExamsService} from '../exams.service';
import {AutotextService} from '../autotext.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-ovaries',
  templateUrl: './ovaries.component.html',
  styleUrls: ['./ovaries.component.scss']
})
export class OvariesComponent implements OnInit,OnDestroy {

  AdnexaForm: UntypedFormGroup;
  lnormal: string = "Normal";
  rnormal: string = "Normal";
  formSub:Subscription;
  mediaSub: Subscription;
  ovaryChangeSub:Subscription;
  atSTitleLeftOvaryComments = this.translate.instant("GUI.Auto Text.Titles.Left Ovary Comments");
  atSTitleRightOvaryComments = this.translate.instant("GUI.Auto Text.Titles.Right Ovary Comments");
  atSTitlePODComments = this.translate.instant("GUI.Auto Text.Titles.POD Comments");


  constructor(
    public atS: AutotextService,
    public es: ExamsService,
    private fb: UntypedFormBuilder,
    private translate: TranslateService
  ) {
    this.AdnexaForm = this.fb.group({
      LObserved: [''],
      LSizeX: [''],
      LSizeY:  [''],
      LSizeZ:  [''],
      LSizeVol:[''],
      LPI:  [''],
      LPIStatus:  [''],
      LRI:  [''],
      LRIStatus:  [''],
      LSD:[''],
      LSDStatus:[''],
      RObserved:  [''],
      RSizeX:  [''],
      RSizeY:  [''],
      RSizeZ:  [''],
      RSizeVol:[''],
      RPI:  [''],
      RPIStatus:  [''],
      RRI: [''],
      RRIStatus: [''],
      RSD: [''],
      RSDStatus: [''],
      Fluid:[''],
      POD:[''],
      Comments:[''],
      LComments:[''],
      RComments:[''],
      DiagnosisCodes: [''],
      Diagnosis: ['']
    });
   }

  ngOnInit() {
    this.es.fillForm(this.AdnexaForm,"adnexa",false,true);
    this.formSub = this.es.formLoaded$.subscribe(()=>{
      this.lnormal = this.AdnexaForm.get('LObserved').value;
      this.rnormal = this.AdnexaForm.get('RObserved').value;
      this.AdnexaForm.get('RSizeVol').disable({emitEvent:false});
      this.AdnexaForm.get('LSizeVol').disable({emitEvent:false});

      if(!this.es.isClosed){
       // Subscribe to changes in SizeX, SizeY, and SizeZ after loaded in the first time
    ['LSizeX', 'LSizeY', 'LSizeZ', 'RSizeX', 'RSizeY', 'RSizeZ'].forEach(controlName => {
      const side = controlName.startsWith('L') ? 'L' : 'R';
      this.ovaryChangeSub=this.AdnexaForm.get(controlName).valueChanges.subscribe((data) => {
        this.updateVolume(side);
      });
    });
  }
    });
    this.es.getSRs();
    if(!this.es.isClosed)
      this.mediaSub =  this.es.mediaArrived$.subscribe((mediatype)=>{
        if(mediatype == 'html')
          this.es.fillForm(this.AdnexaForm,"adnexa",false,true);
      });
  }

  ngOnDestroy(){
    this.formSub.unsubscribe();
    if(this.mediaSub)
      this.mediaSub.unsubscribe();
      if(this.ovaryChangeSub)
      this.ovaryChangeSub.unsubscribe();
  }

  volume(side:string):number{
    let sd = 'L';
    if(side == 'r')
      sd = 'R';
    if(this.AdnexaForm.get(sd+'SizeX').value && this.AdnexaForm.get(sd+'SizeY').value && this.AdnexaForm.get(sd+'SizeZ').value)
    //the simplified formula 0.5 × length × width × thickness is used
      return (0.5 * this.AdnexaForm.get(sd+'SizeX').value * this.AdnexaForm.get(sd+'SizeY').value* this.AdnexaForm.get(sd+'SizeZ').value) / 1000;
    return null;
  }

  setN(side:string, val:string){
    this.AdnexaForm.get(side + "Observed").setValue(val);
  }

  reset(item:string){
    if(! this.es.isClosed){
      this.AdnexaForm.get(item).setValue(-1);
    }
  }

  updateVolume(side: 'L' | 'R'): void {
    const sd = side === 'R' ? 'R' : 'L';
    const volumeControl = this.AdnexaForm.get(sd + 'SizeVol');
    const sizeX = this.AdnexaForm.get(sd + 'SizeX').value;
    const sizeY = this.AdnexaForm.get(sd + 'SizeY').value;
    const sizeZ = this.AdnexaForm.get(sd + 'SizeZ').value;

    if (sizeX && sizeY && sizeZ) {
      // Calculate volume and update the control value
      const calculatedVolume = (0.5 * sizeX * sizeY * sizeZ) / 1000;
      volumeControl.setValue(calculatedVolume);
    } else {
      volumeControl.setValue(null);
    }
  
  }



}
