import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder,FormGroup,FormControl } from '@angular/forms';
import {ExamsService} from '../exams.service';
import { Anatomies} from './anatomy';
import {AutotextService} from '../autotext.service';
import {DiagnosisService} from '../diagnosis.service';
import {LoginService} from '../login.service';
import { Subscription } from 'rxjs';
import { FetalechoService } from '../fetalecho.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-anatomy',
  templateUrl: './anatomy.component.html',
  styleUrls: ['./anatomy.component.scss']
})
export class AnatomyComponent implements OnInit {

  Items: Anatomies;
  Group: string = "cns";
  fetusSub;
  diag1: number;
  diag2: number;
  diag3: number;
  Diagnoses;
  sub: Subscription;
  loaded = false;
  examCreationTime = new Date();
  doubleVersionTime:Date 
  newVersion:boolean

  atSTitleComments = this.translate.instant('GUI.Auto Text.Titles.Comments');
  // TODO: fix the autotext titles
  atSTitleSystem = this.translate.instant(`GUI.Anatomy.Titles.{SYSTEM HERE}`);
  console = console;
  constructor(
    public fes: FetalechoService,
    public es: ExamsService,
    public atS: AutotextService,
    private ds: DiagnosisService,
    public ls: LoginService,
    public fb: UntypedFormBuilder,
    private translate: TranslateService,
  ) {
    this.Diagnoses=[];
    this.examCreationTime=new Date(es.CreationTime);
    this.doubleVersionTime=new Date(ls.doubleOrgans);
    this.newVersion=this.examCreationTime>=this.doubleVersionTime
    this.Items = new Anatomies(new UntypedFormBuilder, this.ls.anatomyAltNames,this.newVersion);
    
  }

  ngOnInit() {
    // this.getForm();
    this.fetusSub = this.es.fetusChange$.subscribe(()=>{
      this.loaded = false;
      this.Diagnoses=[];
      for(let i in this.Items[this.Group+"form"]){
        this.Items[this.Group+"form"][i].setValue('',{emitEvent: false});
      }
      if(this.es.ExamType != 'Fetal Echo')
        this.es.fillUngrouped(this.Items[this.Group+"form"],this.Group, true);
    });
    if(this.es.ExamType==='Fetal Echo' || this.es.ExamType === 'Pediatric Echo'){
      this.setGroup('chestFetal')
    }else{
      this.setGroup('cns')
    }
    this.sub = this.es.formLoaded$.subscribe((data) =>{
      this.loaded = true;
    })


  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.fetusSub.unsubscribe();
    this.sub.unsubscribe();
  }

  setGroup(gname: string){  
    this.Group=gname;
    this.Diagnoses=[];
    if(this.es.ExamType != 'Fetal Echo' && this.es.ExamType !== 'Pediatric Echo')
      this.getForm();
  }

  
  getForm(){
    this.loaded = false;
    this.es.fillUngrouped(this.Items[this.Group+"form"],this.Group, true);
    
  }


  getClass(item: any): string {
    const dbNote = this.Items[this.Group + "form"][item.db + "Note"];
    const dbDoubleNote =
      this.Items[this.Group + "form"][
        item.double ? item.name + "Note" : item.db + "Note"
      ];

    if (item !== null && dbNote && dbNote.value) {
      return "type-v";
    } else if (item !== null && dbDoubleNote && dbDoubleNote.value)
      return "type-v";
    {
    }

    return "type-plus";
  }

  clear(grp:string, name: string){
    if(! this.es.isClosed)
      this.Items[grp+'form'][name].setValue(-1);
  }

  groupID(gname: string): number {
    switch (gname) {
      case "cns":
        return 1;
      case "face":
        return 2;
      case "chest":
        return 3;
      case "digest":
        return 4;
      case "urology":
        return 5;
      case "skeleton":
        return 6;
      case "chestFetal":
        return 7;
    }
    return 0;
  }

  groupName(gname: string): string {
    switch (gname) {
      case "cns":
        return "CNS";
      case "face":
        return "Face & Neck";
      case "chest":
        return "Chest & Cardio";
      case "chestFetal":
        return "Fetal Heart Structure";
      case "digest":
        return "Digest";
      case "urology":
        return "Urology";
      case "skeleton":
        return "Skeleton";
    }
    return "";
  }

  setDefaults() {
    this.es.Defaults = 1;
    this.es.setDefaults().subscribe(
      (data) => {
        this.getForm(); //reload this one
      },
      (err) => {
        console.log(err);
        this.es.Defaults = null;
      }
    );
  }

  reformatItemName(itemName: string): string {
    let formattedItemName = "";
    if (itemName === "Cavum S.P.") {
      formattedItemName = "Cavum SP";
      return formattedItemName;
    } else {
      formattedItemName = itemName;
      return formattedItemName;
    }
  }
}
