import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ExamsService } from './exams.service';
import { UntypedFormBuilder, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BrowserModule } from '@angular/platform-browser';
//import { browser } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class GalleryService {
  Clips;
  Images;
  Fulls;
  SRs;
  HasDemos = false;
  imageGroup: UntypedFormGroup;
  Timeline;
  public currentImg: number;
  public isOpen = false;
  public mode = 'img';
  public showSelect = false;

  constructor(private http: HttpClient, private es: ExamsService, private fb: UntypedFormBuilder) {
    this.imageGroup = this.fb.group({
      Text: [''],
      imageSelection: this.fb.array([])
    });
    this.http.get('/api/demogallery').subscribe((data) => {
      this.HasDemos = data["demo"];
    }, (error) => {
      console.log(error);
    });
  }

  get imageSelection() {
    return this.imageGroup.get('imageSelection') as UntypedFormArray;
  }

  saveText(i, text){
    this.http.post(`/api/newExams/saveImageText/${this.Images[i][0]}`,  {text: text}).pipe(debounceTime(500), distinctUntilChanged()).subscribe((data) => {
      console.log(data);
    })
    
  }
  getGallery(cb = null, inbox: number) {
    this.imageGroup = this.fb.group({
      Text: [''],
      imageSelection: this.fb.array([])
    });
    let req = this.http.get('/api/gallerydetails', { params: { 'ExamID': this.es.examID.toString() } });
    if (inbox != null)
      req = this.http.get('/api/gallerydetails', { params: { 'InboxID': inbox.toString() } });
    req.subscribe(
      (data) => {
        this.Timeline = data["timeline"];
        this.Clips = data["clips"];
        this.Images = data["images"];
        this.Fulls = data["fulls"];

        for (let i = 0; i < this.Images.length; i++) {
          this.imageSelection.push(this.fb.control(this.Images[i][1]));
          this.imageSelection.at(i).valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(data => {
            this.save(i);
          });
        }
        if (cb != null)
          cb();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  selectAll(select = true) {
    for (let i = 0; i < this.imageSelection.length; i++) {
      this.imageSelection.at(i).setValue(select);
    }
  }

  save(item: number) {
    this.http.get('/api/printimg/' + this.Images[item][0] + '/' + this.imageSelection.at(item).value).subscribe(
      (data) => { },
      (error) => {
        console.log(error);
      }
    );
  }

  assignInbox(inbox: number) {
    this.http.post('/api/assigninbox', { 'InboxID': inbox, 'ExamID': this.es.examID }).subscribe((data) => { }, (error) => {
      console.error(error)
    });
  }

  delete() {
    let item = 0;
    try {
      switch (this.mode) {
        case 'img':
          item = this.Images[this.currentImg][0]
          break;
        case 'clip':
          item = this.Clips[this.currentImg];
          break;
        case 'full':
          item = this.Fulls[this.currentImg];
          break;
      }
    }
    catch (e) {
      console.log(e);
    }
    if (item > 0)
      this.http.delete('/api/media/' + item).subscribe((data) => { }, (error) => {
        console.log(error);
      });
  }
  downloadImages() {
    var temp = [];
    this.http.get('/api/gallerydetails', { params: { 'ExamID': this.es.examID.toString() } }).subscribe(
      (data) => {
        var images = data['images']
        if (images.filter(i => i[1] == true).length === 1) {
          var tArray = images.filter(i => i[1] == true).pop()
          
          // downloads.download(`/api/getmedia/${tArray[0]}/n/y`,'exam/1.jpg');
          window.open(`/api/getmedia/${tArray[0]}/n/y`,'_blank'); 
        } else {
          window.open(`/api/disk/${this.es.examID}/y`,'_blank'); 
        }
      }
    )
    this.showSelect =false;
  }

  cancel() {
    console.log("cancel");
  }

}
