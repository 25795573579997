import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  Input,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import {PatientsService} from '../patients.service';
import {Patient} from '../../app/patients/patient';
import {PregnancyService} from '../pregnancy.service';
import {AutotextService} from '../autotext.service';
import {ExamsService} from '../exams.service';
import { getLocaleDateTimeFormat } from '@angular/common';
import { LoginService } from '../login.service';
import { UntypedFormBuilder, FormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TranslateService } from "@ngx-translate/core";



@Component({
  selector: 'app-pregnancy',
  templateUrl: './pregnancy.component.html',
  styleUrls: ['./pregnancy.component.scss']
})

export class PregnancyComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() setFetuses = new EventEmitter<number>();
  @Input('modality') modality: string='Ultrasound'
  @ViewChild("lmpField") lmpField: ElementRef;
  show = false
  pat: Patient;
  weeks=[];
  days=[];
  lmpmin: string;
  lmpmax: string;
  today:string;
  EctopicPos = {};
  fetuses: string;
  insitu:boolean = true;
  showclose=false;
  isClosed = false;
  showText=false;
  lastMed:string;
  awlLMPwarn=false;
  invalidlmp='';
  ectopts = ["Cervical","Tubal","Ovarian","Peritoneal","Abdominal"];
  IsWeightAss = false;
  typeOfUser: string;
  showHistoryB: boolean = false;
  fetalEchoForm: UntypedFormGroup;
  lastDiagnosis: string;
  lastRecommendations: string;
  mediaSub: Subscription;
  canChangeFetuses: boolean = true;
  public lmpAutoFocus: boolean = false;
  public lmpFocusSub: Subscription;
  //formVals = new PregVals();

  atSTitlePurpose = this.translate.instant("pregnancy.Purpose_of_Exam");

  constructor(
    private patService: PatientsService,
    private fb: UntypedFormBuilder,
    private ls: LoginService,
    public ps: PregnancyService,
    public atS: AutotextService,
    public es: ExamsService,
    private translate: TranslateService,
  ) {
    for(let i=2;i < 45; i++){
      this.weeks.push(i);
    }
    for(let i =0; i < 7; i++){
      this.days.push(i);
    }
    this.fetalEchoForm = this.fb.group({
      Repeat1: [''],
      Repeat2: [''],
      Diagnosis: [''],
      Recomm: [''],
      ReferingDoc: [''],
      ExamMethod: [''],
      DiagnosisCodes:[''],
      Anomalies:[''],
      Abdominal:[''],
      Vaginal:[''],
      Transperineal: [''],
      Rectal: [''],
      DiagnosisCopy: [''],
      HMO: [''],
      PRN: [''],
      TreatmentCode: [''],
      FollowUpExam: [''],
      IndicationCodes:[''],
      AnomaliesIndication:[''],
      BloodType:['']
    });
    
    let d = new Date();
    this.today = d.getUTCFullYear() + '-'+PregnancyComponent.add0(d.getMonth()+1)+'-'+PregnancyComponent.add0(d.getDate());
    let lmpd = Date.now() - 2*7*24*60*60000;//2 weeks ago
    d.setTime(lmpd)
    this.lmpmax = d.getUTCFullYear() + '-'+PregnancyComponent.add0(d.getMonth()+1)+'-'+PregnancyComponent.add0(d.getDate());

    lmpd = Date.now() - 44*7*24*60*60000;//44 weeks ago
    d.setTime(lmpd)
    this.lmpmin = d.getUTCFullYear() + '-'+PregnancyComponent.add0(d.getMonth()+1)+'-'+PregnancyComponent.add0(d.getDate());
    //this.pregForm=ps.pregForm;
    if(! this.es.isClosed){
      this.es.getLastHistory().subscribe((data)=>{
        this.lastMed = data['history'];
      },(err)=>{
        console.log(err);
      });
    }
    if(! this.es.isClosed){
      this.es.getLastSummary().subscribe((data)=>{
        this.lastDiagnosis = data["diagnosis"];
        this.lastRecommendations = data['recommendations'];
      },(err)=>{
        console.log(err);
      });
    }
  }

  static add0(val: number): string{
    if(val > 9)
      return val.toString();
    return "0"+ val.toString();
  }
  
  ngOnInit() {
    
    if(this.es.ExamType === 'Weight Assessment' || this.es.ExamType == 'Tech Exam'){
      this.IsWeightAss = true;
      this.typeOfUser = this.ls.getUserType();
    }
    if(this.es.ExamType == 'Fetal Echo'){
      this.es.fillForm(this.fetalEchoForm,"summary",false,true);
      this.show = true;
    }
    this.pat= this.patService.getPat();
    this.ps.getCervicalMeas();
    if(! this.ps.pregForm.get('LMP').value && this.es.AWLlmp){
      this.ps.pregForm.get('LMP').setValue(this.es.AWLlmp);
    }
    if(this.ps.pregForm.get('LMP').value && this.es.AWLlmp && this.ps.pregForm.get('LMP').value != this.es.AWLlmp)
      this.awlLMPwarn = true;
    else
      this.mwl();

    if(!this.es.isClosed)
      this.mediaSub =  this.es.mediaArrived$.subscribe((mediatype)=>{
        if(mediatype == 'html')
          this.ps.getCervicalMeas();
      });

       
    if(this.modality=='Ultrasound'){
      this.canChangeFetuses=false;
      this.ps.pregForm.get('NumFetuses')?.disable();
    }
    
    this.lmpAutoFocus = this.es.shouldAutoFocus;
  }

  ngAfterViewInit() {
    if (this.es.shouldAutoFocus) {
      this.lmpField.nativeElement.focus();
    }

    this.lmpFocusSub = this.es.shouldAutoFocus$.subscribe((data: any) => {
      if (data) {
        this.lmpField.nativeElement.focus();
        this.lmpAutoFocus = data;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.modality) {
      console.log('Modality changed:', changes.modality.currentValue);
      if (changes.modality.currentValue=='Ultrasound'){
        this.ps.pregForm.get('NumFetuses').setValue(this.ps.pregForm.get('NumFetuses_US').value);
        this.canChangeFetuses=false
        this.ps.pregForm.get('NumFetuses')?.disable();

      }
      else{
        this.canChangeFetuses=true
        this.ps.pregForm.get('NumFetuses')?.enable()
      }
    }
  }

  ngOnDestroy(): void {
    if (this.mediaSub) {
      this.mediaSub.unsubscribe();
    }
    if (this.lmpFocusSub) {
      this.lmpFocusSub.unsubscribe();
    }
  }

  setShow($event){
    this.show =$event
  }

  mwl(){
    if(this.ls.lmp_in_mwl && ! this.es.isClosed)
      setTimeout(()=>{
        this.es.relayWorklist();
      },2000);
  }

  changeFetuses():void{
    /*if(this.fetuses != "other"){
      this.pregForm.get('NumFetuses').setValue(parseInt(this.fetuses));
    }*/  
    this.setFetuses.emit(this.ps.pregForm.get('NumFetuses').value);
  }

  checklmp(){
    if(this.ps.pregForm.get('LMP').value){
      let lmp = new Date(this.ps.pregForm.get('LMP').value);
      if(lmp < new Date(this.lmpmin)  || lmp > new Date(this.lmpmax))
        this.invalidlmp = 'is-invalid';
      else
        this.invalidlmp='';
    }
  }

  setDefaults(){
    this.es.Defaults = 1;
    this.es.setDefaults().subscribe((data)=>{
    },(err)=>{
      console.log(err);
      this.es.Defaults=null;
    });
  }

  ivftoggle(val:string){
    if(this.ps.pregForm.get('IVFType').value && this.ps.pregForm.get('IVFType').value.includes(val)){
      if(this.ps.pregForm.get('IVFType').value.includes('|' + val))
        this.ps.pregForm.get('IVFType').setValue(this.ps.pregForm.get('IVFType').value.replace('|' + val,''));
      else{
        if(this.ps.pregForm.get('IVFType').value.includes(val+'|'))
          this.ps.pregForm.get('IVFType').setValue(this.ps.pregForm.get('IVFType').value.replace(val + '|',''));
        else
          this.ps.pregForm.get('IVFType').setValue(this.ps.pregForm.get('IVFType').value.replace(val,''));
      }
    }
    else{
      if(this.ps.pregForm.get('IVFType').value && this.ps.pregForm.get('IVFType').value.length> 0)
        this.ps.pregForm.get('IVFType').setValue(this.ps.pregForm.get('IVFType').value + '|' + val);
      else
        this.ps.pregForm.get('IVFType').setValue(val);
    }
  }

  toggleECTOPIC(selectedValue: string): void {
    const ectopicValues = [
      "CervicalEctop",
      "TubalEctop",
      "OvarianEctop",
      "PeritonealEctop",
      "AbdominalEctop",
    ];

    ectopicValues.forEach((ectopicValue) => {
      if (selectedValue !== ectopicValue) {
        this.ps.pregForm.get(ectopicValue).setValue("");
      }
    });
  }

  unselectECTOPIC(): void {
    this.ps.pregForm.get("CervicalEctop").setValue("");
    this.ps.pregForm.get("TubalEctop").setValue("");
    this.ps.pregForm.get("OvarianEctop").setValue("");
    this.ps.pregForm.get("PeritonealEctop").setValue("");
    this.ps.pregForm.get("AbdominalEctop").setValue("");
  }
  
}
