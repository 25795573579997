<ng-container [formGroup]="doppler">
    <div class="w100 placenta-box placenta-ammiotic summary" *ngFor="let group of groups; index as i" style='height:auto; margin-bottom: 1em;'>
        <section class="m-box">
            <h2>
                {{group}}
            </h2>
                    
            <!-- set (start) -->
            <div class="l-flex-c l-flex-w l-justify placenta-row">

                <label class="t-label-top" style="margin-bottom: 0.2em;">
                    <span>
                        Peak Velocity
                    </span>
                    <span class="t-field t-field--number">
                        <input class="t-field-text" type="number" min="0" [formControlName]="group + 'PV'"/>
                        <span class="t-field-desc">
                            m/s
                        </span>
                    </span>
                </label>

                <label class="t-label-top">
                    <span>
                        Peak Gradient
                    </span>
                    <span class="t-field t-field--number">
                        <input class="t-field-text" type="number" min="0" [formControlName]="group + 'PG'"/>
                        <span class="t-field-desc">
                            mm/Hg
                        </span>
                    </span>
                </label>

                <div class="t-label-top">
                    <label id="gestantional_sac_appearance">
                        Mean Gradient
                    </label>
                    <span class="t-field t-field--number">
                        <input class="t-field-text" type="number" min="0" [formControlName]="group + 'MG'"/>
                        <span class="t-field-desc">
                            mm/Hg
                        </span>
                    </span>
                    
                </div>

                <div class="t-label-top">
                    <label id="gestantional_sac_appearance">
                        Valve Area
                    </label>
                    <span class="t-field t-field--number">
                        <input class="t-field-text" type="number" min="0" [formControlName]="group + 'VA'"/>
                        <span class="t-field-desc">
                            cm²
                        </span>
                    </span>
                    
                </div>
                <div class="t-label-top">
                    <label id="gestantional_sac_appearance">
                        Reg Flow Velocity
                    </label>
                    <span class="t-field t-field--number">
                        <input class="t-field-text" type="number" min="0" [formControlName]="group + 'RFV'"/>
                        <span class="t-field-desc">
                            m/sec
                        </span>
                    </span>
                    
                </div>
                <div class="t-label-top" *ngIf="group == 'Tricuspid'">
                    <label id="gestantional_sac_appearance">
                       RAP
                    </label>
                    <span class="t-field t-field--number">
                        <input class="t-field-text" type="number" min="0" formControlName="RAP"/>
                        <span class="t-field-desc">
                        </span>
                    </span>
                    
                </div>

                <div class="t-label-top" *ngIf="group == 'Tricuspid'">
                    <label id="gestantional_sac_appearance">
                        Est. Pulmonic Pressure
                    </label>
                    <span class="t-field t-field--number">
                        <input class="t-field-text" type="number" min="0" [formControlName]="group + 'EPP'"/>
                        <span class="t-field-desc">
                            mm/HG
                        </span>
                    </span>
                    
                </div>
                <div class="t-label-top" *ngIf="group == 'Mitral'">
                    <label id="gestantional_sac_appearance">
                       PISA
                    </label>
                    <span class="t-field t-field--number">
                        <input class="t-field-text" type="number" min="0" formControlName="PISA"/>
                        <span class="t-field-desc">
                        </span>
                    </span>
                    
                </div>
                <div class="t-label-top" *ngIf="group == 'Mitral'">
                    <label id="gestantional_sac_appearance">
                        VENA CONTRACTA
                    </label>
                    <span class="t-field t-field--number">
                        <input class="t-field-text" type="number" min="0" formControlName="VENACONTRACTA"/>
                        <span class="t-field-desc">
                        </span>
                    </span>
                </div>

                <div class="t-label-top" *ngIf="group == 'LVOT'" style="margin-bottom: 0.2em;">
                    <label id="gestantional_sac_appearance">
                        Diamemeter
                    </label>
                    <span class="t-field t-field--number">
                        <input class="t-field-text" type="number" min="16" max="24" formControlName="LVOTDiam"/>
                        <span class="t-field-desc">
                            mm
                        </span>
                    </span>
                </div>

                <div class="t-label-top" *ngIf="group == 'LVOT'" style="margin-bottom: 0.2em;">
                    <label id="gestantional_sac_appearance">
                        Trace
                    </label>
                    <span class="t-field t-field--number">
                        <input class="t-field-text" type="number" min="0" formControlName="LVOTTrace"/>
                        <span class="t-field-desc">
                        </span>
                    </span>
                </div>

                <div class="t-label-top" *ngIf="group == 'LVOT'" style="margin-bottom: 0.2em;">
                    <label id="gestantional_sac_appearance">
                        Envelope Time
                    </label>
                    <span class="t-field t-field--number">
                        <input class="t-field-text" type="number" min="0" formControlName="LVOTEnv"/>
                        <span class="t-field-desc">
                        </span>
                    </span>
                </div>

                <div class="t-label-top" *ngIf="group == 'LVOT'">
                    <label id="gestantional_sac_appearance">
                        Velocity Time Integ
                    </label>
                    <span class="t-field t-field--number">
                        <input class="t-field-text" type="number" min="15" max="25" formControlName="LVOTVTI"/>
                        <span class="t-field-desc">
                            cm
                        </span>
                    </span>
                </div>
                <div class="t-label-top" *ngIf="group == 'LVOT'">
                    <label id="gestantional_sac_appearance">
                        Heart Rate
                    </label>
                    <span class="t-field t-field--number">
                        <input class="t-field-text" type="number" min="0" formControlName="HR"/>
                        <span class="t-field-desc">
                            bps
                        </span>
                    </span>
                </div>

                <div class="t-label-top" *ngIf="group == 'LVOT'">
                    <label id="gestantional_sac_appearance">
                        Stroke Volume
                    </label>
                    <span class="t-field t-field--number">
                        <input class="t-field-text" type="number" min="0" formControlName="LVSV"/>
                        <span class="t-field-desc">
                            ml
                        </span>
                    </span>
                </div>

                <div class="t-label-top" *ngIf="group == 'LVOT'">
                    <label id="gestantional_sac_appearance">
                        LVCO
                    </label>
                    <span class="t-field t-field--number">
                        <input class="t-field-text" type="number" min="0" formControlName="LVCO"/>
                        <span class="t-field-desc">
                            l
                        </span>
                    </span>
                </div>
            </div>
            <div class="l-flex-c menstrual-row menstrual-row--LMP_by_US" *ngIf="doppler.get(group) && doppler.get(group).value" style="margin-top: 1em;">
                <span  *ngIf="doppler.get(group) && doppler.get(group).value" style="border: 1px solid #bdd3d5;white-space: pre-wrap;unicode-bidi: plaintext;width: 100%;padding: 0.3em;">{{doppler.get(group).value}}</span>
            </div>
            <!-- set (end) -->
            <button class="t-btn-edit" *ngIf="! es.isClosed" (click)="atS.showAuto(doppler.get(group),group,605+i);">Auto Text</button>

        </section>


    </div>
</ng-container>