import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GastroFormsService } from 'src/app/gastro-forms.service';
import { LoginService } from 'src/app/login.service';

@Component({
  selector: 'app-timeout',
  templateUrl: './timeout.component.html',
  styleUrls: ['./timeout.component.scss']
})
export class TimeoutComponent implements OnInit {
  @Output() closed = new EventEmitter<string>();
  @Output() print = new EventEmitter<string>();


  constructor(public gfs:GastroFormsService, public ls:LoginService) { }

  ngOnInit() {
  }
  printForm(){
    this.print.emit('1')
  }
  close(toclose: boolean) {
    this.closed.emit(toclose ? '1': null);
  }
}
