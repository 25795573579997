<main class="l-deashboard" [formGroup]="Form">
		<div class="h100 l-flex-w t-boxMargin--spacebyPadding placenta-panel">

			<!-- cell : placenta -->
			<div class="placenta-box placenta-placenta" style="width:51%;">
				<section class="m-box">
					<h2>
						<!-- Placenta / -->
						{{'placentaetc.title' | translate | titlecase}}
					</h2>

					<!-- set (start) -->
					<div class="l-flex-c l-flex-w l-justify h50 placenta-row">
						
						<label class="t-label-top" *ngFor="let item of position | keyvalue">
							<input type="checkbox" [(ngModel)]="position[item.key]" [name]="item.key" [ngModelOptions]="{standalone: true}" (change)="updateSac()" class="custom-check" [disabled]="es.isClosed" />
							<i class="customInput-display"></i>
							<span>
								<!-- {{item.key}} -->
								<!-- \ -->
								{{'placentaetc.Placenta_Position.'+item.key | translate | titlecase}} 
							</span>
						</label>

					</div>
					<!-- set (end) -->

					<!-- set (end) -->
					<div class="l-flex-c l-flex-w l-justify h50 placenta-row">

						<label class="t-label-top">
							<span>
								<!-- Number of Blood Vessels \  -->
								{{'placentaetc.Placenta_Position.#ofbloodvessels' | translate | titlecase}}
							</span>
							<select class="t-select t-field" formControlName="BloodVessels">
								<option></option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
							</select>
						</label>

						<label class="t-label-top">
							<span>
								<!-- Placental Grading \ -->
								{{'placentaetc.Placenta_Position.PlacentalGrading' | translate | titlecase}}
							</span>
							<select class="t-select t-field" formControlName="Grading">
                                <option></option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
							</select>
						</label>
						
					</div>
				</section>
			</div>
			<!-- endcel : placenta -->

			<!-- cell : Lie & Presention -->
			<div class="placenta-box placenta-lie" style="width:49%;">
				<section class="m-box">
					<h2>
						<!-- Lie & Presention \ -->
						<!-- {{'placentaetc.Lie_and_Presentation.title' | translate | titlecase}} -->
						{{'GUI.Placenta.Lie_And_Presentation' | translate }}
					</h2>
					<!-- set (start) -->
					<div class="l-flex-c l-flex-w l-justify h50 placenta-row" (dblclick)="resetGrp('LieAndPresent')">
						
						<label class="t-label-top">
                            <input type="radio" name="LieAndPresent" class="custom-check" formControlName="LieAndPresent" value="Vertex"/>
                            <i class="customInput-display"></i>
							<span>
								<!-- Vertex -->
								{{'placentaetc.Lie_and_Presentation.Vertex' | translate | titlecase}}
							</span>
						</label>
						
						<label class="t-label-top">
                            <input type="radio" name="LieAndPresent" class="custom-check" formControlName="LieAndPresent" value="Transverse"/>
                            <i class="customInput-display"></i>
							<span>
								<!-- Transverse -->
								{{'placentaetc.Lie_and_Presentation.Transverse' | translate | titlecase}}

							</span>
						</label>
						
						<label class="t-label-top">
                            <input type="radio" name="LieAndPresent" class="custom-check" formControlName="LieAndPresent" value="Breech"/>
                            <i class="customInput-display"></i>
							<span>
								<!-- Breech -->
								{{'placentaetc.Lie_and_Presentation.Breech' | translate | titlecase}}

							</span>
						</label>
						
						<label class="t-label-top">
                            <input type="radio" name="LieAndPresent" class="custom-check" formControlName="LieAndPresent" value="Oblique"/>
                            <i class="customInput-display"></i>
							<span>
								<!-- Oblique -->
								{{'placentaetc.Lie_and_Presentation.Oblique' | translate | titlecase}}

							</span>
						</label>
						
						<label class="t-label-top">
                            <input type="radio" name="LieAndPresent" class="custom-check" formControlName="LieAndPresent" value="Unstable"/>
                            <i class="customInput-display"></i>
							<span>
								<!-- Unstable -->
								{{'placentaetc.Lie_and_Presentation.Unstable' | translate | titlecase}}

							</span>
						</label>
						
					</div>
					<!-- set (end) -->

					<!-- set (start) -->
					<div class="l-flex-c l-flex-w l-justify h50 placenta-row" *ngIf="ps.fetusCt() > 1">
						
						<label id="fetal_position_for_twins">
							<!-- Fetal Position (When Twins) -->
							{{'placentaetc.Lie_and_Presentation.fetal_position.title' | translate | titlecase}}
						</label>
						<app-genradio formname="Position" [formgroup]="Form" value1="Left" value2="Right" [lname]="'GUI.Geninput.Left' | translate | titlecase" [rname]="'GUI.Geninput.Right' | translate | titlecase" [resetval]="null"></app-genradio>
						<app-genradio formname="PositionVertical" [formgroup]="Form" value1="Top" value2="Bottom" [lname]="'GUI.Geninput.Top' | translate | titlecase" [rname]="'GUI.Geninput.Bottom' | translate | titlecase" [resetval]="null"></app-genradio>

					
					</div>
					<!-- set (end) -->					

				</section>
			</div>
			<!-- endcel : Lie & Presention -->

			<!-- cell : Amniotic Fluid Volume -->
			<div class="w100 placenta-box placenta-ammiotic">
				<section class="m-box">
					<h2>
						<!-- Amniotic Fluid Volume -->
						{{'placentaetc.BioPhysicalScore.AmnioticFluid' | translate | titlecase}}
					</h2>
					
					<!-- set (start) -->
					<div class="l-flex-c l-flex-w h50 placenta-row" (dblclick)="resetGrp('AmniFluidVolume')" >

						<label class="t-label-top">
                            <input type="radio" name="AmniFluidVolume" formControlName="AmniFluidVolume" class="custom-check" value="Normal"/>
                            <i class="customInput-display"></i>
							<span>
								<!-- Normal -->
								{{'placentaetc.AFV.Normal' | translate | titlecase}}
							</span>
						</label>
						
						<label class="t-label-top">
                            <input type="radio" name="AmniFluidVolume" formControlName="AmniFluidVolume" class="custom-check" value="Oligohydramnios"/>
                            <i class="customInput-display"></i>
							<span>
								<!-- Oligohydramnios -->
								{{'placentaetc.AFV.Oligohydramnios' | translate | titlecase}}

							</span>
						</label>
						
						<label class="t-label-top">
                            <input type="radio" name="AmniFluidVolume" formControlName="AmniFluidVolume" class="custom-check" value="Mild Oligohydramnios"/>
                            <i class="customInput-display"></i>
							<span>
								<!-- Mild Oligohydramnios -->
								{{'placentaetc.AFV.Mild Oligohydramnios' | translate | titlecase}}

							</span>
						</label>
						
						<label class="t-label-top">
                            <input type="radio" name="AmniFluidVolume" formControlName="AmniFluidVolume" class="custom-check" value="Polyhydramnios"/>
                            <i class="customInput-display"></i>
							<span>
								<!-- Polyhydramnios -->
								{{'placentaetc.AFV.Polyhydramnios' | translate | titlecase}}

							</span>
						</label>
						
						<label class="t-label-top">
                            <input type="radio" name="AmniFluidVolume" formControlName="AmniFluidVolume" class="custom-check" value="Mild Polyhydramnios"/>
                            <i class="customInput-display"></i>
							<span>
								<!-- Mild Polyhydramnios -->
								{{'placentaetc.AFV.Mild Polyhydramnios' | translate | titlecase}}

							</span>
						</label>

					</div>
					<!-- set (end) -->
					
					<!-- set (start) -->
					<div class="l-flex-c l-flex-w l-justify h50 placenta-row">

						<label class="t-label-top">
							<span>
								<!-- Amniotic Index -->
								{{'placentaetc.AFV.AFI' | translate | titlecase}}

							</span>
							<span class="t-field t-field--number">
								<input class="t-field-text" type="number" min="0" formControlName="AmniFluidIndex"/>
								<span class="t-field-desc">
									mm
								</span>
							</span>
						</label>

						<label class="t-label-top">
							<span>
								<!-- Max Pocket Size -->
								{{'placentaetc.AFV.MaxPocketSize' | translate | titlecase}}

							</span>
							<span class="t-field t-field--number">
								<input class="t-field-text" type="number" min="0" formControlName="MaxPocket"/>
								<span class="t-field-desc">
									mm
								</span>
							</span>
						</label>

						<div class="t-label-top">
							<label id="gestantional_sac_appearance">
								<!-- Gestational Sac Appearance -->
								{{'placentaetc.AFV.GestationalSacAppearance.title' | translate | titlecase}}

							</label>
							<app-genradio formname="SacApear" [formgroup]="Form" value1="Normal" value2="Abnormal" lname="{{'GUI.Labels.Normal' | translate}}" rname="{{'placentaetc.AFV.GestationalSacAppearance.AbnormalValue' | translate | titlecase}}" [resetval]="null"></app-genradio>
							
						</div>

					</div>
					<!-- set (end) -->
					
				</section>
			</div>
			<!-- endcel : Amniotic Fluid Volume -->

			<div class="w100"><div class="l-flex t-boxMargin--spacebyPadding placenta-table">

				<!-- cell : Biophusical Score -->
				<div class="l-fg1 placenta-box placenta-biophsical">
					<section class="m-box">
						<h2>
							<!-- Biophysical Score -->
							{{'placentaetc.BioPhysicalScore.title' | translate | titlecase}}
						</h2>

						<div class="l-flex-w placenta-biophsical-table">

							<div class="t-label-top" *ngFor="let s of scores">
								<label id="amorfic_f">
									<!-- {{s.nm}} -->
									{{'placentaetc.BioPhysicalScore.'+s.nm | translate | titlecase}}
								</label>
								<app-genradio [formname]="s.fc" [formgroup]="Form" [value1]="2" [value2]="0" lname="2" rname="0" [resetval]="-1"></app-genradio>
							</div>
							
                            <div class="t-label-top">
                                <label id="score">

                                    <!-- Score -->
									{{'placentaetc.BioPhysicalScore.Score' | translate | titlecase}}

                                </label>
                                <div class="t-field l-flex t-twin-fieldset">
                                    <input type="number" name="amorfic_f_TEMP" class="t-field-text" aria-labelledby="score">
                                    <span class="t-twin-fieldset-sep">{{getScore()}} / {{scorePosible()}}</span>
                                    <input type="number" name="amorfic_f_TEMP" class="t-field-text" aria-labelledby="score">
                                </div>
                            </div>
							
						</div>

					</section>
				</div>
				<!-- endcel : Biophusical Score -->

				<!-- cell : Biophusical Score -->
				<div class="placenta-box placenta-fetal-options">
					<section class="m-box">

						
						<label class="t-label-top">
							<input type="checkbox" name="placenta_TEMP_name" class="custom-check -sq" formControlName="Heartbeat"/>
                            <i class="customInput-display"></i>
							<span style="margin-inline-end: 0.3em;">
								<!-- Heart Beat -->
								{{'placentaetc.BioPhysicalScore.HeartBeat' | translate | titlecase}}

							</span>
							<input class="t-field" type="number" formControlName="Heartrate" style="width: 4em; border: none;margin-left: 0;text-align: center;text-align-last:center ;"> bpm
						</label>
						
							
						<label class="t-label-top">
                            <input type="checkbox" name="placenta_TEMP_name" class="custom-check -sq" formControlName="Movements" />
                            <i class="customInput-display"></i>
							<span>
								<!-- Fetal Movements -->
								{{'placentaetc.BioPhysicalScore.FetalMovements' | translate | titlecase}}

							</span>
						</label>

					</section>
				</div>
				<!-- endcel : Biophusical Score -->

			</div></div>

			<!-- cell : Biophusical Score -->
			<div class="w100 placenta-box--fluidHeight placenta-comments summary">
				<section class="m-box">
					<h2>
						{{'GUI.General.Placenta Comments' | translate | titlecase}} 
					</h2>

					<textarea class="scroller" formControlName="Comments" rows="6"></textarea>
					<button class="t-btn-edit" *ngIf="! es.isClosed" (click)="atS.showAuto(Form.get('Comments'),atSTitlePlacentaComments ,21)">Auto Text</button>
				</section>
			</div>
			<!-- endcel : Biophusical Score -->

			
		</div><!-- box margin -->

    </main>
    