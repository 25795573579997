import { Component, OnInit } from '@angular/core';
import {VideoCaptureService} from '../video-capture.service';

@Component({
  selector: 'app-remotevideo',
  templateUrl: './remotevideo.component.html',
  styleUrls: ['./remotevideo.component.css']
})
export class RemotevideoComponent implements OnInit {

  audioInputs:{ value: any, text: any }[];
  videoInputs:{ value: any, text: any }[];
  audioSource: string;
  videoSource: string;
  soundtest = false;
  Stream: MediaStream;
  maxWidth: number = 720;
  frameRate = 20;
  bitRate = 400;
  timer:string;
  streamdetails='';

  constructor(public vs: VideoCaptureService) { }


  async ngOnInit() {
    let inputs = await this.vs.getDevices();
    this.videoInputs = inputs.video;
    this.audioInputs = inputs.audio;
    try{
      let oldv = window.localStorage.getItem("video");
     if(oldv && this.videoInputs.find((item)=> {return item.value == oldv})){
       this.videoSource = oldv;
     }
     var olda = window.localStorage.getItem("audio");
     if(olda&& this.audioInputs.find((item)=> {return item.value == olda})){
       this.audioSource = olda;
     }
     await this.startVideo();
     this.vs.PeerConenct();
    }
    catch(e){
     console.log(e);
   }
  }

  async startVideo(){
    this.Stream = await this.vs.startVideo(this.videoSource, this.audioSource,this.soundtest, this.maxWidth, this.frameRate);
  }

  updatedetails(){
    try{
      let tracks = this.vs.mediaRecorder.stream.getVideoTracks();
      for(let track of tracks){
        this.streamdetails = 'stream settings:\n' +JSON.stringify( track.getSettings());
        this.streamdetails += '\nstream options:' + JSON.stringify(track.getCapabilities());
        this.streamdetails += '\nstream constrints:' + JSON.stringify(track.getConstraints()); 
        
      }
    }
    catch(e){
      console.log(e);
    }
  }

  setBitRate(){
    this.vs.setBitRate(this.bitRate);
  }

}
