import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortCreationTime'
})
export class SortCreationTimePipe implements PipeTransform {
  transform(array: any[], property: string): any[] {
    if (!array || array.length === 0) {
      return [];
    }

    return array.sort((a, b) => {
      const dateA = new Date(a[property]).getTime();
      const dateB = new Date(b[property]).getTime();
      return dateB - dateA;
    });
  }
}
