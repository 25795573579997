import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder,UntypedFormGroup,FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { AutotextService } from 'src/app/autotext.service';
import {ExamsService} from '../../exams.service';
import { CardioService } from '../cardio.service';

@Component({
  selector: 'app-cardio-doppler',
  templateUrl: './cardio-doppler.component.html',
  styleUrls: ['./cardio-doppler.component.scss']
})
export class CardioDopplerComponent implements OnInit, OnDestroy {
  doppler: UntypedFormGroup;
  groups = ['Mitral','Tricuspid','Aortic','Pulmonic'];
  sub = new Subscription();
  mediaSub: Subscription;

  constructor(public es: ExamsService, private fb:UntypedFormBuilder, public atS:AutotextService, public cardioS:CardioService) { 
    let forms = {
      TricuspidEPP: [''],
      PISA:[''],
      RAP:[''],
      VENACONTRACTA: [''],
      LVOTDiam:[''],
      LVOTTrace:[''],
      LVOTEnv:[''],
      LVOTVTI:[''],
      HR:[''],
      LVSV:[''],
      LVCO:[''],
      Ewave:[''],
      Awave:[''],
      EA:[''],
      DecT:[''],

    };
    for(let g of this.groups){
      forms[g+'PV'] = ['']
      forms[g +'PG'] = ['']
      forms[g +'MG'] = ['']
      forms[g +'VA'] = ['']
      forms[g +'RFV'] = ['']
    }
    forms['Mitral'] =[''];
    forms['Tricuspid'] =[''];
    forms['Aortic'] =[''];
    forms['Pulmonic'] =[''];
    forms['LVOT'] =[''];
    forms['LV'] = ['']

    
    this.doppler = fb.group(forms);

  }

  ngOnInit() {
    this.es.fillForm(this.doppler,"cardioDoppler",false,true);
    this.es.getSRs();
    for(let g of this.groups){
      this.sub = this.doppler.get(g).valueChanges.pipe(distinctUntilChanged()).subscribe((data) => { 
        // if(data)
          this.cardioS.addToFindings(data, g);
      })
    }
    if(!this.es.isClosed)
      this.mediaSub =  this.es.mediaArrived$.subscribe((mediatype)=>{
        if(mediatype == 'html')
          this.es.fillForm(this.doppler,"cardioDoppler",false,true);
      });

  }

  ngOnDestroy(): void {
      this.sub.unsubscribe()
      if(this.mediaSub)
        this.mediaSub.unsubscribe();
  }

}
