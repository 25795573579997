import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Chart, ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip, MultiDataSet, SingleDataSet } from 'ng2-charts';
import { Subscription } from 'rxjs';
import { LoginService } from '../login.service';
import { StatsService } from '../stats.service';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit, OnDestroy {
  selecteduser;
  data;
  public ChartOptions: ChartOptions = {
    responsive: true,
    title:{
      display:true,
      text:'Exams'
    },
    scales:{
      yAxes: [{
        display: false,
        stacked: false,
        ticks: {
            min: 0, // minimum value
        }
    }]
    }
  };
  public ChartLabels: Label[] = [];
  public ChartDataMulti: MultiDataSet = [];
  public dougnutData=[]
  public ChartDataSingle: any = [];
  public barChartData: ChartDataSets[] = [{data:[], label:'Exams'}];

  
  public ChartType: ChartType = 'pie';
  public ChartLegend = true;
  public ChartPlugins = [];
  public ChartColor = [{
    backgroundColor: [
      'lightgreen', 'lightblue', 'lightyellow', 'indianred', 'mediumpurple', 'darkgreen', 'deepskyblue', 'lightpink', 'orangered', 'purple','bisque','yellow'
    ]
  }];

  chartType='pie';
  dataType='exams';
  hmoType='';
  type = 'date';
  showprint;
  date='1';
  name;
  showprintopts;
  ExamType;
  startdate;
  enddate;
  dateType ='months';
  filterdUsers = [];
  typeOfPrint;
  Direction;
  code;
  examsArray = [];
  hmoNotAssign =[];
  sub = new Subscription()
  loaded = false;
  searchByType = 'TerminationRemarks';
  constructor(private route: ActivatedRoute,private http:HttpClient, public sts:StatsService, public ls:LoginService) {
    this.ls.loginMissing();
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
  }


  ngOnInit() {
    this.examsArray = this.sts.GYN;
    if(this.ls.isGastro){
      this.examsArray = this.sts.GASTRO;
    }
    if(this.ls.isCardio)
      this.examsArray = this.sts.CARDIO
    this.onSearch();
    this.sts.statsLoaded$.subscribe(() => {
      this.filterByName(null);
      this.setData();
    })

  }

  onSearch(){
    this.loaded = false;
    var params;
    switch (this.type) {
      case 'date':
        if(this.dateType == 'months'){
          params ={
            type: this.type,
            date: this.date.toString(),
            searchByType:this.searchByType

          }
        }else{
          var fd;
          var td;
          try{
            fd = this.enddate._i.year+'-'+(this.enddate._i.month + 1)+'-'+this.enddate._i.date + ' 23:59:59' ;
            td = this.startdate._i.year+'-'+(this.startdate._i.month + 1)+'-'+this.startdate._i.date + ' 00:00:00';
          }catch{
            fd =  this.enddate.toISOString().slice(0, 19).split('T')[0] + ' 23:59:59';
            td = this.startdate.toISOString().slice(0, 19).split('T')[0] + ' 00:00:00';
          }
          params ={
            type: this.type,
            start: td,
            end: fd,
            searchByType:this.searchByType

          }
        }
        this.selecteduser =null
        this.sts.search(params, null)
        break;
      case 'name':
          params ={
            type: this.type,
            searchByType:this.searchByType

          }
          break;
        case 'exam':
          params ={
            type: this.type,
            ExamType: this.ExamType,
            searchByType:this.searchByType

          }
          break;
      
      default:
        break;
    }
  }

  onSetType(){
    switch (this.type) {
      case 'date':
          this.name= null;
          this.ExamType=null;
          this.dateType='months';
          this.date = '1'
          this.onSearch();
        break;
      case 'name':
        this.date=null;
        this.ExamType=null;
        this.dateType=null;
        this.onSearch();
        break;
      case 'exam':
        this.date=null;
        this.dateType=null;
        this.name=null;
        this.onSearch();
        break;
      default:
        break;
    }
  }

  filterByName(str){
    if(str){
      this.filterdUsers =  this.sts.usersStats.filter((i) => i.user.UserName.includes(str))
    }else{
      this.filterdUsers = this.sts.usersStats;
    }
  }

  getDb(exam){
    if(!exam)
      return;
    if(exam == '1st')
      return 'first'
    if(exam == '2nd/3rd')
      return 'second'
    return exam.replace(' ', '').replace('-', '')
  }



  setData(){
    if( !this.ls.isAdmin  && !this.selecteduser){
      var temp = this.sts.usersStats.filter((i) => i.user.UserName.includes(this.ls.loginname))
      this.sts.statsForm.patchValue(temp[0].exams)
      this.sts.totalLength = temp[0].exams.length
    }
    this.ChartLabels = [];
    this.ChartDataSingle = [];
    this.ChartDataMulti = [];
    var tempTitle = this.selecteduser ? this.selecteduser.user.UserName+' Exams' : 'All Exams';
    this.barChartData = [{data:[], label:tempTitle}];
    // this.barChartDataSingle[0]['data']=[]
    this.dataType = 'exams';
    this.dougnutData = [];
    var tempData =[];

    if(this.selecteduser){
      var i =0 
      for( var ob in this.selecteduser.exams)
      tempData[i++]=ob
    }else{
      tempData = this.sts.examsArray.filter((exam) =>  exam != 'All');
    }
    let sarr= [];
    for(let name of tempData){
      var exams = this.selecteduser ? this.selecteduser.exams[name] : this.sts.statsForm.get(this.getDb(name)).value;
      var allexams = this.selecteduser ? this.selecteduser.exams['All'] : this.sts.statsForm.get(this.getDb('All')).value;
      if(name !='All' && exams && exams.length > 0){
        this.ChartLabels.push(name =='first' ? '1ST' : name);
        switch (this.chartType) {
          case 'pie':
            sarr.push(this.getPrecent(exams.length, allexams));
            //this.ChartDataSingle.push(Math.round(this.getPrecent(exams.length, allexams)));            
            this.ChartOptions.scales.yAxes[0].display = false;
            this.ChartOptions.scales.yAxes[0].stacked = false;

            break;
          case 'doughnut':
            this.ChartOptions.scales.yAxes[0].display = false;
            this.ChartOptions.scales.yAxes[0].stacked = false;
            this.dougnutData.push(this.getPrecent(exams.length, allexams))
            // this.dougnutData.push(this.sts.statsForm.get(this.getDb(name)).value.length);
            break;
          case 'bar':
            this.ChartOptions.scales.yAxes[0].display = true;
            this.ChartOptions.scales.yAxes[0].stacked = true;
            // var prece =Math.round(this.sts.statsForm.get(this.getDb(name)).value.length)
            this.dougnutData.push(exams.length);
            break;
          default:
            break;
        }
      }
    }
    if(sarr)
      this.ChartDataSingle = sarr;
    if(this.chartType != 'pie'){
      this.barChartData[0].data = this.dougnutData;

    }
    if(this.chartType == 'bar')
      this.ChartOptions.title.display =false;
    this.data = [{data:this.data}];
    if(this.selecteduser && !this.selecteduser.exams.All){
      this.ChartLabels=[];
      this.ChartDataSingle=[];
      this.dougnutData=[];
      this.barChartData=[];
      this.data=[];
    }
    
    this.loaded = true;
  }

  getPrecent(count=0,totalLength){
    if(!totalLength)
      return 0;
    return (count / totalLength.length * 100).toFixed(2)

  }

  getNotAssigned(array){
    if(! array){
      this.hmoNotAssign = []
      return 0;
    }
    var temp = array;
    this.hmoNotAssign =temp.filter((i) => i.HMO == null)
    return temp.filter((i) => i.HMO == null).length
  }

  chageChart(){

    switch (this.chartType) {
      case 'pie':
        this.ChartDataSingle = [];
        this.ChartType = 'pie';
        this.setData();
        break;
      case 'doughnut':
        this.ChartDataMulti=[];
        this.ChartType = 'doughnut';
        this.setData();
        break;
      case 'bar':
        this.ChartDataSingle = [];
        this.ChartType = 'bar';
        this.setData();
        break;
      default:
        break;
    }
  }
  chageData(){
    if(this.dataType =='exams'){
      this.ChartLabels = [];
      this.ChartDataSingle = [];
      this.ChartDataMulti = [];
      this.setData()
    }else{
      if(this.sts.usersStats.length > 0){
        this.ChartLabels = [];
        this.ChartDataSingle = [];
        this.ChartDataMulti = [];
        this.sts.usersStats.forEach((i) => {
            this.ChartLabels.push(i.user.UserName)
            var t = i.exams.All ? i.exams.All.length : 0;
            this.ChartDataSingle.push(t);
        })
        this.barChartData[0].data = this.ChartDataSingle;


      }
    }
    
  }

  selectUser(user){
    this.selecteduser = user
    this.dataType = 'exams';
    this.chageChart();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

}
