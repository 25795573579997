import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimerService {
  canceltimer;
  public time: string;
  constructor() { }

  startTimer(){
    try{
      this.stopTimer()
    }
    catch{}
    let starttime = new Date().getTime();
    this.canceltimer = setInterval(()=>{
      let now = new Date().getTime();
      let distance = now - starttime;
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes:any = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds:any = Math.floor((distance % (1000 * 60)) / 1000);
      if(minutes < 10)
        minutes = "0" + minutes;
      if(seconds < 10)
        seconds = "0" + seconds;
      //timer.innerHTML = hours+":"+minutes+":"+seconds;
      this.time = hours+":"+minutes+":"+seconds;
    },1000);
  }

  stopTimer(){
    clearInterval(this.canceltimer);
    this.time = '';
  }
}


