import {Measure} from './meaure';

export class AllMeasures{
   //AllMeas: Measure[];
   private static mopts: {name: string, displayName:string,printName:string,GAo: string[],GGo: string[],min: number, max: number}[] = [
    
      {name: "BPD", displayName:"BPD",printName:"", GAo: ["Hadlock","Jeanty","Hadlock_84"],GGo: ["Hadlock","Merz","Hadlock_84","Hansman"],min: 16,max: 100},
      {name:"AC", displayName:"AC",printName:"", GAo:["Hadlock","Jeanty"],GGo:["Hadlock_84","Hadlock","Jeanty"],min: 56,max: 330},
      {name: "CRL", displayName:"CRL",printName:"", GAo:['Hadlock','Hansman'],GGo:['Had'],min:2,max:150},
      {name: "HC", displayName:"HC",printName:"", GAo:["Hadlock_82","Jeanty"],GGo:["Hadlock_84","Hadlock_82","Jeanty"],min: 85, max: 360},
      {name: "FL", displayName:"FL",printName:"", GAo:["Hadlock","Jeanty"],GGo:["Hadlock_84","Hadlock","Jeanty"], min: 10, max: 75},
      {name: "Nuchal", displayName:"Nuchal",printName:"", GAo:[],GGo:["Singh"], min: 2.1, max: 5.9},
      {name: "EFW 1", displayName:"EFW",printName:"", GAo:["Hadlock"],GGo:["Hadlock",/*"Dolberg_Female","Dolberg_Male","Dolberg_F_Multi","Dolberg_M_Multi"*/], min: 10, max: 75},
      {name: "EFW 2", displayName:"EFW",printName:"", GAo:["Hadlock"],GGo:["Hadlock",/*"Dolberg_Female","Dolberg_Male","Dolberg_F_Multi","Dolberg_M_Multi"*/], min: 10, max: 75},
      {name: "HL", displayName:"HL",printName:"", GAo:["Jeanty"],GGo:["Jeanty"], min: 10, max: 69},
      {name: "RADIUS", displayName:"RADIUS",printName:"", GAo:["Merz"],GGo:["Merz"], min: 6, max: 57},
      {name: "UL", displayName:"UL",printName:"", GAo:["Jeanty"],GGo:["Jeanty"], min: 10, max: 64},
      {name: "TL", displayName:"TL",printName:"", GAo:["Jeanty"],GGo:["Jeanty"], min: 10, max: 69},
      {name: "CL", displayName:"CL",printName:"", GAo:["Yarkoni"],GGo:["Yarkoni"], min: 11, max: 45},
      {name: "TC", displayName:"TC",printName:"", GAo:[],GGo:["Chitkara"], min: 45, max: 361},
      {name: "HCAC", displayName:"HCAC",printName:"", GAo:[],GGo:["Campbell"], min: 1, max: 2},
      {name: "OFD", displayName:"OFD",printName:"", GAo:["Merz","Hansman"],GGo:["Merz","Hansman"], min: 23, max:117},
      {name: "OOD", displayName:"OOD",printName:"", GAo:["Jeanty"],GGo:["Merz"], min: 15, max: 65},
      {name: "IOD", displayName:"IOD",printName:"", GAo:[],GGo:["Merz"], min: 5.8, max: 22},
      {name: "OD", displayName:"OD",printName:"", GAo:[],GGo:["Romero"], min: 10.7, max: 56.9},
      {name: "EarLen", displayName:"EarLen",printName:"", GAo:[],GGo:["Lettieri"], min: 7, max: 21},
      {name: "CEREB", displayName:"CEREB",printName:"", GAo:["Hill"],GGo:["Hill","Goldstein"], min: 14, max: 52},
      {name: "CM", displayName:"CM",printName:"", GAo:[],GGo:["Nicolaides"], min: 1.9, max: 10.1},
      {name: "FIBULA", displayName:"FIBULA",printName:"", GAo:["Jeanty"],GGo:["Merz"], min: 6, max: 63},
      {name: "Foot", displayName:"Foot",printName:"", GAo:[],GGo:[], min: 10, max: 75},
      {name: "PEEFF",displayName:"PE",printName:"Pericardial Effusion", GAo: [],GGo: [],min: 1,max: 1000},
      {name: "PeefSet",displayName:"set",printName:"", GAo: [],GGo: [],min: 1,max: 1000},
      {name: "TRC", displayName:"Tricupsid Valve",printName:"Tricupsid Valve Area", GAo: [],GGo: [],min: 1,max: 1000},
      {name: "MIT", displayName:"Mitral Valve",printName:"Mitral Valve Area", GAo: [],GGo: [],min: 1,max: 1000},
      {name: "AOR", displayName:"Aortic Valve",printName:"Aortic Valve Area", GAo: [],GGo: [],min: 1,max: 1000},
      {name: "PulmonaryV", displayName:"Pulmonary Valve",printName:"Pulmonary Valve", GAo: [],GGo: [],min: 1,max: 1000},
      {name: "MPASYS", displayName:"MPA diam",printName:"Main Pulmonary artery diam", GAo: [],GGo: [],min: 1,max: 1000},
      {name: "LPA", displayName:"LPA",printName:"Left Pulmonary artery flow", GAo: [],GGo: [],min: 1,max: 1000},
      {name: "RPA", displayName:"RPA",printName:"Right Pulmonary artery flow", GAo: [],GGo: [],min: 1,max: 1000},
      {name: "ISTHMUS", displayName:"Istmus",printName:"Isthmus Flow", GAo: [],GGo: [],min: 1,max: 1000},
      {name: "ASCAO", displayName:"Asc AO",printName:"Ascending Aorta", GAo: [],GGo: [],min: 1,max: 1000},
      {name: "TrasArch", displayName:"Trans. Ao",printName:"Tras Arch", GAo: [],GGo: [],min: 1,max: 1000},
      {name: "AOFlow", displayName:"Ao Flow",printName:"Aortic Flow", GAo: [],GGo: [],min: 1,max: 1000},
      {name: "PULFlow", displayName:"Pulm Flow",printName:"Pulmonary flow", GAo: [],GGo: [],min: 1,max: 1000},
      {name: "DASYSFlow", displayName:"Da Sys Flow",printName:"DA SYS Flow", GAo: [],GGo: [],min: 1,max: 1000},
      {name: "DADIASTFLOW", displayName:"Da Diast Flow",printName:"DA DIAST Flow", GAo: [],GGo: [],min: 1,max: 1000},
      {name: "DuctusVenosus", displayName:"Ductus Venosus",printName:"", GAo: [],GGo: [],min: 1,max: 1000},
      {name: "AWave", displayName:"A-Wave", printName:"", GAo: [],GGo: [],min: 1,max: 1000},
      {name: "PI", displayName:"PI",printName:"", GAo: [],GGo: [],min: 1,max: 1000},
      {name: "PVR", displayName:"Pulmonary Venous Return",printName:"", GAo: [],GGo: [],min: 1,max: 1000},
      {name: "SVR", displayName:"Systemic Venuos Return SVC + IVC",printName:"", GAo: [],GGo: [],min: 1,max: 1000},
      {name: "VoPl", displayName: "VoP(l)",printName:"VoP(l)", GAo: [],GGo: [],min: 1,max: 10},
      {name: "VoPr", displayName: "VoP(r)",printName:"VoP(r)", GAo: [],GGo: [],min: 1,max: 10},
      {name: "Coronary", displayName: "Coronary Sinus",printName:"Coronary Sinus", GAo: [],GGo: [],min: 1,max: 10},
      {name: "IFI", displayName: "IFI",printName:"IFI", GAo: [],GGo: [],min: 1,max: 10},
  
  
      
  
      {name: "CRL", displayName:"CRL",printName:"", GAo:['Hadlock','Hansman'],GGo:['Had'],min:2,max:150},
      {name: "NT", displayName:"NT",printName:"", GAo:[],GGo:[],min:0.6,max:5},
      {name: "GS", displayName:"GS",printName:"", GAo: ['Nyberg'],GGo:[],min: 1,max: 56 }
  
  
  
    ];
   public static AllMeas = [];
    
    
   public static init(nonefirst = false){
      AllMeasures.mopts.forEach(function(meas){
         AllMeasures.AllMeas.push(new Measure(meas.name,meas.GAo,meas.GGo,meas.min,meas.max,"meas","",false,meas.displayName,false,meas.printName,nonefirst));
      });
      AllMeasures.AllMeas[AllMeasures.AllMeas.length-1].Table = "nt";
      AllMeasures.AllMeas[AllMeasures.AllMeas.length-2].Table = "nt";
      AllMeasures.AllMeas[AllMeasures.AllMeas.length-3].Table = "nt";
   }
}; 