import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder,UntypedFormGroup,FormControl } from '@angular/forms';
import {ExamsService} from '../../exams.service';
import {AutotextService} from '../../autotext.service';
import { Subscription } from 'rxjs';
import { CommerrorService } from 'src/app/commerror.service';


@Component({
  selector: 'app-cardio-general',
  templateUrl: './cardio-general.component.html',
  styleUrls: ['./cardio-general.component.scss']
})
export class CardioGeneralComponent implements OnInit, OnDestroy {
  GenForm: UntypedFormGroup;
  lastMed:string;
  sub = new Subscription()


  constructor(private fb:UntypedFormBuilder, public es: ExamsService,public atS:AutotextService, public ce:CommerrorService) { 
    this.GenForm = this.fb.group({
      MedicalHistory: [''],
      Purpose: [''],
      Risk:[''],
      ECG:[''],
      Medications:[''],
      Protocol:[''],
      HeartRate:  [''],
      Procedure:[''],
      Indication:[''],
      Quality:[''],
      bpSystolic:[''],
      bpDiastolic: [''],
      FollowUpExam:[''],
      Repeat1:[''],
      Repeat2:[''],
      ReferingDoc:[''],
      HMO:[''],
      ReferralNum: [''],
      RefererringDocID: ['']
    });
    if(! this.es.isClosed){
      this.es.getLastHistory(true).subscribe((data)=>{
        this.lastMed = data['history'];
      },(err)=>{
        console.log(err);
      });
    }
  }

  ngOnInit() {
    this.es.fillForm(this.GenForm,"cardioGeneral",false,true,['HMO'],['ReferralNum','ReferingDoc','HMO','RefererringDocID']);
    this.sub = this.es.formLoaded$.subscribe(() =>{
      if(this.es.patServ.getPat().HMO && !this.GenForm.get('HMO').value){
        this.GenForm.get('HMO').patchValue(this.es.patServ.getPat().HMO)
      }
      this.ce.isCardio = true;
    })
    this.sub = this.GenForm.get('HMO').valueChanges.subscribe((data) =>{
      if(data != this.es.patServ.getPat()['HMO'] && data !='-1'){
        this.ce.errorCardio('שים לב כי סימנת קופת חולים שונה מפרטי הלקוח. \n האם ברצונך להמשיך?', false);
      }else{
      if(this.GenForm.get('HMO').value)
        this.es.saveVals('HMO', this.GenForm.get('HMO').value, false, 'cardioGeneral');
      }

    })
    this.sub = this.ce.ecnext$.subscribe(() =>{
      if(this.GenForm.get('HMO').value)
        this.es.saveVals('HMO', this.GenForm.get('HMO').value, false, 'cardioGeneral');
    })
  }

  addLastMed(){
    if(this.GenForm.get("MedicalHistory").value)
      this.GenForm.get("MedicalHistory").setValue(this.GenForm.get("MedicalHistory").value + this.lastMed);
    else
      this.GenForm.get("MedicalHistory").setValue(this.lastMed);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

}
