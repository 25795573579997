import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DiagnosisService } from 'src/app/diagnosis.service';
import { GastroFormsService } from 'src/app/gastro-forms.service';
import { PathologyService } from 'src/app/pathology.service';
import { PrintService } from 'src/app/print.service';

@Component({
  selector: 'app-gsprint-form',
  templateUrl: './gsprint-form.component.html',
  styleUrls: ['./gsprint-form.component.scss']
})
export class GsprintFormComponent implements OnInit {
  FormID:number;
  PID:number;
  now:Date;
  
  Direction='ltr';
  
  showCharts = false;
  loaded=false;
  hasImages = false;
  imPerPage = 2;
  mct = 0;
  altHeader:SafeHtml;
  showHeader = true;
  showHistory = true;
  sub: Subscription;
  groups = ['Mitral','Tricuspid','Aortic','Pulmonic','LVOT']

  constructor(public gfs:GastroFormsService,public pathologyS:PathologyService,private ds: DiagnosisService,private route: ActivatedRoute, public print:PrintService, public translate: TranslateService, private sanitizer: DomSanitizer) {
    this.now=new Date();
   }

  ngOnInit() {
    this.translate.use(this.route.snapshot.params.LANG);
    if(this.route.snapshot.params.LANG == 'he-IL' || this.route.snapshot.params.LANG == 'ar-JO')
      this.Direction='rtl';
    
      this.loaded=true;
    let code = this.route.snapshot.params['code'];
    this.FormID = this.route.snapshot.params.examid;
    this.PID= this.route.snapshot.params.PID;
    if(this.route.snapshot.params["images"] == "y"){
      this.hasImages = true;
      this.imPerPage = this.route.snapshot.params["perpage"];
    }
    if(this.route.snapshot.params["perpage"] == "All"){
      this.hasImages = true;
      this.imPerPage = 2;
      this.print.allImages = true;
    }
    else
      this.print.allImages = false;
    if(this.route.snapshot.params.hasOwnProperty('showheader'))
      this.showHeader = this.route.snapshot.params['showheader'] == 'y';
    this.gfs.loadExam(this.FormID,this.PID, this.hasImages,code);
    this.print.loadMsg.subscribe(()=> {
      if(this.route.snapshot.params.charts == 'y')
        this.showCharts=true;
      if(this.print.env['htmlheader']){
        this.altHeader=this.sanitizer.bypassSecurityTrustHtml(this.print.env['htmlheader']);
      }
    });
  }

}
