
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'weeksDays'
})
export class WeeksDaysPipe implements PipeTransform {
  transform(value: number): string {
    if (isNaN(value)) return "";
    const weeks = Math.floor(value / 7);
    const days = value % 7;
    if (weeks==0 && days==0) return ""
    return `${weeks}w ${days}d`;
  }
}
