
<section *ngIf="name!=='EFW' && name!='HCAC' && name!='AUA'"  class="measurment-unit-h is-stat3" style="align-items: flex-start;">
    
    <div class="measurment-unit-in" >
        
        <div class="mearurment-unit-vim">
            
            <header class="measurment-unit-top" >
               
               
                <h2 class="measurment-unit-title" >{{name}}</h2>
                
                <div class="measurment-unit-dim" style="display: block;" >

                     {{ultrasoundMeasurements?.['value']}}

                    <small class="ng-star-inserted">mm</small>
                </div>
                    
            </header>
            

           
            <dl class="l-table measurment-unit-data" >
                <div class="l-row measurment-unit-row">
                    <dt class="l-td-m measurment-cell measurment-unit-key">
                            <span  >GA by {{ultrasoundMeasurements['agingCalculatedBy']}} </span>

                    </dt>
                    <dd class="l-td-m measurment-cell measurment-unit-value">
                        <span  *ngIf="ultrasoundMeasurements['GA']" class="measurment-unit-value-bubble" [matTooltip]="ultrasoundMeasurements['GA']+'d'" ><i ></i>
                            <span>
                                {{ultrasoundMeasurements['GA'] | weeksDays}}
                            </span>

                        </span>
                    </dd>
                </div>
                <div class="l-row measurment-unit-row">
                    <dt class="l-td-m measurment-cell measurment-unit-key">
                            <span > GG by   {{ultrasoundMeasurements['agingCalculatedBy']}} </span>

                        
                    </dt>
                    <dd class="l-td-m measurment-cell measurment-unit-value" >
                        <div *ngIf="ultrasoundMeasurements['GG']" class="measurment-unit-value-bubble"><i></i>
                            <span >
                                {{ultrasoundMeasurements['GG']}}%
                            </span>
                                
                        </div>
                    </dd>
                </div>
            </dl>
        </div>

    </div>

</section>

<!---------------------EFW--------------  -->
<section *ngIf="name=='EFW'" class="is-stat3" class="measurment-unit-h">
    
    <div class="measurment-unit-in" >
        
        <div class="mearurment-unit-vim">
            
            <header class="measurment-unit-top" >
                
                
                <h2 class="measurment-unit-title" >{{name}}</h2>
                <dt class="l-td-m measurment-cell measurment-unit-key" >{{ultrasoundMeasurements['calculatedBy']}} </dt>
                
                <div class="measurment-unit-dim" style="display: block;">

                     {{ultrasoundMeasurements['value']}}

                    <small class="ng-star-inserted">{{ultrasoundMeasurements['unit']}}</small>
                </div>
                    
            </header>
            

           
            <dl class="l-table measurment-unit-data" >
                <div class="l-row measurment-unit-row">
                    <dt class="l-td-m measurment-cell measurment-unit-key">
                            <span  >GA by {{ultrasoundMeasurements['agingCalculatedBy']}} </span>

                    </dt>
                    <dd class="l-td-m measurment-cell measurment-unit-value">
                        <span *ngIf="ultrasoundMeasurements['GA']" class="measurment-unit-value-bubble" [matTooltip]="ultrasoundMeasurements['GA']+'d'" ><i ></i>
                            <span>
                                {{ultrasoundMeasurements['GA'] | weeksDays}}
                            </span>

                        </span>
                    </dd>
                </div>
                <div class="l-row measurment-unit-row">
                    <dt class="l-td-m measurment-cell measurment-unit-key">
                            <span > GG by   {{ultrasoundMeasurements['agingCalculatedBy']}} </span>

                        
                    </dt>
                    <dd class="l-td-m measurment-cell measurment-unit-value" >
                        <div *ngIf="ultrasoundMeasurements['GG']" class="measurment-unit-value-bubble"><i></i>
                            <span>
                                {{ultrasoundMeasurements['GG']}}%
                            </span>
                                
                        </div>
                    </dd>
                </div>
            </dl>
        </div>

    </div>

</section>

<!-- HCAC -->
<section *ngIf="name=='HCAC'" class="is-stat3" class="measurment-unit-h">
    
    <div class="measurment-unit-in" >
        
        <div class="mearurment-unit-vim">
            
            <header class="measurment-unit-top" >
                
                
                <h2 class="measurment-unit-title" >{{name}}</h2>
                <dt class="l-td-m measurment-cell measurment-unit-key" >{{ultrasoundMeasurements['calculatedBy']}} </dt>
                
                <div class="measurment-unit-dim" style="display: block;">

                     {{ultrasoundMeasurements['value']}}

                   
                </div>
                    
            </header>
            

           
            <dl class="l-table measurment-unit-data" >
                <div class="l-row measurment-unit-row">
                    <dt class="l-td-m measurment-cell measurment-unit-key">
                            <span  >GA by  </span>

                    </dt>
                    <dd class="l-td-m measurment-cell measurment-unit-value">
                        <span *ngIf="ultrasoundMeasurements['GA']" class="measurment-unit-value-bubble" ><i></i>
                            <span>
                               
                            </span>

                        </span>
                    </dd>
                </div>
                <div class="l-row measurment-unit-row">
                    <dt class="l-td-m measurment-cell measurment-unit-key">
                            <span > GG by    </span>

                        
                    </dt>
                    <dd class="l-td-m measurment-cell measurment-unit-value" >
                        <div *ngIf="ultrasoundMeasurements['GG']" class="measurment-unit-value-bubble"><i></i>
                            <span>
                             
                            </span>
                                
                        </div>
                    </dd>
                </div>
            </dl>
        </div>

    </div>

</section>

<section *ngIf="name=='AUA'" class="is-stat3" class="measurment-unit-h">
    
    <div class="measurment-unit-in" >
        
        <div class="mearurment-unit-vim">
            
            <header class="measurment-unit-top" >
                
                
                <h2 class="measurment-unit-title" >GA ({{name}})</h2>
                
                
                <div class="measurment-unit-dim" style="display: block;" [matTooltip]="ultrasoundMeasurements['value']+'d'">

                     {{ultrasoundMeasurements['value'] | weeksDays}}

                   
                </div>
                    
            </header>
            

           
            <dl class="l-table measurment-unit-data" >
                <div class="l-row measurment-unit-row">
                    <dt class="l-td-m measurment-cell measurment-unit-key">
                            <span  >  </span>

                    </dt>
                    <dd class="l-td-m measurment-cell measurment-unit-value">
                        
                    </dd>
                </div>
                <div class="l-row measurment-unit-row">
                    <dt class="l-td-m measurment-cell measurment-unit-key">
                            <span >  </span>

                        
                    </dt>
                    <dd class="l-td-m measurment-cell measurment-unit-value" >
                        
                    </dd>
                </div>
            </dl>
        </div>

    </div>

</section>
