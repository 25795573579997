<div class="section-inner mb-20 px-16" *ngIf="
    show &&
    System !== 'chestFetal' &&
    (alist.length > 0 ||
      (System == 'urology' &&
        print.urology[fetus] &&
        print.urology[fetus].FetalSex > -1 &&
        print.urology[fetus].DoNotPrintSex != 1 &&
        2 > print.urology[fetus].FetalSex) ||
      (System == 'cns' && print.cns[fetus] && print.cns[fetus].Sulcation > -1 && print.cns[fetus].DiagnosisList.length > 0) ||
      (print[System][fetus].DiagnosisList && print[System][fetus].DiagnosisList.length > 0) ||
      (print[System][fetus] && print[System][fetus].Comments) || print[System][fetus].Diagnosis || print[System][fetus].FHR)
  " [ngClass]="dir">
  <div class="formatted-title" *ngIf="System !== 'chestFetal'">
    {{ "systems.Category_Names." + System | translate }}
  </div>
  <table class="symetric" *ngIf="System !== 'meas' && System !== 'chestFetal'">
    <ng-container *ngFor="let a of alist; let i = index">
      <tr *ngIf="i % 4 == 0">
        <td [ngClass]="getClass(a)">
          <span> {{ "systems." + System + "." + a.nm | translate }}</span>
        </td>
        <td [ngClass]="getClass(alist[i + 1])" *ngIf="i + 1 < alist.length">
          <span>
            {{ "systems." + System + "." + alist[i + 1].nm | translate }}
          </span>
        </td>
        <td [ngClass]="getClass(alist[i + 2])" *ngIf="i + 2 < alist.length">
          <span>
            {{ "systems." + System + "." + alist[i + 2]?.nm | translate }}
          </span>
        </td>
        <td [ngClass]="getClass(alist[i + 3])" *ngIf="i + 3 < alist.length">
          <span>
            {{ "systems." + System + "." + alist[i + 3]?.nm | translate }}
          </span>
        </td>
      </tr>
    </ng-container>
  </table>

  <div *ngIf="System == 'chest' && print.chest[fetus].FHR">
    <strong>{{ "systems.chest.fetal_heart_rate.title" | translate }}: </strong>{{ print.chest[fetus].FHR }}
    {{ "systems.chest.fetal_heart_rate.units.title" | translate }}
  </div>
  <div class="w-1/3" *ngIf="
      System == 'urology' &&
      print.urology[fetus] &&
      print.urology[fetus].FetalSex > -1 &&
      print.urology[fetus].DoNotPrintSex != 1 &&
      print.urology[fetus].FetalSex < 2
    ">
    {{ "systems.urology.FetusSex" | translate }}:
    {{
    "systems.urology.fetus_sex." +
    (print.urology[fetus].FetalSex == 0 ? "female" : "male") | translate
    }}
  </div>
  <table class="mb-8" *ngIf="
      System == 'cns' && print.cns[fetus] && print.cns[fetus].Sulcation > -1
    ">
    <tr>
      <td class="w-1/5" *ngIf="print.cns[fetus].Sulcation > -1">
        <strong>{{ "systems.cns.Sulcation" | translate }}:</strong>
      </td>
      <td *ngIf="print.cns[fetus].Sulcation > -1">
        <strong>{{
          "general.PositiveNegative." +
          (print.cns[fetus].Sulcation == 1 ? "yes" : "No") | translate
          }}</strong>
      </td>
      <!-- <td class="w-1/5">{{print.cns[fetus].SulcationIndic}}</td> -->
      <td *ngIf="print.cns[fetus].Sulcation === 1">
        {{ print.cns[fetus].Insula == 1 ? "Insula" : "" }}
      </td>
      <td *ngIf="print.cns[fetus].Sulcation === 1">
        {{ print.cns[fetus].CalcaneSuleas == 1 ? "Calcarine Sulcus" : "" }}
      </td>
      <td *ngIf="print.cns[fetus].Sulcation === 1">
        {{
        print.cns[fetus].ParietoOcipiteSulcus == 1
        ? "Parieto-ocipite Sulcus"
        : ""
        }}
      </td>
      <td *ngIf="print.cns[fetus].Sulcation === 1">
        {{ print.cns[fetus].CovnexitySulci == 1 ? "Convexity Sulci" : "" }}
      </td>
    </tr>
  </table>
  <div class="section" *ngIf="
      (print[System][fetus].DiagnosisList &&
        print[System][fetus].DiagnosisList.length > 0) ||
      print[System][fetus].Diagnosis
    ">
    <h4>{{ "summary.diagnosis.title" | translate }}</h4>
    <div style="text-align: left; direction: ltr">
      <ng-container *ngFor="let a of print[System][fetus].DiagnosisList">{{ a[1] }}<br /></ng-container>
    </div>
    <hr *ngIf="
        print[System][fetus].Diagnosis &&
        print[System][fetus].DiagnosisList &&
          print[System][fetus].DiagnosisList.length > 0
      " />
    <span class="comment" *ngIf="print[System][fetus].Diagnosis">{{
      print[System][fetus].Diagnosis
      }}</span>
  </div>
  <div *ngIf="print[System][fetus] && print[System][fetus].Comments" class="comment">
    <strong>{{ "general.Comment" | translate }}: </strong>
    {{ print[System][fetus].Comments }}
  </div>
</div>

<div *ngIf="print.exam.ExamType === 'Fetal Echo' || print.exam.ExamType === 'Pediatric Echo'">
  <!-- =============== Situs Items (Start) =============== -->
  <div class="section-inner mb-20 px-20" [ngClass]="dir"
    *ngIf="SitusItems && SitusItems.length > 0 && hasValue(SitusItems, 'val')">
    <table class="symetric">
      <ng-container *ngFor="let a of SitusItems">
        <tr *ngIf="a.val !== null && a.val !== -1">
          <!-- ======= Input Name (Start) =======  -->
          <td>
            <strong>
              {{
              (a.nm === "AVC" || a.nm === "VAC"
              ? a.val
              ? "systems." + System + "." + a.nm + ".yes"
              : "systems." + System + "." + a.nm + ".no"
              : a.nm == "PVR"
              ? "systems." + System + "." + a.nm + ".return"
              : "systems." + System + "." + a.nm
              ) | translate
              }}
            </strong>
            <span *ngIf="a.nm === 'PVR'">
              {{ fes.fetalEchoForm.get("PVRVessels").value }}
              {{
              (fes.fetalEchoForm.get("PVRVessels").value === 1
              ? "systems." + System + "." + a.nm + ".returnSingle"
              : "systems." + System + "." + a.nm + ".title"
              ) | translate
              }}
            </span>
          </td>
          <!-- ======= Input Name (End) =======  -->

          <!-- ======= Input Value (Start) =======  -->
          <td>
            <!-- uncomented this for now -->
            <!-- <td *ngIf="a.nm !== 'AVC' && a.nm !== 'VAC'"> -->
            {{ "systems." + System + "." + getAnswer(a) | translate }}
          </td>
          <!-- ======= Input Value (End) =======  -->
        </tr>

        <!-- ======= Input Comments (Start) =======  -->
        <tr *ngIf="a.com" class="comment">
          <div class="section">
            <strong>
              {{ "systems." + System + ".Comments" | translate }}: </strong>
            {{ a.com }}
          </div>
        </tr>
        <!-- ======= Input Comments (End) =======  -->
      </ng-container>
    </table>
  </div>
  <!-- =============== Situs Items (End) =============== -->

  <!-- =============== Atria Items (Start) =============== -->
  <div class="section-inner mb-20 px-20" [ngClass]="dir"
    *ngIf="AtriaItems && AtriaItems.length > 0 && hasValue(AtriaItems, 'val')">
    <table class="symetric">
      <div class="formatted-title">
        {{ "systems." + System + ".Atria" | translate }}
      </div>
      <ng-container *ngFor="let a of AtriaItems">
        <tr *ngIf="a.val !== null && a.val !== -1">
          <!-- ======= Input Name (Start) =======  -->
          <td>
            {{ "systems." + System + "." + a.nm | translate }}
          </td>
          <!-- ======= Input Name (End) =======  -->

          <!-- ======= Input Value (Start) =======  -->
          <td>
            {{ "systems." + System + "." + getAnswer(a) | translate }}
          </td>
          <!-- ======= Input Value (End) =======  -->

          <!-- ======= Input Comments (Start) =======  -->
          <td *ngIf="a.com" class="comment">
            <strong>{{ "systems." + System + ".Comments" | translate }}: </strong>{{ a.com }}
          </td>
          <!-- ======= Input Comments (End) =======  -->
        </tr>
      </ng-container>
    </table>
  </div>
  <!-- =============== Atria Items (End) =============== -->

  <!-- =============== Ventricles Items (Start) =============== -->
  <div class="section-inner mb-20 px-16" [ngClass]="dir"
    *ngIf="VentriclesItems && VentriclesItems.length > 0 && hasValue(VentriclesItems, 'val')">
    <!-- FIX - Leaving this here uncomented we might need it later: -->
    <!-- *ngIf="(VentriclesItems && VentriclesItems.length > 0) || (showIVS && (ivsStatus || ivs.val || ivs.com))" -->
    <table class="symetric">
      <div *ngIf="(VentriclesItems && VentriclesItems.length > 0) || ivsStatus || ivs.val|| ivs.com"
        class="formatted-title">
        {{ "systems." + System + ".Ventricles" | translate }}
      </div>

      <ng-container *ngFor="let a of VentriclesItems">
        <tr *ngIf="a.val !== null && a.val !== -1">
          <td>
            {{
            (a.nm === "IVSStatus"
            ? "systems." + System + ".IVS"
            : "systems." + System + "." + a.nm
            ) | translate
            }}
          </td>
          <td *ngIf="a.nm === 'IVS'">
            {{ "systems." + System + "." + getAnswer(ivsStatus) | translate }}
          </td>
          <td>
            {{
            a.nm === "IVS"
            ? a.val + "mm"
            : ("systems." + System + "." + getAnswer(a) | translate)
            }}
          </td>

          <!-- ======= Input Comments (Start) =======  -->
          <td *ngIf="a.com" class="comment">
            <strong>{{ "systems." + System + ".Comments" | translate }}: </strong>{{ a.com }}
          </td>
          <!-- ======= Input Comments (End) =======  -->

        </tr>
      </ng-container>
      <tr *ngIf="showIVS">
        <td *ngIf="ivsStatus">
          {{ "systems." + System + ".InterventricularSeptum" | translate }}
        </td>
        <td *ngIf="ivsStatus">
          {{ "systems." + System + "." + getAnswer(ivsStatus) | translate }}
        </td>
        <td *ngIf="ivs">
          {{
          ivs.nm === "IVS"
          ?ivs.val ? ivs.val + "mm" : ""
          : ("systems." + System + "." + getAnswer(ivs) | translate)
          }}
        </td>
        <td *ngIf="(ivs && ivs.com) || (ivsStatus && ivsStatus.com)" class="comment">
          <strong>{{ "systems." + System + ".Comments" | translate }}</strong>{{ ivs ? ivs.com : ivsStatus.com }}
        </td>
      </tr>
    </table>
  </div>
  <!-- =============== Ventricles Items (End) =============== -->

  <!-- =============== Valves Items (Start) =============== -->
  <div class="section-inner mb-20 px-16" [ngClass]="dir"
    *ngIf="ValvesItems && ValvesItems.length > 0 && hasValue(ValvesItems, 'val')">
    <table class="symetric">
      <div class="formatted-title" style="margin: 0">
        {{ "systems." + System + ".Valves" | translate }}
      </div>
      <ng-container *ngFor="let a of ValvesItems">
        <tr *ngIf="a.val !== null && a.val !== -1">
          <td>
            {{ "systems." + System + "." + a.nm | translate }}
          </td>
          <td>
            {{ "systems." + System + "." + getAnswer(a) | translate }}
          </td>

          <!-- ======= Input Comments (Start) =======  -->
          <td *ngIf="a.com" class="comment">
            <strong>{{ "systems." + System + ".Comments" | translate }}: </strong>{{ a.com }}
          </td>
          <!-- ======= Input Comments (End) =======  -->

        </tr>
      </ng-container>
    </table>
  </div>
  <!-- =============== Valves Items (End) =============== -->

  <!-- =============== Vessels Items (Start) =============== -->
  <div class="section-inner mb-20 px-16" [ngClass]="dir"
    *ngIf="VesselsItems && VesselsItems.length > 0 && hasValue(VesselsItems, 'val')">
    <table class="symetric">
      <div class="formatted-title">
        {{ "systems." + System + ".Vessels" | translate }}
      </div>
      <ng-container *ngFor="let a of VesselsItems">
        <tr *ngIf="a.val !== null && a.val !== -1">
          <td>
            {{ "systems." + System + "." + a.nm | translate }}
          </td>
          <td>
            {{ "systems." + System + "." + getAnswer(a) | translate }}
          </td>
        </tr>
        <td *ngIf="a.com" class="comment">
          <strong>{{ "systems." + System + ".Comments" | translate }}: </strong>{{ a.com }}
        </td>
      </ng-container>
    </table>
  </div>

  <!-- ======= Input Comments (Start) =======  -->
  <div *ngIf="comments" class="comment">
    <strong>{{ "general.Comment" | translate }}: </strong> {{ comments }}
  </div>
  <!-- ======= Input Comments (End) =======  -->

  <!-- =============== Vessels Items (End) =============== -->

</div>