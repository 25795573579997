<div class="no-scroll patient-ob">
  <div class="wrapper">
    <!-- ========================== Print History Popup (Start) ========================== -->
    <div *ngIf="showPrintHistoryPopup" class="print-history-popup">
      <div class="print-history-popup-body">
        <h2 class="popup-title">{{'GUI.Patient History.PrintHistory.Print History' | translate}}</h2>
        <button
          (click)="togglePrintHistoryPopup()"
          class="print-history-popup-close"
          aria-label="close popup"
        ></button>

        <img
          *ngIf="loadingPrint"
          src="../../assets/images/Wedges.svg"
          class="pdf-loader"
        />

        <table
          mat-table
          [dataSource]="printHistoryData"
          *ngIf="!loadingPrint"
          class="mat-elevation-z8 print-table"
        >
          <!-- Position Column -->
          <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef>#</th>
            <td mat-cell *matCellDef="let element">
              {{ printHistoryData.indexOf(element) + 1 }}
            </td>
          </ng-container>

          <!-- Date Column -->
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>
            {{'GUI.Patient History.PrintHistory.Date' | translate}}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element?.creationDate | date : "dd/MM/yyyy"  }}
            </td>
          </ng-container>

          <!-- Time Column -->
          <ng-container matColumnDef="time">
            <th mat-header-cell *matHeaderCellDef>
            {{'GUI.Patient History.PrintHistory.Hour' | translate}}
            
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element?.creationTime }}
            </td>
          </ng-container>

          <!-- Comments Column -->
          <ng-container matColumnDef="comments">
            <th mat-header-cell *matHeaderCellDef>
              <!-- Comments -->
              {{'GUI.Patient History.PrintHistory.Comments' | translate}}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element?.comments }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            (click)="printSelectPDF(row)"
            [class.demo-row-is-clicked]="clickedRows.has(row)"
            *matRowDef="let row; columns: displayedColumns"
          ></tr>
        </table>
      </div>
    </div>
    <!-- ========================== Print History Popup (End) ========================== -->

    <!-- ========================== header (start) ========================== -->
    <header class="l-header">
      <div class="on-mobile header-logo">
        <img src="../../assets/icons/logo.svg" alt="Artisan Sonics" />
      </div>
      <div class="l-header-panel">
        <i class="off-mobile header-icon icon-fetus" *ngIf="false"></i>

        <div class="header-pagi" *ngIf="false">
          <a href="#" class="header-pagi-item">1</a>
          <a href="#" class="header-pagi-item is-active">2</a>
          <a href="#" class="header-pagi-item">3</a>
        </div>

        <nav class="header-menu" style="display: flex; align-items: center; ">
          <!-- <a href="#" class="header-menu-link icon-book" aria-label="library"></a> -->
          <!-- <a href="#" class="header-menu-link icon-print" aria-label="Print"></a> -->
          <img *ngIf="login.premissions && login.premissions?.uploadDocuments" style="cursor: pointer; margin-right: 2em;"
            matTooltip="{{'GUI.documents.tooltips.Attach Document' | translate}}" height="41em" width="41em" (click)="openDocumentsAdd=true"
            src="../../assets/icons/fileupload.svg" />
          <img
          *ngIf=" login.premissions?.Billings"
          style="cursor: pointer; margin-right: 2em;"
          [matTooltip]="'GUI.Tooltips.Get Invoice' | translate"
         (click)="redirectAdministration('billing')"
          height="41em"
          width="41em"
          src="../../assets/icons/billings-white.svg"
        />
          <img
          *ngIf=" login.premissions?.Appointments"
          style="cursor: pointer;"
          [matTooltip]="'GUI.Tooltips.Set Appointment' | translate"
         (click)="redirectAdministration('appointments')"
          height="41em"
          width="41em"
          src="../../assets/icons/calendar-white.svg"
        />
          <a
            (click)="
              calendarForm = true; selectedForm = null; cf.selcForm = null
            "
            class="header-menu-link icon-calendar"
            [matTooltip]="'GUI.Tooltips.Pregnancy Planner' | translate"
            style="background-color: white; border-radius: 5px; cursor: pointer"
            aria-label="calendar"
            *ngIf="login.premissions?.Planner
            "
          ></a>
          <!-- <a href="#" class="header-menu-link icon-chart" aria-label="chart"></a> -->
          <!-- <a href="#" class="header-menu-link icon-image" aria-label="image"></a> -->
          <!-- <a href="#" class="header-menu-link icon-searchdoc" aria-label="image"></a> -->
          <!-- <a href="#" class="header-menu-link icon-save" aria-label="save"></a> -->
          <!-- <a href="#" class="header-menu-link icon-date" aria-label="date"></a> -->
          <!-- <a href="#" class="header-menu-link icon-confirm" aria-label="confirm"></a> -->
        </nav>

        <nav class="on-mobile header-mobilePanel">
          <button
            class="btn-mobileMenu"
            id="open-headerMenu"
            data-show=".header-menu"
            aria-label="open mobile menu"
          ></button>
          <button
            class="btn-mobileMenu"
            id="open-mainNav"
            data-show=".l-sidebar"
            aria-label="open mobile sidebar"
          ></button>
        </nav>
      </div>
    </header>
    <!-- ========================== header (end) ========================== -->

    <!-- ========================== intro (start) ========================== -->

    <main class="l-deashboard">
      <div class="h100 l-flex l-flex-w t-boxMargin--spacebyPadding">
        <!-- history (start) -->
        <div class="w50 patient-ob-box">
          <div
            class="h100 m-box"
            *ngIf="pat?.ID === 'default'"
            [formGroup]="examServe.newTitleItem"
          >
            <h2>Additional Titles Setting</h2>
            <div
              class="h100 l-flex l-column"
              style="justify-content: space-between"
            >
              <div>
                <div class="headDiv">
                  <div class="inputLine">
                    <section>
                      <span>Define new title:</span>
                      <input
                        class="input"
                        #inputTitle
                        type="text"
                        formControlName="Title"
                        [value]="examServe.newTitleItem.get('Title').value"
                      />
                    </section>
                    <span
                      class="t-validation-wrap l-fg1"
                      style="text-align: center"
                    >
                      <span class="s-count" *ngIf="examServe.CustomTitles">
                        <span
                          [ngClass]="
                            !examServe.disableSaveTitle ? '' : 'is-invalid'
                          "
                        >
                          <strong
                            >{{
                              examServe.CustomTitles
                                ? examServe.CustomTitles?.length
                                : 0
                            }}/8</strong
                          >
                        </span>
                        <div class="t-validation-message">
                          <span>Maximum Custom Titles, Please Remove One</span>
                        </div>
                      </span>
                    </span>
                    <button
                      (click)="examServe.saveNewTitle(login.ClinicID, false)"
                      style="
                        background-color: #d7a95b;
                        color: white;
                        width: 4em;
                        border-radius: 10px;
                      "
                    >
                      save
                    </button>
                  </div>
                </div>
                <div>
                  <div
                    class="linked-radios-list linked-radios-set"
                    style="margin-top: 0"
                    [formGroup]="examServe.newTitleItem"
                  >
                    <label
                      class="linked-radios-item"
                      *ngFor="let c of examServe.CustomTitles"
                    >
                      <input
                        type="radio"
                        [value]="c.AutoIndex"
                        formControlName="AutoIndex"
                        (click)="examServe.getTitle(c.AutoIndex)"
                      />
                      <span class="linked-radios-row">
                        <span
                          class="l-fg1 linked-radios-title"
                          (dblclick)="examServe.resetTitleItem()"
                          >{{ c.Title }}</span
                        >
                        <span
                          class="linked-radios-duration"
                          style="margin-inline-end: 5em"
                        >
                          <p>
                            {{ c.Creation | date : "dd/MM/yyyy" }} 
                            <!-- at -->
                            {{'GUI.Patient History.at'  | translate}}

                            {{ c.Creation | date : "HH:mm" }}
                          </p>
                        </span>
                        <span class="linked-radios-duration"
                          ><img
                            src="../../assets/icons/del.svg"
                            matTooltip="Delete"
                            (click)="examServe.deleteTitle(c.AutoIndex)"
                        /></span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="l-flex l-flex-w l-just-center patient-ob-btns-row">
                <a class="patient-ob-btn -exit_patient" routerLink="/patsearch">
                  Exit Titles Setting
                </a>
              </div>
            </div>
          </div>
          <div
            class="h100 m-box"
            *ngIf="pat?.ID != 'default'"
          >
            <h2>
              <!-- Patient History -->
            {{'GUI.Patient History.Patient History' | translate | titlecase}}
            </h2>
            <!-- ==================== Pregnancy with LMP  (Start) ================== -->
            <div
              class="linked-radios-list linked-radios-set"
              *ngFor="let preg of pregnancies"
            >
              <strong
                class="linked-radios-row"
                *ngIf="
                  (preg.calendarForms && preg.calendarForms?.length > 0) ||
                  preg.exams
                "
                >
                {{'GUI.Patient History.Pregnancy with LMP' | translate }} {{ preg.LMP | date : "dd/MM/yyyy" }}

                <span
                  class="linked-radios-date"
                  style="
                    position: absolute;
                    right: 0.5em;
                    font-size: 11pt;
                    cursor: pointer;
                  "
                  (click)="closePreg = preg.AutoIndex; pregExams = preg.exams"
                  *ngIf="
                    preg.ClosedDate ||
                    login.userType == 'doctor' ||
                    login.userType == 'tech' ||
                    login.userType == 'visitingdoc'
                  "
                  >{{
                    preg.ClosedDate
                      ? ('GUI.Patient History.Closed on' | translate) + (preg.ClosedDate | date : "dd/MM/yyyy")
                      : ('GUI.Patient History.Close' | translate)
                  }}
                </span>
              </strong>
              <ul *ngIf="preg.exams">
                <li class="l-flex l-flex-c">
                  <span style="width: 40%">
                    <strong> 
                      <!-- Exam Name -->
                      {{'GUI.Patient History.Exam Name' | translate | titlecase}}
                    </strong>
                  </span>
                  <span style="width: 40%">
                    <strong>
                      <!-- Main Diagnosis -->
                    {{'GUI.Patient History.Main Diagnosis' | translate | titlecase}}
                    </strong>
                  </span>
                  <span style="width: 25%">
                    <strong>
                      <!-- Done by -->
                    {{'GUI.Patient History.Done by' | translate | titlecase}}
                    </strong>
                  </span>
                </li>
              </ul>

              <ul>
                <li class="l-flex l-flex-c" *ngFor="let exam of preg.exams">
                  <span
                    class="examlink"
                    style="
                      display: flex;
                      position: relative;
                      width: 100%;
                      align-items: center;
                    "
                    [ngClass]="
                      switchMainDiagnosisContent(exam) !== null ? 'd-main' : ''
                    "
                    (contextmenu)="askDelete(exam)"
                  >
                    <span style="width: 35%">
                      <span
                        (click)="
                          goExam(preg, exam, exam.ExamType, exam.CreationTime)
                        "
                        style="margin-inline-end: 5px"
                        [matTooltip]="
                          canView(exam)
                            ? ''
                            : ('GUI.Patient History.LockedByMsg' | translate)
                        "
                      >
                      {{'GUI.Patient History.ExamTypes.OB.'+exam.ExamType | translate}}
                        <!-- {{ exam.ExamType }}  -->
                        <strong> 
                          {{ exam?.hasAnatomyNote ? '(N)' : '' }}
                        </strong>
                      </span>
                      <span *ngIf="exam.Changed">
                        ({{ exam.ChangedExamTitle }})
                      </span>
                      <ng-container *ngIf="exam.Locked">
                        <img
                          src="../../assets/icons/login/password.svg"
                          style="height: 1em; margin-left: 1em"
                        />
                        {{ exam.LockedBy }}
                      </ng-container>
                      <ng-container 
                        *ngIf="getConcent(exam) && login.premissions?.Consent && false"
                      >
                        <img *ngIf="false"
                          src="../../assets/icons/login/text-file-icon.svg"
                          style="height: 1.5em; width: 1.5em"
                          matTooltip="Open Concent Form"
                          (click)="startForm(getConcent(exam))"
                        />
                      </ng-container>
                    </span>

                    <img
                      [ngClass]="
                        examServe.awaitPrintHistory
                          ? 'print-history-icon-disabled'
                          : 'print-history-icon'
                      "
                      src="../../assets/icons/print-history.svg"
                      matTooltip="{{
                        showPrintHistoryPopup
                          ? ('GUI.Patient History.PrintHistory.Close Print History' | translate)
                          : ('GUI.Patient History.PrintHistory.Open Print History' | translate)
                      }}"
                      [matTooltipDisabled]="examServe.awaitPrintHistory"
                      (click)="
                        togglePrintHistoryPopup(); getPrintHistoryList(exam)
                      "
                    />

                    <span
                      style="width: 35%"
                      class="diagnosis anotomy-show-more"
                      (click)="
                        goExam(preg, exam, exam.ExamType, exam.CreationTime)
                      "
                    >
                      <img
                        *ngIf="switchMainDiagnosisContent(exam) !== null"
                        class="show-more-diagnosis-icon"
                        src="../../assets/icons/anatomy-plus.svg"
                      />
                      <span
                        *ngIf="
                          !switchMainDiagnosisContent(exam) &&
                          !showDiagnosisComments
                        "
                      >
                        <!-- No relevant data -->
                        {{'GUI.Patient History.No relevant data' | translate}}
                      </span>
                    </span>
                    <span
                      class="d-span"
                      *ngIf="switchMainDiagnosisContent(exam) !== null"
                    >
                      <div class="d-span-body">
                        <div class="d-span-details">
                          <div
                            *ngIf="switchMainDiagnosisContent(exam) !== null"
                            [ngClass]="
                              switchMainDiagnosisContent(exam) === ''
                                ? 'd-span-line'
                                : 'd-span-desc'
                            "
                            [innerHTML]="switchMainDiagnosisContent(exam)"
                          >
                          </div>
                          <div
                            *ngIf="showDiagnosisComments"
                            [ngClass]="
                              displayDiagnosisComments(exam) === ''
                                ? 'd-span-line'
                                : 'd-span-desc'
                            "
                          >
                            {{ displayDiagnosisComments(exam) }}
                          </div>
                        </div>
                      </div>
                    </span>

                    <span style="width: 20%">
                      <!-- by -->
                      {{'GUI.Patient History.by' | translate}}
                      {{ exam.Opener }}</span>
                    <span style="width: 20%" class="linked-radios-date">
                      {{ exam.CreationTime | date : "dd/MM/yyyy" }} 
                      <!-- at -->
                      {{'GUI.Patient History.at'  | translate}}
                      {{ exam.CreationTime | date : "HH:mm" }}
                    </span>
                  </span>
                </li>
              </ul>
              <div
                style="margin-top: 1em"
                *ngIf="
                  (preg.calendarForms &&
                  preg.calendarForms.length > 0) || (preg.forms && preg.forms.length)
                "
              >
                <strong class="linked-radios-row">
                  <!-- Forms -->
                {{'GUI.Patient History.Forms' | translate}}
                </strong>

                <ul>
                  <li
                    class="l-flex l-flex-c"
                    style="border-bottom: 1px solid #bdd3d5; margin-bottom: 1em;"
                    *ngFor="let form of preg.calendarForms"
                  >
                    <span
                      class="l-fg1 examlink"
                      (click)="loadCalendarForm(form)"
                      (contextmenu)="askDelete(form, false, false, false, true)"
                    >
                      <!-- Pregnancy Planner -->
                      {{'GUI.Patient History.Pregnancy Planner'  | translate}}
                    </span>
                    <span style="margin-right: 2em">
                      <!-- by  -->
                      {{'GUI.Patient History.by' | translate}}
                      {{ form.Opener }}</span>
                    <span class="linked-radios-date"
                      >{{ form.CreationTime | date : "dd/MM/yyyy" }} 
                      
                      <!-- at -->
                      {{'GUI.Patient History.at'  | translate}}

                      {{ form.CreationTime | date : "HH:mm" }}</span
                    >
                  </li>
                </ul>
                <ul *ngIf="preg.forms && preg.forms.length > 0">
                  <li
                    class="l-flex l-flex-c"
                    *ngFor="let concent of preg.forms | sortCreationTime: 'CreationTime'"
                  >
                    <span
                      class="l-fg1 examlink"
                      (click)="startForm(concent)"
                      (contextmenu)="askDelete(concent, true)"
                    >
                      Consent form For {{ concent.ExamType }}
                    </span>
        
                    <div [ngClass]="checkApprovedConcent(concent) ? 'signed-consent' : 'not-signed-consent'" [matTooltip]="checkApprovedConcent(concent) ? ('GUI.Tooltips.Consent Signed' | translate ) : ('GUI.Tooltips.Consent Not Signed' | translate )"
                    ></div>
                    <span style="margin-right: 2em">
                      <!-- by  -->
                      {{'GUI.Patient History.by' | translate}}
                      {{ concent.Opener }}
                      </span>
                    <span class="linked-radios-date"
                      >{{ concent.CreationTime | date : "dd/MM/yyyy" }}
                       <!-- at -->
                       {{'GUI.Patient History.at'  | translate}}
                      {{ concent.CreationTime | date : "HH:mm" }}</span
                    >
                  </li>
                </ul>
              </div>
            </div>
            <!-- ==================== Pregnancy with LMP  (End) ================== -->

            <!-- ========================== Other Exams (Start) ========================== -->
            <div
              class="linked-radios-list linked-radios-set"
              *ngIf="notFormExams && notFormExams.length > 0"
            >
            <strong class="linked-radios-row" style="margin-bottom: 2%">
              {{'GUI.Patient History.Other Exams' | translate}}
            </strong>
              <ul>
                <li class="l-flex l-flex-c" *ngFor="let exam of notFormExams | uniqueAutoIndex">
                  <ng-container *ngIf="!FORMS.includes(exam.ExamType)">
                    <span
                      class="examlink"
                      style="
                        display: flex;
                        position: relative;
                        width: 100%;
                        align-items: center;
                      "
                      [ngClass]="
                        switchMainDiagnosisContent(exam) !== null
                          ? 'd-main'
                          : ''
                      "
                    >
                      <span
                        style="width: 35%"
                        (click)="
                          goExam(null, exam, exam.ExamType, exam.CreationTime)
                        "
                        (contextmenu)="askDelete(exam)"
                      >
                      {{'GUI.Patient History.ExamTypes.GYN.'+exam.ExamType | translate}}

                        <!-- {{ exam.ExamType }} -->
                      </span>
                      <ng-container *ngIf="exam.Locked">
                        <img
                          src="../../assets/icons/login/password.svg"
                          style="height: 1em"
                        />
                        {{ exam.LockedBy }}
                      </ng-container>

                      <img
                        [ngClass]="
                          examServe.awaitPrintHistory
                            ? 'print-history-icon-disabled'
                            : 'print-history-icon'
                        "
                        src="../../assets/icons/print-history.svg"
                        matTooltip="{{
                          showPrintHistoryPopup
                            ? ('GUI.Patient History.PrintHistory.Close Print History' | translate)
                            : ('GUI.Patient History.PrintHistory.Open Print History' | translate)
                        }}"
                        [matTooltipDisabled]="examServe.awaitPrintHistory"
                        (click)="
                          togglePrintHistoryPopup(); getPrintHistoryList(exam)
                        "
                      />

                      <span
                        class="diagnosis anotomy-show-more"
                        style="width: 35%"
                        (click)="
                          goExam(null, exam, exam.ExamType, exam.CreationTime)
                        "
                      >
                        <img
                          *ngIf="switchMainDiagnosisContent(exam) !== null"
                          class="show-more-diagnosis-icon"
                          src="../../assets/icons/anatomy-plus.svg"
                        />
                        <span
                          *ngIf="
                            !switchMainDiagnosisContent(exam) &&
                            !showDiagnosisComments
                          "
                        >
                          
                        <!-- No relevant data -->
                        {{'GUI.Patient History.No relevant data' | translate}}

                        </span>
                      </span>
                      <span
                        class="d-span"
                        *ngIf="switchMainDiagnosisContent(exam) !== null"
                      >
                        <div class="d-span-body">
                          <div class="d-span-details">
                            <div
                              *ngIf="switchMainDiagnosisContent(exam) !== null"
                              [ngClass]="
                                switchMainDiagnosisContent(exam) === ''
                                  ? 'd-span-line'
                                  : 'd-span-desc'
                              "
                              [innerHTML]="switchMainDiagnosisContent(exam)"
                            ></div>
                            <div
                              *ngIf="showDiagnosisComments"
                              [ngClass]="
                                displayDiagnosisComments(exam) === ''
                                  ? 'd-span-line'
                                  : 'd-span-desc'
                              "
                            >
                              {{ displayDiagnosisComments(exam) }}
                            </div>
                          </div>
                        </div>
                      </span>

                      <span style="width: 20%">
                        <!-- by -->
                        {{'GUI.Patient History.by' | translate}}
                        {{ exam.Opener }}</span>
                      <span style="width: 20%" class="linked-radios-date">
                        {{ exam.CreationTime | date : "dd/MM/yyyy" }} 
                        
                        <!-- at -->
                        {{'GUI.Patient History.at'  | translate}}

                        {{ exam.CreationTime | date : "HH:mm" }}
                      </span>
                    </span>
                  </ng-container>
                </li>
              </ul>
            </div>
            <!-- ========================== Other Exams (End) ========================== -->

            <!-- ========================== Other (Unlinked) Consent Form (Start) ========================== -->
            <div
            style="margin-top: 1em; padding-left: 1.8em;"
            *ngIf="(unlinkedConsents?.length) && this.login.premissions?.Consent">
            <!-- <strong class="linked-radios-row">Other Consent Forms </strong> -->
            
            <!-- <ul> -->
              <li
                class="l-flex l-flex-c"
                *ngFor="let concent of unlinkedConsents | sortCreationTime: 'CreationTime'"
              >
              
                <span
                  class="l-fg1 examlink"
                  (click)="startForm(concent)"
                  (contextmenu)="askDelete(concent, false, false, true)"
                >Consent form For {{ concent.ExamType }}
                </span>
                <div [ngClass]="checkApprovedConcent(concent) ? 'signed-consent' : 'not-signed-consent'" [matTooltip]="checkApprovedConcent(concent) ? ('GUI.Tooltips.Consent Signed' | translate ) : ('GUI.Tooltips.Consent Not Signed' | translate )"
                ></div>
                <span style="margin-right: 2em"
                  >
                  <!-- by -->
                  {{'GUI.Patient History.by' | translate}} 
                  {{ concent.Opener }}</span
                >
                <span class="linked-radios-date"
                  >{{ concent.CreationTime | date : "dd/MM/yyyy" }}
                   <!-- at -->
                   {{'GUI.Patient History.at'  | translate}}

                  {{ concent.CreationTime | date : "HH:mm" }}</span
                >
              </li>
            <!-- </ul> -->
          </div>
            <!-- ========================== Other (Unlinked) Consent Form (End) ========================== -->
            <div
              class="linked-radios-list linked-radios-set"
              *ngIf="formsExams && formsExams?.length > 0"
            >
              <strong class="linked-radios-row">
                <!-- Letters and Certificates -->
                {{'GUI.Patient History.ExamTypes.Letters and Certificates' | translate}}
              </strong>

              <ul>
                <li class="l-flex l-flex-c" *ngFor="let exam of formsExams | uniqueAutoIndex">
                  <ng-container *ngIf="FORMS.includes(exam.ExamType)">
                    <span
                      class="l-fg1 examlink"
                      (click)="
                        goExam(null, exam, exam.ExamType, exam.CreationTime)
                      "
                      (contextmenu)="askDelete(exam)"
                    >
                      {{ exam.ChangedExamTitle || ('GUI.Patient History.ExamTypes.Letter' | translate) }}
                      <ng-container *ngIf="exam.Locked">
                        <img
                          src="../../assets/icons/login/password.svg"
                          style="height: 1em"
                        />
                        {{ exam.LockedBy }}
                        </ng-container>
                    </span>
                    <span style="margin-right: 2em">
                      <!-- by -->
                      {{'GUI.Patient History.by' | translate}}
                      {{ exam.Opener }}
                    </span>
                    <span class="linked-radios-date">
                      {{ exam.CreationTime | date : "dd/MM/yyyy" }} 
                      <!-- at -->
                      {{'GUI.Patient History.at'  | translate}}
                      {{ exam.CreationTime | date : "HH:mm" }}
                      </span>
                  </ng-container>
                </li>
              </ul>
            </div>
            <!-- ========================== Patient Clinial Documents (Start) ========================== -->
            <ng-container *ngIf="login.premissions && login.premissions?.uploadDocuments">
              <div class="linked-radios-list linked-radios-set" *ngIf="AttachedDocuments && AttachedDocuments?.length > 0">
                <strong class="linked-radios-row" style="margin-bottom: 1em;">
                  {{'GUI.documents.labels.Attached Clinical Documents' | translate}}
                </strong>
            
                <ul>
                  <li class="l-flex l-flex-c" *ngFor="let document of AttachedDocuments | sortCreationTime: 'CreationTime'">
            
                    <span class="l-fg1 examlink" (click)="openDocument(document.AutoIndex)"
                      (contextmenu)="askDelete(document,false,false,false,false,true)">
                      {{ document.title }}
                    </span>
            
                    <span style="margin-right: 2em"> {{ document.comments }}</span>
                    <span class="linked-radios-date">{{ document.CreationTime | date : "dd/MM/yyyy" }} at
                      {{ document.CreationTime | date : "HH:mm" }}
                    </span>
            
                  </li>
                </ul>
              </div>
              <!-- ========================== Patient Clinial Documents (End) ========================== -->
            
              <!-- ========================== Patient Administrative Documents (Start) ========================== -->
              <div class="linked-radios-list linked-radios-set"
                *ngIf="AttachedAdminDocuments && AttachedAdminDocuments?.length > 0">
                <strong class="linked-radios-row" style="margin-bottom: 1em;">
                  {{'GUI.documents.labels.Attached Administrative Documents' | translate}}
                </strong>
            
                <ul>
                  <li class="l-flex l-flex-c" *ngFor="let document of AttachedAdminDocuments | sortCreationTime: 'CreationTime'">
            
                    <span class="l-fg1 examlink" (click)="openDocument(document.AutoIndex)"
                      (contextmenu)="askDelete(document,false,false,false,false,true)">
                      {{ document.title }}
                    </span>
            
                    <span style="margin-right: 2em"> {{ document.comments }}</span>
                    <span class="linked-radios-date">{{ document.CreationTime | date : "dd/MM/yyyy" }} at
                      {{ document.CreationTime | date : "HH:mm" }}
                    </span>
                  </li>
                </ul>
              </div>
            </ng-container>
            <!-- ========================== Patient Administrative Documents (End) ========================== -->
          </div>
        </div>
        <!-- history (end) -->

        <!-- new exam (start) -->
        <div class="w50 patient-ob-box">
          <div class="h100 m-box">
            <h2>
              {{
                pat?.ID !== "default" ? ('GUI.Patient History.Create New' | translate) : ('GUI.Patient History.Exams Defaults Setting' | translate)
              }}
            </h2>

            <div class="h100 l-flex l-column" style="height: fit-content">
              <div class="l-fg1">
                <div class="">
                  <!-- new exam top (start) -->
                  <div class="l-flex-c l-flex-w l-justify h50 new_exam-head">
                    <label
                      class="t-label-top"
                      *ngIf="login.category.name != 'forms'">
                      <input
                        type="radio"
                        name="TEMP_name"
                        [(ngModel)]="etype"
                        class="custom-check"
                        value="OB STD"
                      />
                      <i class="customInput-display"></i>
                      <span>
                        <!-- OB  -->
                        {{'GUI.Patient History.ExamTypes.OB.OB' | translate}}
                      </span>
                    </label>

                    <label
                      class="t-label-top"
                      *ngIf="login.category.name != 'forms'">
                      <input
                        type="radio"
                        name="TEMP_name"
                        [(ngModel)]="etype"
                        class="custom-check"
                        value="GYN"
                      />
                      <i class="customInput-display"></i>
                      <span>
                         <!-- GYN  -->
                        {{'GUI.Patient History.ExamTypes.GYN.GYN' | translate}}
                        </span>
                    </label>
                    <label class="t-label-top" *ngIf="login.category.name !== 'forms' && login.premissions?.Pediatric">
                      <input type="radio" name="TEMP_name" [(ngModel)]="etype" class="custom-check" value="Other Exams" />
                      <i class="customInput-display"></i>
                      <span>
                        <!-- Other Exams -->
                        {{'GUI.Patient History.Other Exams' | translate}}
                      </span>
                    </label>
                    <label class="t-label-top" *ngIf="login.invasiveExams">
                      <input
                        type="radio"
                        name="TEMP_name"
                        [(ngModel)]="etype"
                        class="custom-check"
                        value="Invasive"
                      />
                      <i class="customInput-display"></i>
                      <span> Invasive </span>
                    </label>
                    <label class="t-label-top">
                      <input
                        type="radio"
                        name="TEMP_name"
                        [(ngModel)]="etype"
                        class="custom-check"
                        value="Forms"
                        style="display: none"
                      />
                      <i class="customInput-display"></i>
                      <span>
                        <!-- Letters and Certificates -->
                        {{'GUI.Patient History.ExamTypes.Letters and Certificates' | translate}}
                      </span>
                    </label>
                  </div>
                  <!-- new exam top (end) -->

                  <div class="linked-radios-list linked-radios-set">
                    <ng-container *ngIf="etype == 'OB STD'">
                      <label class="linked-radios-item">
                        <input
                          type="radio"
                          name="TEMP_name1"
                          value="1st"
                          [(ngModel)]="examname"
                        />
                        <span class="linked-radios-row">
                          <span class="l-fg1 linked-radios-title">
                            <!-- 1st -->
                            {{'GUI.Patient History.ExamTypes.OB.1st' | translate}}
                          </span>
                          <span class="linked-radios-duration"
                            ><img
                              *ngIf="defaults && defaults['1st']"
                              src="../../assets/icons/del.svg"
                              matTooltip="Delete"
                              (click)="deleteDefault('1st')"
                            />
                            1-9 {{'GUI.Patient History.Weeks' | translate}}
                            </span>
                          <span class="linked-radios-counter"><i>1</i></span>
                        </span>
                      </label>

                      <label class="linked-radios-item">
                        <input
                          type="radio"
                          name="TEMP_name1"
                          value="NT"
                          [(ngModel)]="examname"
                        />
                        <span class="linked-radios-row">
                          <span class="l-fg1 linked-radios-title">
                            <!-- NT -->
                            {{'GUI.Patient History.ExamTypes.OB.NT' | translate}}

                          </span>
                          <span class="linked-radios-duration"
                            ><img
                              *ngIf="defaults && defaults['NT']"
                              src="../../assets/icons/del.svg"
                              matTooltip="Delete"
                              (click)="deleteDefault('NT')"
                            />
                            11-14 {{'GUI.Patient History.Weeks' | translate}}</span
                          >
                          <span class="linked-radios-counter"><i>1</i></span>
                        </span>
                      </label>

                      <label class="linked-radios-item">
                        <input
                          type="radio"
                          name="TEMP_name1"
                          value="Early"
                          [(ngModel)]="examname"
                        />
                        <span class="linked-radios-row">
                          <span class="l-fg1 linked-radios-title"
                            >
                            <!-- Early Anatomy -->
                            {{'GUI.Patient History.ExamTypes.OB.Early' | translate}}
                            </span>
                          <span class="linked-radios-duration"
                            ><img
                              *ngIf="defaults && defaults['Early']"
                              src="../../assets/icons/del.svg"
                              matTooltip="Delete"
                              (click)="deleteDefault('Early')"
                            />
                            14-16 {{'GUI.Patient History.Weeks' | translate}}</span
                          >
                          <span class="linked-radios-counter"><i>1</i></span>
                        </span>
                      </label>

                      <label class="linked-radios-item">
                        <input
                          type="radio"
                          name="TEMP_name1"
                          value="2nd/3rd"
                          [(ngModel)]="examname"
                        />
                        <span class="linked-radios-row">
                          <span class="l-fg1 linked-radios-title"
                            >
                            <!-- 2nd/3rd Trimester -->
                            {{'GUI.Patient History.ExamTypes.OB.2nd/3rd' | translate}}
                            </span>
                          <span class="linked-radios-duration"
                            ><img
                              *ngIf="defaults && defaults['2nd/3rd']"
                              src="../../assets/icons/del.svg"
                              matTooltip="Delete"
                              (click)="deleteDefault('2nd/3rd')"
                            />
                            12-40 {{'GUI.Patient History.Weeks' | translate}}</span
                          >
                          <span class="linked-radios-counter"><i>1</i></span>
                        </span>
                      </label>

                      <label class="linked-radios-item">
                        <input
                          type="radio"
                          name="TEMP_name1"
                          value="Weight Assessment"
                          [(ngModel)]="examname"
                        />
                        <span class="linked-radios-row">
                          <span class="l-fg1 linked-radios-title">
                            <!-- Weight Assessment -->
                            {{'GUI.Patient History.ExamTypes.OB.Weight Assessment' | translate}}
                            </span>
                          <span class="linked-radios-duration"
                            ><img
                              *ngIf="defaults && defaults['Weight Assessment']"
                              src="../../assets/icons/del.svg"
                              matTooltip="Delete"
                              (click)="deleteDefault('Weight Assessment')"
                            />
                            12-40 {{'GUI.Patient History.Weeks' | translate}}</span
                          >
                          <span class="linked-radios-counter"><i>1</i></span>
                        </span>
                      </label>
                      <label class="linked-radios-item">
                        <input
                          type="radio"
                          name="TEMP_name1"
                          value="Late"
                          [(ngModel)]="examname"
                        />
                        <span class="linked-radios-row">
                          <span class="l-fg1 linked-radios-title"
                            >
                            <!-- Late Anatomy -->
                            {{'GUI.Patient History.ExamTypes.OB.Late' | translate}}
                            </span>
                          <span class="linked-radios-duration"
                            ><img
                              *ngIf="defaults && defaults['Late']"
                              src="../../assets/icons/del.svg"
                              matTooltip="Delete"
                              (click)="deleteDefault('Late')"
                            />
                           21-24 {{'GUI.Patient History.Weeks' | translate}}</span
                          >
                          <span class="linked-radios-counter"><i>1</i></span>
                        </span>
                      </label>
                      <label class="linked-radios-item" *ngIf="login.premissions.thirdScan">
                        <input
                          type="radio"
                          name="TEMP_name1"
                          value="Third Scan"
                          [(ngModel)]="examname"
                        />
                        <span class="linked-radios-row">
                          <span class="l-fg1 linked-radios-title">
                            <!-- Third Scan -->
                            {{'GUI.Patient History.ExamTypes.OB.Third Scan' | translate}}
                            </span>
                          <span class="linked-radios-duration"
                            ><img
                              *ngIf="defaults && defaults['Third Scan']"
                              src="../../assets/icons/del.svg"
                              matTooltip="Delete"
                              (click)="deleteDefault('Third Scan')"
                            />
                           28-33 {{'GUI.Patient History.Weeks' | translate}}</span
                          >
                          <span class="linked-radios-counter"><i>1</i></span>
                        </span>
                      </label>

                      <label class="linked-radios-item">
                        <input
                          type="radio"
                          name="TEMP_name1"
                          value="Specialized"
                          [(ngModel)]="examname"
                        />
                        <span class="linked-radios-row">
                          <span class="l-fg1 linked-radios-title">
                            <!-- Specialized -->
                            {{'GUI.Patient History.ExamTypes.OB.Specialized' | translate}}
                            </span>
                          <span class="linked-radios-duration"
                            ><img
                              *ngIf="defaults && defaults['Specialized']"
                              src="../../assets/icons/del.svg"
                              matTooltip="Delete"
                              (click)="deleteDefault('Specialized')"
                            />
                            12-40 {{'GUI.Patient History.Weeks' | translate}}</span
                          >
                          <span class="linked-radios-counter"><i>1</i></span>
                        </span>
                      </label>

                      <label class="linked-radios-item">
                        <input
                          type="radio"
                          name="TEMP_name1"
                          value="Fetal Echo"
                          [(ngModel)]="examname"
                        />
                        <span class="linked-radios-row">
                          <span class="l-fg1 linked-radios-title">
                            <!-- Fetal Echo -->
                            {{'GUI.Patient History.ExamTypes.OB.Fetal Echo' | translate}}
                            </span>
                          <span class="linked-radios-duration"
                            ><img
                              *ngIf="defaults && defaults['Fetal Echo']"
                              src="../../assets/icons/del.svg"
                              matTooltip="Delete"
                              (click)="deleteDefault('Fetal Echo')"
                            />
                            12-40 {{'GUI.Patient History.Weeks' | translate}}</span
                          >
                          <span class="linked-radios-counter"><i>1</i></span>
                        </span>
                      </label>
                    </ng-container>

                    <ng-container *ngIf="etype == 'Forms'">
                      <label class="linked-radios-item">
                        <input
                          type="radio"
                          name="TEMP_name1"
                          value="Consultation Letter"
                          [(ngModel)]="examname"
                        />
                        <span class="linked-radios-row">
                          <span class="l-fg1 linked-radios-title">
                            <!-- Letter -->
                            {{'GUI.Patient History.ExamTypes.Letter' | translate}}
                          </span>
                          <span class="linked-radios-duration"></span>
                          <span class="linked-radios-counter"><i>1</i></span>
                        </span>
                      </label>
                      <label
                        class="linked-radios-item"
                        *ngIf="login.premissions?.Consent"
                      >
                        <input
                          type="radio"
                          name="TEMP_name1"
                          value="Inform Consent"
                          [(ngModel)]="examname"
                        />
                        <span class="linked-radios-row">
                          <span class="l-fg1 linked-radios-title">
                            {{'GUI.Patient History.ExamTypes.Informed Consent' | translate}}                            
                            <span *ngIf="login.premissions?.concentLimit" style="align-self:center;margin-inline-start: 2.31em;" [ngClass]="login.concentCount >=login.premissions?.concentLimit ? 'error': '' ">
                              {{login.concentCount}}/{{login.premissions?.concentLimit}}
                            </span>
                            </span
                          >
                          <span class="linked-radios-duration"></span>
                          <span class="linked-radios-counter"><i>1</i></span>
                        </span>
                      </label>
                    </ng-container>
                    <ng-container *ngIf="etype == 'GYN'">
                      <label class="linked-radios-item">
                        <input
                          type="radio"
                          name="TEMP_name1"
                          value="Gynecology"
                          [(ngModel)]="examname"
                          checked
                        />
                        <span class="linked-radios-row">
                          <span class="l-fg1 linked-radios-title">
                            <!-- Gynecology -->
                            {{'GUI.Patient History.ExamTypes.GYN.Gynecology' | translate}}
                            </span>
                          <span class="linked-radios-duration"
                            ><img
                              *ngIf="defaults && defaults['Gynecology']"
                              src="../../assets/icons/del.svg"
                              matTooltip="Delete"
                              (click)="deleteDefault('Gynecology')"
                            />
                          </span>
                          <span class="linked-radios-counter"><i>1</i></span>
                        </span>
                      </label>
                    </ng-container>

                    <ng-container *ngIf="etype === 'Other Exams' && login.premissions?.Pediatric">
                      <label class="linked-radios-item">
                        <input type="radio" name="TEMP_name1" value="Pediatric Echo" [(ngModel)]="examname" checked />
                        <span class="linked-radios-row">
                          <span class="l-fg1 linked-radios-title">
                            Pediatric Echo
                          </span>
                          <span class="linked-radios-duration">
                            <img *ngIf="defaults && defaults['Pediatric Echo']" src="../../assets/icons/del.svg" matTooltip="Delete"
                              (click)="deleteDefault('Pediatric Echo')" />
                          </span>
                          <span class="linked-radios-counter"><i>1</i></span>
                        </span>
                      </label>
                    </ng-container>

                    <ng-container *ngIf="etype == 'Invasive'">
                      <label class="linked-radios-item">
                        <input
                          type="radio"
                          name="TEMP_name1"
                          value="Amniocentesis"
                          [(ngModel)]="examname"
                        />
                        <span class="linked-radios-row">
                          <span class="l-fg1 linked-radios-title"
                            >Amniocentesis</span
                          >
                          <span class="linked-radios-counter"><i>1</i></span>
                        </span>
                      </label>
                      <label class="linked-radios-item">
                        <input
                          type="radio"
                          name="TEMP_name1"
                          value="CVS"
                          [(ngModel)]="examname"
                        />
                        <span class="linked-radios-row">
                          <span class="l-fg1 linked-radios-title">CVS</span>
                          <span class="linked-radios-counter"><i>1</i></span>
                        </span>
                      </label>
                    </ng-container>
                  </div>
                </div>
              </div>
              <ng-container *ngIf=" login.userType === 'secretary' || 'doctor' && login.premissions?.Consent && etype === 'Forms'">
                <label class="t-label-top" style="margin-top: 1em;" *ngIf="examname == 'Inform Consent'">
                  <span>
                    Exam Type
                  </span>
                  <select class="t-select t-field"
                    (change)="examServe.consentExamType = $event.target.value;">
                    <option></option>
                    <option value="1st">1st</option>
                    <option value="NT">NT</option>
                    <option value="Early">Early</option>
                    <option value="2nd/3rd">2nd/3rd</option>
                    <option value="Weight Assessment">Weight Assessment</option>
                    <option value="Late">Late</option>
                    <option *ngIf="login.premissions.thirdScan"value="Third Scan">Third Scan</option>
                    <option value="Specialized">Specialized</option>
                    <option value="Fetal Echo">Fetal Echo</option>
                    <!-- <option value="Gynecology">Gynecology</option> -->
                  </select>
                </label>
                <ng-container *ngIf="clinicPractitioners?.length > 1">
                  
                  <label class="t-label-top" style="margin-top: 1em;" *ngIf="examname == 'Inform Consent' && login.userType !== 'doctor'">
                    <span>
                      Clinic's US Practitioners:
                    </span>
                    <select class="t-select t-field"
                    (change)="examServe.consentPractitioner = $event.target.value; setConsentDocUID();">
                    <option></option>
                    <ng-container *ngFor="let option of clinicPractitioners">
                      <option [ngValue]="option">{{option.Title}} {{option.Name}}</option>
                    </ng-container>
                  </select>
                </label>
              </ng-container>
            </ng-container>

              <div class="l-flex l-flex-w l-just-center patient-ob-btns-row">
                <a class="patient-ob-btn -exit_patient" routerLink="/patsearch">
                  {{ pat.ID != "default" ? ('GUI.General.Buttons.Exit Patient' | translate) : ('GUI.General.Buttons.Exit Defaults' | translate)}}
                </a>
                <button
                  class="patient-ob-btn -start_exam"
                  [ngClass]="(examname && etype && !examServe.awaitPrintHistory) ||  (pat.ID == 'default' && examname && etype  ) ? '' : 'opaque'"
                  [disabled]=" !login.category.canCreate.exam"
                  (click)="(etype !== 'Forms') && (login.category.canCreate.exam) ? (shouldOpenExam = true) : null; startExam()"
                  *ngIf="
                    canOpen() &&
                    examname != 'Inform Consent'
                  "
                >
                  {{
                    pat.ID != "default"
                      ? etype != "Forms"
                        ? ('GUI.General.Buttons.Start Exam' | translate)
                        : ('GUI.General.Buttons.New Letter' | translate)
                      : ('GUI.General.Buttons.Construct/Edit Defaults' | translate)
                  }}
                </button>
                <button
                  class="patient-ob-btn -start_exam"
                  [ngClass]="examname && etype ? '' : 'opaque'"
                  [disabled]="!login.category.canCreate.form"
                  (click)="startForm()"
                  *ngIf="examname == 'Inform Consent'"
                >
                  {{
                    pat.ID != "default" ? "New Form" : "Construct/Edit Defaults"
                  }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- box margin -->
    </main>

    <!-- ========================== main sidebar (start) ========================== -->

    <aside class="l-sidebar l-flex l-column">
      <img
        class="off-mobile sidebar-logo"
        src="assets/icons/logo.svg"
        alt="Artisan Sonics"
      />

      <!-- user panel (start) -->
      <div class="userPanel" *ngIf="pat.ID != 'default'">
        <i class="userPanel-icon icon-female">
          <button
            class="btn-user_data"
            aria-label="user data"
            (click)="showPat = true"
          ></button>
        </i>

        <div class="userPanel-userName">
          {{ pat.First + " " + pat.Last }}
        </div>

        <dl class="l-table userPanel-userInfo">
          <div class="l-row">
            <dt class="l-td-m">
              <!-- ID Number -->
              {{'GUI.General.ID Number' | translate | titlecase}}
            </dt>
            <dd class="l-td-m">{{ pat.ID }}</dd>
          </div>
          <div class="l-row">
            <dt class="l-td-m">
              <!-- Age -->
              {{'GUI.General.Age' | translate | titlecase}}
            </dt>
            <dd class="l-td-m">{{ patService.getAgeMo() }}</dd>
          </div>
          <div class="l-row">
            <dt class="l-td-m">GA</dt>
            <dd class="l-td-m">{{ pat.getGA() }}</dd>
          </div>
          <div
            class="l-row"
            style="margin-top: 1em"
            *ngIf="login.getArchiveServer()"
          >
            <dt class="l-td-m">History</dt>
            <dd class="l-td-m">
              <a target="_blank" [href]="login.getArchiveServer() + pat.ID"
                ><img
                  src="../../assets/icons/oldarchive.svg"
                  style="height: 2.2em; margin-top: 1em"
              /></a>
            </dd>
          </div>
        </dl>
      </div>
      <!-- user panel (end) -->

      <!-- main nav (start) -->
      <nav class="mainNav l-fg1 scroller"></nav>
      <!-- main nav (end) -->

      <app-docpanel></app-docpanel>
    </aside>

    <!-- ========================== main sidebar (end) ========================== -->

    <app-bottombar></app-bottombar>
  </div>
  <!-- end wrapper -->
  <div
    class="t-popup popup-patientDetails"
    *ngIf="toDelete"
    style="display: block; max-width: 40vw"
  >
    <div class="t-popup-frame">
      <div class="t-popup-body">
        <button
          class="t-btn-popup-close"
          aria-label="close popup"
          (click)="toDelete = null; readydelete = false"
        ></button>
        <h2 *ngIf="documentDelete" style="color: #D7A95B">
          {{"Delete " +(toDelete.title)+ " document from " +
        (toDelete.CreationTime | date : "d/M/yy, HH:mm a")
        }}?
        </h2>
        <h2 *ngIf="plannerToDelete" style="color: #D7A95B">
          {{"Delete Pregnancy Planner from " +
          (toDelete.CreationTime | date : "d/M/yy, HH:mm a")
          }}?
        </h2>
        <h2 *ngIf="!plannerToDelete && !documentDelete" style="color: #D7A95B">
          <!-- Delete -->
        {{'GUI.Patient History.Delete' | translate}} {{ toDelete.ExamType }}
          {{
            defaults
              ? "defaults"
              : "exam from today at " +
                (toDelete.CreationTime | date : "HH:mm")
          }}?
        </h2>
        <!-- box (end) -->
        <div *ngIf="!readydelete">
          <button (click)="readydelete = true">
            <!-- Yes -->
            {{'general.PositiveNegative.yes' | translate}}
          </button>
          <button
            (click)="toDelete = null; readydelete = false"
            style="margin-left: 2em"
          >
            <!-- No -->
            {{'general.PositiveNegative.No' | translate}}
          </button>
        </div>
        <div *ngIf="readydelete && plannerToDelete">
          <strong>
            WARNING Pregnancy Planner Data Will Be Lost!
            <br />
          </strong>
          <button (click)="cf.deleteCalendar(); toDelete = null; readydelete = false;">
            OK
          </button>
          <button (click)="toDelete = null; readydelete = false" style="margin-left: 2em">
            Cancel
          </button>
        </div>
        <div *ngIf="readydelete && documentDelete">
          <strong>
            WARNING document  Will Be Lost!
            <br />
          </strong>
          <button (click)="deleteDocument(); toDelete = null; readydelete = false;documentDelete=false">
            OK
          </button>
          <button (click)="toDelete = null; readydelete = false;documentDelete=false" style="margin-left: 2em">
            Cancel
          </button>
        </div>
        <div *ngIf="readydelete && !plannerToDelete && !documentDelete">
          WARNING exam data and media will we lost!<br />
          <button (click)="deleteExam();  readydelete = false;">OK</button>
          <button
            (click)="toDelete = null; readydelete = false"
            style="margin-left: 2em"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>

  <app-patdetails
    *ngIf="showPat"
    [pat]="pat"
    (closed)="showPat = false"
    (show_surrogate)="show_surrogate = true"
  ></app-patdetails>
  <app-surrogate
    *ngIf="show_surrogate"
    (closed)="show_surrogate = false"
    [genMom]="pat"
  ></app-surrogate>
  <app-patforms *ngIf="typeForm" (closed)="typeForm = false"></app-patforms>
  <app-informconcent
    *ngIf="inform "
    (closed)="closCalForm($event)"
    [form]="concentForm"
    [ExamType]="etype === 'Forms' ? examServe.consentExamType : examname"
    ></app-informconcent>
<app-pat-documents *ngIf="openDocumentsAdd"   (closed)="openDocumentsAdd = false;resetPregs()"></app-pat-documents>
  <app-commerror></app-commerror>
  <app-calexams
    *ngIf="calendarForm"
    (closed)="closCalForm($event)"
    [selectedForm]="selectedForm"
  ></app-calexams>
  <app-loginbox
    *ngIf="showLogin"
    (closed)="showLogin = false"
    (viewonly)="viewonly()"
    [isnew]="newExam"
  ></app-loginbox>
  <app-closepregnancy
    *ngIf="closePreg"
    [pregtoload]="closePreg"
    [exams]="pregExams"
    (examClosed)="pregClosed()"
    (closed)="closePreg = null"
  ></app-closepregnancy>
</div>
