import { PatientsService } from "./../patients.service";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Patient } from "../patients/patient";
import { LoginService } from "../login.service";
import { HttpClient } from "@angular/common/http";
@Component({
  selector: "app-pat-documents",
  templateUrl: "./pat-documents.component.html",
  styleUrls: ["./pat-documents.component.scss"],
})
export class PatDocumentsComponent implements OnInit {
  @Output() closed = new EventEmitter<string>();
  @Input("showDocuments") showDocuments: boolean;
  selectedFile: File | null = null;
  selectedAdminFile: File | null = null;
  documentTitle: string = "";
  adminDocumentTitle;
  documentComments: string = "";
  adminDocumentComments: string = "";
  errorUploadDocs = "";
  errorUploadAdminDocs = "";
  documents;
  adminDocuments;

  showDeleteAlert;
  toDeleteDocument;
  constructor(private http: HttpClient, public PatientsService: PatientsService) {}

  ngOnInit(): void {
    if (this.showDocuments) {
      this.getDocuments();
    }
  }

  close() {
    this.closed.emit();
  }

  onAdminFileSelected(event: any) {
    this.errorUploadAdminDocs = "";
    const file = event.target.files[0];
    if (file) {
      // Check file type
      const allowedTypes = ["application/pdf", "image/jpeg", "image/png", "image/gif", "image/jpg"];
      if (!allowedTypes.includes(file.type)) {
        this.errorUploadAdminDocs = "Unauthorized file type";
        this.selectedAdminFile = null;
        return;
      }

      // Check file size (5 MB)
      const maxSize = 5 * 1024 * 1024; // 5 MB in bytes
      if (file.size > maxSize) {
        this.errorUploadAdminDocs = "File size exceeds 5 MB";
        this.selectedAdminFile = null;
        return;
      }
      this.selectedAdminFile = file;
    }
  }

  onFileSelected(event: any) {
    this.errorUploadDocs = "";
    const file = event.target.files[0];
    if (file) {
      // Check file type
      const allowedTypes = ["application/pdf", "image/jpeg", "image/png", "image/gif", "image/jpg"];
      if (!allowedTypes.includes(file.type)) {
        this.errorUploadDocs = "Unauthorized file type";
        this.selectedFile = null;
        return;
      }

      // Check file size (5 MB)
      const maxSize = 5 * 1024 * 1024; // 5 MB in bytes
      if (file.size > maxSize) {
        this.errorUploadDocs = "File size exceeds 5 MB";
        this.selectedFile = null;
        return;
      }
      this.selectedFile = file;
    }
  }

  saveAdminDocument() {
    this.errorUploadAdminDocs = "";
    if (this.selectedAdminFile) {
      const formData = new FormData();
      formData.append("file", this.selectedAdminFile);
      formData.append("title", this.adminDocumentTitle);
      formData.append("comments", this.adminDocumentComments);
      formData.append("patId", this.PatientsService.currentPatient.ID);
      formData.append("isAdministration", "true");

      this.http.post("/api/patient/uploadDocs", formData).subscribe(
        (response) => {
          console.log("Upload successful", response);
          if (this.showDocuments) {
            this.getDocuments();
            this.selectedAdminFile = null;
            this.adminDocumentComments = "";
            this.adminDocumentTitle = "";
            return;
          }
          this.close();
        },
        (error) => {
          this.errorUploadAdminDocs = error["error"]["message"];
          console.error("Upload failed", error);
        }
      );
    }
  }

  saveDocument() {
    this.errorUploadDocs = "";
    if (this.selectedFile) {
      const formData = new FormData();
      formData.append("file", this.selectedFile);
      formData.append("title", this.documentTitle);
      formData.append("comments", this.documentComments);
      formData.append("patId", this.PatientsService.currentPatient.ID);

      this.http.post("/api/patient/uploadDocs", formData).subscribe(
        (response) => {
          console.log("Upload successful", response);
          if (this.showDocuments) {
            this.getDocuments();
            this.selectedFile = null;
            this.documentComments = "";
            this.documentTitle = "";
            return;
          }
          this.close();
        },
        (error) => {
          this.errorUploadDocs = error["error"]["message"];
          console.error("Upload failed", error);
        }
      );
    }
  }

  getDocuments() {
    this.http.get(`api/patient/getDocuments/${this.PatientsService.getPat().ID}`).subscribe((data) => {
      this.documents = data["clinicalDocs"].map((document) => {
        const fileType = document.type.split("/")[1];
        return {
          ...document,
          fileType: fileType,
        };
      });
      this.adminDocuments = data["administrationDocs"].map((adminDocument) => {
        const fileType = adminDocument.type.split("/")[1];
        return {
          ...adminDocument,
          fileType: fileType,
        };
      });
    });
  }

  openDocument(docId) {
    window.open(`api/patient/getDocument/${docId}`);
  }

  deleteDocument() {
    if (!this.toDeleteDocument || !this.toDeleteDocument.AutoIndex) return;
    this.http.delete(`api/patient/document/${this.toDeleteDocument.AutoIndex}`).subscribe(() => {
      this.getDocuments();
      this.toDeleteDocument = null;
      this.showDeleteAlert = false;
    });
  }
}
