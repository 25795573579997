import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {LoginService} from '../app/login.service';
import {TimerService} from './timer.service';
import { Subject } from 'rxjs';

declare var Peer: any;

@Injectable({
  providedIn: 'root'
})
export class VideoCaptureService {

  Stream: MediaStream;
  AVStream: MediaStream;
  mediaRecorder: MediaRecorder;
  public recording= false;
  lastSoundtest: boolean;
  lastVdevice:string;
  lastAdevice:string;
  recordedBlobs;
  justStopped = false;
  anchor;
  ExamID: number;
  recordMaxed;
  canceltimer;
  public timer: string;
  MyPeer;
  bitRate:number = 400000;
  imageCapture:ImageCapture;
  pictureSocket: WebSocket;
  _photoS: Subject<ImageBitmap>;
  audio = new Audio('../assets/sounds/cammera.mp3');


  constructor(private http: HttpClient, private ls: LoginService, private ts: TimerService) { 
    this.Answer = this.Answer.bind(this);
    this.SetRTCID =  this.SetRTCID.bind(this);
    this.MakeCall = this.MakeCall.bind(this);
    this.Connected = this.Connected.bind(this);
    this.RecieveRTCMessage = this.RecieveRTCMessage.bind(this);
    this.Reconnect = this.Reconnect.bind(this);
    this.bitRate = this.ls.bitRate;
    this._photoS = new Subject();
  }

  async getDevices(){
    try{
      let audioInputs:{ value: any, text: any }[] = [];
      let videoInputs:{ value: any, text: any }[] = [];
      let deviceInfos = await navigator.mediaDevices.enumerateDevices();
      for (let i = 0; i !== deviceInfos.length; ++i) {
        let deviceInfo = deviceInfos[i];
        let option = {value: deviceInfo.deviceId, text: deviceInfo.label};
        if (deviceInfo.kind === 'audioinput') {
          option.text = deviceInfo.label || `microphone ${audioInputs.length + 1}`;
          audioInputs.push(option);
        }  else if (deviceInfo.kind === 'videoinput') {
          option.text = deviceInfo.label || `camera ${videoInputs.length + 1}`;
          videoInputs.push(option);
        } else {
          console.log('Some other kind of source/device: ', deviceInfo);
        }
      }
      return {video: videoInputs,audio:audioInputs}
    }
    catch(e){
      console.log(JSON.stringify(e));
    }
  }

  async startVideo(videosrc:string,audiosrc:string,soundtest = false,maxwidth: number = 720, frameRate:number = 25):Promise<MediaStream> {
    if (this.Stream) {
      this.Stream.getTracks().forEach(track => {
        track.stop();
      });
    }
    if (this.AVStream) {
        this.AVStream.getTracks().forEach(track => {
          track.stop();
        });
      }
    
    //save to local storage
    window.localStorage.setItem("audio",audiosrc);
    window.localStorage.setItem("video",videosrc);
    
    var constraints = {
      audio: {deviceId: audiosrc ? {exact: audiosrc} : undefined},
      video: {deviceId: videosrc ? {exact: videosrc} : undefined,
        frameRate: {
          max: frameRate
        },
        width: {
            max: maxwidth
        }
      }
    
    };
    if(this.ls.premissions && this.ls.premissions.Video)
      delete constraints.video['width']
    try{this.AVStream = await navigator.mediaDevices.getUserMedia(constraints);}
    catch{
      this.AVStream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: true
      })
    }
    //constraints.audio = false;
    
    if(soundtest){
      constraints["echoCancellation"] = true;//for audio testing
    }
    else
      constraints.audio = undefined;
    try{this.Stream = await navigator.mediaDevices.getUserMedia(constraints);}
    catch{
      this.Stream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: true
      });
    }
    this.lastAdevice = audiosrc;
    this.lastVdevice = videosrc;
    this.lastSoundtest = soundtest;
    if(this.ls.hasImaging)
      this.imageCapture = new ImageCapture(this.Stream.getVideoTracks()[0]);
    return this.Stream;
  }

  PeerConenct(){
    if(this.ls.loggedIn){
      this.MyPeer = new Peer(this.ls.PeerServ);
      this.PeerSetup();
    }
    else{//for remote recording
      this.http.get('/api/getenv').subscribe((data)=>{
        let server = data["peerServer"];
        this.MyPeer = new Peer(data['peerID'], {host: server.host, port: server.port, path: server.path});
        this.PeerSetup();
      });
    }
    
  }

  PeerSetup(){
    this.MyPeer.on('open',this.SetRTCID)
    this.MyPeer.on('connection',this.Connected);
    this.MyPeer.on('disconnected', this.Reconnect);
    try{
     // this.MyPeer.on('call', this.Answer);
      this.MyPeer.on('error',function(err){
        console.log(err);
      });
    }
    catch(e){
      console.log(e);
    }
  }

  Connected(conn){
    conn.on('data',this.RecieveRTCMessage)
    conn.on('close', this.Clean);
    conn.on('error', (err)=>{
      console.log(err);
    })
  }

  Reconnect(){
    this.MyPeer.reconnect();
  }

  RecieveRTCMessage(data){
    let msg = JSON.parse(data);
    switch(msg["cmd"]){
      case "callme":
        this.MakeCall(msg["id"]);
        break;
      case "startrecord":
        if(! this.recording){
          this.Record(msg["ExamID"])
        }
        else{
          this.recording = false;
          this.stopRecording();
          setTimeout(()=> {this.Record(msg["ExamID"])},1000);
        }
        break;
      case "stoprecord":
        if(this.recording){
          this.recording = false;
          this.stopRecording();
        }
        break;
    }
  }

  SetRTCID(id){
    this.http.get('/api/setarchiverid?ID='+id).subscribe(()=>{},
    (err)=> {
      console.log(err);
    })
  }

  Answer(call){
    try{
     call.answer(this.Stream);
    }
    catch(e){
      console.log(e);
    }
  }

  MakeCall(data){
    this.MyPeer.call(data,this.Stream);
  }

  async Record(examid: number = null){

    this.recording = ! this.recording;
    
    if(this.recording){
      this.ExamID = examid;
      if(this.lastSoundtest){
        await this.startVideo(this.lastVdevice, this.lastAdevice, false);
      }
       
      var options;
      if(MediaRecorder.isTypeSupported( this.ls.VideoCodec)){
        options = {mimeType: this.ls.VideoCodec,videoBitsPerSecond: (this.ls.premissions && this.ls.premissions['Video'] ? this.ls.highBit :this.ls.bitRate)};
      }
      else{
        if (MediaRecorder.isTypeSupported('video/webm;codecs=vp9')) {
          options = {mimeType: 'video/webm; codecs=vp9',videoBitsPerSecond: this.ls.bitRate};
        } else if (MediaRecorder.isTypeSupported('video/webm;codecs=vp8')) {
          options = {mimeType: 'video/webm; codecs=vp8'};
        } else {
          // ...
        }
      }
      try {
        
        this.mediaRecorder = new MediaRecorder(this.AVStream, options);
      } catch (e) {
        console.error('Exception while creating MediaRecorder:', e);
        return;
      }
      this.mediaRecorder.onstop = (event) => {
        console.log('Recorder stopped: ', event);
      };
      this.recordedBlobs = [];
      this.mediaRecorder.ondataavailable = (event)=>{
        console.log('handleDataAvailable', event);
        if (event.data && event.data.size > 0) {
          this.recordedBlobs.push(event.data);
          if(this.justStopped){
            
            
            if(this.ls.fullDelivery == 'download' && ! this.ls.localServer){
              this.downLoad();
            }
            else{//send to server
              this.sendToServer();
            }
            this.justStopped = false;
            this.recordedBlobs = [];
          }
        }
      };
      this.mediaRecorder.start();
      console.log('MediaRecorder started', this.mediaRecorder);
      this.startTimer();
      this.recordMaxed = setTimeout(()=> {this.stopRecording()}, 90 * 60  * 1000);
    }
    else{
      this.stopRecording();
    }
    
  }

  sendToServer(){
    const blob = new Blob(this.recordedBlobs, {type: 'video/webm'});
    var formData: any = new FormData();
    formData.append("connector", "artcon");
    formData.append("type","full");
    formData.append("examID",this.ExamID);
    formData.append("needsCopy","y");
    formData.append("file", blob,"full.webm");
    this.http.post(this.ls.localServer? 'http://localhost:8088/savefull' :'/api/savefull',formData).subscribe((data)=>{
      console.log(data);
    },(error)=>{
      console.log(error);
      this.downLoad(blob);
    }
    );
  }

  downLoad(blob = null,image = false){
    if(! blob)
      blob = new Blob(this.recordedBlobs, {type: 'video/webm'});
    let DownloadUrl = URL.createObjectURL(blob);
    //hacked way
    this.anchor = document.createElement("a");
    var ds = (new Date()).toISOString().replace(/[^0-9]/g, "");
    this.anchor.download = this.ExamID + '_'+  ds + (image? 'im.png':'.full.webm');
    this.anchor.href = DownloadUrl;
    this.anchor.click();
    setTimeout(()=>{this.anchor.remove()}, 2000);
  }

  stopRecording(pageunload = false){
    if(! this.recording || pageunload){
      try{
        this.mediaRecorder.stop();
        this.justStopped = true;
        this.ts.stopTimer();
        clearTimeout(this.recordMaxed);
        this.recording = false;
      }
      catch(e){
        console.log(e);
      }
    }
  }

  startTimer(){
    this.ts.startTimer();
  }

  Clean(){
    this.stopRecording(true);
    if (this.Stream) {
      this.Stream.getTracks().forEach(track => {
        track.stop();
      });
    }
    if (this.AVStream) {
        this.AVStream.getTracks().forEach(track => {
          track.stop();
        });
      }
    if(this.anchor){
      try{
        this.anchor.remove();
      }
      catch{}
    }
  }

  setBitRate(kbps: number){
    this.bitRate = kbps * 1000;
  }

  async openWebSocket(){
    this.pictureSocket = new WebSocket('ws://localhost:8088/myWebsocket');
    this.pictureSocket.onmessage = (e)=>{
      this.takePicture();
    }
  }

  async closeWebSocket(){
    if(this.pictureSocket)
      this.pictureSocket.close();
  }

  async takePicture(){
    if(this.imageCapture){
      var img:ImageBitmap;
      try{
        //im = await this.imageCapture.takePhoto({});
        img = await this.imageCapture.grabFrame();
        this._photoS.next(img);
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        canvas.getContext('2d').drawImage(img, 0, 0);
        canvas.classList.remove('hidden');
        canvas.toBlob((im)=>{
          canvas.remove();
        
          var formData: any = new FormData();
          formData.append("connector", "artcon");
          formData.append("type","img");
          formData.append("examID",this.ExamID);
          formData.append("needsCopy","n");
          formData.append("file", im,"im.png");
          this.http.post(this.ls.localServer? 'http://localhost:8088/saveimg' :'/api/saveimg',formData).subscribe((data)=>{
            this.audio.play();
            console.log(data);
          },(error)=>{
            console.log(error);
            this.downLoad(im,true);
          })
        },"image/png");
        
      }
      catch(e){
        console.log(e);
      }
      
    
    }

  }
}
