import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LoginService } from "../login.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { PatientsService } from "../patients.service";

@Component({
  selector: "app-patient-redirect",
  templateUrl: "./patient-redirect.component.html",
  styleUrls: ["./patient-redirect.component.scss"],
})
export class PatientRedirectComponent implements OnInit {
  code;
  showPatientSearch = false;
  private destroy$ = new Subject();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public ls: LoginService,
    public patientsService: PatientsService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if (params["code"]) {
        this.code = params["code"];
        this.ls.login(undefined, undefined, false, false, undefined, this.code);
      } else {
        this.ls.loginMissing();
        this.showPatientSearch = true;
      }
      this.ls.onFailedLogin.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.router.navigate(["/"]);
      });

      this.ls.onLogin.pipe(takeUntil(this.destroy$)).subscribe(() => {
        if (params["pid"]) {
          this.patientsService.getPatient(params["pid"], (data) => {
            if (data) {
              this.router.navigate(["/exams"], { state: { data: this.patientsService.currentPatient } });
            }
          });
        }
        this.showPatientSearch = true;
      });
    });
  }
}
