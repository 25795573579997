<div class="h100 l-flex-w t-boxMargin--spacebyPadding summary" [formGroup]="tdForm" style='margin-top: -2em;'>    
    <div class="w50 summary-anamnesis" style="height: 50%;">

        
        <section class="m-box">

            <h2>
                Left Ventricle
            </h2>
            <div class="pregnancy-menstrual">

                <!-- row (start) -->
                <div class="l-flex-c menstrual-row menstrual-row--LMP">

                    <label class="l-fg1 menstural-label" id="menstural_LMP"> 
                        LVDD
                    </label>

                    <span class="l-flex t-boxMargin menstural-fields-set t-validation-wrap">

                        <input  formControlName="LVDD" type="number" min="42" max="54" class="t-field" aria-labelledby="menstural_LMP">
                        
                        <span class="t-field menstural-field menstural-info">42mm-54mm</span>

                    </span>

                </div>
                <!-- row (end) -->

                <!-- row (start) -->
                <div class="l-flex-c menstrual-row menstrual-row--LMP_by_US">

                    <label class="l-fg1 menstural-label" id="menstural_LMP"> 
                        LVSD
                    </label>

                    <span class="l-flex t-boxMargin menstural-fields-set t-validation-wrap">

                        <input  formControlName="LVSD" type="number" min="25" max="45"  class="t-field" aria-labelledby="menstural_LMP">
                        <span class="t-field menstural-field menstural-info">25mm-40mm</span>

                    </span>

                </div>
                <div class="l-flex-c menstrual-row menstrual-row--LMP_by_US">

                    <label class="l-fg1 menstural-label" id="menstural_LMP"> 
                        IVSP/LVSPW
                    </label>

                    <span class="l-flex t-boxMargin menstural-fields-set t-validation-wrap">

                        <input  formControlName="IVSP" type="number" min="6" max="11" class="t-field" aria-labelledby="menstural_LMP">
                        <span class="t-field menstural-field menstural-info">6mm-11mm</span>

                    </span>

                </div>
                <div class="l-flex-c menstrual-row menstrual-row--LMP_by_US">

                    <label class="l-fg1 menstural-label" id="menstural_LMP"> 
                        Prox. sept.	
                    </label>

                    <span class="l-flex t-boxMargin menstural-fields-set t-validation-wrap">

                        <input  formControlName="ProximalSeptum" type="number"  class="t-field" aria-labelledby="menstural_LMP">

                        <span class="t-field menstural-field menstural-info"></span>
                        
                    </span>

                </div>
                <div class="l-flex-c menstrual-row menstrual-row--LMP_by_US">

                    <label class="l-fg1 menstural-label" id="menstural_LMP"> 
                        IVS Diastole	
                    </label>

                    <span class="l-flex t-boxMargin menstural-fields-set t-validation-wrap">

                        <input  formControlName="IVSDiastole" type="number" min="6" max="11" class="t-field" aria-labelledby="menstural_LMP">
                        
                        <span class="t-field menstural-field menstural-info">6mm-11mm</span>

                    </span>

                </div>
                <div class="l-flex-c menstrual-row menstrual-row--LMP_by_US">

                    <label class="l-fg1 menstural-label" id="menstural_LMP"> 
                        LVPW Diastole	
                    </label>

                    <span class="l-flex t-boxMargin menstural-fields-set t-validation-wrap">

                        <input  formControlName="LVPW" type="number" min="6" max="11" class="t-field" aria-labelledby="menstural_LMP">
                        
                        <span class="t-field menstural-field menstural-info">6mm-11mm</span>

                    </span>

                </div>
                <div class="l-flex-c menstrual-row menstrual-row--LMP_by_US">

                    <label class="l-fg1 menstural-label" id="menstural_LMP"> 
                        Apex	
                    </label>

                    <span class="l-flex t-boxMargin menstural-fields-set t-validation-wrap">

                        <input  formControlName="Apex" type="number"  class="t-field" aria-labelledby="menstural_LMP">

                        <span class="t-field menstural-field menstural-info"></span>
                        
                    </span>

                </div>
                <div class="l-flex-c menstrual-row menstrual-row--LMP_by_US">

                    <label class="l-fg1 menstural-label" id="menstural_LMP"> 
                        LVEF	
                    </label>

                    <span class="l-flex t-boxMargin menstural-fields-set t-validation-wrap">

                        <input  formControlName="LVEF" type="number" min="55" max="65" class="t-field" aria-labelledby="menstural_LMP">
                        
                        <span class="t-field menstural-field menstural-info">55-65%</span>

                    </span>

                </div>
                <div class="l-flex-c menstrual-row menstrual-row--LMP_by_US">

                    <label class="l-fg1 menstural-label" id="menstural_LMP"> 
                        LVFS	
                    </label>

                    <span class="l-flex t-boxMargin menstural-fields-set t-validation-wrap">

                        <input  formControlName="LVFS" type="number" min="29" max="42" class="t-field" aria-labelledby="menstural_LMP">
                        
                        <span class="t-field menstural-field menstural-info">29-42%</span>

                    </span>

                </div>
                <div class="l-flex-c menstrual-row menstrual-row--LMP_by_US">

                    <label class="l-fg1 menstural-label" id="menstural_LMP"> 
                        LVMs	
                    </label>

                    <span class="l-flex t-boxMargin menstural-fields-set t-validation-wrap">

                        <input  formControlName="LVMs" type="number" min="0" class="t-field" aria-labelledby="menstural_LMP">
                        
                        <span class="t-field menstural-field menstural-info">ml</span>

                    </span>

                </div>
                <div class="l-flex-c menstrual-row menstrual-row--LMP_by_US">

                    <label class="l-fg1 menstural-label" id="menstural_LMP"> 
                        LVMd	
                    </label>

                    <span class="l-flex t-boxMargin menstural-fields-set t-validation-wrap">

                        <input  formControlName="LVMd" type="number" min="0" class="t-field" aria-labelledby="menstural_LMP">
                        
                        <span class="t-field menstural-field menstural-info">ml</span>

                    </span>

                </div>
                <div class="l-flex-c menstrual-row menstrual-row--LMP_by_US">

                    <label class="l-fg1 menstural-label" id="menstural_LMP"> 
                        LVM	
                    </label>

                    <span class="l-flex t-boxMargin menstural-fields-set t-validation-wrap">

                        <input  formControlName="LVM" type="number" min="0" class="t-field" aria-labelledby="menstural_LMP">
                        
                        <span class="t-field menstural-field menstural-info">gm</span>

                    </span>

                </div>
                <div class="l-flex-c menstrual-row menstrual-row--LMP_by_US">

                    <label class="l-fg1 menstural-label" id="menstural_LMP"> 
                        LVMI	
                    </label>

                    <span class="l-flex t-boxMargin menstural-fields-set t-validation-wrap">

                        <input  formControlName="LVMI" type="number" min="53" max="79" class="t-field" aria-labelledby="menstural_LMP">
                        
                        <span class="t-field menstural-field menstural-info">53-79gm/m²</span>

                    </span>

                </div>
                <!-- row (end) -->
                <div class="l-flex-c menstrual-row menstrual-row--LMP" *ngIf="tdForm.get('LeftVentrical').value" style="border-bottom: transparent;">
                    <span *ngIf="tdForm.get('LeftVentrical').value" style="border: 1px solid #bdd3d5;white-space: pre-wrap;unicode-bidi: plaintext;width: 100%;padding: 0.3em;">{{tdForm.get('LeftVentrical').value}}</span>
                </div>

            <button class="t-btn-edit" *ngIf="! es.isClosed" (click)="atS.showAuto(tdForm.get('LeftVentrical'),'Left Ventrical',600);" style="top: -2.5em;">Auto Text</button>

            </div>

        </section>

        <section class="m-box">

            <h2>
                Right Ventricle
            </h2>

            <div class="pregnancy-menstrual">
                <!-- row (start) -->
                <div class="l-flex-c menstrual-row menstrual-row--LMP">
                    <label class="l-fg1 menstural-label" id="menstural_LMP"> 
                        RV dis
                    </label>
                    <span class="l-flex t-boxMargin menstural-fields-set t-validation-wrap">
                        <input  formControlName="RVdis" type="number" min="7" max="23" class="t-field" aria-labelledby="menstural_LMP">
                        <span class="t-field menstural-field menstural-info">7-23mm</span>

                    </span>

                </div>
                 <!-- row (start) -->
                 <div class="l-flex-c menstrual-row menstrual-row--LMP">
                    <label class="l-fg1 menstural-label" id="menstural_LMP"> 
                        TAPSE
                    </label>
                    <span class="l-flex t-boxMargin menstural-fields-set t-validation-wrap">
                        <input  formControlName="TAPSE" type="number" min="0" class="t-field" aria-labelledby="menstural_LMP">
                        <span class="t-field menstural-field menstural-info"></span>

                    </span>

                </div>
                
                <!-- row (end) -->
                <div class="l-flex-c menstrual-row menstrual-row--LMP_by_US" *ngIf="tdForm.get('RightVentrical').value">
                    <span *ngIf="tdForm.get('RightVentrical').value" style="border: 1px solid #bdd3d5;white-space: pre-wrap;unicode-bidi: plaintext;width: 100%;padding: 0.3em;">{{tdForm.get('RightVentrical').value}}</span>
                </div>
            </div>
            <button class="t-btn-edit" *ngIf="! es.isClosed" (click)="atS.showAuto(tdForm.get('RightVentrical'),'Right Ventrical',601);">Auto Text</button>
           
               
            

        </section>
    
    </div>
    <div class="w50 summary-anamnesis" style="height: 50%;">
        <section class="m-box">

            <h2>
                Aorta
            </h2>
            
            <div class="pregnancy-menstrual">
                
                <!-- row (start) -->
                <div class="l-flex-c menstrual-row menstrual-row--LMP">

                    <label class="l-fg1 menstural-label" id="menstural_LMP"> 
                        Aortic root
                    </label>

                    <span class="l-flex t-boxMargin menstural-fields-set t-validation-wrap">

                        <input  formControlName="AorticRoot" type="number" min="23" max="30" class="t-field" aria-labelledby="menstural_LMP">
                        
                        <span class="t-field menstural-field menstural-info">23mm-30mm</span>

                    </span>

                </div>
                <div class="l-flex-c menstrual-row menstrual-row--LMP">

                    <label class="l-fg1 menstural-label" id="menstural_LMP"> 
                        Ascending Aorta
                    </label>

                    <span class="l-flex t-boxMargin menstural-fields-set t-validation-wrap">

                        <input  formControlName="AscendingAorta" type="number" min="27" max="34"  class="t-field" aria-labelledby="menstural_LMP">
                        
                        <span class="t-field menstural-field menstural-info">27mm-34mm</span>

                    </span>

                </div>
                <div class="l-flex-c menstrual-row menstrual-row--LMP">

                    <label class="l-fg1 menstural-label" id="menstural_LMP"> 
                        Sinus Aorta
                    </label>

                    <span class="l-flex t-boxMargin menstural-fields-set t-validation-wrap">

                        <input  formControlName="SinusAorta" type="number" min="30" max="37" class="t-field" aria-labelledby="menstural_LMP">
                        
                        <span class="t-field menstural-field menstural-info">30mm-37mm</span>

                    </span>

                </div>
                <div class="l-flex-c menstrual-row menstrual-row--LMP_by_US" *ngIf="tdForm.get('Aorta').value">
                    <span  *ngIf="tdForm.get('Aorta').value" style="border: 1px solid #bdd3d5;white-space: pre-wrap;unicode-bidi: plaintext;width: 100%;padding: 0.3em;">{{tdForm.get('Aorta').value}}</span>
                </div>
            </div>
            <button class="t-btn-edit" *ngIf="! es.isClosed" (click)="atS.showAuto(tdForm.get('Aorta'),'Aorta',602);">Auto Text</button>

        </section>

        <section class="m-box">

            <h2>
                Left Atrium
            </h2>

            <div class="pregnancy-menstrual">

                <!-- row (start) -->
                <div class="l-flex-c menstrual-row menstrual-row--LMP">

                    <label class="l-fg1 menstural-label" id="menstural_LMP"> 
                        LAAP
                    </label>

                    <span class="l-flex t-boxMargin menstural-fields-set t-validation-wrap">

                        <input  formControlName="LAAP" type="number" min="27" max="40" class="t-field" aria-labelledby="menstural_LMP">
                        
                        <span class="t-field menstural-field menstural-info">27mm-40mm</span>

                    </span>

                </div>
                <!-- row (end) -->

                <!-- row (start) -->
                <div class="l-flex-c menstrual-row menstrual-row--LMP_by_US">

                    <label class="l-fg1 menstural-label" id="menstural_LMP"> 
                        LASI	
                    </label>

                    <span class="l-flex t-boxMargin menstural-fields-set t-validation-wrap">

                        <input  formControlName="LASI" type="number" min="33" max="52" class="t-field" aria-labelledby="menstural_LMP">
                        
                        <span class="t-field menstural-field menstural-info">33mm-52mm</span>

                    </span>

                </div>
                <div class="l-flex-c menstrual-row menstrual-row--LMP_by_US">

                    <label class="l-fg1 menstural-label" id="menstural_LMP"> 
                        Area	
                    </label>

                    <span class="l-flex t-boxMargin menstural-fields-set t-validation-wrap">

                        <input  formControlName="lArea" type="number" max="20" class="t-field" aria-labelledby="menstural_LMP">
                        
                        <span class="t-field menstural-field menstural-info"><20cm²</span>

                    </span>

                </div>
                <div class="l-flex-c menstrual-row menstrual-row--LMP_by_US">

                    <label class="l-fg1 menstural-label" id="menstural_LMP"> 
                        Volume	4AC
                    </label>

                    <span class="l-flex t-boxMargin menstural-fields-set t-validation-wrap">

                        <input  formControlName="lAVol" type="number" min="13" max="34" class="t-field" aria-labelledby="menstural_LMP">
                        
                        <span class="t-field menstural-field menstural-info">13cc-34cc</span>

                    </span>

                </div>
                <div class="l-flex-c menstrual-row menstrual-row--LMP_by_US">

                    <label class="l-fg1 menstural-label" id="menstural_LMP"> 
                        Volume	A2C
                    </label>

                    <span class="l-flex t-boxMargin menstural-fields-set t-validation-wrap">

                        <input  formControlName="lAVol2" type="number" class="t-field" aria-labelledby="menstural_LMP">
                        
                        <span class="t-field menstural-field menstural-info">cc</span>

                    </span>

                </div>
                <!-- row (end) -->
                <div class="l-flex-c menstrual-row menstrual-row--LMP_by_US" *ngIf="tdForm.get('LeftAtrium').value">
                    <span  *ngIf="tdForm.get('LeftAtrium').value" style="border: 1px solid #bdd3d5;white-space: pre-wrap;unicode-bidi: plaintext;width: 100%;padding: 0.3em;">{{tdForm.get('LeftAtrium').value}}</span>
                </div>
            </div>
            <button class="t-btn-edit" *ngIf="! es.isClosed" (click)="atS.showAuto(tdForm.get('LeftAtrium'),'Left Atrium',603);">Auto Text</button>

        </section>
        
        <section class="m-box">

            <h2>
                Right Atrium
            </h2>

            <div class="pregnancy-menstrual">
                <!-- row (start) -->
                <div class="l-flex-c menstrual-row menstrual-row--LMP">

                    <label class="l-fg1 menstural-label" id="menstural_LMP"> 
                        RA
                    </label>

                    <span class="l-flex t-boxMargin menstural-fields-set t-validation-wrap">

                        <input  formControlName="RA" type="number" min="33" max="52" class="t-field" aria-labelledby="menstural_LMP">
                        
                        <span class="t-field menstural-field menstural-info">33-52mm</span>

                    </span>

                </div>
                <div class="l-flex-c menstrual-row menstrual-row--LMP">

                    <label class="l-fg1 menstural-label" id="menstural_LMP"> 
                        RA Area
                    </label>

                    <span class="l-flex t-boxMargin menstural-fields-set t-validation-wrap">

                        <input  formControlName="RAAS" type="number" min="0" class="t-field" aria-labelledby="menstural_LMP">
                        
                        <span class="t-field menstural-field menstural-info">mm²</span>

                    </span>

                </div>
                <div class="l-flex-c menstrual-row menstrual-row--LMP">

                    <label class="l-fg1 menstural-label" id="menstural_LMP"> 
                        RA Volume
                    </label>

                    <span class="l-flex t-boxMargin menstural-fields-set t-validation-wrap">

                        <input  formControlName="RAAESV" type="number" min="0" class="t-field" aria-labelledby="menstural_LMP">
                        
                        <span class="t-field menstural-field menstural-info">mm³</span>

                    </span>

                </div>
                <!-- row (end) -->
                <div class="l-flex-c menstrual-row menstrual-row--LMP_by_US" *ngIf="tdForm.get('RightAtrium').value">
                    <span  *ngIf="tdForm.get('RightAtrium').value" style="border: 1px solid #bdd3d5;white-space: pre-wrap;unicode-bidi: plaintext;width: 100%;padding: 0.3em;">{{tdForm.get('RightAtrium').value}}</span>
                </div>
            </div>
            <button class="t-btn-edit" *ngIf="! es.isClosed" (click)="atS.showAuto(tdForm.get('RightAtrium'),'Right Atrium',604);">Auto Text</button>

        </section>
        <!-- box (end) -->
    
    </div>

    <!-- endcell : Reason for Examinanion -->

</div>