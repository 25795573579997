import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder,FormControl, UntypedFormGroup } from '@angular/forms';
import { AutotextService } from 'src/app/autotext.service';
import { ExamsService } from 'src/app/exams.service';

@Component({
  selector: 'app-cardio-flow-meas',
  templateUrl: './cardio-flow-meas.component.html',
  styleUrls: ['./cardio-flow-meas.component.scss']
})
export class CardioFlowMeasComponent implements OnInit {
  FLOWMEAS=['CCA', 'Bulb', 'ICA Prox', 'Mid ICA', 'ECA', 'VA', 'ICA/CCA']
  flowmeasForm: UntypedFormGroup;
  constructor(public es:ExamsService, private fb:UntypedFormBuilder,public atS:AutotextService) { }

  ngOnInit() {
    this.resetForm();
    
    
  }

  resetForm(){
    let forms= {
      Reason:[''],
      Summary:[''],
      CCALeft: [''],
      ICALeft: [''],
      ECALeft: [''],
      VALeft: [''],
      CCARight: [''],
      ICARight: [''],
      ECARight: [''],
      VARight: [''],
    }
    for(let i of this.FLOWMEAS){
      forms[this.getDb(i)+"PSVLEFT"] = [''];
      forms[this.getDb(i)+"PSVRIGHT"] = [''];

      forms[this.getDb(i)+"EDVLEFT"] = [''];
      forms[this.getDb(i)+"EDVRIGHT"] = [''];
    }
    forms["IMTRIGHT"] = [''];
    forms["IMTLEFT"] = [''];


    this.flowmeasForm = this.fb.group(forms);
    this.es.fillForm(this.flowmeasForm, 'flowmeas', false, true)

  }
  getDb(str){
    return str.replace(" ", "");
  }

}
