
import { Injectable } from "@angular/core";
import { ExamsService } from "./exams.service";
import * as dlist from "../assets/tables/diagnosiscodes.json";
import * as shebaList from "../assets/tables/ShebaDiagnoses.json";
import * as cardiolist from "../assets/tables/cardio_diagnoses.json";
import * as fetalEchodlist from "../assets/tables/fetalEchoDiagnosis.json";
import * as fetalEchoIndicationlist from "../assets/tables/fetalechoIndecation.json";
import { LoginService } from "./login.service";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { ClosepregnancyComponent } from "./closepregnancy/closepregnancy.component";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class DiagnosisService {
  diagnosis_list;
  fetalEchoForm!: UntypedFormGroup;
  isFetal;
  isCardio = false;
  inSummary;
  inAnatomy;
  diagnosisText: string[];

  constructor(
    private es: ExamsService,
    private ls: LoginService,
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
  ) {
    if (this.ls.isSheba) {
      this.diagnosis_list = shebaList["default"];
    } else if (this.es.ExamType === "Fetal Echo") {
      this.diagnosis_list = fetalEchodlist["default"];
    } else {
      this.diagnosis_list = dlist["default"];
    }
    this.fetalEchoForm = this.fb.group({
      FetaDiagnosisCodes: new UntypedFormControl(""),
      FetalAnomalies: new UntypedFormControl(""),
    });
  }

  getList(code: number) {
    let list = [];
    for (
      let i = 0;
      this.diagnosis_list && i < this.diagnosis_list.length;
      i++
    ) {
      if (this.diagnosis_list[i][3] == code) list.push(this.diagnosis_list[i]);
    }
    return list;
  }

  getTitle(insummary = false): string {
    const translatedDiagnosis = this.translate.instant("summary.diagnosis.title");

    if (this.ls.summaryOnlyAnamolies || insummary) return translatedDiagnosis;
    return "Anomailes";
  }

  getItems(items: number[]) {
    if (this.ls.isSheba) {
      this.diagnosis_list = shebaList["default"];
    } else if (this.isFetal) {
      if (this.inSummary) {
        this.diagnosis_list = fetalEchodlist["default"];
      } else {
        this.diagnosis_list = fetalEchoIndicationlist["default"];
      }
    } else {
      if (this.ls.isCardio || this.isCardio)
        this.diagnosis_list = cardiolist["default"];
      else this.diagnosis_list = dlist["default"];
    }
    let list = [];
    for (let item of items) {
      list.push(this.diagnosis_list[item - 1]);
    }
    return list;
  }

  getName(id: number): string {
    if (this.ls.isSheba){ 
      return  this.diagnosis_list[id-1][5]}
    if (id < this.diagnosis_list.length){
       return this.diagnosis_list[id - 1][2];
      }
    return "";
  }

  save(diagnoses, table: string) {
    if (this.es.isClosed) return;
    //make list
    let list = "";
    for (let d of diagnoses) {
      list += d[0] + ",";
    }
    // to check
    list = list.substr(0, list.length - 1);
    if (this.isFetal && !this.inSummary) {
      this.es.saveVals("IndicationCodes", list, false, table);
    } else {
      this.es.saveVals("DiagnosisCodes", list, false, table);
    }
    let diagnosisText = "";
    for (let diag of diagnoses) {
      diagnosisText = diagnosisText + diag[1] + ",";
    }
    if (!diagnosisText) {
      diagnosisText = null;
    }
   ;
  }

  BuildList(values: string, inSummary = false,examType=null) {
    if (!values || values === "") {
      return [];
    }

    let list = [];
    let ids = values.split(",").map((id) => +id);

    if (this.ls.isSheba) {
      this.diagnosis_list = shebaList["default"];
    } else if (this.es.ExamType === "Fetal Echo" || examType==="Fetal Echo" || this.es.ExamType === "Pediatric Echo" || examType==="Pediatric Echo") {
      this.diagnosis_list = inSummary
        ? fetalEchodlist["default"]
        : fetalEchoIndicationlist["default"];
    } else {
      this.diagnosis_list =
        this.ls.isCardio || this.isCardio
          ? cardiolist["default"]
          : dlist["default"];
    }

    if(this.ls.isSheba){
      for (let id of ids) {
      let element=this.diagnosis_list[+id - 1];
      
      if(element && element[5]){
      let name=element[5];
      list.push([id,name])
      }
      else if (element) {
        let name = element[2];
        let currentId = id;
        while (element[3] !== 0) {
          let parentElement = this.diagnosis_list[element[3] - 1];
          if (parentElement) {
            name = parentElement[2] + " > " + name;
            currentId = parentElement[0];
            element = parentElement;
          } else {
            break;
          }
        }
       
          list.push([id, name]);
        
        
      }

    }
  }
 else{
    for (let id of ids) {
      let element = this.diagnosis_list[+id - 1];
      if (element) {
        let name = element[2];
        let currentId = id;
        while (element[3] !== 0) {
          let parentElement = this.diagnosis_list[element[3] - 1];
          if (parentElement) {
            name = parentElement[2] + " > " + name;
            currentId = parentElement[0];
            element = parentElement;
          } else {
            break;
          }
        }
       
          list.push([id, name]);
        
        
      }
    }
  }

    return list;
  }

  // build exam diagnosis's for showing in patient history
  BuildListPatientHistory(values: string, inSummary = false,examType=null) {
    if (!values || values === "") {
      return [];
    }

    let list = [];
    let ids = values.split(",").map((id) => +id);

    if (this.ls.isSheba) {
      this.diagnosis_list = shebaList["default"];
    } else if (examType==="Fetal Echo") {
      this.diagnosis_list = inSummary
        ? fetalEchodlist["default"]
        : fetalEchoIndicationlist["default"];
    } else {
      this.diagnosis_list =
        this.ls.isCardio || this.isCardio
          ? cardiolist["default"]
          : dlist["default"];
    }

    if(this.ls.isSheba){
      for (let id of ids) {
      let element=this.diagnosis_list[+id - 1];
      
      if(element && element[5]){
      let name=element[5];
      list.push([id,name])
      }
      else if (element) {
        let name = element[2];
        let currentId = id;
        while (element[3] !== 0) {
          let parentElement = this.diagnosis_list[element[3] - 1];
          if (parentElement) {
            name = parentElement[2] + " > " + name;
            currentId = parentElement[0];
            element = parentElement;
          } else {
            break;
          }
        }
       
          list.push([id, name]);
        
        
      }

    }
  }
 else{
    for (let id of ids) {
      let element = this.diagnosis_list[+id - 1];
      if (element) {
        let name = element[2];
        let currentId = id;
        while (element[3] !== 0) {
          let parentElement = this.diagnosis_list[element[3] - 1];
          if (parentElement) {
            name = parentElement[2] + " > " + name;
            currentId = parentElement[0];
            element = parentElement;
          } else {
            break;
          }
        }
       
          list.push([id, name]);
        
        
      }
    }
  }
    return list;
  }

  getGroup(group: string) {
    let items: number[] = [];
    if (this.ls.isSheba) {
      if ((group = "summary")) {
        if (this.es.ExamType == "Gynecology") {
          items = [1408, 1433, 1445, 1467, 1489,1509];
          return this.getItems(items);
        }
        items = [1019, 725, 886];
        return this.getItems(items);
      }
      items = [];
      return this.getItems(items);
    } else {
      switch (group) {
        case "cns":
          items = [18, 161];
          break;
        case "face":
          items = [113, 222];
          break;
        case "chest":
          items = [170, 286];
          break;
        case "digest":
          items = [1, 141];
          break;
        case "urology":
          items = [137, 191];
          break;
        case "skeleton":
          items = [42, 233, 244];
          break;
        case "fetal":
          items = [1, 25, 31];
          break;
        case "summary":
          if (this.es.ExamType != "Gynecology") {
            items = [315, 321];
            if (this.ls.summaryOnlyAnamolies)
              items = [
                315, 321, 18, 161, 113, 222, 170, 286, 1, 141, 137, 191, 42,
                233, 244,
              ];
            if (this.es.ExamType === "Fetal Echo" || this.es.ExamType=='Pediatric Echo') {
              items = [
                1, 27, 43, 67, 77, 86, 99, 110, 126, 131, 142, 152, 158, 166,
                172, 212, 224, 234, 253, 269,
              ];
            }
          } else items = [331];
          break;

        case "uterus":
          items = [520, 617, 644, 667];
          break;
        case "adnexa":
          items = [468];
          break;
        case "cardioFindings":
          items = [1, 7, 27, 38, 77, 83, 92, 100, 110, 120, 130];
      }
      return this.getItems(items);
    }
  }
}
