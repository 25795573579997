<ng-container [formGroup]="UterusForm">

<section class="dashboard-section p-5">

    <header class="dashboard-section-header">

        <h2>
            <!-- Size & Position -->
        {{'GUI.Gynecology.Size & Position' | translate}}
        </h2>

    </header>			

    <div class="flex flex-wrap items-center" (dblclick)="reset('Position')">

        <div class="w-full lgw-1_6 xlw-1_12 py-4">
            <!-- Position -->
            {{'GUI.Gynecology.Position' | translate}}
        </div>

        <div class="flex flex-wrap w-full lgw-5_6 xlw-11_12">
            
            <label class="t-label-top py-4">
                <input type="radio" name="Position" class="custom-check" formControlName="Position" value="AV"/>
                <i class="customInput-display"></i>
                <span>
                    <!-- AV (Ante Verted) -->
                {{'GUI.Gynecology.AV (Ante Verted)' | translate}}
            </span>
        </label>
        
        <label class="t-label-top py-4">
            <input type="radio" name="Position" class="custom-check" formControlName="Position" value="Axial"/>
            <i class="customInput-display"></i>
            <span>
                <!-- Axial -->
                {{'GUI.Gynecology.Axial' | translate}}
            </span>
        </label>
        
        <label class="t-label-top py-4">
            <input type="radio" name="Position" class="custom-check" formControlName="Position" value="RV"/>
            <i class="customInput-display"></i>
            <span>
                <!-- RV (Retro Verted) -->
                {{'GUI.Gynecology.RV (Retro Verted)' | translate}}
                </span>
            </label>

        </div>

    </div>

    <hr>

    <div class="flex flex-wrap items-center">

        <div class="w-full lgw-1_6 xlw-1_12 py-4">
            <!-- Size -->
        {{'GUI.Gynecology.Size' | translate}}
    </div>
    
    <div class="flex flex-wrap w-full lgw-2_6 xlw-2_12" (dblclick)="reset('Size')">
        
        <label class="t-label-top py-4">
            <input type="radio" name="Size" class="custom-check" formControlName="Size" value="Body + Cervix" />
            <i class="customInput-display"></i>
            <span>
                <!-- Body + Cervix -->
                {{'GUI.Gynecology.Body + Cervix' | translate}}
            </span>
        </label>
        
        <label class="t-label-top py-4">
            <input type="radio" name="Size" class="custom-check" formControlName="Size" value="Body"/>
            <i class="customInput-display"></i>
            <span>
                <!-- Body -->
                {{'GUI.Gynecology.Body' | translate}}
            </span>
        </label>
        
    </div>
    
    <div class="flex flex-wrap w-full lgw-3_6 xlw-9_12">

        <label class="t-label-top py-4">
            <span>
                L
            </span>
            <span class="t-field t-field--number">
                <input class="t-field-text" type="number" min="0" formControlName="SizeX" />
                <span class="t-field-desc">
                    mm
                </span>
            </span>
        </label>

            <label class="t-label-top py-4">
                <span>
                    W
                </span>
                <span class="t-field t-field--number">
                    <input class="t-field-text" type="number" min="0" formControlName="SizeY"/>
                    <span class="t-field-desc">
                        mm
                    </span>
                </span>
            </label>
            
            <label class="t-label-top py-4">
                <span>
                    H
                </span>
                <span class="t-field t-field--number">
                    <input class="t-field-text" type="number" min="0" formControlName="SizeZ" />
                    <span class="t-field-desc">
                        mm
                    </span>
                </span>
            </label>
            
            <label class="t-label-top py-4">
                <span>
                    <!-- Vol -->
                    {{'GUI.Gynecology.Vol' | translate}}
                </span>
                <span class="t-field t-field--number">
                    <input class="t-field-text" type="number" min="0" disabled [value]="getVolume()"/>
                    <span class="t-field-desc">
                        cc
                    </span>
                </span>
            </label>
            
        </div>
        
    </div>

    <hr>

    <div class="flex flex-wrap items-center">
        
        <div class="w-full lgw-1_6 xlw-1_12 py-4">
            <!-- Cervical Length -->
            {{'GUI.Gynecology.Cervical Length' | translate}}
        
        </div>

        <div class="flex flex-wrap w-full lgw-5_6 xlw-11_12">

            <label class="t-label-top py-4">

                <span class="t-field t-field--number">

                    <input class="t-field-text" type="number" min="0" formControlName="Cervical" />

                    <span class="t-field-desc">
                        mm
                    </span>

                </span>

            </label>

            <div class="t-label-top py-4">

                <label id="funneling">
                    <!-- Funneling -->
                    {{'GUI.Gynecology.Funneling' | translate}}
                </label>

                <app-genradio formname="Funneling" [formgroup]="UterusForm" [value1]="0" [value2]="1" [lname]="'general.PositiveNegative.yes' | translate" [rname]="'general.PositiveNegative.No' | translate" [resetval]="-1" ></app-genradio>

            </div>

        </div>

    </div>

    <hr>

    <div class="flex flex-wrap items-center">

        <div class="w-full lgw-1_6 xlw-1_12 py-4">
            <!-- Texture -->
            {{'GUI.Gynecology.Texture' | translate}}
        </div>

        <div class="flex w-full lgw-4_6 xlw-11_12">

            <div class="t-label-top py-4">
                <app-genradio formname="Texture" [formgroup]="UterusForm" value1="Homogenius" value2="Non Homogenius" [lname]="'GUI.Gynecology.Homogenius' | translate" [rname]="'GUI.Gynecology.Non Homogenius' | translate" [resetval]="null" ></app-genradio>
            </div>

        </div>

    </div>

</section>

<section class="dashboard-section p-5">

    <header class="dashboard-section-header">

        <h2>
            <!-- Endometrium -->
            {{'GUI.Gynecology.Endometrium' | translate}}
        </h2>

    </header>

    <div class="flex flex-wrap items-center">

        <div class="w-full lgw-1_6 xlw-1_12 py-4">
            <!-- Width -->
        {{'GUI.Gynecology.Width' | translate}}
        </div>

        <div class="flex flex-wrap w-full lgw-5_6 xlw-11_12">

            <label class="t-label-top py-4">

                <span class="t-field t-field--number">

                    <input class="t-field-text" type="number" min="0" formControlName="Endometrial"/>

                    <span class="t-field-desc">
                        mm
                    </span>

                </span>

            </label>

            <div class="t-label-top py-4">

                <label id="funneling">
                    <!-- Thin Stripe -->
                    {{'GUI.Gynecology.Thin Stripe' | translate}}
                </label>
                <app-genradio formname="EndoStripe" [formgroup]="UterusForm" [value1]="0" [value2]="1" [lname]="'general.PositiveNegative.yes' | translate" [rname]="'general.PositiveNegative.No' | translate" [resetval]="null" ></app-genradio>

            </div>

        </div>

    </div>

    <hr>

    <div class="flex flex-wrap items-center" (dblclick)="reset('Echogenicity')">

        <div class="w-full lgw-1_6 xlw-1_12 py-4">
            <!-- Echogenicity -->

            {{'GUI.Gynecology.Echogenicity' | translate}}
        </div>

        <div class="flex flex-wrap w-full lgw-5_6 xlw-11_12" >
            
            <label class="t-label-top py-4">
                <input type="radio" name="Echogenicity" class="custom-check" formControlName="Echogenicity" value="Normal"/>
                <i class="customInput-display"></i>
                <span>
                    <!-- Normal -->
                    {{'GUI.Gynecology.Normal' | translate}}

                </span>
            </label>
            
            <label class="t-label-top py-4">
                <input type="radio" name="Echogenicity" class="custom-check" formControlName="Echogenicity" value="Hyperechogenicity"/>
                <i class="customInput-display"></i>
                <span>
                    <!-- Hyperechogenicity -->
                    {{'GUI.Gynecology.Hyperechogenicity' | translate}}

                </span>
            </label>
            
            <label class="t-label-top py-4">
                <input type="radio" name="Echogenicity" class="custom-check" formControlName="Echogenicity" value="Hypoechogenicity"/>
                <i class="customInput-display"></i>
                <span>
                    <!-- Hypoechogenicity -->
                    {{'GUI.Gynecology.Hypoechogenicity' | translate}}
                    
                </span>
            </label>

        </div>

    </div>

    <hr>

    <div class="flex flex-wrap items-center" (dblclick)="reset('EndoPhase')">

        <div class="w-full lgw-1_6 xlw-1_12 py-4">
            <!-- Phase -->
            {{'GUI.Gynecology.Phase' | translate}}
        </div>

        <div class="flex flex-wrap w-full lgw-5_6 xlw-11_12" >
            
            <label class="t-label-top py-4">
                <input type="radio" name="EndoPhase" class="custom-check" formControlName="EndoPhase" value="Follicular"/>
                <i class="customInput-display"></i>
                <span>
                    <!-- Follicular -->
                    {{'GUI.Gynecology.Follicular' | translate}}

                </span>
            </label>
            
            <label class="t-label-top py-4">
                <input type="radio" name="EndoPhase" class="custom-check" formControlName="EndoPhase" value="Luteal"/>
                <i class="customInput-display"></i>
                <span>
                    <!-- Luteal -->
                    {{'GUI.Gynecology.Luteal' | translate}}
                </span>
            </label>

        </div>

    </div>

</section>

<section class="dashboard-section p-5 myoma">

    <header class="dashboard-section-header">

        <h2>
            <!-- Myoma (Fibroid) -->
            {{'GUI.Gynecology.Myoma (Fibroid)' | translate}}

        </h2>

    </header>

    <div class="flex flex-wrap">

        <div class="w-full lgw-1_3">
        
            <div class="flex items-center">

                <label class="t-label-top py-4">

                    <span class="t-field t-field--number min-w-auto">

                        <input class="t-field-text" type="number" min="0" [(ngModel)]="mx" [ngModelOptions]="{standalone: true}" />
                        <span class="t-field-desc">
                            mm
                        </span>
                    </span>

                </label>

                <div class="t-label-top py-4">X</div>

                <label class="t-label-top py-4">

                    <span class="t-field t-field--number min-w-auto">

                        <input class="t-field-text" type="number" min="0" [(ngModel)]="my" [ngModelOptions]="{standalone: true}" />
                        <span class="t-field-desc">
                            mm
                        </span>
                    </span>

                </label>

                <div class="t-label-top py-4">X</div>

                <label class="t-label-top py-4">

                    <span class="t-field t-field--number min-w-auto">

                        <input class="t-field-text" type="number" min="0" [(ngModel)]="mz" [ngModelOptions]="{standalone: true}"/>
                        <span class="t-field-desc">
                            mm
                        </span>

                    </span>

                </label>

            </div>
            
        
        </div>

        <div class="flex items-center w-full lgw-1_3">
        
            <label class="t-label-top py-4">
                <input type="radio" name="position" class="custom-check" checked [(ngModel)]="msort" [ngModelOptions]="{standalone: true}" value="Submucusal"/>
                <i class="customInput-display"></i>
                <span>
                    <!-- Submucusal -->
                    {{'GUI.Gynecology.Submucusal' | translate}}
                </span>
            </label>
            
            <label class="t-label-top py-4">
                <input type="radio" name="position" class="custom-check" [(ngModel)]="msort" [ngModelOptions]="{standalone: true}" value="Intramural"/>
                <i class="customInput-display"></i>
                <span>
                    <!-- Intramural -->
                    {{'GUI.Gynecology.Intramural' | translate}}
                </span>
            </label>
            
            <label class="t-label-top py-4">
                <input type="radio" name="position" class="custom-check" [(ngModel)]="msort" [ngModelOptions]="{standalone: true}" value="Subserosal"/>
                <i class="customInput-display"></i>
                <span>
                    <!-- Subserosal -->
                    {{'GUI.Gynecology.Subserosal' | translate}}
                </span>
            </label>

        </div>
        
        <div class="flex justify-end items-center w-full lgw-1_3 py-4">
        
            <button class="add-btn" (click)="addMyoma()" *ngIf="! es.isClosed">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M18 10h-4v-4c0-1.104-0.896-2-2-2s-2 0.896-2 2l0.071 4h-4.071c-1.104 0-2 0.896-2 2s0.896 2 2 2l4.071-0.071-0.071 4.071c0 1.104 0.896 2 2 2s2-0.896 2-2v-4.071l4 0.071c1.104 0 2-0.896 2-2s-0.896-2-2-2z"></path>
                </svg>
                <span>
                    <!-- Add -->
                    {{'GUI.General.Buttons.Add' | translate}}
                </span> 
            </button>

        </div>
    </div>
     <!-- mayomas after split -->
     <ng-container *ngFor="let m of Myomas; let i = index">
        <div style="display: block;">
        <div (dblclick)="remove(i)" style="display: inline-flex; width: 50%;justify-content: flex-start;">
            <!-- <span >{{m.x}}mm</span> x <span >{{m.y + (m.y? "mm": "")}}</span> x <span >{{m.z+ (m.z? "mm": "")}}</span> <span   *ngIf="m.vol && m.vol!='null'">&nbsp;vol: {{m.vol}}cm^3</span>  <span *ngIf="m.sort && m.sort!='null' && m.sort!='undefined'">&nbsp;{{m.sort}}</span> -->
            <span>{{ m.x !== undefined && m.x !== 'undefined' && m.x !== 'null'&& m.x !== null ? m.x + 'mm' : 'null' }}</span>
            &nbsp;
            x
            &nbsp;
            <span>{{ m.y !== undefined && m.y !== 'undefined'&& m.y!== 'null'&& m.y !== null  ? m.y + (m.y ? 'mm' : '') : 'null' }}</span>
            &nbsp;
            x
            &nbsp;
            <span>{{ m.z !== undefined && m.z !== 'undefined'&& m.z !== 'null'&& m.z !== null ? m.z + (m.z ? 'mm' : '') : 'null' }}</span>
            <span *ngIf="m.vol && m.vol !== 'null'">&nbsp;vol: {{ m.vol }}cm^3</span>
            <span *ngIf="m.sort && m.sort !== 'null' && m.sort !== 'undefined'">&nbsp;{{ m.sort }}</span>
            

        </div>
        <div *ngIf="( m.sort == 'null' || m.sort=='undefined' || !m.sort) && !es.isClosed"  style="display: inline-flex;">
            <label class="t-label-top ">
                <input type="radio" name="position_{{i}}" class="custom-check" (change)="updateSort($event, i)" value="Submucusal"/>
                <i class="customInput-display"></i>
                <span>
                    
                    <!-- Submucosal -->
                    {{'GUI.Gynecology.Subserosal' | translate}}

                </span>
            </label>
            <label class="t-label-top ">
                <input type="radio" name="position_{{i}}" class="custom-check" (change)="updateSort($event, i)" value="Intramural"/>
                <i class="customInput-display"></i>
                <span>
                    
                    <!-- Intramural -->
                    {{'GUI.Gynecology.Intramural' | translate}}

                </span>
            </label>
            <label class="t-label-top ">
                <input type="radio" name="position_{{i}}" class="custom-check" (change)="updateSort($event, i)" value="Subserosal"/>
                <i class="customInput-display"></i>
                <span>
                    
                    <!-- Subserosal -->
                    {{'GUI.Gynecology.Subserosal' | translate}}

                </span>
            </label>
        </div>
    </div>
    </ng-container>


</section>

<section class="dashboard-section p-5">

    <div class="flex flex-wrap items-center">

        <div class="w-full mdw-1_2">

            <label class="t-label-top py-4">

                <span>
                    <!-- Number of Cervical Cysts -->
                    {{'GUI.Gynecology.Number of Cervical Cysts' | translate}}
                </span>

                <select class="t-select t-field pl-20" formControlName="Cysts">
                    <option></option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                </select>

            </label>

        </div>

        <div class="flex mdjustify-end w-full mdw-1_2">
        
            <div class="t-label-top py-4">

                <label for="">
                    <!-- Adenomyosis -->
                    {{'GUI.Gynecology.Adenomyosis' | translate}}
                </label>
                <app-genradio formname="Adenomyosis" [formgroup]="UterusForm" [value1]="0" [value2]="1" [lname]="'general.PositiveNegative.yes' | translate" [rname]="'general.PositiveNegative.No' | translate" [resetval]="null" ></app-genradio>
            </div>
        
        </div>

    </div>

</section>

<div class="w100 summary" style="margin-bottom: 2em;">
    <div class="m-box doppler-comments">
        <h2>
            <!-- Comments -->
            {{'general.Comments' | translate}}
        </h2>
        
            <textarea class="full" formControlName="Comments"></textarea>
            <button class="t-btn-edit" *ngIf="! es.isClosed" (click)="atS.showAuto(UterusForm.get('Comments'),atSTitleUterusComments,71)">Auto Text</button>		
    </div>      
</div>


<app-diagnoses group="uterus" [form]="UterusForm.get('DiagnosisCodes')" [text]="UterusForm.get('Diagnosis')" autoid="72" class="w100 summary-boxHeight summary-extradocs"></app-diagnoses>

</ng-container>