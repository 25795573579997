<div [formGroup]="gs.gastroForm">
    <div class="h100 w100 l-flex-w summary">
        <div class="m-box w100">
            <h2>Time Out</h2>
            <textarea class="fulltext minH8" formControlName="TimeOut" style="height: 100%;"></textarea>
            <button class="t-btn-edit" style="right: 8em;" *ngIf="! es.isClosed && ls.userType == 'tech'"
            (click)="showTimeOutForm = true;gfs.loadForm(true, true)">Time Out Form</button>
            <button class="t-btn-edit" *ngIf="! es.isClosed"
            (click)="atS.showAuto(gs.gastroForm.get('TimeOut'),'Time Out',109)">Auto Text</button>
        </div>
        <section class="m-box" style="height: 100%;width: 100%;">
            <h2>Exam Indication</h2>
            <textarea class="fulltext minH8" formControlName="Reasone" style="height: 100%;"></textarea>
            <button class="t-btn-edit" *ngIf="! es.isClosed"
            (click)="atS.showAuto(gs.gastroForm?.get('Reasone'),'Reasone For Endoscopy',110)">Auto Text</button>

            <div style="display: inline-flex;justify-content: space-between;width: 30%;">
                <label>
                    <span>ASA </span>
                    <select class="t-field t-select" formControlName="ASA">
                        <option value="-1"></option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </label>
                <label>
                    <span>Mallampati </span>
                    <select class="t-field t-select" formControlName="Mellampeti">
                        <option value="-1"></option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>

                    </select>
                </label>
            </div>
        </section>
    </div>
    <div class="h100 l-flex-w summary">
        <div class="w50">
            <section class="m-box" style="height: 100%;">
                <h2>Preparation</h2>
                <div style="display: inline-flex;width: 100%;margin-block-end: 2em;">
                    <span style="margin-inline-end: 2em;align-self: center;width: inherit;">Preparation Protocol</span>
                    <mat-form-field style="width: 100%;">
                        <input type="text"
                                class="t-field t-input"
                               style="padding: 0.5em;"
                               matInput
                               formControlName="inputControl"
                               [matAutocomplete]="auto">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"  >
                            <ng-container>
                                <mat-option [value]="gs.gastroForm.get('inputControl').value" *ngIf="! checkInPrep()" (onSelectionChange)="addAndSave(gs.gastroForm.get('inputControl').value, 'Preparetion', 95, true);Save(gs.gastroForm.get('inputControl').value)">{{gs.gastroForm.get('inputControl').value}}</mat-option>
                            </ng-container>
                          <div *ngFor="let option of filteredOptionsPrep | async" style="display: flex;justify-content: space-between;">                          
                            <mat-option style="width: 100%;"  [value]="option" (click)="Save(option)">
                                {{option}}
                            </mat-option>
                          </div>
                        </mat-autocomplete>
                    </mat-form-field>
                    <!-- <select class="t-field t-select" formControlName="Preparetion" *ngIf="es.ExamType == 'Gastroscopy'">
                        <option value="-1"></option>
                        <option value="FAST">FAST</option>
                    </select> -->
                </div>
                <textarea class="fulltext" style="border: 1px solid;height: 6em;" formControlName="Preparetion"></textarea>
                <div style="display: inline-flex;width: 100%;">
                    <section style="width: 30%;" *ngIf="es.ExamType !='Gastroscopy'">
                        <span style="margin-inline-end: 2em;">BBPS</span>
                        <select class="t-field t-select" formControlName="BBPS">
                            <option value="-1"></option>
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                        </select>
                    </section>
                    <div class="l-flex-c l-flex-w indicative-fieldset" style="flex-wrap: inherit;">
                        <label class="t-label-top">
    
                            <input type="radio" formControlName="Quality" value="Poor" class="custom-check -sq" />
                            <i class="customInput-display -sq"></i>
                            <span>
                                Poor
                            </span>
    
                        </label>
    
                        <label class="t-label-top">
                            <input type="radio" formControlName="Quality" value="Avarage" class="custom-check -sq" />
                            <i class="customInput-display -sq"></i>
                            <span>
                                Average
                            </span>
                        </label>
    
                        <label class="t-label-top">
                            <input type="radio" formControlName="Quality" value="Good" class="custom-check -sq" />
                            <i class="customInput-display -sq"></i>
                            <span>
                                Good
                            </span>
                        </label>
    
                        <label class="t-label-top">
                            <input type="radio" formControlName="Quality" value="Excellent" class="custom-check -sq" />
                            <i class="customInput-display -sq"></i>
                            <span>
                                Excellent
                            </span>
                        </label>
                    </div>
                </div>
            </section>
        </div>
        <div class="w50" style="padding: 0 0 0 1em">
                <form [formGroup]="medS.Medication" style="height: 100%;">
                    <section class="m-box" style="height: 100%;" >
                        <h2>Administered Medications</h2>
                        <div class="l-flex-c">
                            
                            <div class="l-fg1 repeatExamination-text" style="justify-content: space-between;display: inline-table;font-size: 1.15em;"  >
                                <div style="width: 100%;" >
                                    <select class="t-select t-field" formControlName="Medication" name="Medication">
                                        <option></option>
                                        <ng-container>
                                            <option value="Fentanyl">Fentanyl</option>
                                            <option value="Dormicum">Midazolam</option>
                                            <option value="Propofol">Propofol</option>
                                            <option value="Dormicum">Dormicum</option>
                                            <option value="Spray">Spray Lidocaine 15%</option>
                                            <option value="Oxygen">Oxygen</option>
                                            <option value="Antibiotics(Cefanizim)">Antibiotics(Cefanizim)</option>
                                        </ng-container>
                                    </select>
                                    Dose 
                
                                    <span class="t-highlight-glowingCircle">
                                        <input type="number" min="1" max="40"  style='width: 2em; background: none; border: none; color: white; font-size: 1.1em' formControlName="Dose" name="Dose">
                                    </span>
                                    Unit
                                    <select class="t-select t-field" formControlName="Unit" style="padding-right: 1em;">
                                        <option></option>
                                        <option value="mg">mg</option>
                                        <option value="mg/kg">ml/kg</option>
                                        <option value="ml">ml</option>
                                        <option value="ml/kg">ml/kg</option>
                                    </select>
                                    <button class="btnAdd" (click)="medS.saveNew()" [disabled]="es.isClosed">Add</button>

                                </div>
                                <!-- <div style="width: 100%;text-align: center;">
                                    <button class="btnAdd" (click)="medS.saveNew()">Add</button>
                                </div> -->
                            </div>
                        </div>
                        <section class="m-box padd" style="height: auto;" *ngIf="medS.patMedication && medS.patMedication.length >0">
                            <table class="weightAss-table" style="width: 100%;">
                                <tr class="weightAss-thead">
                                    <td class="l-td-m">#</td>
                                    <td class="l-td-m">Name</td>
                                    <td class="l-td-m">Dose</td>
                                    <td class="l-td-m">Unit</td>
                                    <td class="l-td-m"></td>
                                </tr>
                                <ng-container *ngIf="medS.patMedication">
                                    <tr class="weightAss-tbody" *ngFor="let med of medS.patMedication; index as i">
                                        <td class="l-td-m">{{i+1}}</td>
                                        <td class="l-td-m">{{med.Medication}}</td>
                                        <td class="l-td-m">{{med.Dose}}</td>
                                        <td class="l-td-m">{{med.Unit}}</td>
                                        <td class="l-td-m"><span class="linked-radios-duration"><img src="../../assets/icons/del.svg"
                                            matTooltip="Delete"
                                            (click)="medS.deleteMed(med.AutoIndex)"></span></td>
                                    </tr>
                                </ng-container>
                            </table>
                        </section>
                    </section>
                </form>
            </div>
    </div>
    <div class="h100 w100 l-flex-w summary">
        <section class="m-box" style="height: 100%;width: 100%;">
            <h2>Comments</h2>
            <textarea class="fulltext minH8" formControlName="Comments"></textarea>
            <button class="t-btn-edit" *ngIf="! es.isClosed"
            (click)="atS.showAuto(gs.gastroForm?.get('Comments'),'Comments',111)">Auto Text</button>
        </section>
    </div>
    <div class="h100 l-flex-w t-boxMargin--spacebyPadding">
        <div class="w50">
            <section class="m-box h100">
                <h2>Examination Equipment</h2>
                <div>
                    <section class="w100" style="display: inline-flex;place-items:baseline">
                        <section style="width: 25%;align-self: center;">
                            <label>Imaging device name  </label>
                        </section>
                        <mat-form-field style="width: 15%">
                            <input class="t-field t-field-text" type="text"
                                   matInput
                                   formControlName="imag1"
                                   [matAutocomplete]="imag1">
                            <mat-autocomplete autoActiveFirstOption #imag1="matAutocomplete"  >
                              <mat-option  [value]="gs.gastroForm.get('imag1').value" (onSelectionChange)="addAndSave(gs.gastroForm.get('image1').value, 'image1', 94)" >{{gs.gastroForm.get('imag1').value}}</mat-option>
                              <div *ngFor="let option of filteredOptions" style="display: flex;">                          
                                <mat-option style="width:100%"  [value]="option">
                                    {{option}}
                                </mat-option>
                              </div>
                            </mat-autocomplete>
                        </mat-form-field>
                        #
                        <mat-form-field style="width: 25%">
                            <input class="t-field t-field-text" type="text"
                                   matInput
                                   formControlName="imag2">
                        </mat-form-field>
                    </section>
                    <section class="w100" style="display: inline-flex;place-items:baseline">
                        <section style="width: 25%;align-self: center;">
                            <label>Scope name  </label>
                        </section>
                        <mat-form-field style="width: 15%">
                            <input class="t-field t-field-text" type="text"
                                   matInput
                                   formControlName="scope1"
                                   [matAutocomplete]="scope1">
                            <mat-autocomplete autoActiveFirstOption #scope1="matAutocomplete"  >
                              <mat-option  [value]="gs.gastroForm.get('scope1').value" (onSelectionChange)="addAndSave(gs.gastroForm.get('scope1').value, 'scope1', 93)" >{{gs.gastroForm.get('scope1').value}}</mat-option>
                              <div *ngFor="let option of filteredOptions" style="display: flex;">                          
                                <mat-option style="width:100%"  [value]="option">
                                    {{option}}
                                </mat-option>
                              </div>
                            </mat-autocomplete>
                        </mat-form-field>
                        #
                        <mat-form-field style="width: 25%">
                            <input class="t-field t-field-text" type="text"
                                   matInput
                                   formControlName="scope2">
                        </mat-form-field>
                    </section>
                </div>
            </section>
        </div>
        <div class="w50 summary" style="margin-bottom: 0;">
            <div class="m-box h100">
                <h2>Other Staff</h2>
                <textarea class="fulltext minH4 h100" formControlName="OtherStaff"></textarea>
                <button class="t-btn-edit" *ngIf="! es.isClosed"
                (click)="atS.showAuto(gs.gastroForm?.get('OtherStaff'),'Other Staff',112)">Auto Text</button>
            </div>
        </div>
    </div>
</div>
<app-patforms *ngIf="showTimeOutForm" (closed)="close()"></app-patforms>