import { Injectable } from '@angular/core';
import {ExamsService} from './exams.service';
import { HttpClient } from '@angular/common/http';
import { UntypedFormGroup,UntypedFormControl,UntypedFormBuilder } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {DoppleritemComponent} from './doppleritem/doppleritem.component';
import {Measure} from './measurements/meaure';
import { ThemeService } from 'ng2-charts';
@Injectable({
  providedIn: 'root'
})
export class DopplerService {

  examData;
  MPI: number;
  UPI:number;
  CPR: DoppleritemComponent;
  public Doppler:UntypedFormControl[] = [];
  dForm:UntypedFormGroup;
  Items:Measure[] = [];
  IsLoaded=false;
  IsEmpty=true;

  names= [
    ['LAPI','UtAPI'],
    ['LARI','UtARI'],
    ['RAPI','UtAPI'],
    ['RARI','UtARI'],
    ['USD',null],
    ['UPI','UmAPI'],
    ['URI','UmARI'],
    ['MPI','MCAPI'],
    ['MRI','MCARI'],
    ['PSV','MCAPSV'],
    ['CPR','CPR'],
    ['MSD',null],
    ['LASD',null],
    ['RASD',null]
  ];
  public loaded$: Subject<void> = new Subject<void>();
  constructor(private es: ExamsService, private http: HttpClient, private fb: UntypedFormBuilder) { 
    
    for(let i=0; i < this.names.length; i++){
      this.Doppler[this.names[i][0]+"_OK"] = new UntypedFormControl('');
      if(this.names[i][1])
        this.Items[this.names[i][0]] = new Measure(this.names[i][1],[],[''],0,1000,'doppler',this.names[i][1]);
      else
        this.Items[this.names[i][0]] = new Measure(this.names[i][1],[],[],0,1000,'doppler');
    }

    this.dForm = this.fb.group({
      AnemicIndic:[''],
      Ductus: [''],
      Awave:[''],
      DuctosComments:[''],
      Comments:['']
    });
  }

  setValue(name:string, value:number,GAf:string,GGf:string){
    this.Items[name].value=value;
    if(GAf)
      this.Items[name].GAfig=GAf;
    if(GGf)
      this.Items[name].GGfig=GGf;
    this.Items[name].calcGATable();
    this.Items[name].calcGGTable(this.es.examGA());
  }

  loadData(){
    this.IsLoaded=false;
    this.es.setTable('doppler');
    //this.dForm.reset(false,{emitEvent:false});
    this.http.get("/api/get",{params:{'table':'doppler',fetus: this.es.getFetus().toString(),ExamID: this.es.examID.toString()}}).subscribe(
      (data)=>{
        this.examData = data;
        for(let name in this.Items){
          this.Items[name].value=data[name];
          this.Items[name].GG = null;
          if(data[name+"_GAfig"])
            this.Items[name].GAfig=data[name+"_GAfig"];
          if(data[name+'_GGfig'])
            this.Items[name].GGfig=data[name+"_GGfig"];
          if(this.Items[name].value){
            this.Items[name].calcGATable();
            this.Items[name].calcGGTable(this.es.examGA());
          }

        }

        
        this.MPI = data["MPI"];
        this.UPI = data["UPI"];
        if(this.CPR){
          if(this.MPI && this.UPI){
            this.CPR.MyMeasure.value = Math.round(100*this.MPI/this.UPI)/100;
            this.CPR.checkNum();
          }
          else{
            this.CPR.MyMeasure.value=null;
          }
        }

        if(! data.hasOwnProperty("empty")){
          for (let key in data) {
            if(this.dForm.get(key)){
            
              this.dForm.get(key).setValue(data[key],{emitEvent: false});//null ruins formcontrol
            
            }
          }
          for(let key in this.Doppler){
            if(data.hasOwnProperty(key))
              this.Doppler[key].setValue(data[key],{emitEvent: false});
          }
          this.IsEmpty=false;
        }else{//clear form for twins
          this.IsEmpty=true;
          this.dForm.reset(false,{emitEvent:false});
          for(let key in this.Doppler){
            this.Doppler[key].reset(false,{emitEvent: false});
          }
        }
        for (let key in this.dForm.controls) {
          if(this.es.isClosed){
            this.dForm.disable();
          }
          else
            this.dForm.controls[key].valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(data=>{
              this.es.saveVals(key,this.dForm.get(key).value,true);
          }); 
        }
        for(let key in this.Doppler){
          if(this.es.isClosed)
            this.Doppler[key].disable();
          else
            this.Doppler[key].valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(data=>{
              this.es.saveVals(key,this.Doppler[key].value,true);
            }); 
        }
        this.loaded$.next();
        this.IsLoaded=true;
      },
      (error)=>{
        console.log(error);
      });
  }

  getValue(dbname:string){
    if(this.examData.hasOwnProperty(dbname))
      return this.examData[dbname];
    return null;
  }

  setCPR(cpr:DoppleritemComponent){
    this.CPR=cpr;
    if(this.MPI && this.UPI){
      this.CPR.MyMeasure.value = Math.round(100*this.MPI/this.UPI)/100;
      this.CPR.checkNum();
    }
    else{
      this.CPR.MyMeasure.value=null;
    }
  }

  saveMeas(dbname:string, value:number){
    this.es.saveVals(dbname,value,true);
    if(dbname == "MPI" || dbname == "UPI"){
      this[dbname] = value;
     if(this.MPI && this.UPI){
        this.CPR.MyMeasure.value = Math.round(100*this.MPI/this.UPI)/100;
        this.CPR.checkNum();
        if(this.CPR.MyMeasure.GG){
          if(this.CPR.MyMeasure.GG < 5 || this.CPR.MyMeasure.GG > 95)
            this.CPR.fg[this.CPR.OK].setValue(1);
          else
          this.CPR.fg[this.CPR.OK].setValue(0);
        }
      }
      else{
        this.CPR.MyMeasure.value = null;
        this.CPR.fg[this.CPR.OK].setValue(-1);
        this.CPR.MyMeasure.GG = null;
      }
    }
    
  }

  getOK(name:string){
    try{
      if(this.Doppler[name+'_OK'].value === 0)
        return 'OK';
      if(this.Doppler[name+'_OK'].value == 1)
        return 'Not OK';
      return '';
    }
    catch{
      return '';
    }
  }


  saveGGdoppler(name,value,fetus){
    this.http.post('/api/saveGAGGtoDB/doppler',{name ,value,fetus,examID:this.es.examID}).subscribe((data)=>{
      console.log(data);
      })
  }

}
