<main class="l-deashboard l-flex l-column doppler" [formGroup]="dops.dForm">

		<header class="deashboard-header" style="margin: 0 0 2em 0;">
			<h1 class="deashboard-title"  [ngStyle]="(isLMPMissing && patientsService.getPat().ID !== 'default') ? {'flex-grow': '0'} : {}">
				<!-- Fetus Doppler Measurements -->
				{{'GUI.OBS Doppler.Fetus Doppler Measurements' | translate}}

			</h1>
			<div *ngIf="isLMPMissing && patientsService.getPat().ID !== 'default'" style="flex-grow: 1; font-size: 1.5em;">
				<span class="lmp-msg">
					<!-- LMP is empty therefore GGs are not computed. -->
					{{'GUI.Measurements.CustomTooltips.Missing LMP Message' | translate}}
				</span>
			</div>
			<div class="legend">
				<i class="legend-item"></i>
				<i class="legend-item"></i>
				<i class="legend-item">Normal</i>
				<i class="legend-item"></i>
				<i class="legend-item"></i>
			</div>
		</header>
		

		<div class="l-fg1 l-flex l-column doppler-panel" *ngIf="dopplerLoaded">

			<!-- top panels (start) -->
			<div class="l-fg1 l-flex">
				
				<div class="l-f1 l-flex doppler-measurements-panel-top">
					
					<div class="l-fg1 l-flex l-column">

						<div class="l-flex l-flex-c doppler-row">

							<div class="m-box m-box--circular doppler-radios">
								
								<section class="l-flex l-flex-w doppler-radios-row">

									<h2 class="doppler-panel-title">
										<strong>
											MCA
										</strong>
										<!-- Middle Cerebral Artery -->
										{{'GUI.OBS Doppler.Middle Cerebral Artery'| translate | titlecase}}
									</h2>
                                    <app-doppleritem Type="PI" Artery="MCA" [GG]="['Edding','Bahlman']" OK="MPI_OK" [fg]="dops.Doppler"></app-doppleritem>
									<app-doppleritem Type="RI" Artery="MCA" [GG]="['Bahlman','Kurmanavicius']" OK="MRI_OK" [fg]="dops.Doppler"></app-doppleritem>
                                    <app-doppleritem Type="PSV" Artery="MCA" [GG]="['Ebbing']"  OK="PSV_OK" [fg]="dops.Doppler"></app-doppleritem>
                                    <app-doppleritem Type="S/D" Artery="MCA"  OK="MSD_OK" [fg]="dops.Doppler"></app-doppleritem>
								</section>

								<div class="l-flex l-flex-w dopplers-radios-bottom">
									<h4 class="t-fields-title">
										<!-- Anemic Indication -->
										{{'GUI.OBS Doppler.Anemic Indication' | translate}}
									</h4>
									<label class="t-label-top">
                                        <input type="radio" name="AnemicIndic" class="custom-check customInput" value="Normal" formControlName="AnemicIndic">
                                        <i class="customInput-display"></i>
										<span>
											<!-- Normal -->
											{{'GUI.OBS Doppler.Normal' | translate}}

										</span>
									</label>
									<label class="t-label-top">
                                        <input type="radio" name="AnemicIndic" formControlName="AnemicIndic" value="Mild" class="custom-check customInput">
                                        <i class="customInput-display"></i>
										<span>
											<!-- Mild -->
											{{'GUI.OBS Doppler.Mild' | translate}}

										</span>
									</label>
									<label class="t-label-top">
                                        <input type="radio" name="AnemicIndic" formControlName="AnemicIndic" value="Moderate" class="custom-check customInput">
                                        <i class="customInput-display"></i>
										<span>
											<!-- Moderate -->
											{{'GUI.OBS Doppler.Moderate' | translate}}

										</span>
									</label>
									<label class="t-label-top">
                                        <input type="radio" name="AnemicIndic" formControlName="AnemicIndic" value="Severe" class="custom-check customInput">
                                        <i class="customInput-display"></i>
										<span>
											<!-- Severe -->
											{{'GUI.OBS Doppler.Severe' | translate}}
										</span>
									</label>
								</div>

							</div>

						</div>						

						<div class="l-flex l-flex-c doppler-row">

							<div class="m-box m-box--circular doppler-radios">
								
								<section class="l-flex l-flex-w doppler-radios-row">

									<h2 class="doppler-panel-title">
										<strong>
											UmA
										</strong>
										<!-- Umbilical Artery -->
										{{'GUI.OBS Doppler.Umbilical Artery'| translate | titlecase}}

                                    </h2>
                                    <app-doppleritem Type="PI" Artery="UmA" [GG]="['1']" OK="UPI_OK" [fg]="dops.Doppler"></app-doppleritem>
									<app-doppleritem Type="RI" Artery="UmA" [GG]="['1']" OK="URI_OK" [fg]="dops.Doppler"></app-doppleritem>
                                    
                                    <app-doppleritem Type="S/D" Artery="UmA" [GG]="['1']" OK="USD_OK" [fg]="dops.Doppler"></app-doppleritem>

								</section>

							</div>

						</div>

					</div>

					<div class="l-flex l-flex-c doppler-aside">
						
						<div class="m-box m-box--circular doppler-radios">
							<div class="doppler-radios-row">
                                    <app-doppleritem Type="CPR" Artery="" [GG]="['']" OK="CPR_OK" [fg]="dops.Doppler"></app-doppleritem>
                            </div>
						</div>

						<i class="dotted-connector _for-large-views"></i>

					</div>

					<i class="dotted-connector _for-small-views"></i>

				</div>

			</div>
			<!-- top ppanels (end) -->

			<div class="doppler-row--bottom" *ngIf="es.getFetus() == 1">

				<div class="doppler-row-symetric">

					<div class="m-box l-flex l-flex-w m-box--circular doppler-radios">
						
						<section class="w50 l-flex l-flex-w doppler-radios-row">

							<h2 class="doppler-panel-title">
								<strong>
									UtA
								</strong>
								<!-- Left Uterine Artery -->
								{{'GUI.OBS Doppler.Left Uterine Artery'| translate | titlecase}}

                            </h2>
                            <app-doppleritem Type="PI" Artery="UtA" side="L" [GG]="['']" OK="LAPI_OK" [fg]="dops.Doppler"></app-doppleritem>
                            <app-doppleritem Type="RI" Artery="UtA" side="L" [GG]="['']" OK="LARI_OK" [fg]="dops.Doppler"></app-doppleritem>
                            
                            <app-doppleritem Type="S/D" Artery="UtA" side="L" OK="LASD_OK" [fg]="dops.Doppler"></app-doppleritem>

						</section>

						<section class="w50 l-flex l-flex-w doppler-radios-row">

							<h2 class="doppler-panel-title">
								<strong>
									UtA
								</strong>
								<!-- Right Uterine Artery -->
								{{'GUI.OBS Doppler.Right Uterine Artery'| translate | titlecase}}

							</h2>
                            <app-doppleritem Type="PI" Artery="UtA" side="R" [GG]="['']"  OK="RAPI_OK" [fg]="dops.Doppler"></app-doppleritem>
                            <app-doppleritem Type="RI" Artery="UtA" side="R" [GG]="['']"  OK="RARI_OK" [fg]="dops.Doppler"></app-doppleritem>
                            
                            <app-doppleritem Type="S/D" Artery="UtA" side="R" OK="RASD_OK" [fg]="dops.Doppler"></app-doppleritem>
						</section>

					</div>
					
				</div>

			</div>

			<!-- bottom panels (start) -->
			<div class="l-flex l-flex-w t-boxMargin--spacebyPadding ductus">

				<!-- ductus venosus (start) -->
				<div class="w100 summary">
					<div class="m-box">
						<h2>
							<!-- Ductus Venosus -->
							{{'GUI.OBS Doppler.Ductus Venosus'| translate | titlecase}}

						</h2>

						<div class="l-flex l-flex-c l-flex-w">
							
							<div class="w50 l-flex l-flex-c l-flex-w ductus-radios">
								<!-- set (start) -->
								<div class="t-label-top">	
									<app-genradio formname="Ductus" [formgroup]="dops.dForm" [value1]="0" [value2]="1" [lname]="'GUI.OBS Doppler.Observed' | translate" [rname]="'GUI.OBS Doppler.Not Observed' | translate" [resetval]="-1"></app-genradio>
								</div>
								<!-- set (end) -->			
								
								<!-- set (start) -->			
								<div class="t-label-top">	
									<label id="a_wave">
										A-Wave
									</label>
									<app-genradio formname="Awave" [formgroup]="dops.dForm" [value1]="0" [value2]="1" [lname]="'GUI.OBS Doppler.Positive' | translate" [rname]="'GUI.OBS Doppler.Negative' | translate" [resetval]="-1"></app-genradio>
								</div>	
								<!-- set (end) -->
							</div>			

							<div class="w50">
								<textarea class="l-textarea t-field--limitRad w100 h100" aria-label="Additional comment" [placeholder]="'general.Comments' | translate" formControlName="DuctosComments"></textarea>
								<button class="t-btn-edit" *ngIf="! es.isClosed" (click)="atS.showAuto(dops.dForm.get('DuctosComments'),atSTitleDuctosVenosusComments, 51)">Auto Text</button>		
							</div>
											
						</div>
					</div>
				</div>
				<!-- ductus venosus (end) -->

				
				
				<div class="w100 summary">
					<div class="m-box doppler-comments">
						<h2>
							<!-- Comments -->
							{{'general.Comments'| translate | titlecase}}
						</h2>	
						<textarea class="full" formControlName="Comments"></textarea>
						<button class="t-btn-edit" *ngIf="! es.isClosed" (click)="atS.showAuto(dops.dForm.get('Comments'),atSTitleGeneralDopplerComments, 50)">Auto Text</button>		
					</div>
				</div>

				
			</div>
			<!-- bottom panels (end) -->


			
		</div><!-- box margin -->

	</main>
