import { ExamsService } from './exams.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Subject} from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class UltrasoundMeasurementsService {
  measures=['BPD','FL','HC','AC','OFD','TL','Foot','HL','UL','OOD','IOD','OD','EarLen','CM','CEREB','Nuchal','FIBULA','CL','TC','RADIUS','VoPl','EFW','HCAC','AUA']
  ultrasoundMeasures={};
  public loading = false;
  public loaded$: Subject<null> = new Subject();

  constructor(private http:HttpClient,public es:ExamsService) { 
    
  }

  loadMeasurements(){
    //load us measurements
    this.ultrasoundMeasures={}
    this.loading=true
    this.http.get("/api/compareMeas/getUs", {
      params: {
        ExamID: this.es.examID.toString()
      }
    }).subscribe((data:any) => {
      // Save the ultrasound measurements for all fetuses,  into this.ultrasoundMeasures

      data.forEach(fetusMeas => {
        const fetusNum=fetusMeas['InstanceNumber']
        this.ultrasoundMeasures[fetusNum]={}
      this.measures.forEach((key) => {
          this.ultrasoundMeasures[fetusNum][key]={}
        const ga = `${key}_GA`;
        const gg = `${key}_GG`;
        const value = `${key}_US`;
        const ageCalculatedBy = `${key}_agingCalculatedBy`;
        this.ultrasoundMeasures[fetusNum][key][ga] = fetusMeas[ga];
        this.ultrasoundMeasures[fetusNum][key][gg] = fetusMeas[gg];
        this.ultrasoundMeasures[fetusNum][key][value] = fetusMeas[value];
        this.ultrasoundMeasures[fetusNum][key][ageCalculatedBy] = fetusMeas[ageCalculatedBy];

        if (key === 'EFW') {
          const unit = `${key}_unit`;
          const calculatedBy = `${key}_calculatedBy`;
          this.ultrasoundMeasures[fetusNum][key][unit] = fetusMeas[unit];
          this.ultrasoundMeasures[fetusNum][key][calculatedBy] = fetusMeas[calculatedBy];
          this.ultrasoundMeasures[fetusNum][key][value] = fetusMeas[key];
        }
        if (key==='HCAC'){
          const calculatedBy = `${key}_calculatedBy`;
          this.ultrasoundMeasures[fetusNum][key][calculatedBy] = fetusMeas[calculatedBy];

        }



        });
   
  })
    this.loading=false
    this.loaded$.next()

})
    
       
  }

  



  getMeas(name: string,fetus) {
    //return meas[name]
   
          const meas = {};
          if (!this.ultrasoundMeasures[fetus] || !this.ultrasoundMeasures[fetus][name]) {
            return {
              value: null,
              GA: null,
              GG: null,
              agingCalculatedBy: null,
              unit: null,
              calculatedBy: null
            };
          }
          if (name==='EFW 1'){
            name='EFW'
          }
          meas['value'] = this.ultrasoundMeasures[fetus][name][`${name}_US`];
          meas['GA'] = this.ultrasoundMeasures[fetus][name][`${name}_GA`];
          meas['GG'] = this.ultrasoundMeasures[fetus][name][`${name}_GG`];
          meas[`agingCalculatedBy`] = this.ultrasoundMeasures[fetus][name][`${name}_agingCalculatedBy`];
  
          if (name == 'EFW') {
            meas[`unit`] = this.ultrasoundMeasures[fetus][name][`${name}_unit`];
            meas[`calculatedBy`] = this.ultrasoundMeasures[fetus][name][`${name}_calculatedBy`];
          }
          if(name ==='HCAC'){
            meas[`calculatedBy`] = this.ultrasoundMeasures[fetus][name][`${name}_calculatedBy`];
          }
           
          return meas;
      
    }
  
  

}
