import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { ExamsService } from '../exams.service';

@Injectable({
  providedIn: 'root'
})
export class CardioService {
  // public Findings;
  public Findings = [];
  public FindingsStr;
  public parseString;
  sub;

  public FindingsChange$ = new Subject<string>();
  fields =[ "LeftVentrical", "RightVentrical","Aorta", "Mitral", "Tricuspid", "Aortic", "Pulmonic", "LVOT", "LV", "Septal", "Lateral","LVAfter", "SeptalAfter", "LateralAfter", "LeftAtrium", "RightAtrium","Response","Response","Conduction","ST","EndReason", "ECG"]
  constructor(private http: HttpClient, private es:ExamsService) {
    this.es.examLoaded$.subscribe(() => {
      var res = this.getFindings(this.es.examID);
      res.subscribe((data) => {
        this.FindingsStr = data['Findings'];
        this.parseFindings(data['Findings']);
      })
    })
    if(this.es.examID){
      var res = this.getFindings(this.es.examID);
      res.subscribe((data) => {
        this.FindingsStr = data['Findings'];
        this.parseFindings(data['Findings']);
      })
    }
   }

  getLVEF(examid:number){
    let params = {'table':'cardio2D',ExamID: examid.toString()};
    return this.http.get("/api/get",{'params':params});
  }

  getHMOinfo(examid:number){
    let params = {'table': 'cardioGeneral',ExamID: examid.toString()};
    return this.http.get("/api/get",{'params': params});
  }

  addToFindings(data:any, name:any){
    if(name){
      if(data){
        var temp = this.Findings.filter(i => i.name == name);
        if(temp.length > 0){
          this.Findings = this.Findings.filter(i => i.name != name)
        }
        this.Findings.push({name:name, data:data});
      }else{
        var temp = this.Findings.filter(i => i.name == name);
        if(temp.length > 0){
          this.Findings = this.Findings.filter(i => i.name != name)
        }
      }
      if(this.FindingsStr){
        this.FindingsStr=null;
          this.fields.forEach((i) => {
            if(this.Findings.filter(item => item.name == i).length > 0){
              if(this.FindingsStr)
                this.FindingsStr += '$%'+this.Findings.filter(item => item.name == i)[0].name +'_'+this.Findings.filter(item => item.name == i)[0].data;
              else
                this.FindingsStr = this.Findings.filter(item => item.name == i)[0].name +'_'+this.Findings.filter(item => item.name == i)[0].data
            }
          })
          this.FindingsChange$.next(this.FindingsStr)
          // if(!this.FindingsStr)
            this.es.saveVals('Findings', this.FindingsStr, false, 'summary');
      }
    }
  }
  getFindings(examid){
    let params = {'table':'summary',ExamID: examid.toString()};
    return this.http.get("/api/get",{'params':params});
  }
  parseFindings(data){
    this.Findings = data;
    var temp =[];
    if(data && data.split('$%').length > 0){
      data.split('$%').forEach((i) => {
        temp.push({name:i.split('_')[0], data:i.split('_')[1]})
      })
      this.Findings = temp;
    }else{
      this.Findings = [];
    }
  }

  async getStr(){
    if(this.es.examID && this.Findings.length == 0){
      var res  = this.getFindings(this.es.examID);
      await res.subscribe((data) => {
        this.FindingsStr = data['Findings'];
        this.parseFindings(data['Findings']);
        var str;
        this.fields.forEach((i) => {
          if(this.Findings.filter(item => item.name == i).length > 0){
            if(str)
              str += '\n'+this.Findings.filter(item => item.name == i)[0].data;
            else
              str = this.Findings.filter(item => item.name == i)[0].data
          }
        })
        this.parseString = str;
      })
    }else{
      var str;
      this.fields.forEach((i) => {
        if(this.Findings.filter(item => item.name == i).length > 0){
          if(str)
            str += '\n'+this.Findings.filter(item => item.name == i)[0].data;
          else
            str = this.Findings.filter(item => item.name == i)[0].data
        }
      })
      this.parseString = str;
    }

  }
}
