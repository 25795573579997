import { Component } from '@angular/core';
import { LoginService } from '../app/login.service';
//import {TranslateService} from '@ngx-translate/core';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Aweb';
  constructor(private loginS: LoginService/*,translate: TranslateService*/) { 
   /*// this language will be used as a fallback when a translation isn't found in the current language
   translate.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('he-IL');*/
  }
}
