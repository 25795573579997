import { Component, OnInit,Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {ExamsService} from '../exams.service';
@Component({
  selector: 'app-genradio',
  templateUrl: './genradio.component.html',
  styleUrls: ['./genradio.component.scss']
})
export class GenradioComponent implements OnInit {
  @Input('formname') Name: string;
  @Input('formgroup') parentform: UntypedFormGroup;
  @Input('value1') Value1;
  @Input('value2') Value2;
  @Input('lname') Left: string;
  @Input('rname') Right: string;
  @Input('resetval') resetv;

  

  constructor(private es: ExamsService) { }

  ngOnInit() {
  }

  reset(){
    if(! this.es.isClosed)
      this.parentform.get(this.Name).setValue(this.resetv);
  }

}
