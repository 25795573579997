<div class="m-box" [formGroup]="fes.fetalMeasureForm">
    <h2>{{name}}</h2>
    <div class="t-set-title" (dblclick)="fes.fetalMeasureForm.get(dbName).setValue(-1)"
        style="display: flex;align-items: center; text-align-last: center;justify-content: space-around;">
        <span *ngIf="type" style="margin: 1em;">
            <strong>Drainig into</strong>
        </span>
        <div class="l-flex-c t-field t-twin-fieldset t-fieldset-big" *ngIf="name !=='PI'">

            <label>
                <input type="radio" class="custom-check" [formControlName]="dbName" [value]="1" />
                <i class="customInput-display"></i>
                <span>
                    {{first}}
                </span>

            </label>

            <label>

                <input type="radio" class="custom-check" [formControlName]="dbName" [value]="0" />
                <i class="customInput-display"></i>
                <span>
                    {{second}}
                </span>

            </label>

        </div>

        <div class="l-flex-c t-field t-twin-fieldset t-fieldset-big" *ngIf="name === 'PI'"
            style="width: 60%;display: flex;justify-content: center;">
            <input class="fetal-mesure-in" type="text" [formControlName]="dbName"
                style="width: 60%; text-align-last: center; text-align: center;">
        </div>
    </div>
</div>