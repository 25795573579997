<ng-container [formGroup]="anamnesisForm">
  <div class="h100 l-flex-w t-boxMargin--spacebyPadding summary">

    <div class="w100 summary-anamnesis" style="height: 50%;">
      <div class="w100 summary-anamnesis" style="display: flex;">

        <div class="m-box w50 summary-anamnesis" style="padding: 1.31em 1.43em; margin-right: 1.5em;">
          <h2>
            Medical History
          </h2>
          <textarea class="scroller" formControlName="MedicalHistory"></textarea>
          <button class="t-btn-edit" *ngIf="!examsService.isClosed"
            (click)="autoTextService.showAuto(anamnesisForm.get('MedicalHistory'),'Medical History',1)">
            Auto Text
          </button>
          <!-- ====== Gender Section (Start) ====== -->
          <div class="m-box" style="margin-top: 1em;">
            <h2>
              Gender
            </h2>
            <div class="anatomy-fetus">
              <div class="anatomy-twin-radios-dobule anatomy-twin-radios-dobule">
                <label class="observe">
                  <input type="radio" class="custom-check" name="patientGender" value="F" [(ngModel)]="patientGender"
                    (change)="updateGender($event)" [checked]="patientGender === 'F'">
                  <i class="customInput-display"></i>
                  <span>
                    Female
                  </span>
                </label>
                <label class="observe">
                  <input type="radio" class="custom-check" name="patientGender" value="M" [(ngModel)]="patientGender"
                    (change)="updateGender($event)" [checked]="patientGender === 'M'">
                  <i class="customInput-display"></i>
                  <span>
                    Male
                  </span>
                </label>
              </div>
            </div>
          </div>
          <!-- ====== Gender Section (End) ====== -->

        </div>
        <div class="m-box w50  summary-anamnesis" style="padding: 1.31em 1.43em;">
          <h2>
            Indications of Exam
          </h2>
          <textarea class="scroller" formControlName="IndicationsOfExam"></textarea>
        </div>

      </div>
    </div>

    <div class="m-box w100" style="padding: 1.31em 1.43em; margin-left: 1em;">
      <div style="display: flex; padding: 1em 0; justify-content: space-between;">
        <div style="width: 100%;">
          <div style="display: flex;">
            <ng-container *ngFor="let type of inputTypes; let i = index">
              <section *ngIf="i < 2" class="w50" style="margin: 0 1em 0 0;">
                <section class="m-box"
                  [ngStyle]="{'margin': '0.87em 0 0', 'padding': '1.3em 0 0', 'margin-top': '1em'}">
                  <h2 style="cursor: pointer;">
                    {{getTitle(type)}}
                  </h2>
                  <div class="l-flex-c l-flex-w indicative-fieldset h100"
                    style="justify-content: space-between; z-index: auto;">
                    <section style="display: flex; min-width: 40%; min-height: 4.2em;">
                      <label class="t-label-top" (dblclick)="reset(type)">
                        <input type="radio" [formControlName]="type" [name]="type" [value]="1" class="custom-check" />
                        <i class="customInput-display"></i>
                        <span>
                          Yes
                        </span>
                      </label>

                      <ng-container *ngIf="anamnesisForm.get(type).value === 1">
                        <mat-form-field style="width: 100%;">
                          <input type="text" style="direction: rtl;text-align-last: right;text-align: right;" matInput
                            [formControlName]="type+'Text'" [matAutocomplete]="auto">
                          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                            <mat-option style="text-align: right;" *ngIf="!checkIn(type)"
                              [value]="anamnesisForm.get(type+'Text').value"
                              (onSelectionChange)="addAndSave(anamnesisForm.get(type+'Text').value, type)">
                              {{anamnesisForm.get(type+'Text').value}}
                            </mat-option>
                            <div *ngFor="let option of filteredOptions" style="display: flex;">
                              <mat-option style="text-align: right;width:100%" [value]="option">
                                {{option}}
                              </mat-option>
                              <button class="auto-text-delete-button" (click)="delete(option, type)"
                                style="height: auto;">
                                delete
                              </button>
                            </div>
                          </mat-autocomplete>
                        </mat-form-field>
                      </ng-container>
                    </section>

                    <label class="t-label-top" style="width: fit-content;" (dblclick)="reset(type)">
                      <input type="radio" [formControlName]="type" [name]="type" [value]="0" class="custom-check" />
                      <i class="customInput-display"></i>
                      <span>
                        No
                      </span>
                    </label>

                    <section [ngClass]="getClass(type)"
                      style="display: flex;width: 40%;justify-content: end;padding-inline-end: 1em;">
                      <span>
                        Comments
                      </span>
                      <span class="anatomy-show-more" style="bottom:1em;right:6em">
                        <div class="on-hoverPanel">
                          <div class="on-hoverPanel-body">
                            <div class="anatomy-item-details">
                              <h3 class="anatomy-item-details-title">
                                {{getTitle(type)}}
                              </h3>

                              <div class="anatomy-items-desc">
                                <div class="anatomy-items-edited scroller mCustomScrollbar _mCS_2 mCS_no_scrollbar"
                                  contenteditable="true">
                                  <div id="mCSB_2" class="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside"
                                    tabindex="0" style="max-height: none;">
                                    <div id="mCSB_2_container" class="mCSB_container mCS_y_hidden mCS_no_scrollbar_y"
                                      style="position:relative; top:0; left:0;" dir="ltr"> </div>
                                    <div id="mCSB_2_scrollbar_vertical"
                                      class="mCSB_scrollTools mCSB_2_scrollbar mCS-light mCSB_scrollTools_vertical"
                                      style="display: none;">
                                      <div class="mCSB_draggerContainer">
                                        <div id="mCSB_2_dragger_vertical" class="mCSB_dragger"
                                          style="position: absolute; min-height: 30px; top: 0px; display: block; height: 12px; max-height: 106.938px;">
                                          <div class="mCSB_dragger_bar" style="line-height: 30px;">
                                          </div>
                                        </div>
                                        <div class="mCSB_draggerRail">
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <textarea class="anatomy-items-edit" [name]="type+'Comment'"
                                  [formControlName]="type+'Comment'">
                              </textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                      </span>
                    </section>
                  </div>
                </section>
              </section>
            </ng-container>
          </div>

          <div style="display: flex;">
            <ng-container *ngFor="let type of inputTypes; let i = index">

              <section *ngIf="1 < i" class="w50" style="margin: 0 1em 0 0;">
                <section class="m-box"
                  [ngStyle]="{'margin': '0.87em 0 0', 'padding': '1.3em 0 0', 'margin-top': '1em'}">
                  <h2 style="cursor: pointer;">
                    {{getTitle(type)}}
                  </h2>
                  <div class="l-flex-c l-flex-w indicative-fieldset h100"
                    style="justify-content: space-between; z-index: auto;">
                    <section style="display: flex; min-width: 40%; min-height: 4.2em;">
                      <label class="t-label-top" (dblclick)="reset(type)">
                        <input type="radio" [formControlName]="type" [name]="type" [value]="1" class="custom-check" />
                        <i class="customInput-display"></i>
                        <span>
                          Yes
                        </span>
                      </label>

                      <ng-container *ngIf="anamnesisForm.get(type).value === 1">
                        <mat-form-field style="width: 100%;">
                          <input type="text" style="direction: rtl;text-align-last: right;text-align: right;" matInput
                            [formControlName]="type+'Text'" [matAutocomplete]="auto">
                          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                            <mat-option style="text-align: right;" *ngIf="!checkIn(type)"
                              [value]="anamnesisForm.get(type+'Text').value"
                              (onSelectionChange)="addAndSave(anamnesisForm.get(type+'Text').value, type)">
                              {{anamnesisForm.get(type+'Text').value}}
                            </mat-option>
                            <div *ngFor="let option of filteredOptions" style="display: flex;">
                              <mat-option style="text-align: right;width:100%" [value]="option">
                                {{option}}
                              </mat-option>
                              <button class="auto-text-delete-button" (click)="delete(option, type)"
                                style="height: auto;">
                                delete
                              </button>
                            </div>
                          </mat-autocomplete>
                        </mat-form-field>
                      </ng-container>
                    </section>

                    <label class="t-label-top" style="width: fit-content;" (dblclick)="reset(type)">
                      <input type="radio" [formControlName]="type" [name]="type" [value]="0" class="custom-check" />
                      <i class="customInput-display"></i>
                      <span>
                        No
                      </span>
                    </label>

                    <section [ngClass]="getClass(type)"
                      style="display: flex;width: 40%;justify-content: end;padding-inline-end: 1em;">
                      <span>
                        Comments
                      </span>
                      <span class="anatomy-show-more" style="bottom:1em;right:6em">
                        <div class="on-hoverPanel">
                          <div class="on-hoverPanel-body">
                            <div class="anatomy-item-details">
                              <h3 class="anatomy-item-details-title">
                                {{getTitle(type)}}
                              </h3>

                              <div class="anatomy-items-desc">
                                <div class="anatomy-items-edited scroller mCustomScrollbar _mCS_2 mCS_no_scrollbar"
                                  contenteditable="true">
                                  <div id="mCSB_2" class="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside"
                                    tabindex="0" style="max-height: none;">
                                    <div id="mCSB_2_container" class="mCSB_container mCS_y_hidden mCS_no_scrollbar_y"
                                      style="position:relative; top:0; left:0;" dir="ltr"> </div>
                                    <div id="mCSB_2_scrollbar_vertical"
                                      class="mCSB_scrollTools mCSB_2_scrollbar mCS-light mCSB_scrollTools_vertical"
                                      style="display: none;">
                                      <div class="mCSB_draggerContainer">
                                        <div id="mCSB_2_dragger_vertical" class="mCSB_dragger"
                                          style="position: absolute; min-height: 30px; top: 0px; display: block; height: 12px; max-height: 106.938px;">
                                          <div class="mCSB_dragger_bar" style="line-height: 30px;">
                                          </div>
                                        </div>
                                        <div class="mCSB_draggerRail">
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <textarea class="anatomy-items-edit" [name]="type+'Comment'"
                                  [formControlName]="type+'Comment'">
                              </textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                      </span>
                    </section>
                  </div>

                </section>

              </section>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <!-- ====== Physical Examination (Start) ====== -->
    <div class="m-box w100 summary-anamnesis" style="padding: 1.31em 1.43em; margin-top: 2em; margin-left: 1em;">
      <h2>
        Physical Examination
      </h2>
      <textarea class="scroller scroller-intro fulltext" formControlName="PhysicalExamination"
        style="min-height: 10em;"></textarea>
      <button class="t-btn-edit" *ngIf="!examsService.isClosed"
        (click)="autoTextService.showAuto(anamnesisForm.get('PhysicalExamination'),'Physical Examination',2)">
        Auto Text
      </button>
    </div>
    <!-- ====== Physical Examination (End) ====== -->

  </div>
</ng-container>