import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import {PatientsService} from '../patients.service';
import {ExamsService} from '../exams.service';
import { LoginService } from '../login.service';
import { Subject, Subscription } from "rxjs";

@Component({
  selector: 'app-send-link',
  templateUrl: './send-link.component.html',
  styleUrls: ['./send-link.component.scss']
})
export class SendLinkComponent implements OnInit {
  @Output() closeFromLinks: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input('exam') exam;
  @Input('isForm') isForm;
  @Input('concent') concent;

  @Output() closed = new EventEmitter<boolean>();

  phone: string;
  error='';
  sending=false;
  sent = false;

  consentSavedSub: Subscription;
  enableSend: boolean = false;

  constructor(private pat: PatientsService, private examS: ExamsService, public ls:LoginService) { }

  ngOnInit() {
    let mobile = this.pat.getPat().Mobile;
    let p2 = this.pat.getPat().Phone;
    if(mobile)
      this.phone = mobile;
    else if(p2)
      this.phone=p2;

    this.consentSavedSub = this.examS.consentSaved$.subscribe(data => {
      this.enableSend = true;
    })
    
  }

  close(){
    this.closed.emit(false);
  }

  closeConsentFromLinks() {
    this.closeFromLinks.emit(true); // Set to 'true' to indicate closing the InformconcentComponent
  }

  sendLink(){
    this.sending = true;
    if(this.phone)
    this.phone = this.phone.replace(/[^0-9-]/gm,'');
    if(this.isForm){
      // this.closeConsentFromLinks();
      this.examS.sendLinkConcent(this.phone, this.concent).subscribe((data)=>{
        if(data["status"] != "ok"){
          this.error = data["status"];
          this.sending=false;
        }
        else{
          this.sent= true;
          setTimeout(()=>{
            this.closed.emit(true);
          },2000);
          
        }
      },(error)=>{
        this.error = error.error;
        this.sending=false;
      })
    }else{

      this.examS.sendLink(this.phone,).subscribe((data)=>{
        if(data["status"] != "ok"){
          this.error = data["status"];
          this.sending=false;
        }
        else{
          this.sent= true;
          this.ls.linksent+1;
          setTimeout(()=>{
            this.closed.emit();
          },2000);
          
        }
      },(error)=>{
        this.error = error;
        this.sending=false;
      })
    }
  }

  ngOnDestroy(): void {
    if(this.consentSavedSub){
      this.consentSavedSub.unsubscribe();
    } 
  }
}
