import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  Input,
} from "@angular/core";
import { Patient } from "../patients/patient";
import { PatientsService } from "../patients.service";
import { ExamsService } from "../exams.service";
import { LoginService } from "../login.service";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CommerrorService } from "../commerror.service";
import { PregnancyService } from "../pregnancy.service";
import { GastroFormsService } from "../gastro-forms.service";
import { DiagnosisService } from "../diagnosis.service";
import { CalendarService } from "../calendar.service";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { HttpClient } from "@angular/common/http";
import { DatePipe } from '@angular/common';
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "../language.service";

export interface PrintHistoryTable {
  date: string;
  position: number;
  time: string;
  comments: string;
}

@Component({
  selector: "app-patexams",
  templateUrl: "./patexams.component.html",
  styleUrls: ["./patexams.component.scss"],
})
export class PatexamsComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ["position", "date", "time", "comments"];
  clickedRows = new Set<PrintHistoryTable>();

  @ViewChild("inputTitle")
  set inputTitle(element: ElementRef<HTMLInputElement>) {
    if (element) {
      element.nativeElement.focus();
    }
  }
  inform = false;
  showComment = false;
  selectedForm;
  calendarForm: boolean = false;
  pat: Patient;
  showPat: boolean = false;
  pregnancies: any = null;
  etype: string = "OB STD";
  typeForm = false;
  examname: string;
  concentForm;
  otherExams: any;
  formsExams: any[] = [];
  AttachedDocuments: any[] = [];
  AttachedAdminDocuments: any[] = [];
  calendarForms: any[] = [];
  notFormExams: any[] = [];
  gynConsents: any[] = [];
  unlinkedConsents: any[] = [];
  ecgExams: any[] = [];
  gastroForms: any[] = [];
  gastroVisits: any[] = [];
  gastroExams: any[] = [];
  letters: any;
  showLogin = false;
  AWLAlreadyExists = false;
  private destroy$ = new Subject();
  public examsLoaded$ = new Subject();
  toDelete;
  readydelete = false;
  goEx;
  newExam = true;
  closePreg: number = null;
  pregExams;
  defaults;
  startClinic: number;
  openAWL = false;
  subscription = new Subscription();
  continueExamSub = new Subscription();
  FORMS = ["Consultation Letter", "Inform Consent"];
  ECG = ["Resting ECG", "Ergometry", "Holter ECG"];
  CARDIO = ["TTE Adults", "Duplex Carotid", "Stress", "Stress-Echo"];
  GASTRO = ["Colonoscopy", "Gastroscopy", "Sigmoidoscopy"];
  INVASIVE = ["Amniocentesis", "CVS"];
  showDiagnosisComments = false;
  GastroForms = ["Admission Form", "Time Out Form"];
  gastroExamsChanged = new Subject();
  fanciness = "simple";
  show_surrogate = false;
  showDiagnosisIcon = false;
  sanitizedSummary: SafeHtml;
  showPrintHistoryPopup = false;
  loadingPrint = false;
  examId: number;
  printHistoryData: any;
  plannerToDelete: boolean = false;
  signatureSubscription: Subscription;
  loginSub: Subscription;
  subDataReceived: Subscription;
  openDocumentsAdd=false
  documentDelete=false

  // Consent Form:
  clinicPractitioners = [];
  shouldOpenExam = false;

  constructor(
    public ce: CommerrorService,
    public gfs: GastroFormsService,
    public patService: PatientsService,
    public ps: PregnancyService,
    public examServe: ExamsService,
    public login: LoginService,
    public ds: DiagnosisService,
    public cf: CalendarService,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private datePipe: DatePipe,
    private translate: TranslateService,
    private languageService: LanguageService,
  ) {
    this.login.loginMissing();
  }

  async ngOnInit() {
    if (
      !this.login.ishospital &&
      this.login.premissions?.Consent &&
      this.login.userType == "secretary"
    ) {
      this.examServe.getAllPractitioners().subscribe((data: any) => {
        this.clinicPractitioners = data;
        if (this.clinicPractitioners.length < 2) {
          const selectedPractitioner = this.clinicPractitioners[0];
          this.examServe.consentPractitioner =
            selectedPractitioner?.Title + " " + selectedPractitioner?.Name;
          this.setConsentDocUID();
        }
      });
    }

    try {
      this.pat = this.patService.getPat();
      //this.pat.asignVals(history.state.data);
    } catch {}
    if (this.login.isCardio) this.etype = "Echo";
    if (this.login.isGastro) this.etype = "Gastro";
    if (this.login.category.name == "forms") this.etype = "Forms";
    if (this.pat?.ID != "default") {
      this.resetPregs();
    } else {
      //defaults
      this.examServe.getDefaults().subscribe(
        (data) => {
          this.defaults = [];
          for (let i = 0; i < data["length"]; i++)
            this.defaults[data[i]["ExamType"]] = data[i];
        },
        (error) => {
          console.error(error);
        }
      );
    }
    this.subscription = this.patService.lettersChange.subscribe((response) => {
      this.letters = response;
    });
    this.subscription = this.cf.caldeleted$.subscribe((data) => {
      this.resetPregs();
    });

    if (!this.login.isCardio && this.pat?.ID === "default") {
      this.examServe.getCustomTitles();
    }

    this.subDataReceived = this.examServe.dataReceived$.subscribe(() => {
      this.resetPregs();
    });

    if (this.login.premissions?.Consent) {
      this.login.countConcent();
    }

    this.continueExamSub = this.examServe.continueExam$.subscribe(
      (shouldContinue: boolean = false) => {
        if (shouldContinue) {
          this.startExam(true);
        }
  }

    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subDataReceived) {
      this.subDataReceived.unsubscribe();
    }
    if (this.loginSub) {
      this.loginSub.unsubscribe();
    }
    if (this.signatureSubscription) {
      this.signatureSubscription.unsubscribe();
      this.patService.closeConsentSocket();
    }

    if(this.continueExamSub) {
      this.continueExamSub.unsubscribe()
    }
  }

  resetPregs() {
    this.examServe
      .getExams(this.pat?.PID.toString())
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          this.AttachedDocuments=data['Documents']
          this.AttachedAdminDocuments=data['AdminDocuments']
          this.pregnancies = data["pregnancies"];
          this.examServe.pregnancies = data["pregnancies"];
          for (let item of this.pregnancies) {
            this.examServe
              .getAllCalForms(item.AutoIndex)
              .pipe(takeUntil(this.destroy$))
              .subscribe((data) => {
                if (data[0].calendar.length > 0)
                  item.calendarForms.push(data[0].calendar[0]);
                if (data[0].concent.length > 0) item.forms = data[0].concent;
                // for(let concentForm of data[0].concent)
                // item.forms.push(concentForm)
                if (!this.login.ishospital && this.login.premissions?.Consent) {
                  if (!this.signatureSubscription) {
                    //to prevent resubsribing after socket event
                    for (let form of item.forms) {
                      if (!this.checkApprovedConcent(form)) {
                        this.patService.openConsentSocket();
                        this.signatureSubscription =
                          this.patService.signatureIncoming.subscribe((s) => {
                            this.resetPregs();
                          });
                        break;
                      }
                    }
                  }
                }
              });
            // this.examServe.getConcentForms(item.AutoIndex).subscribe((data) => {
            //   if(data[0])
            //     item.forms.push(data[0])
            // })
          }

          if (
            this.pregnancies &&
            this.pregnancies.length > 0 &&
            this.pregnancies[0].LMP
          ) {
            this.pat.LMP = this.convertTimeZone(this.pregnancies[0].LMP);
          }
          // this.letters = this.patService.getConsultationLetter(this.pat);
          this.otherExams = data["other"];
          // this.gynConsents = data["otherConsents"];
          this.unlinkedConsents = data["unlinkedConsents"];
          if (this.otherExams.length > 0) {
            this.otherExams.forEach((exam) => {
              if (this.FORMS.includes(exam.ExamType)) {
                this.formsExams.push(exam);
              } else {
                if (this.ECG.includes(exam.ExamType)) this.ecgExams.push(exam);
                else if (this.GASTRO.includes(exam.ExamType)) {
                  this.gastroExams.push(exam);
                } else this.notFormExams.push(exam);
              }
            });
          }
          if (!this.login.ishospital && this.login.premissions?.Consent) {
            if (!this.signatureSubscription) {
              //to prevent resubsribing after socket event
              if (this.unlinkedConsents.length > 0) {
                for (let form in this.unlinkedConsents) {
                  if (!this.checkApprovedConcent(form)) {
                    this.patService.openConsentSocket();
                    this.signatureSubscription =
                      this.patService.signatureIncoming.subscribe((s) => {
                        this.resetPregs();
                      });
                    break;
                  }
                }
              }
            }
          }
          if (this.login.isGastro) {
            this.examsLoaded$.next();
            this.ds.BuildList;
          }
          if (this.pat.AWLID) {
            if (!this.login.isGastro) {
              for (let i = 0; i < this.pregnancies.length; i++) {
                for (let e of this.pregnancies[i]["exams"]) {
                  if (e.AWLID == this.pat.AWLID) {
                    this.AWLAlreadyExists = true;
                    return;
                  }
                }
              }
            }
            for (let e of this.otherExams) {
              if (e.AWLID == this.pat.AWLID) {
                this.AWLAlreadyExists = true;
                return;
              }
            }
          } else {
            if (this.login.isCardio) {
              this.patService.getPatAWL();
            }
          }
        },
        (error) => {
          //add this later
          console.error(error);
        }
      );

    this.subscription = this.examsLoaded$.subscribe(() => {
      var v = this.gfs.getVisits(this.pat.PID);
      v.subscribe((data: any) => {
        this.gastroVisits = data;
        this.gastroVisits.forEach((visit, i) => {
          this.gastroVisits[i]["exams"] = this.gastroExams.filter((exam) => {
            return (
              exam.CreationTime.split("T")[0] == visit.ExamDate.split("T")[0]
            );
          });
          var f = this.gfs.getFormByVisit(visit.AutoIndex);
          f.subscribe((data) => {
            this.gastroVisits[i]["forms"] = data;
          });
        });
        this.gfs.loadForm(false, true, this.gastroVisits[0]["AWLID"]);
      });
    });
    if (!this.loginSub) {
      this.loginSub = this.login.onLogin
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          /*if(this.login.ClinicID && this.login.ClinicID != this.startClinic){
        //trying to switch clinics on opening exams
        this.showLogin=false;
        this.cErr.Error = "Clinic Not Autorized on this patient";
        this.cErr.ErrorType = "Clinic Switched on Login";
        return;
      }*/
          if (this.goEx) {
            this.examServe.goExam(
              this.goEx.preg,
              this.goEx.exam,
              this.goEx.etype
            );
          } else {
            if (
              this.etype == "GastroForms" ||
              this.examname == "Inform Consent" ||
              this.concentForm
            ) {
              this.showLogin = false;

              if (this.etype == "GastroForms") {
                this.typeForm = true;
                this.inform = false;
                var awl = this.pat.AWLID
                  ? this.pat.AWLID.toString()
                  : this.gastroVisits[0].AutoIndex.toString();
                this.examsLoaded$.next();
              }
              if (this.examname == "Inform Consent" || this.concentForm)
                this.inform = true;
            } else {
              if (
                this.pregnancies.length > 0 &&
                this.pregnancies[0].forms.filter(
                  (i) => i.ExamType == this.examname && !i.ExamID
                ).length == 0 &&
                !this.login.ishospital &&
                this.login.premissions.Consent &&
                this.examname !== "Consultation Letter" &&
                this.examname !== "Gynecology" &&
                this.examname !== "Pediatric Echo"
              ) {
                this.inform = true;
                this.showLogin = false;
                this.examServe.openNewExam = true;
              } else if (
                this.pregnancies.length == 0 &&
                this.examname !== "Consultation Letter" &&
                this.examname !== "Gynecology" &&
                this.examname !== "Pediatric Echo" &&
                !this.login.ishospital &&
                this.login.premissions.Consent &&
                (!this.unlinkedConsents.length ||
                  this.unlinkedConsents.filter(
                    (i) => i.ExamType == this.examname && !i.ExamID
                  ).length == 0)
              ) {
                this.inform = true;
                this.showLogin = false;
                this.examServe.openNewExam = true;
              } else {
                if (this.pregnancies.length > 0) {
                  this.examServe.concentForm = this.pregnancies[0].forms.filter(
                    (i) => i.ExamType == this.examname && !i.ExamID
                  )[0];
                }

                this.examServe.startExam(
                  this.examname,
                  this.pat.PID,
                  this.pat.ID,
                  this.pat.AWLID,
                  this.pat.ExamLMP
                );
              }
            }
          }
        });
    }
  }

  viewonly() {
    this.examServe.goExam(
      this.goEx.preg,
      this.goEx.exam,
      this.goEx.etype,
      true
    );
  }

  startForm(form = null) {
    if (this.examname == "Inform Consent" || form) {
      this.examServe.examID = null;
      if (form && form.CreationTime) {
        form.CreationTime=this.convertTimeZone(form.CreationTime)
      }
      this.concentForm = form;
      this.examServe.openNewExam = false;
      this.examServe.loadPrevDocSign = true;
      this.inform = true;
    } else {
      this.concentForm = null;
      this.inform = false;
      var today = new Date().toISOString().split("T")[0];
      var checkAwl = true;
      if (this.gastroVisits.length > 0) {
        checkAwl =
          new Date(this.gastroVisits[0].ExamDate.split("T")[0])
            .toISOString()
            .split("T")[0] !== today;
      }
      this.showLogin = true;
      return;
    }
  }

  goForm(form) {
    this.typeForm = true;
    var isTimeOut = form["FormName"] == "Time Out Form";
    this.gfs.loadForm(isTimeOut, form);
  }

  async startExam(shouldContinue: boolean = false) {
    if (this.examServe.awaitPrintHistory && this.pat.ID !== "default") {
      return;
    }
    if (!shouldContinue && this.examname !== "Consultation Letter" && this.pat.ID !== "default") {
      this.examServe.getAllTodaysExams(this.examname).subscribe((data: any) => {
        let alreadyHasExam: boolean = data;

        if (alreadyHasExam && !shouldContinue) {
          this.showLogin = false;
          let examName;
          if (this.languageService.isOB(this.examname)) {
            examName = this.translate.instant('GUI.Patient History.ExamTypes.OB.'+this.examname);
          } else {
            examName = this.translate.instant('GUI.Patient History.ExamTypes.GYN.'+this.examname);
          }          
          return this.ce.hasExam(`${examName} - ${this.translate.instant("GUI.errorMessages.examExists.title")}`, false);
        }
        this.continueExam();
      });
    } else {
      this.continueExam();
    }
  }

  continueExam() {
    var today = new Date().toISOString().split("T")[0];
    this.examServe.examID = null;
    var checkAwl = true;
    if (this.gastroVisits.length > 0) {
      checkAwl =
        new Date(this.gastroVisits[0].ExamDate.split("T")[0])
          .toISOString()
          .split("T")[0] !== today;
    }

    if (!this.etype || !this.examname) {
      return;
    }

    if (this.pat.ID !== "default") {
      this.concentForm = null;
      this.newExam = true;
      this.showLogin = true;
      return;
    } else {
      if (this.defaults[this.examname]) {
        this.examServe.goExam(
          null,
          this.defaults[this.examname],
          this.examname
        );
      } else {
        this.examServe.startExam(this.examname, 0, "default");
      }
    }
  }

  canOpen(): boolean {
    return (
      this.login.canStartExam(this.pat.AWLID != null) &&
      (!this.AWLAlreadyExists || this.login.AWLUnlimited) &&
      (!this.login.separatedClinic ||
        this.login.isAdmin ||
        !this.patService.getPat().UID ||
        this.patService.getPat().UID == this.login.UID ||
        this.patService.getPat()["AWLUID"] == this.login.UID)
    );
  }

  goExam(pregid: number, exam, examtype: string, examtime: string) {
    if (
      (exam.Locked &&
        exam.LockedBy.toLowerCase() != this.login.loginname.toLowerCase()) ||
      !this.canView(exam)
    )
      return;
    let etime = new Date(examtime);
    var closed;
    if (exam.ClosedDate) closed = new Date(exam.ClosedDate);
    let now = new Date();
    if (
      examtype != "Emergency Room" &&
      ((!this.examServe.examClosed(etime) && !this.login.isCardio) ||
        (this.login.isCardio &&
          (!exam.ClosedDate || this.examServe.examClosed(closed)))) &&
      (this.login.userType == "doctor" ||
        this.login.userType == "visitingdoc" ||
        this.login.userType == "tech")
    ) {
      this.newExam = false;
      this.showLogin = true;
      this.goEx = { preg: pregid, exam: exam, etype: examtype };
      if (this.login.ClinicID) this.startClinic = this.login.ClinicID;
    } else this.examServe.goExam(pregid, exam, examtype);
  }

  canView(exam) {
    return (
      !this.login.separatedClinic ||
      this.login.isAdmin ||
      this.login.userType == "secretary" ||
      exam.UID == this.login.doctor
    );
  }

  askDelete(
    exam,
    concent = false,
    gynConsent = false,
    unlinkedConsents = false,
    calendarPlanner: boolean = false,
    document=false
  ) {
    this.plannerToDelete = false;
    let etime = new Date(exam.CreationTime);
    if(document){
      this.documentDelete=true
      this.toDelete=exam /**document */
    }
    else if (calendarPlanner) {
      this.plannerToDelete = true;
      this.toDelete = exam;

    } else if (this.defaults || !this.examServe.examClosed(etime)) {
      this.plannerToDelete = false;
      //if the exam is from today allow delete
      this.toDelete = {
        ...exam,
        concent,
        gynConsent,
        unlinkedConsents,
        calendarPlanner,
      };
    }
    return false;
  }

  deleteExam() {
    this.pat.AWLID = this.toDelete.AWLID;

    this.examServe.deleteExam(
      this.toDelete.AutoIndex,
      this.toDelete.AWLID,
      this.toDelete.concent ||
        this.toDelete.gynConsent ||
        this.toDelete.unlinkedConsents
        ? "concent"
        : null
    );

    if (!this.defaults) {
      for (let i = 0; i < this.pregnancies.length; i++) {
        for (let j = 0; j < this.pregnancies[i]["exams"]?.length; j++) {
          if (
            this.pregnancies[i]["exams"][j].AutoIndex == this.toDelete.AutoIndex
          ) {
            this.pregnancies[i]["exams"].splice(j, 1);
            break;
          }
        }
      }
      for (let i = 0; i < this.otherExams.length; i++) {
        if (this.otherExams[i].AutoIndex == this.toDelete.AutoIndex) {
          this.otherExams.splice(i, 1);
          this.notFormExams = this.otherExams;
          break;
        }
      }

      if (this.toDelete.concent) {
        for (let i = 0; i < this.pregnancies.length; i++) {
          for (let j = 0; j < this.pregnancies[i]["forms"].length; j++) {
            if (
              this.pregnancies[i]["forms"][j].AutoIndex ==
              this.toDelete.AutoIndex
            ) {
              this.pregnancies[i]["forms"].splice(j, 1);
              break;
            }
          }
        }
      }

      if (this.toDelete.gynConsent) {
        for (let i = 0; i < this.gynConsents.length; i++) {
          if (this.gynConsents[i].AutoIndex == this.toDelete.AutoIndex) {
            this.gynConsents.splice(i, 1);
            break;
          }
        }
      }
      if (this.toDelete.unlinkedConsents) {
        for (let i = 0; i < this.unlinkedConsents.length; i++) {
          if (this.unlinkedConsents[i].AutoIndex == this.toDelete.AutoIndex) {
            this.unlinkedConsents.splice(i, 1);
            break;
          }
        }
      }
    } else {
      this.examServe.getDefaults().subscribe(
        (data) => {
          this.defaults = [];
          for (let i = 0; i < data["length"]; i++)
            this.defaults[data[i]["ExamType"]] = data[i];
        },
        (error) => {
          console.error(error);
        }
      );
    }
    this.toDelete = null;
    this.readydelete = false;
  }

  showClose(pregid: number) {}

  closCalForm(event) {
    if (event == "1") this.examServe.concentForm = null;
    if (
      event == "2" ||
      (this.examServe.openNewExam &&
        this.login.userType !== "secretary" &&
        this.etype !== "Forms" &&
        this.shouldOpenExam)
    ) {
      this.examServe.startExam(
        this.examname,
        this.pat.PID,
        this.pat.ID,
        this.pat.AWLID,
        this.pat.ExamLMP,
        this.examServe.concentForm
      );
    }
    this.examServe.openNewExam = false;
    this.calendarForm = false;
    this.inform = false;
    if (this.pat.ID != "default") {
      this.resetPregs();
    } else {
      //defaults
      this.examServe.getDefaults().subscribe(
        (data) => {
          this.defaults = [];
          for (let i = 0; i < data["length"]; i++)
            this.defaults[data[i]["ExamType"]] = data[i];
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  deleteDefault(ex: string) {
    if (this.defaults[ex]) {
      this.askDelete(this.defaults[ex]);
    }
  }

  getDiagnosis(exam, show = false) {
    let strDiagnosis = "";
    if (exam && exam.DiagnosisCodes) {
      var temp = this.ds.BuildList(exam.DiagnosisCodes);
      if (temp.length > 0) {
        strDiagnosis = "";
        for (let i = 0; i < temp.length; i++) {
          strDiagnosis +=
            temp[i][1].split(">")[temp[i][1].split(">").length - 1];
          if (temp.length > 1 && i < temp.length - 1) strDiagnosis += ",";
        }
      }
    } else if (exam && exam.Anomalies && exam.Anomalies != "")
      strDiagnosis = exam.Anomalies;
    if (show && exam["Anomalies"] && exam.Anomalies.length > 15)
      // return 'Read More'
      return exam.Anomalies.split(" ")[0] + "...";
    else return strDiagnosis;
  }

  // return the diagnosis
  getDiagnosisTree(exam) {
    this.getExamSummary(exam);
    let strDiagnosis = "";
    if (exam && exam.DiagnosisCodes) {
      var temp = this.ds.BuildListPatientHistory(
        exam.DiagnosisCodes,
        true,
        exam.ExamType
      );
      if (temp.length > 0) {
        strDiagnosis = "";
        for (let i = 0; i < temp.length; i++) {
          if (this.login.isSheba) {
            strDiagnosis += temp[i][1] + ",";
          } else {
            strDiagnosis +=
              temp[i][1].split(">")[temp[i][1].split(">").length - 1];
            if (temp.length > 1 && i < temp.length - 1) strDiagnosis += ",\n";
          }
        }
      }
    }
    return strDiagnosis;
  }

  // return summary of exam
  getExamSummary(exam) {
    return exam.Diagnosis;
  }

  //  return the summary recommendations
  getExamRecommendation(exam) {
    return exam.Recomm;
  }

  // change diagnosis hover content order
  switchMainDiagnosisContent(exam) {
    this.showDiagnosisComments = false;
    const diagnosesTree = this.getDiagnosisTree(exam);
    const diagnosisComments = this.displayDiagnosisComments(exam);
    if (diagnosisComments || diagnosesTree) {
      this.showDiagnosisComments = true;
      return diagnosesTree;
    }

    let summaryOfExam = this.getExamSummary(exam);
    if (summaryOfExam) {
      this.showDiagnosisComments = false;
      this.sanitizedSummary = this.sanitizer.sanitize(1, summaryOfExam);

      return this.sanitizedSummary;
    }
    const recommendations = this.getExamRecommendation(exam);
    if (recommendations) {
      this.showDiagnosisComments = false;
      return recommendations;
    }
    return null;
  }

  displayDiagnosisIcon(exam) {
    if (exam.DiagnosisCodes || exam.Anomalies) {
      return (this.showDiagnosisIcon = true);
    }
    this.showDiagnosisIcon = false;
  }

  displayDiagnosisComments(exam) {
    let strDiagnosis = "";
    if (exam && exam.Anomalies) {
      strDiagnosis = exam.Anomalies;
    }
    return strDiagnosis;
  }

  loadCalendarForm(form) {
    this.calendarForm = true;
    this.selectedForm = form;
  }

  getConcent(exam) {
    var temp = this.examServe.pregnancies.filter(
      (i) => i.AutoIndex == exam.PregIndex
    );
    if (temp.length > 0) {
      temp = temp[0].forms.filter((i) => i.ExamID == exam.AutoIndex);
    }
    if (temp.length > 0) return temp[0];
    else return false;
  }

  pregClosed() {
    for (let p of this.pregnancies) {
      if (p.AutoIndex == this.closePreg) p.ClosedDate = new Date();
    }
    this.closePreg = null;
  }

  // This function toggles the print history popup.
  togglePrintHistoryPopup() {
    if (this.examServe.awaitPrintHistory) {
      return;
    }
    this.showPrintHistoryPopup = !this.showPrintHistoryPopup;
  }

  // This function retrieves a list of print histories for a given exam.
  getPrintHistoryList(exam) {
    if (this.examServe.awaitPrintHistory) {
      return;
    }
    this.loadingPrint = true;
    if (!exam) {
      return;
    }
    this.examId = exam.AutoIndex;

    this.http
    .get(`api/print-history/list/${this.examId.toString()}`)
    .subscribe((data) => {
      const sortedData = Object.values(data);
      sortedData.sort((a, b) => new Date(a.creationDate).getTime() - new Date(b.creationDate).getTime());

      // Convert creationDate to UTC for each element
      sortedData.forEach(element => {
        element.creationTime = this.formatDateToUTC(element.creationDate);
        element.creationDate = element.creationDate;
      });

      this.printHistoryData = sortedData;
      this.loadingPrint = false;
    });
  }

  formatDateToUTC(dateString: string): string {
    const date = new Date(dateString);
    // Convert local date to UTC
    const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
    // Format date to string using the desired format and timezone ('Asia/Jerusalem' in this case)
    return this.datePipe.transform(utcDate, 'HH:mm', 'Asia/Jerusalem');
  }
  
  // This function is used to print a PDF file from a selected row.
  printSelectPDF(selectRow) {
    this.loadingPrint = true;
    this.http
      .get(
        `/api/print-history/print/${this.examId.toString()}/${
          selectRow.fileName
        }`,
        { responseType: "blob" }
      )
      .subscribe((data) => {
        const file = new Blob([data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        this.loadingPrint = false;
      });
  }

  setConsentDocUID() {
    let selectedDoc: any;
    if (this.login.userType === "doctor") {
      selectedDoc = this.login;
    } else {
      selectedDoc = this.clinicPractitioners.find((user) => {
        return (
          user?.Title + " " + user?.Name === this.examServe.consentPractitioner
        );
      });
    }
    this.examServe.consentDocUID = selectedDoc?.UID;
  }

  checkApprovedConcent(consent: any) {
    let valid = false;
    if (
      consent.approved &&
      consent.approved !== "appotropus" &&
      consent.patSign &&
      consent.patSignApproved &&
      consent.docSign
    ) {
      valid = true;
    } else if (
      consent.approved &&
      consent.approved === "appotropus" &&
      consent.patSign &&
      consent.docSign
    ) {
      valid = true;
    }
    return valid;
  }

  convertTimeZone(dateToFormat){
   if (!dateToFormat) return;
   const utcTimestamp = dateToFormat;
   const dateInUTC = new Date(utcTimestamp);

   // Set the time zone to Israel (Asia/Jerusalem)
   const israelTimeZone = 'Asia/Jerusalem';
   const options = { timeZone: israelTimeZone, hour12: false };

   // Format the date according to the Israel time zone
   return this.examServe.parseConsentDate(new Date(dateInUTC.toLocaleString('en-US', options)) )
  }


  
  
  redirectAdministration(type) {
    this.http.post('/api/administration', {type}).subscribe((data: string) => {
        window.location.href =data +'/'+this.patService.currentPatient.PID;

      
     
    })
  }


  openDocument(docId){
  window.open(`api/patient/getDocument/${docId}`)
  }


  deleteDocument(){
    console.log(this.toDelete,'to delete')
    if(!this.toDelete ||  !this.toDelete.AutoIndex) return
    this.http.delete(`api/patient/document/${this.toDelete.AutoIndex}`).subscribe(()=>{
      this.resetPregs()
      this.toDelete=null
      this.documentDelete=false
    })
  }

}
