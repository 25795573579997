<div style="position: absolute;  z-index:100;">
    
    <video playsinline autoplay [srcObject]="Stream" ></video>
   
    <div style="position: absolute;margin-top: -5em; z-index: 200; color: white;direction: ltr;" >
        <span style="font-size: 22pt;">{{timer}}</span>
             <span *ngIf="! vs.recording">
                    <div><select [(ngModel)]="videoSource" (change)="startVideo()">
                        <ng-container *ngFor="let item of videoInputs">
                            <option [value]="item.value">{{item.text}}</option>
                        </ng-container>
                    </select>
                </div>
                <select [(ngModel)]="audioSource" (change)="startVideo()">
                    <ng-container *ngFor="let a of audioInputs">
                        <option [value]="a.value">{{a.text}}</option>
                    </ng-container>
                </select> 
                Sound Test <input type="checkbox" [(ngModel)]="soundtest" [value]="true" (change)="startVideo()">
                <br>
                <ng-container *ngIf="false">
                Max Width <input type="number" [(ngModel)]="maxWidth" max="1920" min="640" (change)="startVideo()">
                Frame Rate <input type="number" [(ngModel)]="frameRate" min="20" max="60" (change)="startVideo()">
                Bit Rate (kb/s) <input type="number" [(ngModel)]="bitRate" (change)="setBitRate()" min='150' max='5000'>
                </ng-container>
                
                

            </span>
            <span style="color: red; display: none" *ngIf="false">No Video Device found!</span>
        </div>
   <button (click)="updatedetails()">Update stream details</button>
   <p style='white-space: pre;'>{{streamdetails}}</p>
</div>