import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup } from '@angular/forms';
import { ExamsService } from './exams.service';
import { GastroService } from './gastro.service';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class MedicationService {
  Medication:UntypedFormGroup;
  patMedication:any;

  constructor(private es:ExamsService, private fb:UntypedFormBuilder, private http:HttpClient, private gs:GastroService, public ls:LoginService) { 
    this.resetForm();
    this.es.examLoaded$.subscribe(() => {
      if(this.es.ExamType == 'Colonoscopy' || this.es.ExamType =='Gastroscopy' || this.es.ExamType =='Sigmoidoscopy'){
        this.getAllMedication();
      }
      this.patMedication = [];
    })
    // this.es.fillForm(this.Medication, "Medication",false, true)
  }

  resetForm(){
    this.Medication = this.fb.group({
      AutoIndex: [''],
      Medication: [''],
      Unit: [''],
      Dose: [''],
      Comments: [''],
    })
  }

  getAllMedication(examid =this.es.examID){
    if(examid)
      this.http.get(`/api/gastro/getMedication/${examid}`).subscribe((data) => {
        this.patMedication =data;
      })
    
  }
  saveNew(){
    if(this.Medication.value.Medication && this.Medication.value.Unit && this.Medication.value.Dose){
      this.http.post(`/api/gastro/SaveMed/${this.es.examID}`, {med: this.Medication.value, pat:this.es.patServ.currentPatient},).subscribe((data) => {
        this.Medication.reset();
        if(this.ls.isGastro)
          this.Medication.get('Unit').setValue('mg');
        if(this.ls.ishospital)
          this.Medication.get('Unit').setValue('ml');
        this.getAllMedication();
      })    
    }
  }
  deleteMed(index:number){
    this.http.get(`/api/gastro/deleteMed/${index}`).subscribe((data) => {
      console.log(data);
      this.getAllMedication()
    })
  }
}
