import { Component, OnInit,Output,Input,EventEmitter, OnDestroy } from '@angular/core';
import { PatientsService } from '../patients.service';
import { PregnancyService} from '../pregnancy.service';
import {ExamsService} from '../exams.service';
import { Patient } from '../patients/patient';
import { LoginService } from '../login.service';

import { Router } from '@angular/router';
import { Subscription, Subject } from 'rxjs';

@Component({
  selector: 'app-examsbar',
  templateUrl: './examsbar.component.html',
  styleUrls: ['./examsbar.component.scss']
})
export class ExamsbarComponent implements OnInit, OnDestroy {
  @Output() pageChange = new EventEmitter<string>();
  @Output() showPatDetails = new EventEmitter();
  @Input('switchpage') switch: Subject<string>;
  currentPage = "Pregnancy";
  showPat:boolean=false;
  sub = new Subscription()
  isBookmark =false;
  surrogate:string;
  
  pat:Patient;
  constructor(public patservice: PatientsService, private pregServ: PregnancyService, public es: ExamsService, private router: Router, public ls: LoginService) { }

  ngOnInit() {
    this.pat = this.patservice.getPat();
    if(this.pat.ID == 'default')
      this.currentPage = 'Placenta';
    if(this.es.ExamType == 'Gynecology' || (this.ls.isCardio && ! this.ls.isGastro))
      this.currentPage = 'Introduction';
    if(this.es.ExamType === 'Pediatric Echo'){
      this.currentPage = 'Anamnesis';
    }
    if(this.ls.isGastro)
      this.currentPage ="Preparation"
    this.sub = this.es.NTAdd$.subscribe(() => {
      if(!this.es.addNT)
        this.setPage('Placenta')
    });
    this.switch.subscribe((ex)=>{
      this.currentPage = ex;
    });
    if(this.ls.hasSurrogatePreg)
      this.patservice.getSurrogate(this.pat.PID, this.es.examID ? this.es.examID: null).subscribe((data)=>{
        this.surrogate = data['First']+ ' ' + data['Last'] + ' ('+data['ID']+')';
      },(error)=>{
        console.log(error);
      })
  }

  setPage(page:string){
    this.es.ShowSRs=false;
    this.currentPage=page;
    this.pageChange.emit(page);
  }

  showPatD(){
    this.showPatDetails.emit();
  }

  go(place:string){
    this.router.navigateByUrl(place);
    this.es.exitExam();
  }

  bookmark(){
    console.log(this.es.examID);
    this.isBookmark = !this.isBookmark
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.switch) {
      this.switch.unsubscribe();
    }
  }
}
