import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class StatsService {
  totalLength = 0;
  hmoNotAssign =[]
  statsForm;
  usersStats = [];
  users =[];
  HMO = ['Macabi', 'Leumit', 'Meuhedet', 'Clalit', 'Private' ]
  code;
  Params;
  isGastro;
  isCardio;
  examsArray = [];
  statsLoaded$ = new Subject();
  CARDIO = ['TTE Adults', 'Stress', 'Stress-Echo','Duplex Carotid', 'All']
  GASTRO=['Gastroscopy', 'Colonoscopy','Sigmoidoscopy', 'All'];
  GASTROFORMS=[ 'Admission', 'TimeOut', 'AllForms']
  GYN =[ '1st', 'NT', 'Early', '2nd/3rd','Weight Assessment','Tech Exam', 'Late','Third Scan', 'Specialized' ,'Fetal Echo','Pediatric Echo', 'Gynecology', 'Follicles', 'CVS', 'Amniocentesis','Emergency Room', 'All'];
  INVASIVE =[  'CVS', 'Amniocentesis',  'AllInvasive']
  constructor(private http:HttpClient, private fb: UntypedFormBuilder, private ls:LoginService) { 
    this.resetForm();
    
  }
  resetForm(){
    this.statsForm = this.fb.group({
      Colonoscopy:[''],
      Gastroscopy:[''],
      Sigmoidoscopy:[''],
      Gynecology :[''],
      CVS :[''],
      Amniocentesis :[''],
      Follicles:[''],
      first:[''],
      NT:[''],
      Early:[''],
      second:[''],
      WeightAssessment:[''],
      TechExam:[''],
      Late:[''],
      ThirdScan:[''],
      Specialized:[''],
      FetalEcho:[''],
      PediatricEcho:[''],
      All:[''],
      start:[''],
      end:[''],
      exams:[''],
      Admission:[''],
      TimeOut:[''],
      AllForms:[''],
      examsUsers:[''],
      formsUsers:[''],
      TTEAdults:[''],
      Stress:[''],
      DuplexCarotid:[''],
      StressEcho:[''],
      EmergencyRoom:[''],
    })
  }

  search(params, code=null){
    if(code){
      this.code = code;
      this.loadData(code)
    }
    this.Params = params;
    // this.Params['isGastro'] = this.ls.isGastro
    this.http.get('/api/stats', {'params':params}).subscribe((data) => {
      this.resetForm();
      if(params.end && params.start){
        this.statsForm.get('start').setValue(params.start)
        this.statsForm.get('end').setValue(params.end)
      }else{
        this.statsForm.patchValue(data)
      }
      // this.isGastro = data['isGastro'];
      if(data['type'] =='CARDIO'){
        this.ls.isCardio = true;
        this.ls.isGastro = false;
        this.examsArray = this.CARDIO;
      }
      else if(data['type'] =='GASTRO'){
        this.ls.isGastro = true;
        this.ls.isCardio = false;
        this.examsArray = this.GASTRO;
      }else{
        this.ls.isGastro = false;
        this.ls.isCardio = false;
        this.examsArray = this.GYN;
      }
      this.filterData(data);
      this.statsLoaded$.next()
    })
    
  }
  filterByHMO(data,hmo){
    if(!data)
      return []
    var temp = data;
    this.hmoNotAssign = temp.filter((i) => i.HMO ==null)
    return temp.filter((i) => i.HMO ==hmo)
    // if(temp.length > 0 ){
    // }
    // return temp
    
  }

  filterData(data){
    this.usersStats = data['data'];
    var str;
    if(data['All']){
      var exams = data['All']
      this.totalLength = data['All'].length;
      this.statsForm.get('All').setValue(exams);
      for(let i=0; i<this.GYN.length; i++){
        str = this.GYN[i].replace(' ', '');
        if(this.GYN[i] == '1st')
          str ='first'
        if(this.GYN[i] == '2nd/3rd')
          str = 'second'
        if(this.GYN[i] =='Weight Assessment')
          str = 'WeightAssessment'
        if(this.GYN[i] =='Fetal Echo')
          str = 'FetalEcho'  
          if(this.GYN[i] =='Pediatric Echo')
          str = 'PediatricEcho'  
        if(this.GYN[i] != 'All' && !this.ls.isGastro)
          this.statsForm.get(str).setValue(exams.filter((exam) => exam.ExamType == this.GYN[i]))
      }
    }
    if(this.ls.isGastro){
      if(data['All']){
        var exams = data['All'];
        this.totalLength = exams.length;
        this.statsForm.get('All').setValue(exams);
        for(let i=0; i<this.GASTRO.filter((i)=> i !='All').length; i++){
          this.statsForm.get(this.GASTRO[i]).setValue(exams.filter((exam) => exam.ExamType == this.GASTRO[i]))
        }
        
      }
      if(data['AllForms']){
        this.statsForm.get('AllForms').setValue(data['AllForms']);
        this.statsForm.get('Admission').setValue(data['All'].filter((i) => i.FormName =='Admission Form'))
        this.statsForm.get('TimeOut').setValue(data['All'].filter((i) => i.FormName =='Time Out Form'))
      }
      return;
    }else if(this.ls.isCardio){
      if(data['All']){
        var exams = data['All'];
        this.totalLength = exams.length;
        this.statsForm.get('All').setValue(exams);
        for(let i=0; i<this.CARDIO.filter((i)=> i !='All').length; i++){
          str = this.CARDIO[i].replace('-', '').replace(' ', '');
          this.statsForm.get(str).setValue(exams.filter((exam) => exam.ExamType == this.CARDIO[i]))
        }
    }
  }
    
  }
  loadData(code){
    this.code = code;
    this.http.post('/api/print/resurection', {code:code}).subscribe((data) => {
      this.search(this.Params);
    })
  }

}
