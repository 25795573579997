import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AutotextService } from 'src/app/autotext.service';
import { ExamsService } from 'src/app/exams.service';
import { GastroFormsService } from 'src/app/gastro-forms.service';
import { GastroService } from 'src/app/gastro.service';
import { LoginService } from 'src/app/login.service';
import { MedicationService } from 'src/app/medication.service';

@Component({
  selector: 'app-preparation',
  templateUrl: './preparation.component.html',
  styleUrls: ['./preparation.component.scss']
})
export class PreparationComponent implements OnInit, OnDestroy {
  showTimeOutForm = false;
  sub = new Subscription();
  filteredOptions:any[] = [];
  // filteredOptions: Observable<string[]>;
  // filteredOptionsPrep:any[] = [];
  filteredOptionsPrep: Observable<string[]>;
  item;
  inputControl: UntypedFormControl = new UntypedFormControl()
  options: any[] = ["Olympus"];
  optionsPrep: any[] = ['Fast','Picosalax + laxadin', 'Meroken', 'Moviprep', 'Fleet enema', 'laxadin']
  itemId;
  timeoutSTR=`לפני התחלת הפעולה מולא טופס פסק זמן-נעשה ווידוי שם המטופל/ת וסוג הבדיקה שאמור הנבדק/ת לעבור\nהבדיקה בוצעה לאחר מתן הסבר על מהלך הבדיקה וסיבוכיה, ולאחר שנתקבלה הסכמה מלאה של הנבדק/ת.\nבמהלך הבדיקה הנבדק/ת נוטר/ה עם מד סיטורציה וכן קיבל חמצן במשקפים לפי הצורך.`
  imageSTR = 'Olympus';
  constructor(public ls:LoginService, public gs:GastroService, public es: ExamsService, public atS:AutotextService, public medS:MedicationService, public gfs:GastroFormsService) { }

  ngOnInit() {
    this.sub = this.es.formLoaded$.subscribe(() => {
      if(! this.es.isClosed){
        if(! this.medS.Medication.get('Unit').value)
          this.medS.Medication.get('Unit').setValue('mg', {emitEvent:false});

          if(! this.gs.gastroForm.get('imag1').value)
            this.gs.gastroForm.get('imag1').patchValue(this.imageSTR, {emitEvent:false});
          if(! this.gs.gastroForm.get('scope1').value)
            this.gs.gastroForm.get('scope1').patchValue(this.imageSTR, {emitEvent:false});
        this.getText(93);
        this.getText(94);
        this.getText(95);
        this.filteredOptionsPrep = this.gs.gastroForm.get('inputControl').valueChanges.pipe(startWith(''),
          map(value => this._filter(value)),
        );
      }
    })
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.optionsPrep.filter(option => option.toLowerCase().includes(filterValue));
  }

  getClass(text:any){
    if(this.gs.gastroForm.get('Medication').value)
      return 'type-v'
    return 'type-plus'
  }
  addAndSave(option:any, formcontrol:any, id:any, inPrep = false){
    if(inPrep){
      this.optionsPrep.push(option);
    }else{
      this.options.push(option);
    }
    if(this.gs.gastroForm.get(formcontrol).value !== ""){
      this.atS.showAuto(this.gs.gastroForm.get(formcontrol), formcontrol, id,false)
      var res = this.atS.saveText(option, null)
      this.sub = res.subscribe((data)=>{
        if(this.itemId == null)
          this.itemId = data['insertId'];
      },(error)=>{
        console.log(error);
      });
    }
  }

  Save(option){
    var temp = this.gs.gastroForm.get('Preparetion').value
    if(temp){
      temp = temp + ' \n ' + option
    }else{
      temp = option
    }
    this.gs.gastroForm.get('Preparetion').setValue(temp);
    this.gs.gastroForm.get('inputControl').setValue('');
  }
  getText(field){
    var res = this.atS.getTexts(field);
      this.sub = res.subscribe((data:any) => {
        data.forEach((d) => {
          if(d.DlgID == 95){
            this.optionsPrep.push(d.Text)
          }else{
            this.options.push(d.Text)
          }
        })
        this.inputControl.patchValue('');
      })
  }
  close(){
    this.showTimeOutForm = false;
    if(! this.gs.gastroForm.get('TimeOut').value)
      this.gs.gastroForm.get('TimeOut').patchValue(this.timeoutSTR);
  }

  // checkIn(){
  //   this.filteredOptions = this.options.filter(option => option.includes(this.inputControl.value));
  //   return this.options.filter(option => option.includes(this.inputControl.value)).length > 0;
  // }
  checkInPrep(){
    return this.optionsPrep.filter(option => option.includes(this.gs.gastroForm.get('inputControl').value)).length > 0;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

}
