<div class="patient">
    <div class="wrapper">
        <header class="l-header">
            <div class="on-mobile header-logo">
                <img src="assets/icons/logo.svg" alt="Artisan Sonics" />
            </div>
            <div class="l-header-panel dsp-flex">
                <!-- <div id="1" class="header">
                    <button class="clear-btn " (click)="router.navigate(['/patsearch']);">Medical</button>

                </div>
                <div id="1" class="header">
                    <button class="clear-btn" (click)="redirectAdministration()">Administration</button>
                </div> -->
            </div>
        </header>

        <main class="l-deashboard"></main>

        <aside class="l-sidebar l-flex l-column">
            <img class="off-mobile sidebar-logo" src="assets/icons/logo.svg" alt="Artisan Sonics" />

            <nav class="mainNav l-fg1 scroller"></nav>
            <app-docpanel></app-docpanel>
        </aside>

        <app-bottombar></app-bottombar>
    </div>

    <app-commerror></app-commerror>
</div>