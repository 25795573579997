import { AdvancedSearch } from '../AdvencedSearch.service';
import { DiagnosisService } from "../diagnosis.service";
import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ExamsService } from "../exams.service";
import { LoginService } from "../login.service";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { PatientsService } from "../patients.service";
import { Router } from "@angular/router";

interface Diagnosis {
  id: number;
  step: number;
  name: string;
  parentId: string;
  number: number;
  autoSearchString: string;
}
@Component({
  selector: "app-advanced-search",
  templateUrl: "./advanced-search.component.html",
  styleUrls: ["./advanced-search.component.scss"],
})
export class AdvancedSearchComponent implements OnInit {

  showSearchPopup = false;
  showButton = false;

  msgNoResults = false;
  diagnosesLeaves: Diagnosis[];
  filteredOptions = [];
  isOptionSelected = false;


  constructor(
    private http: HttpClient,
    public examService: ExamsService,
    public loginService: LoginService,
    public AdvancedSearch: AdvancedSearch,
    public patientsService: PatientsService,
    public diagnosisService: DiagnosisService,
    private router: Router
  ) {
    this.loginService.loginMissing();
    this.diagnosesLeaves = diagnosisService.diagnosis_list.filter(
      (diagnose) => diagnose[5]
    );
  }

  ngOnInit(): void {
  
  }
//  function that checks when a diagnosis is selected if selected button will be displayed
  toggleButton(value: any) {
    if (!this.diagnosisService.getList(value).length) {
      this.showButton = true;
      this.AdvancedSearch.valueForSearch = value;
    } else {
      this.showButton = false;
    }
  }
// search exams bi clinic id and value for search (leaves)
  searchExams() {
    if (!this.loginService.isSheba) {
      this.http
        .get(
          `/api/search/${this.AdvancedSearch.selectedCategory.table}/${this.AdvancedSearch.valueForSearch}/${this.loginService.ClinicID}`
        )
        .subscribe((data) => {
          this.AdvancedSearch.matchExams = data as [];
          if (!this.AdvancedSearch.matchExams.length) {
            this.msgNoResults = true;
            this.AdvancedSearch.examTypeCounts = {};
          }

          this.countExamTypes();
        });
    } else {
      this.http
        .get(
          `/api/search/${this.AdvancedSearch.selectedCategory.table}/${this.AdvancedSearch.valueForSearch}`
        )
        .subscribe((data) => {
          this.AdvancedSearch.matchExams = data as [];
          if (!this.AdvancedSearch.matchExams.length) {
            this.msgNoResults = true;
            this.AdvancedSearch.examTypeCounts = {};
          }
          this.countExamTypes();
        });
    }
  }
  
  // generate to excl with all the exams with much diagnosis
  generateExcelFile() {
    let diagnosisFullString;
    const matchDiagnosis = this.diagnosisService.diagnosis_list.find(
      (diagnosisList: any) => diagnosisList.includes(this.AdvancedSearch.valueForSearch)
    );
    if (matchDiagnosis) {
      diagnosisFullString = matchDiagnosis[5];
    }

    const organizedData = this.AdvancedSearch.matchExams.map((exam:any) => {

      const formatDate=new Date(exam.CreationTime).toLocaleString('en-US', {
        hour12: true,
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      })
      return {
        "First Name": exam.First,
        "Last Name": exam.Last,
        "Exam Time": formatDate,
        "Done By": exam.OpenerName,
        Diagnosis: diagnosisFullString,
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(organizedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1;
    const day = now.getDate();
    const dateOnly = `${day}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${year}`;
    saveAs(
      blob,
      `(AWEB Data Center) Search results for - ${diagnosisFullString} ${dateOnly}.xlsx`
    );
  }

  // clear the page from previous selections
  clearSearchSelect() {
    this.AdvancedSearch.selectedCategory = null;
    this.AdvancedSearch.selectedCategory2 = null;
    this.AdvancedSearch.selectedCategory3 = null;
    this.AdvancedSearch.selectedCategory4 = null;
    this.AdvancedSearch.selectedCategory5 = null;
    this.AdvancedSearch.selectedCategory6 = null;
    this.AdvancedSearch.valueForSearch = null;
    this.AdvancedSearch.searchText = "";
    this.showButton = false;
    this.msgNoResults = false;
    this.AdvancedSearch.matchExams = [];
    this.AdvancedSearch.examTypeCounts = {};
    this.filteredOptions = [];
    this.isOptionSelected=false
  }

  // function that count each type of exam
  countExamTypes() {
    this.AdvancedSearch.examTypeCounts;

    for (let exam of this.AdvancedSearch.matchExams) {
      if (exam.ExamType in this.AdvancedSearch.examTypeCounts) {
        this.AdvancedSearch.examTypeCounts[exam.ExamType]++;
      } else {
        this.AdvancedSearch.examTypeCounts[exam.ExamType] = 1;
      }
    }
    return this.AdvancedSearch.examTypeCounts;
  }

  //  function that is activated when the user writes letters and the correct diagnoses are filtered
  filterOptions() {
    this.msgNoResults = false;
    if (this.AdvancedSearch.searchText) {
      this.isOptionSelected = false;
      this.filteredOptions = this.diagnosesLeaves.filter((option) =>
        option[5].toLowerCase().includes(this.AdvancedSearch.searchText.toLowerCase())
      );
      const searchMatchAnomalies = this.filteredOptions.filter(
        (option) => option[5].toLowerCase() === this.AdvancedSearch.searchText.toLowerCase()
      );
      if (searchMatchAnomalies.length) {
        this.isOptionSelected = true;
      }
    } else {
      this.filteredOptions = [];
      this.isOptionSelected = false;
    }
  }

  // A function that sends a request to the server to receive the tests with the selected diagnosis
  searchExamsByFreeText() {
    this.AdvancedSearch.valueForSearch = this.filteredOptions[0][0];
    this.http
      .get(`/api/search/summary/${this.AdvancedSearch.valueForSearch}`)
      .subscribe((data) => {
        this.AdvancedSearch.matchExams = data as [];
        if (!this.AdvancedSearch.matchExams.length) {
          this.msgNoResults = true;
        }
        this.countExamTypes();
      });
  }

  exit() {
    this.router.navigateByUrl("/patsearch");
  }
}
