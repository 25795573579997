<!-- ========================== intro (start) ========================== -->

<main class="l-deashboard">

	<div class="h100 l-flex l-column">
		<ul class="t-menu" *ngIf="es.ExamType === 'Fetal Echo' || es.ExamType === 'Pediatric Echo'">
			<li>
				<button class="t-menu-item" data-open="chestAndCardioFetal" [ngClass]="Group == 'chestFetal' ? 'is-active':''"
					(click)="setGroup('chestFetal')">
					{{
						es.ExamType === "Fetal Echo"
						  ? ("GUI.Fetal Echo.Anatomy.Fetal Heart Structure" | translate)
						  : "Pediatric Heart Structure "
					  }}
				</button>
			</li>
			
		</ul>

		<ul class="t-menu" *ngIf="es.ExamType !== 'Fetal Echo' && es.ExamType !== 'Pediatric Echo'">
			<li>
				<button class="t-menu-item" [ngClass]="Group == 'cns'? 'is-active':''" data-open="cns"
					(click)="setGroup('cns')">
					<!-- {{'systems.Category_Names.cns' | translate | titlecase }} -->
					{{'GUI.Anatomy.Titles.CNS' | translate }}
					<!-- CNS -->
				</button>
			</li>
			<li>
				<button class="t-menu-item" data-open="faceAndNeck" [ngClass]="Group == 'face'? 'is-active':''"
					(click)="setGroup('face')">
					<!-- {{'systems.Category_Names.face' | translate | titlecase }} -->
					{{'GUI.Anatomy.Titles.Face & Neck' | translate }}

					<!-- Face & Neck -->
				</button>
			</li>
			<li>
				<button class="t-menu-item" data-open="chestAndCardio" [ngClass]="Group == 'chest'? 'is-active':''"
					(click)="setGroup('chest')">
					<!-- {{'systems.Category_Names.chest' | translate | titlecase }} -->
					{{'GUI.Anatomy.Titles.Chest & Cardio' | translate }}

					<!-- Chest & Cardio -->
				</button>
			</li>
			<li>
				<button class="t-menu-item" data-open="digest" [ngClass]="Group == 'digest'? 'is-active':''"
					(click)="setGroup('digest')">
					<!-- {{'systems.Category_Names.digest' | translate | titlecase }} -->
					{{'GUI.Anatomy.Titles.GIT' | translate }}


					<!-- GIT -->
				</button>
			</li>
			<li>
				<button class="t-menu-item" data-open="urology" [ngClass]="Group == 'urology'? 'is-active':''"
					(click)="setGroup('urology')">
					<!-- {{'systems.Category_Names.urology' | translate | titlecase }} -->
					{{'GUI.Anatomy.Titles.GenitoUrinary' | translate }}

					<!-- GenitoUrinary -->
				</button>
			</li>
			<li>
				<button class="t-menu-item" data-open="skeleton" [ngClass]="Group == 'skeleton'? 'is-active':''"
					(click)="setGroup('skeleton')">
					<!-- {{'systems.Category_Names.skeleton' | translate | titlecase }} -->
					{{'GUI.Anatomy.Titles.Skeleton' | translate }}

					<!-- Skeleton -->
				</button>
			</li>
			
		</ul>
		<div class="l-flex t-row l-flex-w t-boxMargin--spacebyPadding anatomy-boxes" *ngIf="es.ExamType ==='Fetal Echo' || es.ExamType === 'Pediatric Echo'"
			id="cns">
				<div style="width: 100%;">
					<app-fetalecho [items]="Items[Group]" [group]="Group"></app-fetalecho>
				</div>
			</div>
			<div class="l-flex t-row l-flex-w t-boxMargin--spacebyPadding anatomy-boxes"
				*ngIf="es.ExamType !=='Fetal Echo' && es.ExamType !== 'Pediatric Echo' && loaded" id="cns">
				<!--*ngFor="let item of Items[Group]"-->
				<div class="w25 anatomy-item" *ngFor="let item of Items[Group]">
					<div class="m-box" [ngClass]="getClass(item)">
						
						<!-- <h2>{{item.name}}</h2> -->
						<h2>
							{{('GUI.Anatomy.'+Group+'.'+reformatItemName(item.name)) | translate | titlecase}}
							<!-- || {{item.name}} -->
						</h2>

					<div [ngClass]="{'anatomy-item-double .anatomy-item-double-right' : item.double && newVersion}"> 

						<span class="anatomy-item-double-right" *ngIf="item.double && newVersion"> R </span>
						
						<div class="anatomy-twin-radios anatomy-twin-radios--is_tripple"
							(dblclick)="clear(Group, item.double && newVersion ? item.dbr : item.db)">

							<label class="observe">
								<input type="radio" class="custom-check customInput" [name]="item.double && newVersion ? item.dbr : item.db"
									[formControl]="Items[Group+'form'][item.double && newVersion ? item.dbr : item.db]" [value]="0">
									<i class="customInput-display"></i>
								<span>
									<!-- Observed -->
									{{('GUI.Anatomy.Observed') | translate | titlecase}}

								</span>
							</label>

							<label class="attention">
								<input type="radio" class="custom-check customInput" [name]="item.double && newVersion ? item.dbr : item.db"
									[formControl]="Items[Group+'form'][item.double && newVersion ? item.dbr : item.db]" [value]="2">
									<i class="customInput-display"></i>
								<span>
									<!-- Attention -->
									{{('GUI.Anatomy.Attention') | translate | titlecase}}

								</span>
							</label>

							<label class="not-observe">
								<input type="radio" class="custom-check customInput" [name]="item.double&& newVersion ? item.dbr : item.db"
									[formControl]="Items[Group+'form'][item.double && newVersion ? item.dbr : item.db]" [value]="1"><i
									class="customInput-display"></i>
								<span>
									<!-- Not Observed -->
									{{('GUI.Anatomy.Not Observed') | translate | titlecase}}
								</span>
							</label>
						</div>
					</div>

					<div *ngIf="item.double && newVersion" class="anatomy-item-double anatomy-item-double-left"> 
						<span> L </span>
						<div class="anatomy-twin-radios anatomy-twin-radios--is_tripple"
						(dblclick)="clear(Group, item.dbl)">
						
						<label class="observe">
							<input type="radio" class="custom-check customInput" [name]="item.dbl"
								[formControl]="Items[Group+'form'][item.dbl]" [value]="0"><i
								class="customInput-display"></i>
						</label>

						<label class="attention">
							<input type="radio" class="custom-check customInput" [name]="item.dbl"
								[formControl]="Items[Group+'form'][item.dbl]" [value]="2"><i
								class="customInput-display"></i>
						</label>

						<label class="not-observe">
							<input type="radio" class="custom-check customInput" [name]="item.dbl"
								[formControl]="Items[Group+'form'][item.dbl]" [value]="1"><i
								class="customInput-display"></i>
						</label>

					</div>
				</div>

						<span class="anatomy-show-more">
							<!--form elemnts not working when empty so using hack-->

							<div class="on-hoverPanel">

								<div class="on-hoverPanel-body">

									<div class="anatomy-item-details">

										<h3 class="anatomy-item-details-title">
											<!-- {{item.name}} -->
											{{('GUI.Anatomy.'+Group+'.'+reformatItemName(item.name)) | translate | titlecase}}

										</h3>

										<div class="anatomy-items-desc">

											<div class="anatomy-items-edited scroller mCustomScrollbar _mCS_2 mCS_no_scrollbar"
												contenteditable="true">
												<div id="mCSB_2"
													class="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside"
													tabindex="0" style="max-height: none;">
													<div id="mCSB_2_container"
														class="mCSB_container mCS_y_hidden mCS_no_scrollbar_y"
														style="position:relative; top:0; left:0;" dir="ltr"> </div>
													<div id="mCSB_2_scrollbar_vertical"
														class="mCSB_scrollTools mCSB_2_scrollbar mCS-light mCSB_scrollTools_vertical"
														style="display: none;">
														<div class="mCSB_draggerContainer">
															<div id="mCSB_2_dragger_vertical" class="mCSB_dragger"
																style="position: absolute; min-height: 30px; top: 0px; display: block; height: 12px; max-height: 106.938px;">
																<div class="mCSB_dragger_bar"
																	style="line-height: 30px;"></div>
															</div>
															<div class="mCSB_draggerRail"></div>
														</div>
													</div>
												</div>
											</div>
											<textarea class="anatomy-items-edit"
												[formControl]="Items[Group+'form'][item.double && newVersion ? item.name + 'Note' : item.db + 'Note' ]"></textarea>


										</div>

									</div>

									<!--<button class="anatomy-details-save">Save Comments</button> -->

								</div>

							</div>

						</span>

					</div>
				</div>

				<div class="w25 anatomy-item" *ngIf="Group == 'urology'">

					<div>
						<div class="m-box">
							<h2>
								<!-- Fetus Sex -->
								{{('GUI.Anatomy.urology.Fetus Sex') | translate | titlecase}}
							</h2>

							<div class="anatomy-fetus">
								<div class=" anatomy-twin-radios-dobule anatomy-twin-radios--is_tripple" (dblclick)="Items.urologyform['FetalSex'].setValue(-1)">

									<label class="observe">
										<input type="radio" class="custom-check" name="TEMP_1"
											[formControl]="Items.urologyform['FetalSex']" [value]="0">
										<i class="customInput-display"></i>
										<span>
											<!-- Female -->
											{{'GUI.Anatomy.Female' | translate}}
										</span>
									</label>


									<label class="observe">
										<input type="radio" class="custom-check" name="TEMP_1"
											[formControl]="Items.urologyform['FetalSex']" [value]="1">
										<i class="customInput-display"></i>
										<span>
											<!-- Male -->
											{{'GUI.Anatomy.Male' | translate}}
										</span>
									</label>
								</div>


								<div class="l-flex-c l-just-center">
									<div class="anatomy-print-sex">
										<div>
											<label class="not-observe">
												<input type="checkbox" class="custom-check" name="sTEMP_1"
													aria-labelledby="print_sex"
													[formControl]="Items.urologyform['DoNotPrintSex']" [value]="1">
												<i class=" customInput-display radio-print"></i>
											</label>
										</div>
										<label class="t-label-top" id="print_sex">
											<!-- Don't Print Fetus Sex -->
											{{'GUI.Anatomy.Don\'t Print Fetus Sex' | translate}}
										</label>
									</div>

								</div>
							</div>
						</div>
					</div>

				</div>
			</div>

			<div class="l-fg1 t-row summary">
				<div *ngIf="Group == 'chest'" class="l-flex t-row l-flex-w t-boxMargin--spacebyPadding anatomy-boxes">
					<div class="w20 anatomy-item--sexes">

						<div class="m-box">

							<h2>
								FHR
							</h2>

							<div class="l-flex">
								<input type="number" [formControl]="Items.chestform['FHR']"
									style="width: 4em; border: none"> bpm
							</div>
						</div>
					</div>
				</div>

				<div class="l-flex t-row l-flex-w t-boxMargin--spacebyPadding anatomy-boxes" id="cns"
					*ngIf="Group == 'cns'">

					<div class="w100">

						<div class="m-box">
							<h2 id="sulcation">
								<!-- Sulcation -->
								{{('GUI.Anatomy.cns.Sulcation') | translate | titlecase}}
							</h2>
							<div class="l-flex-c l-flex-w">

								<div class="t-field l-flex t-twin-fieldset"
									(dblclick)="Items.cnsform['Sulcation'].setValue(-1)">
									<label>
										<input type="radio" name="position_TEMP"
											[formControl]="Items.cnsform['Sulcation']" [value]="1" class="custom-check"
											aria-labelledby="sulcation">
										<i class="customInput-display"></i>
										<span>
											{{('general.PositiveNegative.yes') | translate | titlecase}}

											<!-- Yes -->
										</span>
									</label>
									<label>
										<input type="radio" name="position_TEMP" class="custom-check"
											[formControl]="Items.cnsform['Sulcation']" [value]="0"
											aria-labelledby="sulcation">
										<i class="customInput-display"></i>
										<span>
											<!-- No -->
											{{('general.PositiveNegative.No') | translate | titlecase}}

										</span>
									</label>
								</div>


								<ng-container *ngIf="Items.cnsform['Sulcation'].value == 1">
									<div class="t-sep"></div>
									<span (dblclick)="Items.cnsform['SulcationIndic'].setValue(null)" class="l-flex-w">
										<label class="t-label-top">
											<input type="checkbox" name="placenta_TEMP_name" class="custom-check -sq"
												[formControl]="Items.cnsform['Insula']" [value]="1">
											<i class="customInput-display"></i>
											<span>
												<!-- Insula -->
												{{'GUI.Anatomy.Insula' | translate}}
											</span>
										</label>

										<label class="t-label-top">
											<input type="checkbox" name="placenta_TEMP_name" class="custom-check -sq"
												[formControl]="Items.cnsform['CalcaneSuleas']"
												[value]="1">
											<i class="customInput-display"></i>
											<span>
												<!-- Calcarine Sulcus -->
												{{'GUI.Anatomy.Calcarine Sulcus' | translate}}
											</span>
										</label>

										<label class="t-label-top">
											<input type="checkbox" name="placenta_TEMP_name" class="custom-check -sq"
												[formControl]="Items.cnsform['ParietoOcipiteSulcus']"
												[value]="1">
											<i class="customInput-display"></i>
											<span>
												<!-- Parieto-ocipite Sulcus -->
												{{'GUI.Anatomy.Parieto-ocipite Sulcus' | translate}}
											</span>
										</label>

										<label class="t-label-top">
											<input type="checkbox" name="placenta_TEMP_name" class="custom-check -sq"
												[formControl]="Items.cnsform['CovnexitySulci']" [value]="1">
											<i class="customInput-display"></i>
											<span>
												<!-- Convexity Sulci -->
												{{'GUI.Anatomy.Convexity Sulci' | translate}}
											</span>
										</label>
									</span>
								</ng-container>
							</div>

						</div>

					</div>

				</div>

				<!-- <section class="m-box" *ngIf="es.ExamType === 'Fetal Echo'">

					<h2>
						Comments
					</h2>
					<textarea class="scroller anatomy-comments"
					[formControl]="Items[chestFetalform]['Comments']"></textarea>
					<button class="t-btn-edit" *ngIf="! es.isClosed"
						(click)="atS.showAuto(Items[chestFetalform]['Comments'],groupName(Group) + ' Comments',40 + groupID(Group))">Auto
						Text</button>
				</section> -->



				<section class="m-box" *ngIf="es.ExamType !== 'Fetal Echo' && es.ExamType !== 'Pediatric Echo'">

					<h2>
						<!-- Comments -->
						{{('GUI.Anatomy.Comments') | translate | titlecase}}
					</h2>
					<textarea class="scroller anatomy-comments"
						[formControl]="Items[Group+'form']['Comments']"></textarea>
					<button class="t-btn-edit" *ngIf="! es.isClosed"
						(click)="atS.showAuto(Items[Group+'form']['Comments'],groupName(Group) + ' Comments',40 + groupID(Group))">Auto
						Text</button>
				</section>

				<app-diagnoses *ngIf="es.ExamType !=='Fetal Echo' && es.ExamType !== 'Pediatric Echo' && !ls.isSheba" [group]="Group" [form]="Items[Group+'form']['DiagnosisCodes']"
					[text]="Items[Group+'form']['Diagnosis']" [autoid]="400+groupID(Group)"
					></app-diagnoses>

			</div>
		</div>

</main>