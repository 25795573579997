import {
  FormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
} from "@angular/forms";


export class Anatomies {
  cns = [
    { name: "Skull Shape", db: "SkullShape" },
    { name: "Lateral Ventricles", db: "LateralVent" },
    { name: "3rd Ventricle", db: "ThirdVent" },
    { name: "4th Ventricle", db: "FourthVent" },
    { name: "Coroid Plexus", db: "ChoroidPlex" },
    { name: "Cerebral Falx", db: "CerebFalx" },
    { name: "Cavum S.P.", db: "CavumSP" },
    { name: "Corpus Callosum", db: "CorpusCall" },
    { name: "Cerebellum", db: "Cerebellum" },
    { name: "Vermis", db: "Vermis" },
    { name: "Cisterna Magna", db: "PostFossa" }, //Posterior Fossa
    { name: "Cerebral Cortex", db: "CerebalCortex" },
    { name: "Thalamus", db: "Thalamus" },

    /*{name:'Basal',db:'Basal'},
      {name: 'Cerebral Vent', db: 'CerebalVent'},
      {name: 'Cerebral Vermis',db: 'CerebVermis'},
      {name: 'Spine', db: 'Spine'},
          {name: 'Sulcation Indication', db: 'SulcationIndic'},
          {name: 'Sulcation', db: 'Sulcation'}*/
  ];
  face = [
    { name: "Orbits", db: "Orbits" },
    { name: "Lenses" },
    { name: "Eyelids" },
    { name: "Lips" },
    { name: "Maxilla" },
    { name: "Mandibula" },
    { name: "Ears" },
    { name: "Neck" },
    { name: "Tongue" },
    { name: "Nose", db: "Nostrils" },

    // {name: 'Swall'},
  ];
  chest = [
    { name: "Lungs" },
    { name: "4 Chambers View", db: "ChamberView" },
    { name: "3 VT", db: "Chamber3" },
    { name: "Great Vessels", db: "GreatVessels" },
    { name: "Tricuspid Value", db: "TricaspidValve" },
    { name: "Mitral Valve", db: "MitralValve" },
    { name: "Aortic Valve", db: "AorticValve" },
    { name: "Pulmonic Valve", db: "PulmonicValve" },
    { name: "Foramen Ovale", db: "ForamenOvale" },
    { name: "Aortic Arch", db: "AorticArch" },
    { name: "Ductus Arteriosus", db: "Ductus" },
    { name: "SVC" },
    { name: "IVC" },
    { name: "Abdominal Aorta", db: "AbdoAorta" },
    /*
          {name: 'Arrythmia'},
            {name:'AV Valves',db:'AVvalves'},
            {name:'Aop Valves',db:'AopValves'},
            {name:'Great Vessels' , db: 'GreatVessels'},
            */
  ];
  chestFetal = [
    { name: "Left Ventricle", db: "LeftVentricle" },
    { name: "Right Ventricle", db: "RightVentricle" },
    { name: "Interventricular Septum", db: "InterventricularSeptum" },
    { name: "IVS", db: "IVS" },
    { name: "IVSStatus", db: "IVSStatus" },
    { name: "Left Atrium", db: "LeftAtrium" },
    { name: "RightAtrium", db: "RightAtrium" },
    { name: "Interatrial Septum", db: "InteratrialSeptum" },
    { name: "Foramen Ovale", db: "ForamenOvale" },
    { name: "Coronary Sinus", db: "CoronarySinus" },
    { name: "Aortic Valve", db: "AorticValve" },
    { name: "Pulmonary Valve", db: "PulmonaryValve" },
    { name: "Tricupsid Valve", db: "TricupsidValve" },
    { name: "Mitral Valve", db: "MitralValve" },
    { name: "Aortic Arch", db: "AorticArch" },
    { name: "Isthmic Flow", db: "IsthmicFlow" },
    { name: "Pulmonary Artery", db: "PulmonaryArtery" },
    { name: "Ductus Arteriosus", db: "DuctusArteriosus" },
    { name: "Situs", db: "Situs" },
    { name: "AV Concordance", db: "AVC" },
    { name: "VA Concordance", db: "VAC" },
    { name: "PE", db: "PE" },
    { name: "PVRStatus", db: "PVRStatus" },
    { name: "Pulmonary Venous Return", db: "PVR" },
    { name: "Systemic Venous Return", db: "SVR" },

    /*
          {name: 'Arrythmia'},
            {name:'AV Valves',db:'AVvalves'},
            {name:'Aop Valves',db:'AopValves'},
            {name:'Great Vessels' , db: 'GreatVessels'},
            */
  ];
  digest = [
    { name: "Stomach" },
    { name: "Intestines" },
    { name: "Liver" },
    { name: "Gallbladder" },
    { name: "Diaphragm", db: "Diphragm" },
    { name: "Abdominal Wall", db: "AbdoWall" },
    { name: "Umbilical Vessels", db: "UmbVessel" },
    { name: "Umbilical Cord", db: "UmbCord" },
  ];
  urology = [
    {
      name: "Kidneys",
      double: true,
      dbl: "KidneyLeft",
      dbr: "KidneyRight",
      babel: "Kidney",
    },
    {
      name: "Adrenals",
      double: true,
      dbl: "AdrenalLeft",
      dbr: "AdrenalRight",
      babel: "Adrenal",
    },
    {
      name: "Ureters",
      double: true,
      dbl: "UreterLeft",
      dbr: "UreterRight",
      babel: "Ureter",
    },
    { name: "Bladder", db: "Bladder" },
    { name: "Sex Organs", db: "SexOrgans" },
  ];
  skeleton = [
    { name: "Vertebral Column", db: "Spine" },
    { name: "Humerus" },
    { name: "Radius-Ulna", db: "Radius" },
    { name: "Hands" },
    { name: "Fingers" },
    { name: "Femur" },
    { name: "Tibia-Fibula", db: "Tibia" },
    { name: "Feet" },
    { name: "Toes" },
    /*
          {name: 'LBU'},
          {name: 'LBR'},*/
  ];

  cnsform: UntypedFormControl[];
  faceform: UntypedFormControl[];
  chestform: UntypedFormControl[];
  chestFetalform: UntypedFormControl[];
  urologyform: UntypedFormControl[];

  //form groups not working
  constructor(
    private fb: UntypedFormBuilder = new UntypedFormBuilder(),
    altnames = null,
    newVersion: boolean = null
  ) {
    let forms = [
      "cns",
      "face",
      "chest",
      "chestFetal",
      "digest",
      "urology",
      "skeleton",
    ];
    if (newVersion) {
      for (let form of forms) {
        this[form + "form"] = [];
        for (let i = 0; i < this[form].length; i++) {
          if (
            !this[form][i].hasOwnProperty("db") &&
            !this[form][i].hasOwnProperty("dbr") &&
            !this[form][i].hasOwnProperty("dbl")
          ) {
            this[form][i]["db"] = this[form][i].name;
            if (altnames && altnames[this[form][i].db])
              this[form][i].name = altnames[this[form][i].db];
          }
          if (this[form][i].hasOwnProperty("dbr")) {
            (this[form + "form"][this[form][i].dbr] =
              new UntypedFormControl(""));
            (this[form + "form"][this[form][i].dbl] =
              new UntypedFormControl(""));
             (this[form + "form"][this[form][i].name + "Note"] =
              new UntypedFormControl("")); //name of note field instead of db
          } else {
            this[form + "form"][this[form][i].db] = new UntypedFormControl("");
            this[form + "form"][this[form][i].db + "Note"] =
              new UntypedFormControl("");
          }
        }
        this[form + "form"]["Comments"] = new UntypedFormControl("");
        this[form + "form"]["Diagnosis"] = new UntypedFormControl("");
        this[form + "form"]["DiagnosisCodes"] = new UntypedFormControl("");
      }
    } else {
      for (let form of forms) {
        this[form + "form"] = [];
        for (let i = 0; i < this[form].length; i++) {
          if (altnames && altnames[this[form][i].name])
            this[form][i].name = altnames[this[form][i].name];
          if (!this[form][i].hasOwnProperty("db")) {
            this[form][i]["db"] = this[form][i].name;
            if (altnames && altnames[this[form][i].db])
              this[form][i].name = altnames[this[form][i].db];
          }
          this[form + "form"][this[form][i].db] = new UntypedFormControl("");
          this[form + "form"][this[form][i].db + "Note"] =
            new UntypedFormControl("");
        }
        this[form + "form"]["Comments"] = new UntypedFormControl("");
        this[form + "form"]["Diagnosis"] = new UntypedFormControl("");
        this[form + "form"]["DiagnosisCodes"] = new UntypedFormControl("");
      }
    }
    this.cnsform["Sulcation"] = new UntypedFormControl("");
    this.cnsform["SulcationIndic"] = new UntypedFormControl("");
    this.cnsform["Insula"] = new UntypedFormControl("");
    this.cnsform["CalcaneSuleas"] = new UntypedFormControl("");
    this.cnsform["ParietoOcipiteSulcus"] = new UntypedFormControl("");
    this.cnsform["CovnexitySulci"] = new UntypedFormControl("");
    this["urologyform"]["FetalSex"] = new UntypedFormControl("");
    this["urologyform"]["DoNotPrintSex"] = new UntypedFormControl("");
    this.chestform["FHR"] = new UntypedFormControl("");
  }
}
