<div [formGroup]="gs.gastroForm">
    <div class="w100 summary-repeatExamination summary" style="height: auto;">
        <section class="m-box">
            <h2>Anatomical Extent of Examination</h2>
            <textarea class="fulltext" style="height: 100%;min-height: 8em;" formControlName="ExtentOfExam"></textarea>
            <button class="t-btn-edit" *ngIf="! es.isClosed"
            (click)="atS.showAuto(gs.gastroForm.get('ExtentOfExam'),'Anatomical Extent of Examination',205)">Auto Text</button>
            <!-- <select class="t-select t-field" formControlName="ExtentOfExam" style="direction: rtl;">
                <option></option>
                <ng-container>
                    <option value="המעי הגס נבדק עד למעי דק הסופי כולל תרגיל היפוך בחלחולת">המעי הגס נבדק עד למעי דק הסופי כולל תרגיל היפוך בחלחולת</option>
                    <option value="המעי הגס נבדק עד לצקום כולל תרגיל היפוך בחלחולת">המעי הגס נבדק עד לצקום כולל תרגיל היפוך בחלחולת</option>
                    <option value="המעי נבדק עד למעי גס הרחבי. בשל הכנה תת אופטימלית לא ניתן להשלים בדיקה">המעי נבדק עד למעי גס הרחבי. בשל הכנה תת אופטימלית לא ניתן להשלים בדיקה</option>
                    <option value="המעי נבדק עד לסיגמה. בשל הכנה תת אופטימלית לא ניתן להשלים את הבדיקה">המעי נבדק עד לסיגמה. בשל הכנה תת אופטימלית לא ניתן להשלים את הבדיקה</option>
                    <option value="נבדקו וושט, קיבה, תריסריון כולל תרגיל היפוך בקיבה">נבדקו וושט, קיבה, תריסריון כולל תרגיל היפוך בקיבה</option>
                </ng-container>
            </select> -->
        </section>
    </div>
    <div class="h100 l-flex-w t-boxMargin--spacebyPadding summary column" *ngIf="es.ExamType==='Gastroscopy'"  >
        <section class="w100 anatomy-item " style="display: flex">
            <div class="m-box w33" style="margin: 1em;">
                <h2>Esophagus</h2>
                <div class="anatomy-twin-radios" style="margin-block-start: 0.3em;" (dblclick)="gs.gastroForm.get('Esophagus').setValue(-1)">
        
                    <label class="observe">
    
                        <input type="radio" class="custom-check customInput" name="Esophagus"
                            formControlName="Esophagus" [value]="0"/>
                        <i class="customInput-display"></i>
                        <span>
                            Normal
                        </span>
                    </label>
                    <label class="not-observe">
    
                        <input type="radio" formControlName="Esophagus" name="Esophagus"
                            class="custom-check customInput" [value]="1"/>
                        <i class="customInput-display"></i>
                        <span>
                            Abnormal
                        </span>
    
                    </label>
                </div>
                <div class="commDiv">
                    <label>Comments</label>
                    <textarea class="fulltext" style="height: 100%;margin-block-start: 1em;border: revert;" formControlName="EsophagusComment"></textarea>
                    <button class="t-btn-edit" *ngIf="! es.isClosed"
                    (click)="atS.showAuto(gs.gastroForm.get('EsophagusComment'),'Esophagus Comments',206)">Auto Text</button>
                </div>
            </div>
            <div class="m-box w33" style="margin: 1em;height: auto;">
                <h2>Esophagogastric junction</h2>
                <div style="margin: 1em;padding: 0.5em;text-align: -webkit-center;">
                    <span class="t-field t-field--number">
                        <input class="t-field-text" formControlName="EsoJunction" name="EsoJunction">
                        <span class="t-field-desc">cm</span>
                    </span>
                </div>
                <div class="commDiv">
                    <label>Comments</label>
                    <textarea class="fulltext" style="height: 100%;margin-block-start: 1em;border: revert;" formControlName="EsoJunctionComment"></textarea>
                    <button class="t-btn-edit" *ngIf="! es.isClosed"
                    (click)="atS.showAuto(gs.gastroForm.get('EsoJunctionComment'),'Esophagogastric junction Comments',212)">Auto Text</button>
                </div>
            </div>
        </section>
        <!-- <section class="w100 anatomy-item " style="display: flex">
            <div class="m-box w25" style="margin: 1em;height: auto;width: auto;">
                <div class="l-flex-c cervical-row" style="height: 100%;">
                    <label class="l-fg1 cervical-label">Esophagogastric junction</label>
                    <span class="t-field t-field--number">
                        <input class="t-field-text" formControlName="EsoJunction" name="EsoJunction">
                        <span class="t-field-desc">cm</span>
                    </span>
                </div>
            </div>
            <div class="m-box w33" style="margin: 1em;height: auto;">
                <h2>Comments</h2>
                <textarea class="fulltext" style="height: 100%;" formControlName="EsoJunctionComment"></textarea>
                <button class="t-btn-edit" *ngIf="! es.isClosed"
                (click)="atS.showAuto(gs.gastroForm.get('EsoJunctionComment'),'Esophagogastric junction Comments',206)">Auto Text</button>
            </div>
        </section> -->
        <section class="w100 anatomy-item" style="display: flex">
            <div class="m-box w33" style="margin: 1em;">
                <h2>Stomach</h2>
                <div class="anatomy-twin-radios" style="margin-block-start: 0.3em;" (dblclick)="gs.gastroForm.get('Stomach').setValue(-1)">
        
                    <label class="observe">
    
                        <input type="radio" class="custom-check customInput" name="Stomach"
                            formControlName="Stomach" [value]="0"/>
                        <i class="customInput-display"></i>
                        <span>
                            Normal
                        </span>
                    </label>
                    <label class="not-observe">
    
                        <input type="radio" formControlName="Stomach" name="Stomach"
                            class="custom-check customInput" [value]="1"/>
                        <i class="customInput-display"></i>
                        <span>
                            Abnormal
                        </span>
    
                    </label>
                </div>
                <div class="commDiv">
                    <label>Comments</label>
                    <textarea class="fulltext" style="height: 100%;margin-block-start: 1em;border: revert;" formControlName="StomachComment"></textarea>
                    <button class="t-btn-edit" *ngIf="! es.isClosed"
                    (click)="atS.showAuto(gs.gastroForm.get('StomachComment'),'Stomach Comments',207)">Auto Text</button>
                </div>
            </div>
            <div class="m-box w33" style="margin: 1em;">
                <h2>Duodenum</h2>
                <div class="anatomy-twin-radios" style="margin-block-start: 0.3em;" (dblclick)="gs.gastroForm.get('Duodenum').setValue(-1)">
        
                    <label class="observe">
    
                        <input type="radio" class="custom-check customInput" name="Duodenum"
                            formControlName="Duodenum" [value]="0"/>
                        <i class="customInput-display"></i>
                        <span>
                            Normal
                        </span>
                    </label>
                    <label class="not-observe">
    
                        <input type="radio" formControlName="Duodenum" name="Duodenum"
                            class="custom-check customInput" [value]="1"/>
                        <i class="customInput-display"></i>
                        <span>
                            Abnormal
                        </span>
    
                    </label>
                </div>
                <div class="commDiv">
                    <label>Comments</label>
                    <textarea class="fulltext" style="height: 100%;margin-block-start: 1em;border: revert;" formControlName="DuodenumComment"></textarea>
                    <button class="t-btn-edit" *ngIf="! es.isClosed"
                    (click)="atS.showAuto(gs.gastroForm.get('DuodenumComment'),'Duodenum Comments',208)">Auto Text</button>
                </div>
            </div>
        </section>
        <!-- <section class="w100 anatomy-item">
            <section class="m-box">
                <h2>Comments</h2>
                <textarea class="fulltext" style="min-height: 5em;" formControlName="ColoComment"></textarea>
                <button class="t-btn-edit" *ngIf="! es.isClosed"
                (click)="atS.showAuto(gs.gastroForm.get('ColoComment'),'Colonoscopy Comments',203)">Auto Text</button>
            </section>
        </section> -->
    </div>
    <div class="h100 l-flex-w summary"  *ngIf="es.ExamType==='Colonoscopy' || es.ExamType =='Sigmoidoscopy'">
        <div class="w100" style="display: flex;justify-content: space-between;margin-bottom: 1em;">
            <div class="m-box" style="display: flex;width: 25%;">
                <div class="anatomy-twin-radios" style="margin-block-start: 0.3em;width: 100%;" (dblclick)="gs.gastroForm.get('Colonoscopy').setValue(-1)">
        
                    <label class="observe">
    
                        <input type="radio" class="custom-check customInput" name="Colonoscopy"
                            formControlName="Colonoscopy" [value]="0"/>
                        <i class="customInput-display"></i>
                        <span>
                            NPD
                        </span>
                    </label>
                    <label class="not-observe">
    
                        <input type="radio" formControlName="Colonoscopy" name="Colonoscopy"
                            class="custom-check customInput" [value]="1"/>
                        <i class="customInput-display"></i>
                        <span>
                            Pathological Diagnosis
                        </span>
    
                    </label>
                </div>

            </div>
            <div class="excepBox" *ngIf="gs.gastroForm.get('Colonoscopy').value == 0" style="width: 70%;">
                <section class="secExce">except:</section>
                <div class="m-box" style="width: 100%;padding: 0;border-radius: 20px;">
                    <textarea class="textExcept" formControlName="FindingsComment"></textarea>
                </div>
            </div>
        </div>
        <div class="w100 m-box l-flex-w t-boxMargin--spacebyPadding summary marg" style="margin: 0.5em;" *ngIf="gs.gastroForm.get('Colonoscopy').value == 1">
            <h2 style="margin-inline-start: 0.5em;">Pathological Findings</h2>
            <div class="w100" style="display: inline-flex;">
                <div class="w50" style="margin-inline-end: 0.5em;">
                    <app-detail-finding [data]="gs.detailsFinding[0]" [id]="0" style="padding: 0.5em;"></app-detail-finding>
                    <app-detail-finding [data]="gs.detailsFinding[1]" [id]="1" style="padding: 0.5em;"></app-detail-finding>
                    <app-detail-finding [data]="gs.detailsFinding[2]" [id]="2" style="padding: 0.5em;"></app-detail-finding>
                    <app-detail-finding [data]="gs.detailsFinding[3]" [id]="3" style="padding: 0.5em;"></app-detail-finding>
                    <app-detail-finding [data]="gs.detailsFinding[4]" [id]="4" style="padding: 0.5em;"></app-detail-finding>
                    <app-detail-finding [data]="gs.detailsFinding[10]" [id]="10" style="padding: 0.5em;"></app-detail-finding>

                </div>
                <div class="w50" style="margin-inline-end: 0.5em;">
                    <app-detail-finding [data]="gs.detailsFinding[5]" [id]="5" style="padding: 0.5em;"></app-detail-finding>
                    <app-detail-finding [data]="gs.detailsFinding[6]" [id]="6" style="padding: 0.5em;"></app-detail-finding>
                    <app-detail-finding [data]="gs.detailsFinding[7]" [id]="7" style="padding: 0.5em;"></app-detail-finding>
                    <app-detail-finding [data]="gs.detailsFinding[8]" [id]="8" style="padding: 0.5em;"></app-detail-finding>
                    <app-detail-finding [data]="gs.detailsFinding[9]" [id]="9" style="padding: 0.5em;"></app-detail-finding>
                </div>
            </div>
        </div>
        <div class="m-box w100 marg" style="height: 100%;margin-block-start: 2em;">
            <h2>Comments</h2>
            <textarea class="fulltext" style="min-height: 5em;" formControlName="ColoComment"></textarea>
            <button class="t-btn-edit" *ngIf="! es.isClosed"
            (click)="atS.showAuto(gs.gastroForm.get('ColoComment'),es.ExamType +' Comments',202)">Auto Text</button>
        </div>
    </div>
</div>
