import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CalendarService } from '../calendar.service';
import { PatientsService } from '../patients.service';
import { Patient } from '../patients/patient';
import { PrintService } from '../print.service';
import { ExamsService } from '../exams.service';
import { PregnancyService } from '../pregnancy.service';

@Component({
  selector: 'app-printcalendar',
  templateUrl: './printcalendar.component.html',
  styleUrls: ['./printcalendar.component.scss']
})
export class PrintcalendarComponent implements OnInit {
  altHeader:SafeHtml;
  showHeader = true;
  code;
  Direction;
  pregID;
  loaded =false;
  now;
  Pat: Patient;
  OB = [ '1st', 'NT', 'Early', '2nd/3rd','Weight Assessment','Late','Third Scan','Specialized','Fetal Echo'];

  constructor(
    public cf: CalendarService,
    public ps: PatientsService,
    public http: HttpClient,
    private route: ActivatedRoute,
    public print: PrintService,
    public translate: TranslateService,
    private sanitizer: DomSanitizer,
    public examService: ExamsService,
    public pregnancyService: PregnancyService
  ) {
    this.now = new Date();
  }

  async ngOnInit() {
    this.translate.use('he-IL');
    this.Direction='rtl';
    let code = this.route.snapshot.params['code'];
    this.pregID = this.route.snapshot.params['pregID'];
    this.cf.pregID = this.route.snapshot.params['pregID'];
    if(this.route.snapshot.params.code){
      this.loadData(code)
    }

    this.cf.getCalendarData();

    this.cf.claloaded$.subscribe(() =>{
      this.loaded = true;
      if(this.cf.env['htmlheader']){
        this.altHeader=this.sanitizer.bypassSecurityTrustHtml(this.cf.env['htmlheader']);
      }   
    })
    

  }

  loadData(code){
    this.code = code;
    this.http.post('/api/newExams/resurection', {code:code}).subscribe((data) => {
      this.cf.getCalendarData();
      
    })
  }

  checkFirstTable() {
    for (const month of this.cf.triArray[0].months) {
      for (let i = 0; i < month.weeks; i++) {
        const weekKey = "week" + this.cf.getWeek(month.name, i, "first");
        const commentKey = weekKey + "Comment";

        if (
          this.cf.selcForm[weekKey] ||
          this.cf.selcForm[commentKey] ||
          this.cf.selcForm["firstTri"]
        ) {
          return false;
        }
      }
    }

    return true;
  }

  checkSecondTable() {
    for (const month of this.cf.triArray[1].months) {
      for (let i = 0; i < month.weeks; i++) {
        const weekKey = "week" + this.cf.getWeek(month.name, i, "second");
        const commentKey = weekKey + "Comment";

        if (
          this.cf.selcForm[weekKey] ||
          this.cf.selcForm[commentKey] ||
          this.cf.selcForm["secondTri"]
        ) {
          return false;
        }
      }
    }

    return true;
  }

  checkThirdTable() {
    for (const month of this.cf.triArray[2].months) {
      for (let i = 0; i < month.weeks; i++) {
        const weekKey = "week" + this.cf.getWeek(month.name, i, "third");
        const commentKey = weekKey + "Comment";

        if (
          this.cf.selcForm[weekKey] ||
          this.cf.selcForm[commentKey] ||
          this.cf.selcForm["thirdTri"]
        ) {
          return false;
        }
      }
    }

    return true;
  }

  getLmpGA(LMP: Date){
    return this.pregnancyService.lmpGA(LMP, this.now)
  }

}
