import { Component, OnInit,Input, AfterViewInit, AfterViewChecked } from '@angular/core';
import { DiagnosisService } from '../diagnosis.service';
import {MeasgrowthService} from '../measgrowth.service';
import {Measure} from '../measurements/meaure';
import {PrintService} from '../print.service';
import { UltrasoundMeasurementsService } from './../ultrasound-measurements.service';

@Component({
  selector: 'app-printmeasure',
  templateUrl: './printmeasure.component.html',
  styleUrls: ['./printmeasure.component.scss']
})
export class PrintmeasureComponent implements OnInit {
  @Input('MyMeasure') Name:string;
  @Input('fetus') fetus:number;
  @Input('doppler') doppler:boolean;
  @Input('inCm') inCm:boolean = false;
  @Input('inMm') inMm:boolean = false;
  @Input('inMs') inMs:boolean = false;
  @Input('modality') modality: string;
  ultrasoundMeasurements={}
  

  MyMeas:Measure;
  weeks='';
  @Input('minmax') minmax:boolean;
  minPEEFF:any;
  PeefSet;
  dopplerData:any;
  values=[
    {Name: 'DuctusVenosus', type:['Not Observed','Observed'], printName:'Ductus Venosus'},
    {Name: 'AWave', type:['Negative','Positive'], printName:'A-Wave'},
    {Name: 'PI', type:[], printName:'PI'},
]
  

  constructor(private ms: MeasgrowthService, public print:PrintService, private us:UltrasoundMeasurementsService) { 
  }

  ngOnInit() {
    if (this.modality=='Ultrasound'){
      if(! this.us.loading)
        this.ultrasoundMeasurements =this.us.getMeas(this.Name,this.fetus+1)
      this.us.loaded$.subscribe(()=>{
        this.ultrasoundMeasurements =this.us.getMeas(this.Name,this.fetus+1)})
      
    }
    this.MyMeas=this.print.getMeas(this.Name,this.fetus);
    if(this.Name === 'PEEFF')
      this.PeefSet = this.print.getMeas('PeefSet', this.fetus).value
      
    if(! isNaN(Number(this.MyMeas.GA)))
      this.weeks = " weeks";
    this.print['meas']['History'].forEach((i) => {
      if(i.ExamID === +this.print.examID){
        this.minPEEFF = i.PEEFFMIN;
      }
    })
    this.dopplerData = this.values.filter((i) => {
      return i.Name == this.Name
    })
    if(this.print.exam.ExamType =='CVS' || this.print.exam.ExamType =='Amniocentesis' || this.print.exam.ExamType =='Follicles'){
      this.print.showPercentiles = false;
    }
    
  }

  getEnds(num:number){
    let range = this.MyMeas.getRange(this.ms.getGALMP(),this.MyMeas.GGfig,true).split(" to ");
    if(range.length >= num)
      return range[num-1];
    return "";
  }

  inRange(){
    return this.MyMeas.inGGRange(this.ms.getGALMP())
  }

  getName():string{
    if(this.Name.indexOf("EFW") > -1){
      return "EFW by "+ this.print.getMeas(this.Name,this.fetus).WeightType;
    }
    if(this.print.getMeas(this.Name,this.fetus).printName)
      return this.print.getMeas(this.Name,this.fetus).printName;
    return this.Name.split(' ')[0];
  }

  getDoplerValue(index:number){
    if(index > -1)
      return this.values.filter((i) =>{ return i.Name === this.Name})[0].type[index]
  }

  getDiam(){
    if(this.inCm){
      return 'cm/s'
    }
    if(this.inMm)
      return 'mm'
    if(this.inMs)
      return 'm/s'
    // if(this.name )
    return 'cm'
  }

  showGA(){
    return this.MyMeas.GA && this.MyMeas.GAfig != 'none' && (! this.print.env['measDefaultNone'] || this.MyMeas.GAfig)
  }

  showGG(){
    return this.print.showPercentiles && (this.MyMeas.GG || ! this.inRange())  && this.MyMeas.GGfig != 'none' && (! this.print.env['measDefaultNone'] || this.MyMeas.GGfig);
  }

  
  getNameForUs(){
    if(this.Name.indexOf("EFW") > -1){
      let name=this.ultrasoundMeasurements['calculatedBy'];
      if (name.startsWith('"')) {
        name = name.slice(1);
      }
   
      return name
    }
    return (this.Name)
    }
}
