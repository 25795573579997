<div class="body-wrapper rtl" *ngIf="loaded" [ngClass]="sts.isGastro ? '' : 'trans'" style='padding-top: 0;max-width: 1500px;'>
    <header class="header">
        <div class="justify-between items-center" [innerHtml]="altHeader" *ngIf="altHeader && showHeader"></div>
        <div class="justify-between items-center" style="margin-bottom: 5px;">
            <h1 class="header-title" style="width: max-content;font-size: 36px;">סיכום בדיקות</h1>
        </div>
        <div style="display: inline-block;font-size: 12pt">{{'general.Printing_Date' | translate}}:&nbsp;<strong
            style='direction: ltr;'>{{now| date:"dd.MM.yyyy HH:mm"}}</strong></div>
    </header>
    <main class="main">
        <table class="formatted" style="text-align-last: center;">
            <tr>
                <td style="width: 20%;">{{'report.searchBY' | translate}}</td>

                <td style="width: 20%;">
                    {{'report.searchType.'+type |translate}} {{(type =='HMO' ? 'general.HMOs.'+this.route.snapshot.params.hmo : '' ) | translate}}
                </td>

                <td style="width: 20%;">{{'stats.date' | translate}}</td>
                <td style="width: 20%;display: inline-flex;">
                    <dt style="margin-inline-end: 1em;">{{'stats.from' | translate}}</dt>
                    <dd>{{start | date: "dd-MM-yyyy"}}</dd>
                </td>
                <td style="width: 20%;display: inline-flex;">
                    <dt style="margin-inline-end: 1em;">{{'stats.until' | translate}}</dt>
                    <dd>{{end | date: "dd-MM-yyyy"}}</dd>
                </td>
            </tr>
        </table>
        <table class="formatted">
            <tr *ngFor="let exam of examNames; index as i">
                <td>{{i+1}}</td>
                <td style="width: 20%;display: inline-flex;">
                    <dt style="margin-inline-end: 1em;">{{ 'exam.type.Cardio.'+exam | translate}}</dt>
                    <dd>{{filterExams(examsList, exam).length}}</dd>
                </td>
            </tr>
            <tr>
                <td>{{examNames.length +1 }}</td>
                <td style="width: 20%;display: inline-flex;">
                    <dt style="margin-inline-end: 1em;">{{ 'stats.All' | translate}}</dt>
                    <dd>{{examsList.length}}</dd>
                </td>
            </tr>
        </table>
        <div *ngFor="let name of examNames">
            <table class="formatted" *ngIf="filterExams(examsList, name).length >0">
                <tr> <td class="formatted-title" >{{'exam.type.Cardio.'+name | translate}}</td></tr>
                <tr>
                    <td class="td-title" style="width: 5%;">{{'report.num' | translate}}</td>
                    <td class="td-title" style="width: 23%;">{{'general.date' | translate}}</td>
                    <td class="td-title">{{'general.Patient.ID'  | translate}}</td>
                    <td class="td-title">{{'general.Patient.first_name' | translate}}</td>
                    <td class="td-title">{{'general.Patient.last_name' | translate}}</td>
                    <td class="td-title">{{'report.HMO' | translate}}</td>
                    <td class="td-title">{{'report.ExamType' | translate}}</td>
    
    
                </tr>
                <tr *ngFor="let exam of filterExams(examsList, name); index as i">
                    <td class="td-body" style="width: 5%;">{{i+1}})</td>
                    <td class="td-body" style="width: 23%;">{{exam.CreationTime | date:"dd.MM.yyyy HH:mm"}}</td>
                    <td class="td-body">{{exam.ID}}</td>
                    <td class="td-body">{{exam.First}}</td>
                    <td class="td-body">{{exam.Last}}</td>
                    <td class="td-body">{{exam.HMO}}</td>
                    <td class="td-body">{{'exam.type.Cardio.'+exam.ExamType | translate}}</td>
    
                </tr>
            </table>

        </div>
    </main>
</div>