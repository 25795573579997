import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import moment from "moment";
import { of, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { ExamsService } from "./exams.service";
import { LoginService } from "./login.service";
import { PatientsService } from "./patients.service";
import { Patient } from "./patients/patient";
import { PregnancyService } from "./pregnancy.service";

@Injectable({
  providedIn: "root",
})
export class CalendarService {
  public Defaults: number;
  selcForm;
  pregID;
  trimester = "first";
  triArray = [
    {
      trianame: "first",
      months: [
        { name: "1", weeks: 6 },
        { name: "2", weeks: 4 },
        { name: "3", weeks: 3 },
      ],
    },
    {
      trianame: "second",
      months: [
        { name: "3", weeks: 2 },
        { name: "4", weeks: 4 },
        { name: "5", weeks: 4 },
        { name: "6", weeks: 3 },
      ],
    },
    {
      trianame: "third",
      months: [
        { name: "6", weeks: 1 },
        { name: "7", weeks: 5 },
        { name: "8", weeks: 4 },
        { name: "9", weeks: 6 },
      ],
    },
  ];
  formLoaded$ = new Subject();
  loaded = false;
  calForm;
  claloaded$ = new Subject();
  caldeleted$ = new Subject();
  LMP;
  env;
  signers;
  Pat: Patient;
  PID: number;
  Birth: any;
  Address: string;
  Age: string;

  constructor(
    public pregS: PregnancyService,
    private http: HttpClient,
    public ps: PatientsService,
    public es: ExamsService,
    private fb: UntypedFormBuilder,
    public ls: LoginService
  ) {}

  getCalendarData() {
    this.http.get(`/api/getenv`).subscribe(
      (data) => {
        this.env = data;
        if (data["haslogo"]) {
          this.ls.getImageFromService();
        }
      },
      (error) => {
        console.log(error);
      }
    );
    this.http
      .get(`/api/newExams/getAllCalForms/${this.pregID}`)
      .subscribe((data) => {
        const pat = new Patient(
          data[0].calendar[0]["ID"],
          data[0].calendar[0]["First"],
          data[0].calendar[0]["Last"],
          data[0].calendar[0]["phone"]
        );

        data[0].calendar[0].CreationTime =
          data[0].calendar[0].CreationTime.replace("T", " ").replace("Z", "");

        this.selcForm = data[0].calendar[0];
        this.ps.setPat(pat);
        this.Pat = this.ps.getPat();
        // this.LMP = data[0].calendar[0]["LMP"];
        this.Birth = data[0].calendar[0]["Birth"];
        this.Address = data[0].calendar[0]["Address"];
        this.Age = this.getAgeMo();

        this.claloaded$.next();
        this.getSigners();
      }),
      (error) => {
        console.log(error);
      };
  }

  getAgeMo(atDate: Date = new Date()) {
    if (!this.Birth) return "";
    let days = Math.round(
      (atDate.valueOf() - Date.parse(this.Birth)) / (1000 * 60 * 60 * 24)
    );
    let years = Math.floor(days / 365.25);
    let part = days - years * 365.25;
    return Math.floor(days / 365.25) + "y" + Math.floor(part / 30) + "m";
  }

  getSigners() {
    this.http.get("/api/newExams/signers/" + this.pregID).subscribe(
      (data) => {
        this.signers = data;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  resetForm() {
    this.loaded = false;
    let cal = {};
    cal["AutoIndex"] = [""];
    cal["pregID"] = [""];
    cal["firstTri"] = [""];
    cal["secondTri"] = [""];
    cal["thirdTri"] = [""];
    cal["LMP"] = [""];
    cal["UID"] = [""];
    cal["PID"] = [""];
    cal["GID"] = [""];
    cal["CreationTime"] = [""];
    cal["Opener"] = [""];
    cal["ID"] = [""];
    cal["Deleted"] = [""];
    cal["copied"] = [""];
    cal["plannerComment"] = [""];
    cal["printAllFields"] = [""];

    for (let i = 1; i <= 42; i++) {
      cal["week" + i] = [""];
      cal["week" + i + "Comment"] = [""];
    }
    this.calForm = this.fb.group(cal);
    this.es.examID = 0;

    this.load(
      this.calForm,
      this.es.patServ.getPat().ID == "default" ? null : this.es.pregnancies,
      this.es.patServ.getPat().PID,
      this.es.patServ.getPat().ID
    );
  }
  load(formarray, pregs, pid, ID) {
    var nosave = [
      "AutoIndex",
      "UID",
      "PID",
      "GID",
      "CreationTime",
      "Opener",
      "ID",
      "copied",
      "cDefaults",
    ];
    var tpreg;
    if (ID == "default") {
      this.selcForm = null;
      this.pregID = null;
      this.es.pregnancies = null;
      this.Defaults = null;
      this.LMP = null;
    }
    tpreg = "null";
    if (this.selcForm) tpreg = this.selcForm.pregID;
    if (pregs && pregs.length > 0 && !pregs[0]["ClosedDate"])
      tpreg = pregs[0].AutoIndex.toString();
    let f = this.http.post("/api/newExams/get", {
      table: "calendar",
      pregID: tpreg,
      PID: pid.toString(),
      ID: ID,
    });
    f.subscribe(
      (data) => {
        if (!data.hasOwnProperty("empty")) {
          // this.Defaults=null;
          this.getDefaults().subscribe((data: any) => {
            this.Defaults = data && data.length > 0 ? data[0].AutoIndex : null;
          });
          if (data["pregID"]) this.pregID = data["pregID"];
          for (let key in data) {
            if (formarray.controls.hasOwnProperty(key)) {
              if (data[key] !== null)
                formarray.controls[key].setValue(data[key], {
                  emitEvent: false,
                });
              else formarray.controls[key].setValue("", { emitEvent: false }); //empty string ruins formcontrol using dumb hack so things work in anatomy form
            }
          }
        } else {
          //empty - reset everything
          for (let key in formarray.controls)
            formarray.controls[key].reset("", { emitEvent: false });
        }
        for (let key in formarray.controls) {
          if (this.es.isClosed) {
            formarray.controls[key].disable();
          } else if (!nosave.includes(key)) {
            formarray.controls[key].valueChanges
              .pipe(debounceTime(400), distinctUntilChanged())
              .subscribe((data) => {
                this.saveVals(key, formarray.controls[key].value);
              });
          }
        }
        this.formLoaded$.next();
        this.loaded = true;
      },
      (error) => {
        console.log(error);
      }
    );
    return f;
  }

  saveVals(key: string, value: any) {
    var p = this.es.patServ.getPat();
    var tpreg;
    if (this.selcForm) tpreg = this.selcForm.pregID;
    else
      tpreg =
        this.es.pregnancies &&
        this.es.pregnancies.length > 0 &&
        this.es.pregnancies[0].AutoIndex
          ? this.es.pregnancies[0].AutoIndex
          : "null";
    let values = {
      table: "calendar",
      Preg: tpreg,
      PID: p.PID,
      field: key,
      value: value,
    };
    this.http.post("/api/newExams/save", values).subscribe(
      (data) => {
        console.log(data);
        //later on add observer for saves/errors
      },
      (error) => {
        // do this part later
        console.log(error);
      }
    );
  }

  getDateByWeek(week, lmp = this.LMP) {
    var date;
    if (lmp) {
      date = new Date(lmp);
      date.setDate(date.getDate() + Number(week) * 7);
      return (
        date.getUTCFullYear() +
        "-" +
        CalendarService.add0(date.getMonth() + 1) +
        "-" +
        CalendarService.add0(date.getDate())
      );
    } else return "";
  }

  static add0(val: number): string {
    if (val > 9) return val.toString();
    return "0" + val.toString();
  }

  getWeek(i, j, tri = null) {
    var key;
    if (!j) {
      j = 0;
    }
    if (tri) this.trimester = tri;
    switch (i.toString()) {
      case "1":
        key = String(j + 1);
        return String(j + 1);
      case "2":
        key = String(j + 6 + 1);
        return String(j + 6 + 1);
      case "3":
        if (this.trimester == "second") {
          key = String(j + 10 + 4);
          return String(j + 10 + 4);
        }
        key = String(j + 10 + 1);
        return String(j + 10 + 1);
      case "4":
        key = String(j + 15 + 1);
        return String(j + 15 + 1);
      case "5":
        key = String(j + 19 + 1);
        return String(j + 19 + 1);
      case "6":
        if (this.trimester == "third") {
          key = String(j + 23 + 4);
          return String(j + 23 + 4);
        }
        key = String(j + 23 + 1);
        return String(j + 23 + 1);
      case "7":
        key = String(j + 27 + 1);
        return String(j + 27 + 1);
      case "8":
        key = String(j + 32 + 1);
        return String(j + 32 + 1);
      case "9":
        key = String(j + 36 + 1);
        return String(j + 36 + 1);
      default:
        break;
    }
  }

  edd(asTime = false, lmp = "", invalid = false) {
    let d = new Date();
    if (lmp != "") {
      let dt = Date.parse(lmp);
      d.setTime(dt + 40 * 7 * 24 * 60 * 60 * 1000);
      if (asTime) return d;
      return d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear();
    } else if (this.LMP) {
      let dt = Date.parse(this.LMP);
      d.setTime(dt + 40 * 7 * 24 * 60 * 60 * 1000);
      if (asTime) return d;
      return d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear();
    }

    let ov = Date.parse(this.calForm.get("LMP").value);
    d.setTime(ov + 40 * 7 * 24 * 60 * 60 * 1000);
    if (invalid) return "";
    if (asTime) return d;

    return d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear();
  }

  getDefaults() {
    return this.http.get("/api/defaults/y");
  }
  setDefaults() {
    this.http
      .get("/api/newExams/applydefaults/" + this.pregID)
      .subscribe((data) => {
        console.log(data);
        this.resetForm();
      });
  }

  deleteCalendar() {
    const pregnancyId = this.es.pregnancies[0].AutoIndex.toString();

    this.http
      .put(`/api/planner/delete/${pregnancyId}`, {})
      .subscribe((data) => {
        console.log(data);
        this.caldeleted$.next();
      });
  }

  deleteClaendar() {
    this.http
      .get("/api/newExams/deleteCalendar/" + this.pregID.toString())
      .subscribe((data) => {
        console.log(data);
        this.caldeleted$.next();
      });
  }
}
