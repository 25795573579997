import { Subscription } from 'rxjs';
import { UltrasoundMeasurementsService } from './../ultrasound-measurements.service';
import { Component, OnInit,Input} from '@angular/core';
import { ExamsService } from '../exams.service';

@Component({
  selector: 'app-mesure-us',
  templateUrl: './mesure-us.component.html',
  styleUrls: ['./mesure-us.component.scss']
})
export class MesureUsComponent implements OnInit {
  @Input('MyMeasure') name: string;
  ultrasoundMeasurements={}
  fetusSub: Subscription;

 constructor(public us: UltrasoundMeasurementsService,private es: ExamsService) {
   
   
 }
 
 async ngOnInit(): Promise<void> {
  
    this.us.loaded$.subscribe(()=>{
      this.ultrasoundMeasurements =this.us.getMeas(this.name,this.es.getFetus())
    })

    this.fetusSub = this.es.fetusChange$.subscribe(() => {
      this.ultrasoundMeasurements =this.us.getMeas(this.name,this.es.getFetus())
    });
 }

 
 
 ngOnDestroy() {
  if(this.fetusSub){
    this.fetusSub.unsubscribe();
  }
}


}
