import { AfterViewInit, Component, OnDestroy, OnInit,SecurityContext } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {Patient} from '../../../app/patients/patient';
import {PrintService} from '../../print.service';
import { Subscription } from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import { DomSanitizer,SafeHtml} from '@angular/platform-browser';
import { DiagnosisService } from 'src/app/diagnosis.service';
import { LoginService } from 'src/app/login.service';


@Component({
  selector: 'app-cardio-print',
  templateUrl: './cardio-print.component.html',
  styleUrls: ['./cardio-print.component.scss']
})
export class CardioPrintComponent implements OnInit {

  examID:number;
  PID:number;
  now:Date;
  
  Direction='ltr';
  FLOWMEAS=['CCA', 'Bulb', 'ICA Prox', 'Mid ICA', 'ECA', 'VA', 'ICA/CCA']

  
  showCharts = false;
  loaded=false;
  hasImages = false;
  imPerPage = 2;
  mct = 0;
  altHeader:SafeHtml;
  showHeader = true;
  showHistory = true;
  sub: Subscription;
  groups = ['Mitral','Tricuspid','Aortic','Pulmonic']


  constructor(private ds: DiagnosisService,private route: ActivatedRoute, public print:PrintService, public translate: TranslateService, private sanitizer: DomSanitizer, public ls:LoginService) { 
    this.now=new Date();
   this.ds.isCardio=true;
  }

  ngOnInit() {
    this.translate.use(this.route.snapshot.params.LANG);
    if(this.route.snapshot.params.LANG == 'he-IL' || this.route.snapshot.params.LANG == 'ar-JO')
      this.Direction='rtl';
    
      this.loaded=true;
    let code = this.route.snapshot.params['code'];
    this.examID = this.route.snapshot.params.examid;
    this.PID= this.route.snapshot.params.PID;
    if(this.route.snapshot.params["images"] == "y"){
      this.hasImages = true;
      this.imPerPage = this.route.snapshot.params["perpage"];
    }
    if(this.route.snapshot.params["perpage"] == "All"){
      this.hasImages = true;
      this.imPerPage = 2;
      this.print.allImages = true;
    }
    else
      this.print.allImages = false;
    if(this.route.snapshot.params.hasOwnProperty('showheader'))
      this.showHeader = this.route.snapshot.params['showheader'] == 'y';
    this.print.loadExam(this.examID,this.PID, this.hasImages,code);
    this.print.loadMsg.subscribe(()=> {
      if(this.route.snapshot.params.charts == 'y')
        this.showCharts=true;
      if(this.print.env['htmlheader']){
        this.altHeader=this.sanitizer.bypassSecurityTrustHtml(this.print.env['htmlheader']);
      }

      
    });
   
    
    
  }

  getBMI(){
    if(!this.print.Pat.Height || ! this.print.Pat.Weight)
      return "";
    return Math.round(10 * this.print.Pat.Weight / Math.pow(this.print.Pat.Height/100,2))/10;
  }

  getBSA(){
    if(this.print.Pat['BSAus'])
      return this.print.Pat['BSAus'];
    if(!this.print.Pat.Height || ! this.print.Pat.Weight)
      return "";
    return (Math.round(100 * Math.sqrt((this.print.Pat.Height * this.print.Pat.Weight)/3600)) / 100);
  }

  dopplerHas(organ:string):boolean{
   let all = ['PV','PG','MG','VA','RFV'];
   for(let m of all){ 
    if(this.print.cardioDoppler[organ + m])
      return true;
   }
   switch(organ){
     case 'LVOT':
       if(this.print.cardioDoppler['LVOTDiam'] || this.print.cardioDoppler['LVOTTrace'] || this.print.cardioDoppler['LVOTEnv'] || this.print.cardioDoppler['LVOTVTI'] || this.print.cardioDoppler['HR'] || this.print.cardioDoppler['LVSV'] || this.print.cardioDoppler['LVCO'])
        return true;
      break;
      case 'Tricuspid':
        if(this.print.cardioDoppler['TricuspidEPP'] || this.print.cardioDoppler['RAP'])
          return true;
        break;
      case 'Mitral':
        if(this.print.cardioDoppler['PISA']|| this.print.cardioDoppler['VENACONTRACTA'])
          return true;
        break;
      case 'LV Filling':
        if(this.print.cardioDoppler['Ewave']|| this.print.cardioDoppler['Awave'] || this.print.cardioDoppler['EA']|| this.print.cardioDoppler['DecT'])
          return true;
        break;
   }
   return false;
  }
  getDb(str){
    return str.replace(" ", "");
  }

}
