import { Component, OnDestroy, OnInit,Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { ExamsService } from '../exams.service';
import { MeasgrowthService } from '../measgrowth.service';

export interface fetalGrowthElement {
  Date: string;
  position: number;
  GA: number;
  EFW: string;
  Headlock: string;
}

@Component({
  selector: 'app-weight-assessment',
  templateUrl: './weight-assessment.component.html',
  styleUrls: ['./weight-assessment.component.scss']
})
export class WeightAssessmentComponent implements OnInit, OnDestroy {
  @Input('print') forprint: boolean;
  @Input('direction') dir: string='ltr';
  @Input('fetus') fetus: number;
  displayedColumns: string[] = ['position', 'Date', 'GA', 'EFW','Headlock'];
  // dataSource = ELEMENT_DATA;
  dataSource = [];
  IsWeightAss = false;
  fetusSub;
  measSub;


  constructor(private es: ExamsService, private meas: MeasgrowthService) {
    
   }

  ngOnInit() {
    if(!this.forprint){
      if(this.es.getFetus() !== this.fetus){
        this.fetus = this.es.getFetus();
        this.getWeights()
      }
    }
    this.getWeights();
    this.fetusSub = this.es.fetusChange$.subscribe((res)=>{
      this.fetus = res;
      this.getWeights();
    });
    this.measSub = this.meas.allWeightAssmentsSub.subscribe(
      (res) => {
        if(res)
          this.getWeights(); 
      }
    )
    this.measSub = this.meas.addEFW.subscribe((val) => {
      this.dataSource.push({ position: this.dataSource.length +1, Date: new Date().toISOString().replace('T', ' ').replace('Z', ''), GA: this.es.examGAByCreation(new Date().toISOString().replace('T', ' ').replace('Z', '')), EFW: val , Headlock: this.meas.measref["EFW 1"].WeightType})
    })
  }
  ngOnDestroy(){
    this.fetusSub.unsubscribe()
  }

  getWeights(){
    this.es.getAllWeightAssByPre(this.es.pregId, this.es.CreationTime, this.fetus).subscribe(
      (res: any) => {
        if(this.es.ExamType === 'Weight Assessment' || this.es.ExamType === 'Tech Exam') {
          this.meas.allWeightAssments = [];
          this.dataSource = [];
          var lastWeightTable: any[] = [];
          lastWeightTable = res;
          lastWeightTable.forEach((i) => {
            if(!i.Weight1Type){
              i.Weight1Type = this.meas.measref["EFW 1"].WeightType;
            }
            this.dataSource.push({ position: this.dataSource.length +1, Date: i.CreationTime, GA: this.es.examGAByCreation(i.CreationTime), EFW: this.meas.calcEFW(i)[0].y , Headlock: i.Weight1Type})

          })
          this.IsWeightAss = true;
        } else {
          this.IsWeightAss = false;
        }
      },(e)=> {console.log(e)}
    )
  }

}
