import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup } from '@angular/forms';
import { ExamsService } from './exams.service';
import  gastroDiagnosisList  from './../assets/tables/gastroDiagnosis.json'
import { Subject, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AutotextService } from './autotext.service';


@Injectable({
  providedIn: 'root'
})
export class GastroService implements OnDestroy{

  gastroForm: UntypedFormGroup;
  diagnosis= [];
  diagnosisChange$ = new Subject<void>();
  diag1:any;
  sub = new Subscription();
  last;

  summaryDiagnosisAT;
  detailsFinding=[
    {name:'Terminal ileum',db:"Terminal", value: 0, comment:null},
    {name:'Caecum',db:"Caecum", value: 0, comment:null},
    {name:'Ascending colon',db:"AscendingC", value: 0, comment:null},
    {name:'Hepatic flexure',db:"HepaticF", value: 0, comment:null},
    {name:'Transverse colon',db:"TransverseC", value: 0, comment:null},
    {name:'Splenic flexure',db:"SplenicF", value: 0, comment:null},
    {name:'Descending colon',db:"DescendingC", value: 0, comment:null},
    {name:'Sigma',db:"Sigma", value: 0, comment:null},
    {name:'Recto Sigma',db:"RectoS", value: 0, comment:null},
    {name:'Rectum',db:"Rectum", value: 0, comment:null},
    {name:'Anus',db:"Anus", value: 0, comment:null}
  ];
  recoverySTR=`המטופל משתחרר כשהוא מתמצא בזמן ובמקום, רפלקס בליעה ושיעול שמורים\nמצב המודינמי יציב, ללא בחילות הקאות או כאבי בטן משמעותיים.`
  dissmissionSTR=`יש לחזור הביתה בליווי, למנוחה של מספר שעות עקב קבלת הטשטוש חל איסור מוחלט על נהיגה בשמונה השעות הקרובות\nבמידה ויופיעו כאבי בטן,חום,הקאות או דימום רקטלי משמעותי ביממה שלאחר הבדיקה יש לפנות לרופא המבצע בטל: 04-6191885\nבמידה ולא ניתן ליצור קשר יש לפנות לחדר מיון עם מכתב זה`



  constructor(private http:HttpClient,private fb:UntypedFormBuilder, public es:ExamsService, private atS:AutotextService) {
    this.resetForm();
    this.sub = this.es.examLoaded$.subscribe(() => {
      this.resetForm();
      if(this.es.ExamType =='Colonoscopy' || this.es.ExamType=='Gastroscopy' || this.es.ExamType == 'Sigmoidoscopy')
        this.es.fillForm(this.gastroForm, "gastro", false, true,['inputControl']);
      this.buildList();
      if(es.isClosed){
        this.gastroForm.disable({emitEvent:false})
      }
    })
   }

   resetForm(){
     this.gastroForm = this.fb.group({
      Equipment: [''],
      ReffDoc: [''],
      Reasone : [''],
      Comments: [''],
      ExtentOfExam: [''],
      Medication: [''],
      FindingsComment:[''],
      PathologyComments:[''],
      Unit:[''],
      Dose: [''],
      imag1:[''],
      imag2:[''],
      scope1:[''],
      scope2:[''],
      Preparetion: [''],
      Colonoscopy:[''],
      ColoComment: [''],
      DignosisTree:[''],
      Diagnosis:[''],
      Recovery:[''],
      Recomm:[''],
      Esophagus:[''],
      EsophagusComment:[''],
      Stomach:[''],
      StomachComment:[''],
      Duodenum:[''],
      DuodenumComment:[''],
      EsoJunction:[''],
      Terminal: [''],
      TerminalComment:[''],
      Caecum: [''],
      CaecumComment:[''],
      AscendingC: [''],
      AscendingCComment:[''],
      HepaticF: [''],
      HepaticFComment:[''],
      TransverseC: [''],
      TransverseCComment:[''],
      SplenicF: [''],
      SplenicFComment:[''],
      DescendingC: [''],
      DescendingCComment:[''],
      Sigma: [''],
      SigmaComment:[''],
      RectoS: [''],
      RectoSComment:[''],
      Rectum: [''],
      RectumComment:[''],
      HMO: [''],
      OtherStaff:[''],
      ASA:[''],
      BBPS:[''],
      Mellampeti:[''],
      Dissmission:[''],
      TimeOut:[''],
      Quality:[''],
      inputControl:[''],
      Anus:[''],
      AnusComment:[''],
      EsoJunctionComment:['']
     })
   }

   buildList(data = this.gastroForm.get('DignosisTree').value){
      this.getATS();
       this.diagnosis=[]
       var diagnosisNum: string[] =[];
       if(data){
         diagnosisNum = data.split(',');
         diagnosisNum.forEach((i) => {
           if(i)
            if(+i < gastroDiagnosisList.length)
              this.diagnosis.push({index:i, value:gastroDiagnosisList[i].value})
            else
              this.diagnosis.push({index: i, value: this.getTextsWithID(i)})
         })
       }
   }

   deleteDiagnosis(index:number){
     if(this.es.isClosed)
      return;
     var t ="";
     if(this.diagnosis && index < this.diagnosis.length){
      this.diagnosis.splice(index, 1);
      t= this.diagnosis.length === 1 ? this.diagnosis[0].index : '';
      if(this.diagnosis.length >1){
        this.diagnosis.forEach((i) => {
          if(t === ''){
            t=i.index
          }else{
            t = t+','+i.index;
          }
        })
      }
      this.gastroForm.get('DignosisTree').patchValue(t);
      this.diagnosisChange$.next();

    }
   }
   saveDiagnosis(item = this.diag1, addnew = false){
      if(item.value !== ''){
          this.last = item
          var index;
          if(addnew)
            index = gastroDiagnosisList.length
          else
            index = gastroDiagnosisList.indexOf(item).toString()
        if(!this.gastroForm.get('DignosisTree').value){
         this.gastroForm.get('DignosisTree').setValue(index.toString())
        }else if(this.gastroForm.get('DignosisTree').value == ''){
         this.gastroForm.get('DignosisTree').setValue(index.toString())
        }else{

         if(item && index != -1){
           var t = this.gastroForm.get('DignosisTree').value;
           if(! t.split(',').includes(index.toString())){
             t += ','+index;
             this.gastroForm.get('DignosisTree').patchValue(t);
           }
         }
        }
        this.diag1 ="";
        this.diagnosisChange$.next();
        // }
     }
  }

  ngOnDestroy(): void {
      this.sub.unsubscribe()
  }


  getFindings(data){
    var temp = [];
    this.detailsFinding.forEach((i) => {
      i.value = data[i.db];
      i.comment = data[i.db+'Comment']
      if(i.value > -1){
        temp.push(i);
      }
    })
    return temp;
  }
  getATS(){
    let res = this.http.get('/api/autotext',{params:{field:'89'}});
    res.subscribe((data) => {
      this.summaryDiagnosisAT = data;
    })
  }

  getTextsWithID(id){
    if(this.last)
      return this.last.text;
    else
      return this.summaryDiagnosisAT[id - 130].Text;
  }

  getPatients(){
    return this.http.get('/api/gastro/GetTodayPatients');
  }
  getExams(PID:number){
    return this.http.get(`/api/gastro/GetExams/${PID}`);
  }

  signExam(signer){
    return this.http.post('/api/gastro/close/'+ this.es.examID,{'signer': signer});
  }
  getClosing(){
    return this.http.get('/api/gastro/closing/' + this.es.examID);
  }

  saveSign(form){
    this.http.post('/api/gastro/saveSign', {form: form, examid:this.es.examID}).subscribe((data) =>{
      console.log(data);

    })
  }
  getAllSigns(){
    return this.http.get('/api/gastro/getAllSigns/'+this.es.examID);
  }
}
