import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import {AutotextService} from '../autotext.service';
import { LoginService } from '../login.service';


@Component({
  selector: 'app-autotext-row',
  templateUrl: './autotext-row.component.html',
  styleUrls: ['./autotext-row.component.scss']
})
export class AutotextRowComponent implements OnInit {

  @Input('id') id: number;
  @Input('text') Text: string;
  @Output() updated = new EventEmitter<null>();
  @Output() add = new EventEmitter<string>();
  isChecked=false;
  edit: boolean = false;
  debounce=500;
  firstRow=false;

  constructor(public atS: AutotextService, public ls:LoginService) { }

  ngOnInit() {
    if(this.id == null)
      this.setEdit();
    if(this.Text == '')
      this.firstRow=true;
  }

  setEdit(){
    this.edit=true;
  }

  save(move = false){
    let res = this.atS.saveText(this.Text,this.id, move);
    res.subscribe((data)=>{
      if(this.id == null)
        this.id = data['insertId'];
        if(this.firstRow){
          this.Text = '';
          this.id = null;
        }
        this.updated.emit();

    },(error)=>{
      console.log(error);
    });
  }

  addText(){
    if(! this.isChecked)
      return;
    this.add.emit(this.Text);
  }

  delete(){
    this.atS.delete(this.id).subscribe((data)=>{
      this.updated.emit();
    },(error)=>{
      console.log(error);
    });
  }


}
