<span class="login">
	<app-commerror></app-commerror>

	<div class="login-wrapper l-table">
		
		<div class="l-row">

			<header class="l-flex-c login-header">
				<div class="login-logo">
					<img src="assets/icons/logo.svg" alt="Artisan sonics logo"/>
				</div>
				<h1 class="login-title">
					We Communicate Healthcare <strong>To Your Patient</strong>
				</h1>
			</header>

		</div>

		<div class="l-row"><div class="l-td-m h100 login-main">

			
			<h2 class="login-title-2">
				Your patient is at the center of your work,
				<br/>
				<strong>
					With Artisan sharing healthcare information Made Easy 
				</strong>
			</h2>

			<div class="login-form">
				<ng-container *ngIf="!loginService.clinicOptions">
				
                <div *ngIf="loginService.loginError" class="strong">{{loginService.loginError}}</div>
				<div class="login-form-row" *ngIf="! loginService.getCode">
					<input class="login-form-field focusOnParent" type="text" placeholder="User Name" aria-label="user name" [(ngModel)]="user" (keyup)="clean()" (keyup.enter)="checkLogin()"/>
					<i class="login-form-icon icon-userName"></i>
				</div>

				<div class="login-form-row" *ngIf="! loginService.getCode">
					<input class="login-form-field focusOnParent" [type]="hidepw? 'password' : 'text'" placeholder="Password" aria-label="password" (keyup.enter)="checkLogin()" (keyup)="clean()" (focus)="typed=true" [(ngModel)]="pw" pattern="^[a-zA-Z0-9]+$"/>
					<i class="login-form-icon {{! typed ? 'icon-password' : (hidepw ? 'icon-openpw' : 'icon-closepw')}}" (click)="hidepw=!hidepw"></i>
				</div>

				<div *ngIf="loginService.getCode" class="login-form-row">
					<input class="login-form-field focusOnParent" type="text" placeholder="Code from sms" [(ngModel)]="code" (keyup.enter)="checkLogin()" aria-label="user name" />
					<i class="login-form-icon icon-code"></i>
				</div>

				<input type="submit" class="login-form-submit t-btn-grad" value="Login" (click)="getotp();"/>
				</ng-container>
				<ng-container *ngFor="let c of loginService.clinicOptions; let i = index">
					<div class="login-form-row" (click)="setClinic(i)">
						<span style='width:50%'>{{c.ClinicTitle}}</span>
						<i class="login-form-icon icon-userName"></i>
					</div>
				</ng-container>
			</div>

		</div></div>

		<div class="l-row">
			<footer class="l-flex-c l-flex-w login-footer">
				
				<div class="l-fg1 login-footer-text">
					Got Problems with login? 
					<a >Contact Us</a>
				</div>

				<div class="login-footer-credit">
					© All rights reserved Artisan Sonics {{ currentYear }}
				</div>

			</footer>
		</div>

		
	</div>

	<img id="login_display" src="../../assets/images/login-display.png" alt="" *ngIf="! loginService.altSplashPage">	

</span>
<img *ngIf="loginService.altSplashPage" [src]="loginService.altSplashPage" style="position:absolute; top:0; right:0; width: 100%">
<app-totpbox *ngIf="showLogin" (closed)="showLogin=false;" (login)="checkLogin()" [uname]="user" [pw]="pw"></app-totpbox>