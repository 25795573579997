import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder,UntypedFormGroup,FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { AutotextService } from 'src/app/autotext.service';
import {ExamsService} from '../../exams.service';
import { CardioService } from '../cardio.service';

@Component({
  selector: 'app-cardio-tissue-dop',
  templateUrl: './cardio-tissue-dop.component.html',
  styleUrls: ['./cardio-tissue-dop.component.scss']
})
export class CardioTissueDopComponent implements OnInit, OnDestroy {

  grp: UntypedFormGroup;
  ATS = ["LV", "Septal", "Lateral", "LVAfter", "SeptalAfter", "LateralAfter"]
  sub = new Subscription();
  mediaSub: Subscription;

  constructor(private fb: UntypedFormBuilder, public es: ExamsService, public atS:AutotextService, public cardioS:CardioService) {
    this.grp = fb.group({
      Esep:[''],
      Ssep:[''],
      Eesep:[''],
      Elat:[''],
      Slat:[''],
      Eelat:[''],
      Lateral:[''],
      Septal:[''],
      Ewave:[''],
      Awave:[''],
      EA:[''],
      DecT:[''],
      LV:[''],
      RightVentrical:[''],
      LeftVentrical:[''],
      EsepAfter:[''],
      SsepAfter:[''],
      EesepAfter:[''],
      ElatAfter:[''],
      SlatAfter:[''],
      EelatAfter:[''],
      LateralAfter:[''],
      SeptalAfter:[''],
      EwaveAfter:[''],
      AwaveAfter:[''],
      EAAfter:[''],
      DecTAfter:[''],
      LVAfter:[''],
      RightVentricalAfter:[''],
      LVOTPV:[''],
      LVOTPG:[''],
      LVOTMG:[''],
      LVOTVA:[''],
      LVOTRFV:[''],
      LVOTDiam:[''],
      LVOTTrace:[''],
      LVOTEnv:[''],
      LVOTVTI:[''],
      HR:[''],
      LVSV:[''],
      LVCO:['']
    });
   }

  ngOnInit() {
    this.es.fillForm(this.grp,"cardioTissue",false,true);
    for(let g of this.ATS){
      this.sub = this.grp.get(g).valueChanges.pipe(distinctUntilChanged()).subscribe((data) => {
        // if(data)
          this.cardioS.addToFindings(data, g);
      })
    }
    this.es.getSRs();
    if(!this.es.isClosed)
      this.mediaSub =  this.es.mediaArrived$.subscribe((mediatype)=>{
        if(mediatype == 'html')
          this.es.fillForm(this.grp,"cardioTissue",false,true);
      });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

}
