import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { AutotextService } from '../autotext.service';
import { DiagnosisService } from '../diagnosis.service';
import { ExamsService } from '../exams.service';
import { LoginService } from '../login.service';
import { TranslateService } from '@ngx-translate/core';

interface Diagnosis {
  id: number;
  step: number;
  name: string;
  parentId: string;
  number: number;
  autoSearchString: string;
}
@Component({
  selector: 'app-diagnoses',
  templateUrl: './diagnoses.component.html',
  styleUrls: ['./diagnoses.component.scss']
})
export class DiagnosesComponent implements OnInit,OnDestroy {

  @Input('group') Group: string;
  @Input('form') Form: UntypedFormControl;
  @Input('formGroup') formGroup: UntypedFormGroup;

  @Input('text') Text: UntypedFormControl;
  @Input('textStress') TextStress: UntypedFormControl;
  // TextStress: FormControl;
  @Input('autoid') ATsID: number;
  @Input('autoidstress') ATsIDStress: number;
  lastDiagnosis: string;



  
  @Input('insummary') insummary:boolean;
  @Input('inpregnancy') inpregnancy:boolean;
  @Input('close') closed: Observable<void>;
  @Input('textonly') textOnly: boolean = false;

  diag1: number;
  diag2: number;
  diag3: number;
  diag4: number;
  diag5: number;
  diag6: number;

  Diagnoses = [];
  Indication = [];
  searchDiagnosticText="";
  diagnosesLeaves: Diagnosis[];
  filteredDiagnosis = [];
  selectedDiagnosisOpenSearch=false

  private closeSubscription: Subscription;
  atSTitleDiagnosisComments = this.translate.instant("GUI.Auto Text.Titles.Diagnosis Comments");
  atSTitleAnomaliesIndicationComment = this.translate.instant("GUI.Labels.Anomalies Indication Comment");

  constructor(
    public ds: DiagnosisService,
    public es: ExamsService,
    public atS: AutotextService,
    public ls: LoginService,
    public translate: TranslateService
  ) {
    ls.isSheba? this.diagnosesLeaves = ds.diagnosis_list.filter(
      (diagnose) => diagnose[5]
    ):this.diagnosesLeaves=[]
  }

  ngOnInit() {
    //gererate list after the form is loaded
    this.es.formLoaded$.subscribe(() => {
      if(this.insummary){
        this.Diagnoses = this.ds.BuildList(this.Form.value, true);
      }else{
        this.Diagnoses = this.ds.BuildList(this.Form.value);
      }
    });
    this.ds.inAnatomy = this.Group === 'chestFetal';
    this.ds.isFetal = this.es.ExamType === 'Fetal Echo' || this.es.ExamType==='Pediatric Echo';
    this.ds.inSummary = this.Group === 'summary';
    if(this.ds.isFetal){
      this.ATsID= 43;
    }
    if (!this.es.isClosed) {
      this.es.getLastSummary().subscribe((data) => {
        if(data['summary'] && data['summary']["Anomalies"])
          this.lastDiagnosis = data['summary']["Anomalies"];
      }, (err) => {
        console.log(err);
      });
    }
    if(closed){
      this.closeSubscription = this.closed.subscribe(()=>{
        this.Text.disable({emitEvent: false});
        this.TextStress.disable({emitEvent: false});

      })
    }
  }
  addLastDiagnosis() {
    if (this.formGroup.get("Anomalies").value)
      this.formGroup.get("Anomalies").setValue(this.formGroup.get("Anomalies").value + this.lastDiagnosis);
    else
      this.formGroup.get("Anomalies").setValue(this.lastDiagnosis);
  }

  ngOnDestroy(): void {
      if(this.closeSubscription)
        this.closeSubscription.unsubscribe();
  }

  addDiagnosisFreeSearch(){
    if(this.searchDiagnosticText && this.ls.isSheba){
      const [selectedDiag]=this.diagnosesLeaves.filter((leave)=>{
     return leave[5]===this.searchDiagnosticText
      })

      const diagCode=selectedDiag[0]
      if (!diagCode) return
      this.Diagnoses.push([diagCode,this.ds.getName(diagCode)]);
      const str = 'summary';
      this.ds.save(this.Diagnoses, str);
      this.searchDiagnosticText=""
      this.selectedDiagnosisOpenSearch=false
      this.filteredDiagnosis=[]

    }

  }

  addDiagnosis() {
   
   if(this.ls.isSheba){
    if (this.diag2 && this.ds.getList(this.diag2).length == 0) {
      this.Diagnoses.push([this.diag2, this.ds.getName(this.diag2)]);
    this.diag1 = null;
    this.diag2 = null;
    var str = this.ds.isFetal ? 'summary' : this.Group;
    this.ds.save(this.Diagnoses, str);
  }
    if (this.diag3 && this.ds.getList(this.diag3).length == 0) {
      let diagnosisString=
        this.ds.getName(this.diag3)
        this.Diagnoses.push([this.diag3, diagnosisString]);
      this.diag1 = null;
      this.diag2 = null;
      this.diag3 = null;
      var str = this.ds.isFetal ? 'summary' : this.Group;
      this.ds.save(this.Diagnoses, str);
    }
    if(this.diag4 &&  this.ds.getList(this.diag4).length == 0){
      this.Diagnoses.push([this.diag4,this.ds.getName(this.diag4)]);
      this.diag1 = null;
      this.diag2 = null;
      this.diag3 = null;
      this.diag4 = null;
      const str =  'summary' 
      this.ds.save(this.Diagnoses, str);
    }
    if(this.diag5 && this.ds.getList(this.diag5).length == 0){
      this.Diagnoses.push([this.diag5,this.ds.getName(this.diag5)]);
      this.diag1 = null;
      this.diag2 = null;
      this.diag3 = null;
      this.diag4 = null;
      this.diag5 = null;
      const str = 'summary';
      this.ds.save(this.Diagnoses, str);
    }if(this.diag6){
      this.Diagnoses.push([this.diag6,this.ds.getName(this.diag6)]);
      this.diag1 = null;
      this.diag2 = null;
      this.diag3 = null;
      this.diag4 = null;
      this.diag5 = null;
      this.diag6 = null;
      const str = 'summary';
      this.ds.save(this.Diagnoses, str);
    }
    return
   }
    if (this.diag3) {
      if(this.textOnly)
        this.Text.setValue((this.Text.value  ? this.Text.value + '\n': '')  + this.ds.getName(this.diag2) + ' > ' + this.ds.getName(this.diag3));
      else
        this.Diagnoses.push([this.diag3, this.ds.getName(this.diag1) + ' > ' + this.ds.getName(this.diag2) + ' > ' + this.ds.getName(this.diag3)]);
      this.diag1 = null;
      this.diag2 = null;
      this.diag3 = null;
      var str = this.ds.isFetal ? 'summary' : this.Group;
      this.ds.save(this.Diagnoses, str);
    }
    else
      if (this.diag2 && this.ds.getList(this.diag2).length == 0) {
        if(this.textOnly)
          this.Text.setValue((this.Text.value  ? this.Text.value + '\n': '') + this.ds.getName(this.diag2));
        else
          this.Diagnoses.push([this.diag2, this.ds.getName(this.diag1) + ' > ' + this.ds.getName(this.diag2)]);
        this.diag1 = null;
        this.diag2 = null;
        var str = this.ds.isFetal ? 'summary' : this.Group;
        this.ds.save(this.Diagnoses, str);
      }
  }

  removeDiagnosis(id: number) {
    if (this.es.isClosed)
      return;
    for (let i = 0; i < this.Diagnoses.length; i++) {
      if (this.Diagnoses[i][0] == id) {
        this.Diagnoses.splice(i, 1);
        if (this.ds.isFetal) {
          this.ds.save(this.Diagnoses, 'summary')
        } else {
          this.ds.save(this.Diagnoses, this.Group);
        }
      }
    }
  }

  getDiag(d){
    if(d[1].split(">").length > 2){
      return d[1].split(">")[2]
    }
    return d[1].split(">")[1]
  }

  filterOptions(){
    this.selectedDiagnosisOpenSearch = false;
    if (this.searchDiagnosticText) {
     
      this.filteredDiagnosis = this.diagnosesLeaves.filter((option) =>
        option[5].toLowerCase().includes(this.searchDiagnosticText.toLowerCase())
      );
      const searchMatchAnomalies = this.filteredDiagnosis.filter(
        (option) => option[5].toLowerCase() === this.searchDiagnosticText.toLowerCase()
      );
      if (searchMatchAnomalies.length) {
        this.selectedDiagnosisOpenSearch=true
        
      }
    } else {
      this.filteredDiagnosis = [];
      this.selectedDiagnosisOpenSearch=false
      
    }
  }

}
