import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { AutotextService } from 'src/app/autotext.service';
import { ExamsService } from 'src/app/exams.service';
import { CardioService } from '../cardio.service';

@Component({
  selector: 'app-cardio-findings',
  templateUrl: './cardio-findings.component.html',
  styleUrls: ['./cardio-findings.component.scss']
})
export class CardioFindingsComponent implements OnInit, OnDestroy {
  FindingsForm: UntypedFormGroup;
  sub = new Subscription()
  groups = [ 'Response','Arythmia','Conduction','ST','EndReason','ECG'];
  findings = ['CCA', 'Bulb', 'ICAProx', 'MidICA', 'ECA', 'VA','IMT'];


  constructor(private fb:UntypedFormBuilder, public es: ExamsService,public atS:AutotextService, public cardioS:CardioService) { 
    this.FindingsForm = this.fb.group({
      Duration: [''],
      Stage:[''],
      METs:[''],
      HeartRate:[''],
      MaxExpectedHeartRate:[''],
      MaxHeartRate:[''],
      Precentage:[''],
      bpSystolicRest:[''],
      bpDiastolicRest:[''],
      bpSystolic:[''],
      bpDiastolic:[''],
      Response:[''],
      Arythmia:[''],
      Conduction:[''],
      ST:[''],
      EndReason:[''],
      PulseRate:[''],
      PulseStatus:[''],
      Medication:[''],
      Protocol:[''],
      OtherStaff:[''],
      ECG:['']
    });
  }

  ngOnInit() {
    this.es.fillForm(this.FindingsForm,"cardioFindings",false,true);
    this.sub = this.es.formLoaded$.subscribe(() => {
      var age = this.es.patServ.getPat().getAge().split(" ")[0];
      this.FindingsForm.get('MaxExpectedHeartRate').setValue(220- (Number(age)));
      if(! this.FindingsForm.get('Protocol').value)
        this.FindingsForm.get('Protocol').setValue('Bruce', {emitEvent: false})
      this.sub = this.FindingsForm.get('MaxHeartRate').valueChanges.subscribe((data) => {
        var prec = null;
        if(data && +this.FindingsForm.get('MaxExpectedHeartRate').value){
          var MHR = data;
          var EMHR = +this.FindingsForm.get('MaxExpectedHeartRate').value
          prec = (MHR / EMHR) *100;
          prec = prec.toString().split('.')[0];
        }
        if(prec == Infinity){
          prec = null;
        }
        this.FindingsForm.get('Precentage').patchValue(prec);
      })

    })
    for(let g of this.groups){
      this.sub = this.FindingsForm.get(g).valueChanges.pipe(distinctUntilChanged()).subscribe((data) => { 
        // if(data)
          this.cardioS.addToFindings(data, g);
      })
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

}
