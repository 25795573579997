<ng-container [formGroup]="flowmeasForm">
    <div class="w100 l-flex-w t-boxMargin--spacebyPadding summary">
        <div class="w100 summary-anamnesis">
            <div class="m-box">
                <h2>Flow Velocity (cm/sec)</h2>
                <div class="l-flex">
                    <div class="m-box w50" style="margin: 0 1em 0 0;">
                        <h2>Left</h2>
                        <div class="l-flex">
                            <table class="flow-t" style="width: 65%;">
                                <tr>
                                    <td></td>
                                    <td>PSV</td>
                                    <td>EDV</td>
                                </tr>
                                <tr height="30px" *ngFor="let i of FLOWMEAS">
                                    <td><strong>{{i}}</strong></td>
                                    <td><input type="number" [formControlName]="getDb(i+'PSVLEFT')" class="t-field t-field-text"></td>
                                    <td><input type="number" [formControlName]="getDb(i+'EDVLEFT')"  class="t-field t-field-text"></td>
                                </tr>
                                <tr>
                                    <td><strong>IMT</strong></td>
                                    <td colspan="2"><div><input type="number" [formControlName]="'IMTLEFT'" class="t-field t-field-text"></div></td>
                                </tr>
                            </table>
                            <div style="width: 35%;">
                                <img style="border-radius: 10px;" height="100%" width="100%" src="../../../assets/images/leftcarotid.jpg">
                            </div>
                        </div>

                    </div>
                    <div class="m-box w50">
                        <h2>Right</h2>
                        <div class="l-flex">
                            <table class="flow-t" style="width: 65%;">
                                <tr>
                                    <td></td>
                                    <td>PSV</td>
                                    <td>EDV</td>
                                </tr>
                                <tr height="30px" *ngFor="let i of FLOWMEAS">
                                    <td><strong>{{i}}</strong></td>
                                    <td><input type="number" [formControlName]="getDb(i+'PSVRIGHT')"  class="t-field t-field-text"></td>
                                    <td><input type="number" [formControlName]="getDb(i+'EDVRIGHT')"  class="t-field t-field-text"></td>
                                </tr>
                                <tr>
                                    <td><strong>IMT</strong></td>
                                    <td colspan="2"><div><input type="number" [formControlName]="'IMTRIGHT'" class="t-field t-field-text"></div></td>
                                </tr>
                            </table>
                            <div style="width: 35%;">
                                <img style="border-radius: 10px;" height="100%" width="100%" src="../../../assets/images/leftcarotid.jpg">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w100 summary-anamnesis">
            <div class="m-box ">
                <h2>Findings</h2>
                <div class="l-flex-c">
                    <div class="l-justify w50 c-finding">
                        <div class="m-box w100">
                            <h2>Left</h2>
                            <div class="summary-anamnesis i-findings" style="margin-top: -1em;">
                                <section class="m-box" style="padding-bottom: 0;">
                                    <h2>CCA</h2>
                                    <textarea class="scroller" style="min-height: 5em;" formControlName="CCALeft"></textarea>
                                    <!-- /scroller -->
        
                                    <button class="t-btn-edit" *ngIf="! es.isClosed"
                                        (click)="atS.showAuto(flowmeasForm.get('CCALeft'),'CCA Left ',513)">Auto
                                        Text</button>
                                </section>
                            </div>
        
                            <div class="summary-anamnesis i-findings">
                                <section class="m-box" style="padding-bottom: 0;">
                                    <h2>ICA</h2>
                                    <textarea class="scroller" style="min-height: 5em;" formControlName="ICALeft"></textarea>
                                    <!-- /scroller -->
        
                                    <button class="t-btn-edit" *ngIf="! es.isClosed"
                                        (click)="atS.showAuto(flowmeasForm.get('ICALeft'),'ICA Left ',514)">Auto
                                        Text</button>
                                </section>
                            </div>
                            <div class="summary-anamnesis i-findings">
                                <section class="m-box" style="padding-bottom: 0;">
                                    <h2>ECA</h2>
                                    <textarea class="scroller" style="min-height: 5em;" formControlName="ECALeft"></textarea>
                                    <!-- /scroller -->
        
                                    <button class="t-btn-edit" *ngIf="! es.isClosed"
                                        (click)="atS.showAuto(flowmeasForm.get('ECALeft'),'ECA Left ',515)">Auto
                                        Text</button>
                                </section>
                            </div>
                            <div class="summary-anamnesis i-findings">
                                <section class="m-box" style="padding-bottom: 0;">
                                    <h2>VA</h2>
                                    <textarea class="scroller" style="min-height: 5em;" formControlName="VALeft"></textarea>
                                    <!-- /scroller -->
        
                                    <button class="t-btn-edit" *ngIf="! es.isClosed"
                                        (click)="atS.showAuto(flowmeasForm.get('VALeft'),'VA Left ',516)">Auto
                                        Text</button>
                                </section>
                            </div>
                        </div>

                    </div>
                    <!-- Column (end) -->
                    <div class="l-justify w50 c-finding">
                        <div class="m-box w100">
                            <h2>Right</h2>
                            <div class="summary-anamnesis i-findings" style="margin-top: -1em;">
                                <section class="m-box" style="padding-bottom: 0;">
                                    <h2>CCA</h2>
                                    <textarea class="scroller" style="min-height: 5em;" formControlName="CCARight"></textarea>
                                    <!-- /scroller -->
        
                                    <button class="t-btn-edit" *ngIf="! es.isClosed"
                                        (click)="atS.showAuto(flowmeasForm.get('CCARight'),'CCA Right ',517)">Auto
                                        Text</button>
                                </section>
                            </div>
        
                            <div class="summary-anamnesis i-findings">
                                <section class="m-box" style="padding-bottom: 0;">
                                    <h2>ICA</h2>
                                    <textarea class="scroller" style="min-height: 5em;" formControlName="ICARight"></textarea>
                                    <!-- /scroller -->
        
                                    <button class="t-btn-edit" *ngIf="! es.isClosed"
                                        (click)="atS.showAuto(flowmeasForm.get('ICARight'),'ICA Right ',518)">Auto
                                        Text</button>
                                </section>
                            </div>
                            <div class="summary-anamnesis i-findings">
                                <section class="m-box" style="padding-bottom: 0;">
                                    <h2>ECA</h2>
                                    <textarea class="scroller" style="min-height: 5em;" formControlName="ECARight"></textarea>
                                    <!-- /scroller -->
        
                                    <button class="t-btn-edit" *ngIf="! es.isClosed"
                                        (click)="atS.showAuto(flowmeasForm.get('ECARight'),'ECA Right ',519)">Auto
                                        Text</button>
                                </section>
                            </div>
                            <div class="summary-anamnesis i-findings">
                                <section class="m-box" style="padding-bottom: 0;">
                                    <h2>VA</h2>
                                    <textarea class="scroller" style="min-height: 5em;" formControlName="VARight"></textarea>
                                    <!-- /scroller -->
        
                                    <button class="t-btn-edit" *ngIf="! es.isClosed"
                                        (click)="atS.showAuto(flowmeasForm.get('VARight'),'VA Right ',520)">Auto
                                        Text</button>
                                </section>
                            </div>
                        </div>

                    </div>
                    <!-- Column (end) -->
                </div>

            </div>

        </div>
        <div class="w100 summary-anamnesis">
            <div class="m-box" style="height: fit-content;">
                <h2>Summary</h2>
                <textarea class="scroller" formControlName="Summary"></textarea>
                <button class="t-btn-edit" *ngIf="! es.isClosed"
                (click)="atS.showAuto(flowmeasForm.get('Summary'),'Summary ',521)">Auto Text</button>
            </div>
        </div>
    </div>
    <!-- <div class="w100 l-flex-w t-boxMargin--spacebyPadding summary"> -->

    <!-- </div> -->
</ng-container>