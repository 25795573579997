<div class="t-popup popup-patientDetails"  style='display: block'>

    <div class="t-popup-close"></div>
    
    <div class="t-popup-frame">
        <div class="t-popup-body">

            <button class="t-btn-popup-close" aria-label="close popup" (click)="closed.emit()"></button>

            <div class="login-form" *ngIf="totpCode">
                <div *ngIf="loginService.loginError" class="strong">{{loginService.loginError}}</div>
                <div class="login-title">
                    <label>Enter Code</label>
                </div>
                <div class="login-form-row" style="height: auto;" *ngIf="needs_qr && imageUrl">
                    <img [(src)]="imageUrl" alt="" class="img-thumbnail" style="display:block;margin:auto">
                </div>
                <div class="login-form-row" style="height: 4.31em;" [formGroup]="totpForm">
                    <input class="inputBox login-form-field" autofocus type="number" formControlName="totp1" #1 name="1" id="1" maxlength="1" min="0" max="9" (keyup)="onDigitInput($event)"/>
                    <input class="inputBox login-form-field" type="number" formControlName="totp2" #2 name="2" id="2" maxlength="1" min="0" max="9"  (keyup)="onDigitInput($event)"/>
                    <input class="inputBox login-form-field" type="number" formControlName="totp3" #3 name="3" id="3" maxlength="1" min="0" max="9"  (keyup)="onDigitInput($event)"/>
                    <input class="inputBox login-form-field" type="number" formControlName="totp4" #4 name="4" id="4" maxlength="1" min="0" max="9"  (keyup)="onDigitInput($event)"/>
                    <input class="inputBox login-form-field" type="number" formControlName="totp5" #5 name="5" id="5" maxlength="1" min="0" max="9"  (keyup)="onDigitInput($event)"/>
                    <input class="inputBox login-form-field" type="number" formControlName="totp6"  (keyup.enter)="submit()" #6 name="6" id="6" maxlength="1" min="0" max="9"  (keyup)="onDigitInput($event)"/>

                </div>
                <input class="login-form-submit t-btn-grad" type="submit" (click)="submit()" label="Submit Code" (keyup.enter)="submit()" />

			</div> 

            <div class="login-form" *ngIf="!totpCode">
                <div *ngIf="loginService.loginError" class="strong">{{loginService.loginError}}</div>
                <div class="login-title">
                    <label>Enter Answers</label>
                </div>
                <div class="login-form-row" style="background-color: #e4eded;">
                    <span style="align-self: center;">ID:</span>
                    <input [(ngModel)]="ID" class="l-textarea t-field--limitRad w100 h100" style="margin-left: 5px;" placeholder="Please enter ID" />
                </div>
                <div class="login-form-row" style="background-color: #e4eded;">
                    <span style="align-self: center;width: 36%;">Date of Birth:</span>
                    <span>
                        <input [(ngModel)]="Birth" [matDatepicker]="birthpicker" [max]="maxBD" placeholder="dd/mm/yyyy"  class="t-field-text w100"/>
                        <img src="../../assets/icons/date.svg" (click)="birthpicker.open()" style='margin-left: -1.8em;position:absolute'>
                        <mat-datepicker #birthpicker [startAt]="startBD" startView="year"></mat-datepicker>
                    </span>
                    <!-- <input class="l-textarea t-field--limitRad w100 h100" style="margin-left: 5px;" placeholder="Date of Birth" type="date" format="dd-mm-yyyy"/> -->
                </div>
                <div class="login-form-row" style="background-color: #e4eded;">
                    <span style="align-self: center;">Email:</span>
                    <input [(ngModel)]="Email" class="l-textarea t-field--limitRad w100 h100" style="margin-left: 5px;" placeholder="Please enter Email" />
                </div>
                <div class="login-form-row" style="background-color: #e4eded;">
                    <span style="align-self: center;">Phone:</span>
                    <input [(ngModel)]="Phone" class="l-textarea t-field--limitRad w100 h100"  (keyup.enter)="submit()"  style="margin-left: 5px;" placeholder="Please enter Phone" />
                </div>
                <input class="login-form-submit t-btn-grad" *ngIf="Birth && ID && Phone && Email" type="submit" (click)="submit()" label="Submit Code" (keyup.enter)="submit()" />
            </div>
            <div style="margin-top: 10px;">
                <div *ngIf="totpCode">Other option for Login. <span (click)="totpCode = false;" style="cursor: pointer;font-weight: 700;">Click Here</span></div>
                <div *ngIf="!totpCode">Back to Login with Code. <span (click)="totpCode = true;" style="cursor: pointer;font-weight: 700;">Click Here</span></div>

            </div>

        </div>
    </div>

</div>

