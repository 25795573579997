import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { formatDate} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class InboxService {

  constructor(private http: HttpClient) { }

  InboxData;

  getList(from: Date, to: Date){
    this.http.get('/api/inboxes/'+formatDate(from,"yyyy-MM-dd",'en-US') +'/' + formatDate(to,"yyyy-MM-dd",'en-US')).subscribe((data)=>{
      this.InboxData = data;
    },(error)=>{
      console.log(error);
    });
  }


}
