<div>
  <header class="l-header">
    <div class="on-mobile header-logo">
      <img src="assets/icons/logo.svg" alt="Artisan Sonics" />
    </div>
    <div class="l-header-panel" style="justify-content: space-between">
      <h3>Admin Statistics leumit</h3>
      <div style="display: flex; flex-direction: row;" class="buttons-container">
        <img
                    style="cursor: pointer"
                    matTooltip="Download CVS"
                    *ngIf="statistics?.length"
                   (click)="exportExcel()"
                   height="31em"
                    width="31em"
                    src="../../assets/icons/file.svg"
                      />
          <a
            class="header-menu-link icon-confirm"
            style="cursor: pointer; margin-left: 0em"
            aria-label="Confirm"
            (click)="exit()"
            matTooltip="Close Leumit Reports and Open AWL"
          ></a>
      </div>
    </div>
    
    <div class="container">
    <div class="margin-div">
    From  <input [matDatepicker]="startDatepicker"  [(ngModel)]="startDate" (ngModelChange)="onStartDateChange($event)"   class="t-field t-field--date menstural-field" aria-labelledby="menstural_LMP" (click)="startDatepicker.open()">
    <mat-datepicker #startDatepicker></mat-datepicker>
    Until
    <input [matDatepicker]="endDatepicker"  [(ngModel)]="endDate" (click)="endDatepicker.open()" (ngModelChange)="onEndDateChange($event)" class="t-field t-field--date menstural-field" aria-labelledby="menstural_LMP">
    <mat-datepicker #endDatepicker></mat-datepicker>
    <button class="search-button" matTooltip="Search Exams"   matTooltipPosition="after" (click)="searchStatistics()" >Search</button>
   </div>
 </div>
 <div *ngIf="statistics?.length"  class="search-results">
 <table >
    <thead>
      <tr>
        <th>Name</th>
        <th>withoutPrn</th>
        <th>withPrnNotSent</th>
        <th>sentNotConfirm</th>
        <th>confirm</th>
        <th>totalLeumit</th>
        <th>UnknownHMO</th>
        <th>OtherHMO</th>
        <th>totalExams</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of statistics">
        <td>{{ data.Name }}</td>
        <td>{{ data.withoutPrn }}</td>
        <td>{{ data.withPrnNotSent }}</td>
        <td>{{ data.sentNotConfirm }}</td>
        <td>{{ data.confirm }}</td>
        <td>{{ data.totalLeumit }}</td>
        <td>{{ data.UnknownHMO }}</td>
        <td>{{ data.OtherHMO }}</td>
        <td>{{ data.totalExams }}</td>
      </tr>  
      <tr >
        <td>totals</td>
        <td>{{ totals.withoutPrn }}</td>
        <td>{{ totals.withPrnNotSent }}</td>
        <td>{{ totals.sentNotConfirm }}</td>
        <td>{{ totals.confirm }}</td>
        <td>{{ totals.totalLeumit }}</td>
        <td>{{ totals.UnknownHMO }}</td>
        <td>{{ totals.OtherHMO }}</td>
        <td>{{ totals.totalExams }}</td>
      </tr>          

    </tbody>
  </table>
</div>

  
 
    
 
        </header>
        
            
            





<!-- -----------------------------------------------------------------------------------------------------------------------side bar-------------------------------------------------------- -->
  <aside class="l-sidebar l-flex l-column">
    <img
      class="off-mobile sidebar-logo"
      src="../../assets/icons/logo.svg"
      alt="Artisan Sonics"
    />

    <div class="userPanel"></div>
    <nav class="mainNav l-fg1 scroller"></nav>

    <app-docpanel></app-docpanel>
  </aside>

  <!-- -------------------------------------------------------------- -->
  <app-bottombar></app-bottombar>
</div>
