import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  constructor(public translate: TranslateService) {
    // Set the default language
    // translate.setDefaultLang("pl-PL");
    // Use a specific language
    // translate.use("pl-PL");
  }

  private obExams: Array<string> = [
    "1st",
    "NT",
    "Early",
    "Early Anatomy",
    "2nd/3rd",
    "2nd/3rd Trimester",
    "Weight Assessment",
    "Late",
    "Late Anatomy",
    "Specialized",
    "Fetal Echo",
    "Third Scan",
    "Consultation Visit",
  ];

  private gynExams: Array<string> = ["Gynecology"];
  private letters: Array<string> = ["Consultation Letter"];

  public switchLanguage(language: string) {
    this.translate.use(language);
  }

  public isOB(examType: string): boolean {
    return this.obExams.includes(examType);
  }

  public isGYN(examType: string): boolean {
    return this.gynExams.includes(examType);
  }

  public isLetter(examType: string): boolean {
    return this.letters.includes(examType);
  }
}
