import { Router } from "@angular/router";
import { LoginService } from "../login.service";
import { Component, OnInit } from "@angular/core";
import { InboxTransferService } from "../inbox-transfer.service";
@Component({
  selector: "app-inbox-transfer",
  templateUrl: "./inbox-transfer.component.html",
  styleUrls: ["./inbox-transfer.component.scss"],
})
export class InboxTransferComponent implements OnInit {
  constructor(
    public inboxTransferService: InboxTransferService,
    private loginService: LoginService,
    private router: Router
  ) {
    this.loginService.loginMissing();
  }

  ngOnInit(): void {
  }

  togglePopup() {
    this.inboxTransferService.toggleSuccessMessage =
      !this.inboxTransferService.toggleSuccessMessage;
  }

  exit() {
    this.router.navigateByUrl("/patsearch");
  }
}
