import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GastroFormsService } from 'src/app/gastro-forms.service';
import { LoginService } from 'src/app/login.service';

@Component({
  selector: 'app-admission',
  templateUrl: './admission.component.html',
  styleUrls: ['./admission.component.scss']
})
export class AdmissionComponent implements OnInit {

  constructor(public gfs:GastroFormsService, private ls:LoginService) { }
  @Output() closed = new EventEmitter<string>();
  @Output() print = new EventEmitter<string>();

  

  ngOnInit() {
  }

  close(toclose: boolean) {
    this.closed.emit(toclose ? '1': null);
  }
  printForm(){
    this.print.emit('1')
  }

  getClass(item):string{
    if(item !== null  && this.gfs.Form.get(item+'Note')  && this.gfs.Form.get(item+'Note').value )
      return 'type-v'
    return 'type-plus';
  }

}
