import { ExamsService } from 'src/app/exams.service';
import { Router } from '@angular/router';
import { Component, OnInit,Input,} from '@angular/core';
import { LoginService } from "../login.service";
import { HttpClient } from "@angular/common/http";
import * as XLSX from 'xlsx';
import { writeFile } from 'xlsx';

interface statsData {
  totals: {
    withoutPrn: number;
    withPrnNotSent: number;
    sentNotConfirm: number;
    confirm: number;
    UnknownHMO: number;
    OtherHMO: number;
    totalExams: number;
    totalLeumit: number;
  };
  metadata: {
    from: string;
    until: string;
  };
  statistics: {
    Name: string;
    withoutPrn: number;
    withPrnNotSent: number;
    sentNotConfirm: number;
    confirm: number;
    UnknownHMO: number;
    OtherHMO: number;
    totalExams: number;
    totalLeumit: number;
  }[];
}

@Component({
  selector: 'app-admin-leumit-report',
  templateUrl: './admin-leumit-report.component.html',
  styleUrls: ['./admin-leumit-report.component.scss']
})
export class AdminLeumitReportComponent implements OnInit  {
   startDate= new Date();
   endDate = new Date();
   from:string
   until:string
   totals: {
    withoutPrn: number;
    withPrnNotSent: number;
    sentNotConfirm: number;
    confirm: number;
    UnknownHMO: number;
    OtherHMO: number;
    totalExams: number;
    totalLeumit: number;
  };
  statistics: {
    Name: string;
    withoutPrn: number;
    withPrnNotSent: number;
    sentNotConfirm: number;
    confirm: number;
    UnknownHMO: number;
    OtherHMO: number;
    totalExams: number;
    totalLeumit: number;
  }[];
  

  constructor( public loginService: LoginService,private router:Router,public examService:ExamsService, private http: HttpClient) { 
    this.loginService.loginMissing();
    this.from=examService.parseDate(this.startDate);
    this.until=examService.parseDate(this.endDate)
   
  }

  ngOnInit(): void {
  }
// when user pick a date to start search
  onStartDateChange(date: Date) {
    this.from=this.examService.parseDate(date)
    
    console.log('Start date changed:', this.from);
   
  }
// when user pick a date to end search
  onEndDateChange(date: Date) {
    this.until=this.examService.parseDate(date)
    console.log('End date changed:',  this.until);
   
  }
// get reports about all leumit users -only artisan admin can get the info
  searchStatistics(){
    this.http.get('/api/stats/report/admin',{params:{from:this.from,until:this.until}}).subscribe((data:statsData)=>{
    this.totals=data.totals
    this.statistics=data.statistics




    },
     (error) => {
     console.log('error:',error)
    })


  }

  exportExcel(){
  
  const data=[...this.statistics,{Name: "totals",...this.totals}]
  const startDate=this.examService.parseDateDayMonthYear(this.startDate);
  const endDate=this.examService.parseDateDayMonthYear(this.endDate);


  const sheetName ='Report';
  const fileName =`reportForLeumit from ${startDate} to ${endDate}.xlsx`;
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  writeFile(workbook, fileName);


  }
 

  exit() {
    this.router.navigateByUrl("/patsearch");
  }

}
