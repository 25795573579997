<div class="t-field l-flex t-twin-fieldset t-fieldset-big" [formGroup]="parentform" (dblclick)="reset()">
    <label>
        <input type="radio" [name]="Name" [formControlName]="Name" [value]="Value1" class="custom-check" >
        <i class="customInput-display"></i>
        <span>
            {{Left}}
        </span>
    </label>
    <label>
        <input type="radio" [name]="Name" [formControlName]="Name" [value]="Value2" class="custom-check" >
        <i class="customInput-display"></i>
        <span>
            {{Right}}
        </span>
    </label>
</div>
