import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'uniqueExams'
})
export class UniqueExamsPipe implements PipeTransform {
  transform(exams: any[]): any[] {
    // Check for null or undefined input
    if (!exams) {
      return [];
    }

    // Create a Map to store unique exams based on exam.AutoIndex
    const uniqueExamsMap = new Map<number, any>();

    // Iterate through the exams array
    exams.forEach((exam) => {
      const autoIndex = exam.AutoIndex;

      // Check if the current exam.AutoIndex is not already in the Map
      if (!uniqueExamsMap.has(autoIndex)) {
        // If not, add it to the Map
        uniqueExamsMap.set(autoIndex, exam);
      }
    });

    // Convert the Map values back to an array to return the unique exams
    return Array.from(uniqueExamsMap.values());
  }
}
