<div class="body-wrapper" *ngIf="gfs.loaded" [ngClass]="Direction" style='padding-top: 0;'>
    <header class="header">
        <div class="justify-between items-center" [innerHtml]="altHeader" *ngIf="altHeader && showHeader"></div>
        <div class="justify-between items-center" style="margin-bottom: 5px;">
            <h1 class="header-title">{{ 'gastro.form.type.'+gfs.form.FormName | translate }}</h1> 
            <img [src]="! print.env['haslogo'] ? '../../assets/images/logo.png' : '/api/logo'" alt="Logo"
                class="header-logo" *ngIf="print.env && ! altHeader && print.env['haslogo']">
        </div>
        <div class="header-details">
            <div style="display: inline-block; padding-left: 1em;padding-right: 1em">{{'general.ExaminationDate'| translate}}:&nbsp;<strong style='direction: ltr;'>{{gfs.form.CreationTime
                    | date:"dd.MM.yyyy HH:mm"}}</strong></div>
            <div style="display: inline-block">{{'general.Printing_Date' | translate}}:&nbsp;<strong
                    style='direction: ltr;'>{{now| date:"dd.MM.yyyy HH:mm"}}</strong></div>
        </div>
    </header>
    <main class="main">
        <section class="section" *ngIf="gfs.pat">
            <h4 class="text-20 text-gold">{{(gfs.pat.Sex ? (gfs.pat.Sex == 'M' ? 'gastro.Patient.male' :
                'gastro.Patient.female'): 'gastro.Patient.details') | translate}}</h4>
            <div style="width:100%">
                <div>
                    <table>
                        <tr>
                            <td>
                                <dt>{{'general.Patient.first_name' | translate}}</dt>
                                <dd>{{gfs.pat.First}}</dd>
                            </td>
                            <td>
                                <dt>{{'general.Patient.last_name' | translate}}</dt>
                                <dd>{{gfs.pat.Last}}</dd>
                            </td>
                            <td>
                                <dt>{{'general.Patient.ID' | translate}}</dt>
                                <dd>{{gfs.pat.ID}}</dd>
                            </td>
                            <td *ngIf="gfs.pat.Mobile">
                                <dt>{{'general.Patient.mobile' | translate}}</dt>
                                <dd>{{gfs.pat.Mobile}}</dd>
                            </td>
                            <td *ngIf="gfs.pat.Sex">
                                <dt>מגדר</dt>
                                <dd>{{gfs.pat.Sex == 'M'? 'גבר': 'אישה' }}</dd>
                            </td>
                        </tr>
                        <tr *ngIf="gfs.pat.Height || gfs.pat.Weight">
                            <td *ngIf="gfs.pat.Birth">
                                <dt>{{'general.Patient.birth_date' | translate}}</dt>
                                <dd>{{gfs.pat.Birth| date:"dd.MM.yyyy"}}</dd>
                            </td>
                            <td *ngIf="gfs.pat.Birth">
                                <dt>{{'general.Patient.Age' | translate}}</dt>
                                <dd>{{gfs.pat.getAgeMo()}}</dd>
                            </td>
                            <td *ngIf="gfs.pat.Height">
                                <dt>גובה</dt>
                                <dd>{{gfs.pat.Height}}cm</dd>
                            </td>
                            <td *ngIf="gfs.pat.Weight">
                                <dt>משקל</dt>
                                <dd>{{gfs.pat.Weight}}kg</dd>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </section>
        <section class="section" *ngIf="gfs.form.Colonoscopy || gfs.form.Gastroscopy || gfs.form.Sigmoidoscopy">
            <h4 class="text-20 text-gold">בדיקות לביצוע</h4>
            <table class="l-table-auto">
                <tr class="l-row">
                    <td class="l-td-m" *ngIf="gfs.form.Colonoscopy == 0"> <strong>{{'gastro.form.Colonoscopy' | translate}}</strong></td>
                    <td class="l-td-m" *ngIf="gfs.form.Gastroscopy == 0"> <strong>{{'gastro.form.Gastroscopy' | translate}}</strong></td>
                    <td class="l-td-m" *ngIf="gfs.form.Sigmoidoscopy == 0"> <strong>{{'gastro.form.Sigmoidoscopy' | translate}}</strong></td>
                </tr>
            </table>
        </section>
        
        <section class="section" *ngIf="gfs.form.CreationTime && gfs.form.CheckTime && (gfs.form.Temp || gfs.form.Pulse || gfs.form.Saturation || gfs.form.BloodPres || (gfs.form.bpSystolic && gfs.form.bpDyastolic))">
            <h4 class="text-20 text-gold">מדדים</h4>
            <table class="l-table-auto">
                <tr class="l-row">
                    <!-- <td class="l-td-m w14"></td> -->
                    <td class="l-td-m" style="width: 10%;"><strong>{{ 'gastro.form.type.date' | translate }}</strong> </td>
                    <td class="l-td-m" style="width: 10%;"><strong>{{ 'gastro.form.type.time' | translate }}</strong></td>
                    <td class="l-td-m" style="width: 10%;"><strong>{{ 'gastro.form.type.temp' | translate }}</strong></td>
                    <td class="l-td-m" style="width: 10%;"><strong>{{ 'gastro.form.type.pulse' | translate }}</strong></td>
                    <td class="l-td-m" style="width: max-content;"><strong>{{ 'gastro.form.type.bloodpres' | translate }}</strong></td>
                    <td class="l-td-m" style="width: 10%;"><strong>{{ 'gastro.form.type.Saturation' | translate }}</strong></td>

                    <!-- <td class="l-td-m w14"></td> -->
                </tr>
                <tr class="l-row">
                    <!-- <td class="l-td-m w14"></td> -->
                    <td class="l-td-m center">{{gfs.form.CreationTime | date: "dd.MM.yyyy"}}</td>
                    <td class="l-td-m center">{{gfs.form.CheckTime}}</td>
                    <td class="l-td-m center" *ngIf="gfs.form.Temp">{{gfs.form.Temp}} {{'gastro.form.type.cel' | translate}}</td>
                    <td class="l-td-m center" *ngIf="gfs.form.Pulse" style="width: max-content;">{{gfs.form.Pulse}} {{'gastro.form.type.bpm' | translate}}</td>
                    <td class="l-td-m center" *ngIf="gfs.form.bpSystolic && gfs.form.bpDyastolic " style="width: max-content;">{{gfs.form.bpSystolic}}/{{gfs.form.bpDyastolic}} {{'gastro.form.type.mmHg' | translate}}</td>
                    <td class="l-td-m center" *ngIf="gfs.form.Saturation" style="width: max-content;">{{gfs.form.Saturation}}%</td>

                    <!-- <td class="l-td-m w14"></td> -->
                </tr>
            </table>
        </section>
        <ng-container *ngIf="gfs.form.data && gfs.form.FormName == 'Admission Form'">
            <section class="section" *ngIf="gfs.form.data['habits'] && gfs.form.data['habits'].length >0">
                <h4 class="text-20 text-gold" *ngIf="gfs.form.data['habits'] && gfs.form.data['habits'].length >0">
                    {{'gastro.form.Habits.title' | translate}}
                </h4>
                <table class="l-table-auto">
                    <tr *ngFor="let item of gfs.form.data['habits']">
                        <td>
                            <dt>{{item.key.displayName}}</dt>
                            <dd *ngIf="item.key.name !== 'Allergies' && item.key.name !== 'DrugsAllergies'">{{(item.value ? 'gastro.form.no' : 'gastro.form.yes') | translate}}</dd>
                            <dd *ngIf="item.key.name === 'Allergies' || item.key.name === 'DrugsAllergies'">{{(item.value ? (item.key.name === 'Allergies' ? 'gastro.form.Allergies.no' : 'gastro.form.DrugsAllergies.no' ) : (item.key.name === 'Allergies' ? 'gastro.form.Allergies.yes' : 'gastro.form.DrugsAllergies.yes' )) | translate}}</dd>
                        </td>
                        <td *ngIf="item.comment">
                            <dt>הערות</dt>
                            <dd>{{item.comment}}</dd>
                        </td>
                    </tr>
                </table>
            </section>

            <section class="section" *ngIf="gfs.form.data.preparetion && gfs.form.data.preparetion.length >0">
                <h4 class="text-20 text-gold" *ngIf="gfs.form.data['preparetion'] && gfs.form.data['preparetion'].length >0 && gfs.typeForm == 'Admission Form'">
                    {{'gastro.form.Preparation.title' | translate}}
                </h4>
                <table class="l-table-auto">
                    <tr *ngFor="let item of gfs.form.data.preparetion">
                        <td>
                            <dt>{{item.key.displayName}}</dt>
                            <dd>{{(item.value ? 'gastro.form.no' : 'gastro.form.yes') |translate}}</dd>
                        </td>
                        <td *ngIf="item.comment">
                            <dt>הערות</dt>
                            <dd>{{item.comment}}</dd>
                        </td>
                    </tr>
                </table>
            </section>
            <section class="section" *ngIf="gfs.form.data.diseases && gfs.form.data.diseases.length >0">
                <h4 class="text-20 text-gold">{{'gastro.form.diseases' | translate}}</h4>

                <table class="l-table-auto">
                    <tr *ngFor="let item of gfs.form.data.diseases">
                        <td>
                            <dt>{{item.key.displayName}}</dt>
                            <dd>{{(item.value ? 'gastro.form.no' : 'gastro.form.yes') | translate}}</dd>
                        </td>
                        <td *ngIf="item.comment">
                            <dt>הערות</dt>
                            <dd>{{item.comment}}</dd>
                        </td>
                    </tr>
                </table>
            </section>
            <section class="section" *ngIf="gfs.form.data.surgeries && gfs.form.data.surgeries.length >0">
                <table class="l-table-auto">
                    <tr *ngFor="let item of gfs.form.data.surgeries">
                        <td>
                            <dt>{{item.key.displayName}}</dt>
                            <dd>{{(item.value ? 'gastro.form.surgeries.no' : 'gastro.form.surgeries.yes') | translate}}</dd>
                        </td>
                        <td *ngIf="item.comment">
                            <dt>הערות</dt>
                            <dd>{{item.comment}}</dd>
                        </td>
                    </tr>
                </table>
            </section>
            <section class="section" *ngIf="gfs.form.data.medications && gfs.form.data.medications.length >0">
                <table class="l-table-auto">
                    <tr *ngFor="let item of gfs.form.data.medications">
                        <td>
                            <dt>{{item.key.displayName}}</dt>
                            <dd>{{(item.value ? 'gastro.form.no' : 'gastro.form.yes') | translate}}</dd>
                        </td>
                        <td *ngIf="item.comment">
                            <dt>הערות</dt>
                            <dd>{{item.comment}}</dd>
                        </td>
                    </tr>
                </table>
            </section>
            <section class="section" *ngIf="gfs.form.data.status && gfs.form.data.status.length >0">
                <h4 class="text-20 text-gold"> {{'gastro.form.status' | translate}}</h4>
                <table class="l-table-auto">
                    <tr *ngFor="let item of gfs.form.data.status">
                        <td>
                            <dt>{{item.key.displayName}}</dt>
                            <dd>{{(item.value ? 'gastro.form.no' : 'gastro.form.yes') | translate}}</dd>
                        </td>
                        <td *ngIf="item.comment">
                            <dt>הערות</dt>
                            <dd>{{item.comment}}</dd>
                        </td>
                    </tr>
                </table>
            </section>
        </ng-container>
        <ng-container *ngIf="gfs.form && gfs.form.FormName == 'Time Out Form'">
            <section class="section">
                <table class="l-table-auto">
                    <tr *ngIf="gfs.form.Identety == 1">

                        <td>{{'gastro.form.timeout.Identety' | translate}}</td>
                    </tr>
                    <tr *ngIf="gfs.form.IdentetyMatchBreclate == 1">
                        <td>{{'gastro.form.timeout.IdentetyMatchBreclate' |translate}}</td>
                    </tr>
                    <tr *ngIf="gfs.form.IdentetyMatchDocumante  == 1">
                        <td>{{'gastro.form.timeout.IdentetyMatchDocumante' | translate}}</td>
                    </tr>
                    <tr *ngIf="gfs.form.Guardian == 1">
                        <td>{{'gastro.form.timeout.Guardian' | translate}} <span style="margin-inline-start: 20px;"><strong style="margin-inline-end: 5px;">{{'gastro.form.timeout.details' | translate}}:</strong> {{gfs.form.GuardianName}} {{gfs.form.GuardianID}}</span></td>
                    </tr>
                    <tr *ngIf="gfs.form.Prosthesis == 1">
                        <td>{{'gastro.form.timeout.Prosthesis' | translate}}</td>
                    </tr>
                    <tr *ngIf="gfs.form.DAC == 1">
                        <td>{{'gastro.form.timeout.DAC' | translate}} <span style="margin-inline-start: 20px;"><strong style="margin-inline-end: 5px;">{{'gastro.form.timeout.details' | translate}}:</strong>{{gfs.form.DACName}} {{'gastro.form.timeout.stopped' | translate}} {{gfs.form.DACSstopped}} {{'gastro.form.timeout.days' | translate}}</span></td>
                    </tr>
                    <tr *ngIf="gfs.form.isProc == 1">
                        <td>{{'gastro.form.timeout.ProcedureType' | translate}}: {{gfs.form.ProcedureType}}</td>
                    </tr>
                    <tr *ngIf="gfs.form.Med == 1">
                        <td>{{'gastro.form.timeout.MedicationType' | translate}}: {{gfs.form.MedicationType}}</td>
                    </tr>
                    <tr *ngIf="gfs.form.ConcentForm == 1 && (gfs.form.ProcedureConcentForm || gfs.form.MedicConcentForm)">
                        <td>{{'gastro.form.timeout.ConcentForm' | translate}}: {{(gfs.form.ProcedureConcentForm ? 'gastro.form.timeout.ProcedureConcentForm' : '') | translate}} {{(gfs.form.MedicConcentForm ? 'gastro.form.timeout.MedicConcentForm' : '') | translate}}</td>
                    </tr>
                    <tr>
                        <h3 style="margin-block-start:20px">רגישות המטופל לחומרים או תרופות</h3>
                    </tr>
                    <tr *ngIf="gfs.form.IsIdType == 1">
                        <td><strong>{{'gastro.form.timeout.idType' | translate}}:</strong> {{gfs.form.idType}}</td>
                    </tr>
                    <tr *ngIf="gfs.form.medSens">
                        <td><strong>{{'gastro.form.timeout.medSens' | translate}}:</strong> {{gfs.form.medSens}}</td>
                    </tr>
                    <tr *ngIf="gfs.form.eggSens">
                        <td><strong>{{'gastro.form.timeout.eggSens' | translate}}:</strong> {{gfs.form.eggSens}}</td>
                    </tr>
                    <tr *ngIf="gfs.form.soyaSens">
                        <td><strong>{{'gastro.form.timeout.soyaSens' | translate}}:</strong> {{gfs.form.soyaSens}}</td>
                    </tr>
                    <tr *ngIf="gfs.form.Equipment ==1">
                        <td>{{'gastro.form.timeout.Equipment' | translate}}</td>
                    </tr>
                </table>
            </section>
        </ng-container>
        <section style="display: flex;min-height: 60px;vertical-align: bottom;">
            <div class="section" style="width: 50%;display: flex;margin:0 0.5em 0 0;vertical-align: bottom;" *ngIf="gfs.form.FormName == 'Time Out Form'">
                <span style="align-self: end;width: 30%;vertical-align: bottom;">{{'gastro.form.docsign' | translate}}:</span>
                <span style="min-height: 100%;width: 70%;border-bottom: 1px solid black;"></span>

                <!-- <hr style="align-self: end;bottom: auto;vertical-align: baseline;"> -->
            </div>
            <div class="section" style="width: 50%;display: flex;margin:0 0 0 0.5em;vertical-align: bottom;">
                <span style="align-self: end;width: 25%;vertical-align: bottom;">{{'gastro.form.norsesign' | translate}}:</span>
                <span style="width: 70%;border-bottom: 1px solid black;"></span>
                <!-- <hr style="align-self: end;bottom: auto;vertical-align: baseline;"> -->
            </div>
            <div class="section" style="width: 50%;display: flex;margin:0 0.5em 0 0;vertical-align: bottom;" *ngIf="gfs.form.FormName == 'Admission Form'">
                <span style="align-self: end;width: 30%;vertical-align: bottom;">{{'gastro.form.patsign' | translate}}:</span>
                <span style="min-height: 100%;width: 70%;border-bottom: 1px solid black;"></span>

                <!-- <hr style="align-self: end;bottom: auto;vertical-align: baseline;"> -->
            </div>
        </section>
        <section class="section">
            <table>
                <tr *ngFor="let signer of gfs.signers;let i = index">
                    <td class="w-1/3"><strong>{{(signer['Title'] ? signer['Title']+ ' ': '')+ signer.Name}}</strong>
                    </td>
                    <td class="w-1/3">
                        <ng-container *ngIf="signer.DrNum">מ.ר. <strong>{{signer.DrNum}} </strong></ng-container>
                        <ng-container *ngIf="signer.ExpertNum">מ.ר.מ. <strong>{{signer.ExpertNum}}</strong>
                        </ng-container>
                    </td>
                    <td class="w-1/3" *ngIf="i == 0">{{'general.ExaminationDate' | translate}}: <strong>{{gfs.form.CreationTime | date:"dd.MM.yyyy"}}</strong></td>
                </tr>
            </table>
        </section>
        <p class="comment" class="finished" *ngIf="loaded"></p>
    </main>
</div>