<div class="t-popup-body" style="overflow: auto;" [formGroup]="gfs.Form">
    <button class="t-btn-popup-close" aria-label="close popup" (click)="close(true)"></button>
    <div class="formHeader">
        <h2 style="align-self: center;">
            Time Out Form
        </h2>
        <span (click)="printForm()" class="icon-print" style="margin-inline-end: 1.5em;"
            aria-label="Print" matTooltip="Print"><img src="../../../assets/icons/print.svg"></span>
    </div>
    <section class="w100" style="direction: rtl;justify-content: space-evenly;display: flex;flex-direction: column;">
        <div class="l-flex-c l-flex-w indicative-fieldset" style="flex-wrap: inherit;flex-direction: column;">
            <span  class="l-flex-w" style="width: 100%;padding: 5px 0;">
                <label class="t-label-top w100">
                    <span style="width: 33%;text-align: center;"><strong>תאריך:</strong> {{gfs.Form.get('CreationTime').value | date:"dd-MM-yyyy"}}</span>
                    <span style="width: 33%;text-align: center;"><strong>שעה:</strong> {{gfs.Form.get('CreationTime').value | date: "HH:mm"}}</span>
                    <span style="width: 33%;text-align: center;"><strong>מבצע התהליך:</strong> {{ls.username}}</span>
                </label>
            </span>
            <span (dblclick)="gfs.Form.get('Identety').setValue(null)" class="l-flex-w" style="width: 100%;padding: 5px 0;">
                <label class="t-label-top">
                    <input type="checkbox" name="placenta_TEMP_name" class="custom-check -sq"
                        formControlName="Identety" [value]="true">
                    <i class="customInput-display"></i>
                    <span class="f-label-span">
                        המטופל אומר את שמו המלא ומס ת.ז
                    </span>
                </label>
            </span>
            <span (dblclick)="gfs.Form.get('IdentetyMatchBreclate').setValue(null)" class="l-flex-w" style="width: 100%;padding: 5px 0;">
                <label class="t-label-top">
                    <input type="checkbox" name="placenta_TEMP_name" class="custom-check -sq"
                        formControlName="IdentetyMatchBreclate" [value]="true">
                    <i class="customInput-display"></i>
                    <span class="f-label-span">
                        השם ומספר ת.ז תואמים לידון הזיהוי
                    </span>
                </label>
            </span>
            <span (dblclick)="gfs.Form.get('IdentetyMatchDocumante').setValue(null)" class="l-flex-w" style="width: 100%;padding: 5px 0;">
                <label class="t-label-top">
                    <input type="checkbox" name="placenta_TEMP_name" class="custom-check -sq"
                        formControlName="IdentetyMatchDocumante" [value]="true">
                    <i class="customInput-display"></i>
                    <span class="f-label-span">
                        השם ומספר ת.ז תואמים למסמכים
                    </span>
                </label>
            </span>
            <span class="l-flex-w" style="width: 100%;padding: 5px 0;">
                <label class="t-label-top" (dblclick)="gfs.Form.get('Guardian').setValue(null);gfs.Form.get('GuardianName').setValue(null);gfs.Form.get('GuardianID').setValue(null)">
                    <input type="checkbox" name="placenta_TEMP_name" class="custom-check -sq"
                        formControlName="Guardian" [value]="true">
                    <i class="customInput-display"></i>
                    <span class="f-label-span">
                        הזיהוי בוצע ע"י אפוטרופוס
                    </span>
                </label>
                <label class="t-label-top" *ngIf="gfs.Form.get('Guardian').value" style="margin-inline-start: 30px;">
                    <div>
                        <span class="f-label-span">שם: </span>
                        <input class="t-field t-field-text" type="text" formControlName="GuardianName">
                    </div>
                    <div>
                        <span class="f-label-span">ת.ז: </span>
                        <input class="t-field t-field-text" type="text" formControlName="GuardianID">
                    </div>
                </label>
            </span>
            <span (dblclick)="gfs.Form.get('Prosthesis').setValue(null)" class="l-flex-w" style="width: 100%;padding: 5px 0;">
                <label class="t-label-top">
                    <input type="checkbox" name="placenta_TEMP_name" class="custom-check -sq"
                        formControlName="Prosthesis" [value]="true">
                    <i class="customInput-display"></i>
                    <span class="f-label-span">
                        שיניים תותבות
                    </span>
                </label>
            </span>
            <span class="l-flex-w" style="width: 100%;padding: 5px 0;">
                <label class="t-label-top" (dblclick)="gfs.Form.get('DAC').setValue(null);gfs.Form.get('DACName').setValue(null);gfs.Form.get('DACSstopped').setValue(null)">
                    <input type="checkbox" name="placenta_TEMP_name" class="custom-check -sq"
                        formControlName="DAC" [value]="true">
                    <i class="customInput-display"></i>
                    <span class="f-label-span">
                        תרופות נגד קרישיות דם
                    </span>
                </label>
                <label class="t-label-top" *ngIf="gfs.Form.get('DAC').value"  style="margin-inline-start: 30px;">
                    <div>
                        <span class="f-label-span">פרט: </span>
                        <input class="t-field t-field-text" type="text" formControlName="DACName">
                    </div>
                    <div>
                        <span class="f-label-span">הופסק לפני: </span>
                        <input class="t-field t-field-text" style="width: 5em;" type="text" formControlName="DACSstopped">
                        <span class="f-label-span">ימים </span>
                    </div>
                </label>
            </span>
            <span (dblclick)="gfs.Form.get('isProc').setValue(null);gfs.Form.get('ProcedureType').setValue(null)" class="l-flex-w" style="width: 100%;padding: 5px 0;">
                <label class="t-label-top">
                    <input type="checkbox" name="placenta_TEMP_name" class="custom-check -sq"
                        formControlName="isProc">
                    <i class="customInput-display"></i>
                    <span class="f-label-span">
                        סוג הפעולה
                    </span>
                </label>
                <select formControlName="ProcedureType" class="t-select t-field" *ngIf="gfs.Form.get('isProc').value">
                    <option value="קולונוסקופיה">קולונוסקופיה</option>
                    <option value="גסטרוסקופיה">גסטרוסקופיה</option>
                    <option value="סיגמואידוסקופיה">סיגמואידוסקופיה</option>
                </select>
            </span>
            <span (dblclick)="gfs.Form.get('Med').setValue(null);gfs.Form.get('MedicationType').setValue(null)" class="l-flex-w" style="width: 100%;padding: 5px 0;">
                <label class="t-label-top">
                    <input type="checkbox" name="placenta_TEMP_name" class="custom-check -sq"
                        formControlName="Med">
                    <i class="customInput-display"></i>
                    <span class="f-label-span">
                        סוג ההרדמה
                    </span>
                </label>
                <select formControlName="MedicationType" class="t-select t-field" *ngIf="gfs.Form.get('Med').value">
                    <option value="סדציה">סדציה</option>
                    <option value="כללית">כללית</option>
                    <option value="ללא הרדמה">ללא הרדמה</option>
                </select>
            </span>
            <span (dblclick)="gfs.Form.get('ConcentForm').setValue(null)" class="l-flex-w" style="width: 100%;padding: 5px 0;">
                <label class="t-label-top">
                    <input type="checkbox" name="placenta_TEMP_name" class="custom-check -sq"
                        formControlName="ConcentForm" [value]="true">
                    <i class="customInput-display"></i>
                    <span class="f-label-span">
                        המצאת טפסי הסכמה חתומים
                    </span>
                </label>
                <label class="t-label-top" *ngIf="gfs.Form.get('ConcentForm').value">
                    <input type="checkbox" name="placenta_TEMP_name" class="custom-check -sq"
                        formControlName="ProcedureConcentForm" [value]="true">
                    <i class="customInput-display"></i>
                    <span class="f-label-span">
                        פעולה
                    </span>
                </label>
                <label class="t-label-top" *ngIf="gfs.Form.get('ConcentForm').value">
                    <input type="checkbox" name="placenta_TEMP_name" class="custom-check -sq"
                        formControlName="MedicConcentForm" [value]="true">
                    <i class="customInput-display"></i>
                    <span class="f-label-span">
                        הרדמה
                    </span>
                </label>
            </span>
        </div>
        <section class="w100" style="direction: rtl;justify-content: space-evenly;display: flex;flex-direction: column;">
            <div class="l-flex-c l-flex-w indicative-fieldset" style="flex-wrap: inherit;flex-direction: column;">
                <h4>רגישות המטופל לחומרים או תרופות</h4>
                <span (dblclick)="gfs.Form.get('IsIdType').setValue(null)" class="l-flex-w" style="width: 100%;padding: 5px 0;" >
                    <label class="t-label-top" style="width: 30%;">
                        <input type="checkbox" name="placenta_TEMP_name" class="custom-check -sq"
                            formControlName="IsIdType" [value]="true">
                        <i class="customInput-display"></i>
                        <span class="f-label-span">
                            אופן הזיהוי
                        </span>
                    </label>
                    <select formControlName="idType" class="t-select t-field" *ngIf="gfs.Form.get('IsIdType').value">
                        <option value="ידון זיהוי">ידון זיהוי</option>
                        <option value="גליון המטופל">גיליון המטופל</option>
                    </select>
                </span>
                <span (dblclick)="gfs.Form.get('medSens').setValue(null)" class="l-flex-w" style="width: 100%;padding: 5px 0;">
                    <label class="t-label-top w100" style="margin-right: 10px;">
                        <span class="f-label-span" style="width: 25%;">
                            רגישות לתרופות
                        </span>
                        <input type="input" name="placenta_TEMP_name" class="t-field t-field-text" style="margin-inline-start: 10px;padding-right: 10px;"
                            formControlName="medSens">
                    </label>
                </span>
                <span (dblclick)="gfs.Form.get('eggSens').setValue(null)" class="l-flex-w" style="width: 100%;padding: 5px 0;">
                    <label class="t-label-top w100" style="margin-right: 10px;">
                        <span class="f-label-span" style="width: 25%;">
                            רגישות לביצים
                        </span>
                        <input type="input" name="placenta_TEMP_name" class="t-field t-field-text" style="margin-inline-start: 10px;padding-right: 10px;"
                            formControlName="eggSens">
                    </label>
                </span>
                <span (dblclick)="gfs.Form.get('soyaSens').setValue(null)" class="l-flex-w" style="width: 100%;padding: 5px 0;">
                    <label class="t-label-top w100" style="margin-right: 10px;">
                        <span class="f-label-span" style="width: 25%;">
                            רגישות לסויה
                        </span>
                        <input type="input" name="placenta_TEMP_name" class="t-field t-field-text" style="margin-inline-start: 10px;padding-right: 10px;"
                            formControlName="soyaSens">
                    </label>
                </span>
            </div>
        </section>
        <section class="w100" style="direction: rtl;justify-content: space-evenly;display: flex;flex-direction: column;">
            <div class="l-flex-c l-flex-w indicative-fieldset" style="flex-wrap: inherit;flex-direction: column;">
                <span (dblclick)="gfs.Form.get('Equipment').setValue(null)" class="l-flex-w" style="width: 100%;padding: 5px 0;">
                    <label class="t-label-top">
                        <input type="checkbox" name="placenta_TEMP_name" class="custom-check -sq"
                            formControlName="Equipment" [value]="true">
                        <i class="customInput-display"></i>
                        <span class="f-label-span">
                            זמינות ותקינות הציוד בחדר
                        </span>
                    </label>
                </span>
            </div>
        </section>
    </section>
</div>