import { Component, OnInit, OnDestroy } from '@angular/core';
import {ExamsService}from '../../exams.service';
import { UntypedFormGroup,UntypedFormBuilder } from '@angular/forms';
import {AutotextService} from '../../autotext.service';
import {LoginService} from '../../login.service';
import { PatientsService } from '../../patients.service';
import { Subscription,Subject } from 'rxjs';
import {CardioService} from '../cardio.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-cardio-summary',
  templateUrl: './cardio-summary.component.html',
  styleUrls: ['./cardio-summary.component.scss']
})
export class CardioSummaryComponent implements OnInit, OnDestroy {

  summaryForm: UntypedFormGroup;
  lastDiagnosis: string;
  lastRecommendations: string;
  lastFindings:string;
  prnerr:string;
  trerr;
  subscritpion: Subscription;
  checkleumit: boolean;
  isFetalEcho: boolean = false;
  signed:Date;
  signer:String;
  showlock = false;
  HMO: string;
  PRN: string;
  

  constructor(public es: ExamsService, private fb: UntypedFormBuilder, public atS:AutotextService, public ls: LoginService, private ps: PatientsService,private cs: CardioService) { 
    this.summaryForm = this.fb.group({
      Repeat1: [''],
      Repeat2: [''],
      Diagnosis: [''],
      Recomm: [''],
      ReferingDoc: [''],
      ExamMethod: [''],
      DiagnosisCodes: [''],
      Anomalies: [''],
      Abdominal: [''],
      Vaginal: [''],
      Transperineal: [''],
      Rectal: [''],
      DiagnosisCopy: [''],
      HMO: [''],
      PRN: [''],
      TreatmentCode: [''],
      FollowUpExam: [''],
      Quality:[''],
      IndicationCodes: [''],
      AnomaliesIndication: [''],
      ImagingQuality: [''],
      LVEF: [''],
      LVEFP:[''],
      Conclusions:[''],
      Assessments:[''],
      Summary:[''],
      OtherStaff:[''],
      Findings:[''],
      AnomaliesStress:['']

    });
    if (!this.es.isClosed) {
      this.es.getLastSummary().subscribe((data) => {
        this.lastDiagnosis = data["diagnosis"];
        this.lastRecommendations = data['recommendations'];
        if(data['summary'])
          this.lastFindings = data['summary']['Anomalies'];
      }, (err) => {
        console.log(err);
      });
    }
  }

  addLastDiagnosis() {
    if (this.summaryForm.get("Diagnosis").value)
      this.summaryForm.get("Diagnosis").setValue(this.summaryForm.get("Diagnosis").value + this.lastDiagnosis);
    else
      this.summaryForm.get("Diagnosis").setValue(this.lastDiagnosis);
    this.summaryForm.get('DiagnosisCopy').setValue(1);
  }

  addLastRecomm() {
    if (this.summaryForm.get("Recomm").value)
      this.summaryForm.get("Recomm").setValue(this.summaryForm.get("Recomm").value + this.lastRecommendations);
    else
      this.summaryForm.get("Recomm").setValue(this.lastRecommendations);
  }

  addLastFindings(){
    if (this.summaryForm.get("Anomalies").value)
      this.summaryForm.get("Anomalies").setValue(this.summaryForm.get("Anomalies").value + this.lastFindings);
    else
      this.summaryForm.get("Anomalies").setValue(this.lastFindings);
  }

  ngOnInit() {
    this.subscritpion = this.es.formLoaded$.subscribe(()=> {
      //prn and hmo moved to cardio general
      this.cs.getHMOinfo(this.es.examID).subscribe((data)=>{
        if(data['HMO'])
          this.HMO = data['HMO'];
        if(data['ReferralNum'] && data['ReferralNum'] !='')
          this.PRN = data['ReferralNum'];
      },(error)=>{
        console.log(error);
      })
    });
    if(this.ls.PRNcheck)
      this.es.fillForm(this.summaryForm,"summary",false,true,['PRN','TreatmentCode','LVEFP']);
    else
      this.es.fillForm(this.summaryForm,"summary",false,true,['LVEFP']);
    this.es.getClosing().subscribe((data)=>{
      this.cs.getStr();
      if(data['ClosedDate']){
        this.signed = new Date(data['ClosedDate']);
        this.summaryForm.get('LVEFP').disable({emitEvent: false});
      }
      else
      this.summaryForm.get('LVEFP').valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(data=>{
        this.es.saveVals('LVEF',this.summaryForm.get('LVEFP').value,null, 'cardio2D');
      }); 
      if(data['Signer'])
        this.signer = data['Signer'];
    },(error)=>{
      console.log(error);
    });
    this.cs.getLVEF(this.es.examID).subscribe((data)=>{
      this.summaryForm.get('LVEFP').setValue(data['LVEF'],{emitEvent: false});
    }, (err)=>{
      console.log(err);
    });



    
  }

  ngOnDestroy(): void {
      this.subscritpion.unsubscribe();
  }

  checkPRN(){
    let prn = this.summaryForm.get('PRN').value;
    let tcode = this.summaryForm.get('TreatmentCode').value;
    this.ps.checkPRN(this.ps.currentPatient.ID,prn,tcode).subscribe((data)=>{
      if(data['response'] == ''){//verified
        this.prnerr = null;
        this.summaryForm.get('PRN').disable({emitEvent:false});
        this.summaryForm.get('TreatmentCode').disable({emitEvent:false});
        this.es.saveVals('PRN',prn,false);
        this.es.saveVals('TreatmentCode',tcode,false);
        if(this.es.isClosed)
          this.es.saveVals('HMO','Leumit',false);
        this.summaryForm.get('HMO').disable({emitEvent:false});
        this.checkleumit=false;
      }
      else{
        this.prnerr = data['response'];
      }
    },(err)=>{
      this.prnerr = err['error'];
    }) 
  }
  resetGrp(item: string) {
    if (!this.es.isClosed)
      this.summaryForm.get(item).setValue(null);
  }


  checklength(){
    if(! this.ls.PRNcheck)
      return;
    if((this.summaryForm.get("PRN").value + '').length < 9){
      if(this.summaryForm.get("PRN").value)
        this.prnerr = "PRN invalid";
    }
    else{
      this.prnerr = null;
      this.checkleumit = true;
    }
    this.trerr = this.summaryForm.get('TreatmentCode').value && (this.summaryForm.get('TreatmentCode').value + '').length < 5;

  }

  showSign(){
    if(this.es.ExamType == 'Stress' || this.es.ExamType =='Duplex Carotid')
      return this.ls.userType == 'doctor' && ! this.es.isClosed && ! this.showlock && ! this.signed && (this.HMO != 'Macabi' || this.PRN);
    else 
      return this.ls.userType == 'doctor' && ! this.es.isClosed && ! this.showlock && ! this.signed && (this.summaryForm.get('LVEFP').value || this.summaryForm.get('LVEF').value ) && (this.HMO != 'Macabi' || this.PRN);
  }

  addFindings(){
    this.summaryForm.get('Anomalies').setValue(this.cs.parseString);
  }

  signClose(){
    this.es.signExam(this.ls.getUserName()).subscribe((d)=>{
      this.signer = this.ls.getUserName();
      this.signed = new Date();
      this.showlock=false;
      this.es.signed = true;
    },(error)=>{
      console.log(error);
    })
  }
  
}
