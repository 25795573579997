import { Router } from "@angular/router";
import { LoginService } from "../login.service";
import { Component, OnInit } from "@angular/core";
import { InboxTransferService } from "../inbox-transfer.service";
import { ActivatedRoute } from "@angular/router";
import { HttpClient, HttpResponse } from "@angular/common/http";

@Component({
  selector: "app-transfer-history",
  templateUrl: "./transfer-history.component.html",
  styleUrls: ["./transfer-history.component.scss"],
})
export class TransferHistoryComponent implements OnInit {
  public historyType: string;
  tranferHistoryData: any;
  console = console;

  constructor(
    public inboxTransferService: InboxTransferService,
    private loginService: LoginService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient
  ) {
    this.loginService.loginMissing();
  }

  ngOnInit(): void {

    this.activatedRoute.paramMap.subscribe((params) => {
      this.historyType = params.get("type");
    });

    this.http.get(`/api/support/transfer-history`).subscribe((result) => {
      this.tranferHistoryData = result;
      this.tranferHistoryData.sort((a, b) => {
        const dateA = new Date(a.TransferDate);
        const dateB = new Date(b.TransferDate);
        return dateB.getTime() - dateA.getTime();
      });
    });
  }

  goMediaTransfer() {
    this.router.navigate(["/media-transfer"]);
  }

  goInboxTransfer() {
    this.router.navigate(["/inbox-transfer"]);
  }

  exit() {
    this.router.navigateByUrl("/patsearch");
  }

  getSupportCenterText(): string {
    const historyText = this.historyType === "inbox" ? "Inbox" : "Media";
    return `Support Center: ${historyText} Transfer History`;
  }

  getTooltipText(): string {
    const closeText = "Close";
    const historyText = this.historyType === "inbox" ? "Inbox" : "Media";
    const openText = "History and Open AWL";

    return `${closeText} ${historyText} ${openText}`;
  }
}
