<section
  class="m-box w100 summary"
  style="height: 100%"
  *ngIf="es.ExamType != 'Fetal Echo' && !ls.isSheba && es.ExamType!=='Pediatric Echo'"
>
  <h2>
    {{
      Group !== "summary" && Group !== "cns"
        ? Group === "fetal"
          ? ('GUI.Labels.Indications of Exam' | translate | titlecase)
          : ("summary.diagnosis.title" | translate | titlecase)
        : ds.getTitle(true)
    }}
  </h2>

  <label
    class="t-label-top"
    [ngClass]="ds.inSummary || inpregnancy ? 'diagSumm' : ''"
    *ngIf="!es.isClosed"
    style="margin-bottom: 1em"
  >

    <span>
      {{
        ds.isFetal && !ds.inSummary
          ? ds.inAnatomy
            ? "Anomalies Tree"
            : "Indication Tree"
          : ("summary.diagnosis.DiagnosisTree" | translate | titlecase)
      }}
    </span>
    <select
      class="t-select t-field"
      [(ngModel)]="diag1"
      style="margin-block: 0.3em; text-align-last: right; direction: rtl"
      (change)="diag2 = null; diag3 = null"
    >
      <option></option>
      <ng-container *ngFor="let opt of ds.getGroup(Group)">
        <option [value]="opt[0]">{{ opt[2] }}</option>
      </ng-container>
    </select>
    <select
      class="t-select t-field"
      [(ngModel)]="diag2"
      style="margin-block: 0.3em; text-align-last: right; direction: rtl"
      *ngIf="diag1"
      (change)="diag3 = null; addDiagnosis()"
    >
      <option></option>
      <ng-container *ngFor="let opt of ds.getList(diag1)">
        <option [value]="opt[0]">{{ opt[2] }}</option>
      </ng-container>
    </select>
    <select
      class="t-select t-field"
      [(ngModel)]="diag3"
      style="margin-block: 0.3em; text-align-last: right; direction: rtl"
      *ngIf="diag2"
      (change)="addDiagnosis()"
    >
      <option></option>
      <ng-container *ngFor="let opt of ds.getList(diag2)">
        <option [value]="opt[0]">{{ opt[2] }}</option>
      </ng-container>
    </select>
  </label>
  <ng-container *ngFor="let d of Diagnoses">
    <div
      (dblclick)="removeDiagnosis(d[0])"
      [title]="'GUI.Tooltips.Double clicking on this will remove it' | translate"
    >
      {{ d[1] }}
    </div>
  </ng-container>

  <div class="sep-8"></div>
  <div
    class="w100 summary-spreadHeight summary-diagnosis"
    style="margin-top: 32px"
  >
    <section
      class="m-box"
      style="padding: 1.31em 0; box-shadow: none; border: 0; background: none"
    >
      <h2
        style="
          margin-bottom: 16px;
          font-size: inherit;
          font-weight: 400;
          background: transparent;
        "
      >
        {{("summary.diagnosis.DiagnosisComments" | translate | titlecase)}}
      </h2>

      <textarea
        class="scroller anatomy-comments MH5"
        [formControl]="Text"
      ></textarea>

      <button
        class="t-btn-edit"
        *ngIf="!es.isClosed && lastDiagnosis && insummary"
        (click)="addLastDiagnosis()"
        style="margin-right: 6em"
      >
        Previous Diagnosis
      </button>
      <button
        class="t-btn-edit"
        *ngIf="!es.isClosed"
        (click)="
          atS.showAuto(Text, atSTitleDiagnosisComments, ATsID)
        "
      >
        Auto Text
      </button>
    </section>
  </div>
</section>

<section
  class="m-box w100 summary"
  style="height: auto"
  *ngIf="(es.ExamType == 'Fetal Echo'|| es.ExamType=='Pediatric Echo') && !ls.isSheba"
>
  <h2>
    {{
      Group !== "summary" && Group !== "cns"
        ? Group === "fetal"
          ? ('GUI.Labels.Indications of Exam' | translate | titlecase)
          : ("summary.diagnosis.title" | translate | titlecase)
        : ds.getTitle(true)
    }}
  </h2>
  <div
    class="w100 summary-spreadHeight summary-diagnosis"
    style="margin-bottom: 1em"
  >
    <section
      class="m-box"
      style="
        height: 100px;
        padding: 1.31em 0;
        box-shadow: none;
        border: 0;
        background: none;
      "
    >
      <textarea
        class="scroller anatomy-comments"
        [formControl]="Text"
        style="min-height: 5em"
      ></textarea>

      <button
        class="t-btn-edit"
        *ngIf="!es.isClosed"
        (click)="atS.showAuto(Text, atSTitleAnomaliesIndicationComment, ATsID)"
      >
        Auto Text
      </button>
    </section>
  </div>

  <div class="sep-8"></div>

  <label
    class="t-label-top"
    [ngClass]="ds.inSummary || inpregnancy ? 'diagSumm' : ''"
    *ngIf="!es.isClosed"
    style="margin-top: 1em"
  >
    <span>
      {{'GUI.Labels.Please Select' | translate}}
    </span>
    <select
      class="t-select t-field"
      [(ngModel)]="diag1"
      style="margin-block: 0.3em"
      (change)="diag2 = null; diag3 = null"
    >
      <option></option>
      <ng-container *ngFor="let opt of ds.getGroup(Group)">
        <option [value]="opt[0]">{{ opt[2] }}</option>
      </ng-container>
    </select>
    <select
      class="t-select t-field"
      [(ngModel)]="diag2"
      style="margin-block: 0.3em"
      *ngIf="diag1"
      (change)="diag3 = null; addDiagnosis()"
    >
      <option></option>
      <ng-container *ngFor="let opt of ds.getList(diag1)">
        <option [value]="opt[0]">{{ opt[2] }}</option>
      </ng-container>
    </select>
    <select
      class="t-select t-field"
      [(ngModel)]="diag3"
      style="margin-block: 0.3em"
      *ngIf="diag2"
      (change)="addDiagnosis()"
    >
      <option></option>
      <ng-container *ngFor="let opt of ds.getList(diag2)">
        <option [value]="opt[0]">{{ opt[2] }}</option>
      </ng-container>
    </select>
  </label>
  <ng-container *ngFor="let d of Diagnoses">
    <div
      (dblclick)="removeDiagnosis(d[0])"
      title="Double clicking on this will remove it"
    >
      {{ getDiag(d) }}
      <!-- {{d[1]}} -->
    </div>
  </ng-container>
</section>
