import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder,UntypedFormGroup,FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { AutotextService } from 'src/app/autotext.service';
import {ExamsService} from '../../exams.service';
import { CardioService } from '../cardio.service';

@Component({
  selector: 'app-twod',
  templateUrl: './twod.component.html',
  styleUrls: ['./twod.component.scss']
})
export class TwodComponent implements OnInit, OnDestroy {

  tdForm: UntypedFormGroup;
  sub = new Subscription();
  mediaSub: Subscription;
  ATS = ["LeftVentrical", "RightVentrical", "Aorta", "LeftAtrium", "RightAtrium"]

  constructor(private fb: UntypedFormBuilder, public es: ExamsService, public atS:AutotextService, public cardioS:CardioService) { 
    this.tdForm = fb.group({
      AorticRoot:[''],
      AscendingAorta:[''],
      SinusAorta:[''],
      LAAP:[''],
      LASI:[''],
      lArea:[''],
      lAVol:[''],
      RA: [''],
      LVDD:[''],
      LVSD:[''],
      IVSDiastole:[''],
      LVPW: [''],
      LVEF:[''],
      LVFS:[''],
      LVMs:[''],
      LVMd:[''],
      LVM: [''],
      LVMI:[''],
      RVdis:[''],
      lAVol2:[''],
      RAAS:[''],
      RAAESV:[''],
      TAPSE:[''],
      IVSP:[''],
      LeftVentrical:[''],
      RightVentrical:[''],
      Aorta:[''],
      LeftAtrium:[''],
      RightAtrium:[''],
      Apex :[''],
      ProximalSeptum:['']

    })
  }

  ngOnInit() {
    this.es.fillForm(this.tdForm,"cardio2D",false,true);
    this.es.getSRs();
    for(let g of this.ATS){
      this.sub = this.tdForm.get(g).valueChanges.pipe(distinctUntilChanged()).subscribe((data) => {
        // if(data)
          this.cardioS.addToFindings(data, g);
      })
    }
    if(!this.es.isClosed)
      this.mediaSub =  this.es.mediaArrived$.subscribe((mediatype)=>{
        if(mediatype == 'html')
          this.es.fillForm(this.tdForm,"cardio2D",false,true);
      });
  }

  ngOnDestroy(): void {
      this.sub.unsubscribe();
      if(this.mediaSub)
        this.mediaSub.unsubscribe();
  }

}
