import { Component, OnInit, EventEmitter, Input, Output } from "@angular/core";
import { LoginService } from "../login.service";
@Component({
  selector: "app-searchbox",
  templateUrl: "./searchbox.component.html",
  styleUrls: ["./searchbox.component.scss"],
})
export class SearchboxComponent implements OnInit {
  @Output() closed = new EventEmitter<any[]>();
  @Input("startdate") startdate: Date;
  @Input("enddate") enddate: Date;
  @Input("exams") exams;
  @Input("examtype") etype = "All";
  @Input("reportstype") reportstype = "statisticsForLeumit";
  @Input("hmostype") hmostype = "Leumit";

  @Input("isGastro") isGastro;
  @Input("isReports") isReports;
  searchmin = null;
  searchmax = null;
  format: string = "statistics";
  filter: string = "";

  cansearch = true;

  constructor(public ls: LoginService) {}

  ngOnInit() {
    // this.searchmax = new Date(new Date().setDate(new Date().getDate() - 1));
    // this.searchmax.setDate(this.searchmin.getDate() -1 );
    // this.enddate = this.searchmax;
    
    // this.searchmin = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
    // this.searchmin.setDate(this.searchmin.getDate() -1 );
    // this.startdate = this.searchmax;
   


    // this.searchmax = new Date(new Date().setDate(new Date().getDate() - 1));
    // this.enddate = this.searchmax;
    
    // this.searchmin = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
    // this.searchmin.setDate(this.searchmin.getDate() -1 );
    //   this.startdate = this.searchmin;
    // console.log(this.exams);

   
    //   // / Set searchmax to yesterday's date
    //   this.searchmax = new Date();
    //   this.searchmax.setDate(this.searchmax.getDate() - 1);
  }
  search(){
    
    let vals = [this.startdate,this.enddate,this.exams, this.etype];
    if(this.isReports)
      vals  = [this.startdate, this.enddate, [] ,this.reportstype, this.hmostype]
    if(this.etype == 'Travel Report')
      vals = [this.startdate, this.enddate,'Travel Report'];
    
    this.closed.emit(vals);
  }

  setVals(mode: string) {
    switch (mode) {
      case "All":
        for (let e in this.exams) {
          this.exams[e] = true;
        }
        break;
      case "OB":
        for (let e in this.exams) {
          this.exams[e] = true;
          if (e == "Amniocentesis" || e == "CVS" || e == "Follicles") {
            this.exams[e] = false;
          }
        }
        this.exams.Gynecology = false;
        break;
      case "Gastro":
        for (let e in this.exams) {
          this.exams[e] = true;
        }
        this.exams.Gynecology = false;
        break;
      case "GYN":
        for (let e in this.exams) {
          this.exams[e] = false;
        }
        this.exams.Gynecology = true;
        break;
      case "Invasive":
        for (let e in this.exams) {
          this.exams[e] = false;
          if (e == "Amniocentesis" || e == "CVS" || e == "Follicles") {
            this.exams[e] = true;
          }
        }
        this.exams.Gynecology = false;
        break;
      case "Refferelnum":
      case "HMO":
      case "Macabi":
      case "AllReports":
        this.cansearch = true;

        break;
    }
    this.cansearch = this.hasExam();
  }

  setValuesForLeumitReports(type: string) {
    switch (type) {
      case "statisticsForLeumit":
        this.format = "statistics";
        this.filter = "";
        break;
      case "missingPrn":
        this.format = "list";
        this.filter = "missing";
        break;
      case "sentToLeumit":
        this.format = "list";
        this.filter = "sent";
        break;
    }
  }

  searchLeumitReports(){
    let values=[this.format,this.filter,this.startdate,this.enddate,this.reportstype];
    console.log(values);
    
    this.closed.emit(values);
  }
  setE(event, exam: string) {
    if (event.srcElement.type != "checkbox") return;
    this.exams[exam] = !this.exams[exam];
    this.cansearch = this.hasExam();
  }

  hasExam(): boolean {
    if (!this.exams) return false;
    for (let e in this.exams) if (this.exams[e]) return true;
    //if nothing
    return false;
  }
}
