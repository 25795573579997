import { Component, OnInit } from '@angular/core';
import { AutotextService } from 'src/app/autotext.service';
import { ExamsService } from 'src/app/exams.service';
import { GastroService } from 'src/app/gastro.service';

@Component({
  selector: 'app-findings',
  templateUrl: './findings.component.html',
  styleUrls: ['./findings.component.scss']
})
export class FindingsComponent implements OnInit {


  constructor(public gs:GastroService, public atS:AutotextService, public es:ExamsService) { }

  ngOnInit() {
  }

}
