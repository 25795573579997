import {MeasureComponent} from '../measure/measure.component';
import { ChartDataSets, ChartOptions } from 'chart.js';
export class Measure {
    name: string;
    value: number;
    valuemin: number;
    displayName: string;
    printName:string;
    GA: string;
    GG: number;
    GGfig: string;
    GAfig: string;
    GA_opts: string[];
    GA_tables;//from json
    GG_tables;
    GG_opts: string[];
    stylename: string;
    GAmin: number;
    GAmax: number;
    GGmin: string;
    GGmax: string;
    public WeightType: string = 'Hadlock1 (AC,FL)'
    min: number;
    max: number;
    myComp: MeasureComponent;
    lastGA: string;//for when the value loads before tables
    Table:string;
    hasMinMax=false;
    showSD = false;

    constructor(nm: string,GAo: string[],GGo: string[],mmin: number, mmax: number,table:string = "meas",altTable:string=null,printcopy = false, displayName:string ="", minmax = false, printName:string="",defaultNone = false) {
        this.name = nm;
        this.displayName = displayName;
        this.printName = printName;
        this.GA_opts=GAo;
        this.GG_opts=GGo;
        this.stylename="";
        this.GAmin = mmin;
        this.GAmax=mmax;
        this.value = null;
        this.valuemin = null;
        this.Table = table;
        let mtable = nm;
        this.hasMinMax = minmax;
        if(altTable)
            mtable=altTable;
        if(! printcopy){
            try{
            if(nm.indexOf("EFW")  == -1){//for now to stop debug warnings
                    if( GAo.length > 0)
                        import("../../assets/tables/"+mtable+"_ga.json").then((ga) => { 
                            // TypeScript knows that `x` is 'someplace' and will infer the type of `a` correctly
                            this.GA_tables=ga.default.tables;
                        // this.GA_opts = [];
                            this.GA_opts = Object.keys(this.GA_tables);
                            this.GA_opts.push("none");
                            this.GAfig = this.GA_opts[0];
                            if(this.value && this.lastGA)
                                this.calcGATable();
                        });
                    if(GGo.length > 0)
                        import("../../assets/tables/"+mtable.replace('/','')+"_gg.json").then((gg) => { 
                            // TypeScript knows that `x` is 'someplace' and will infer the type of `a` correctly
                            this.GG_tables=gg.default.tables;
                            this.GG_opts = Object.keys(this.GG_tables);
                            if(defaultNone)
                                this.GG_opts.unshift("none");
                            else
                                this.GG_opts.push("none");
                            this.GGfig = this.GG_opts[0];
                            if(this.value && this.lastGA)
                                this.calcGGTable(this.lastGA);
                        });
                }
                if(nm.indexOf("EFW") > -1){
                    import("../../assets/tables/EFW_gg.json").then((gg) => { 
                        this.GG_tables=gg.default.tables;
                        this.GG_opts = Object.keys(this.GG_tables);
                        this.GA_opts = ['Hadlock', 'none'];
                        if(defaultNone){
                            this.GG_opts.unshift("none"); 
                        }
                        else{
                            this.GG_opts.push("none");
                        }
                    });
                    
                }

            }
            catch{}
        }
    }

    addNoneToFront(){
        if(this.GG_opts.includes("none"))
            this.GG_opts.splice(this.GG_opts.indexOf('none'));
        this.GG_opts.unshift('none');
    }

    setCompnent(c: MeasureComponent){
        this.myComp = c;
    }

    setSDColor(){
        try{this.myComp.SDClass();}
        catch{}
    }

    getBoundaries(tablename: string,points:ChartDataSets):ChartDataSets[]{
        let chartData:ChartDataSets[] = [];
        let data={
            p3:[],
            p5 : [],
            p50: [],
            p95: [],
            p97: []
        };
        
        if(this.GG_tables[tablename].hasOwnProperty("type") && this.GG_tables[tablename].type && this.GG_tables[tablename].type == "5-col"){

            for(var i=0; i< this.GG_tables[tablename].data.length; i++){
                data.p3.push(
                    { x: Math.round((Measure.WeekToDays(this.GG_tables[tablename].data[i][0])/7)*10)/10, y: this.GG_tables[tablename].data[i][1]/10 }
                );
                data.p5.push(
                    { x: Math.round((Measure.WeekToDays(this.GG_tables[tablename].data[i][0])/7)*10)/10, y: this.GG_tables[tablename].data[i][2]/10 }
                );
                data.p50.push(
                    { x: Math.round((Measure.WeekToDays(this.GG_tables[tablename].data[i][0])/7)*10)/10, y: this.GG_tables[tablename].data[i][3]/10 }
                );
                data.p95.push(
                    { x: Math.round((Measure.WeekToDays(this.GG_tables[tablename].data[i][0])/7)*10)/10, y: this.GG_tables[tablename].data[i][4]/10 }
                );
                data.p97.push(
                    { x: Math.round((Measure.WeekToDays(this.GG_tables[tablename].data[i][0])/7)*10)/10, y: this.GG_tables[tablename].data[i][5]/10 }
                );
            }
            //return data;
            
                chartData = [
                    points,
                  {data: data.p3,label: '3%',showLine:true,pointRadius: 0,fill: false,borderColor: '#CD5164',backgroundColor:'#CD5164' ,borderWidth: 2},
                  { data: data.p5, label: '5%',showLine:true,pointRadius: 0,fill: false,borderColor: 'orange',backgroundColor:'orange' ,borderWidth: 2 },
                  { data: data.p50, label: '50%' ,showLine:true,pointRadius: 0,fill: false,borderColor: '#CFE4A5', backgroundColor: '#CFE4A5',borderWidth: 2},
                  { data: data.p95, label: '95%',showLine:true,pointRadius: 0,fill: false,borderColor: 'orange',backgroundColor:'orange' ,borderWidth: 2 },
                  {data: data.p97,label: '97%',showLine:true,pointRadius: 0,fill: false,borderColor: '#CD5164',backgroundColor:'#CD5164' ,borderWidth: 2}
                ];
                
               
                return chartData;
        }


        let sd = false;
        if(this.GG_tables[tablename].hasOwnProperty("calculation") && this.GG_tables[tablename].calculation == "SD")
            sd = true;

        if(! this.GG_tables[tablename].hasOwnProperty("weeks")){
            for(var i=0; i< this.GG_tables[tablename].data.length; i++){
                data.p5.push(
                    { x: Math.round((Measure.WeekToDays(this.GG_tables[tablename].data[i][0])/7)*10)/10, y: (sd ? this.GG_tables[tablename].data[i][1]/10 - 2*this.GG_tables[tablename].data[i][2]/10 :  this.GG_tables[tablename].data[i][1]/10) }
                );
                data.p50.push(
                    { x: Math.round((Measure.WeekToDays(this.GG_tables[tablename].data[i][0])/7)*10)/10, y: sd ? this.GG_tables[tablename].data[i][1]/10 : this.GG_tables[tablename].data[i][2]/10 }
                );
                data.p95.push(
                    { x: Math.round((Measure.WeekToDays(this.GG_tables[tablename].data[i][0])/7)*10)/10, y: (sd ? this.GG_tables[tablename].data[i][1]/10 + 2*this.GG_tables[tablename].data[i][2]/10 : this.GG_tables[tablename].data[i][3]/10) }
                );
            }
        }
        else{
            if(this.GG_tables[tablename].hasOwnProperty('mean_formula')){
                for(let GA = this.GG_tables[tablename].min_weeks; GA <= this.GG_tables[tablename].max_weeks; GA++){
                    let mean = Math.round(eval(this.GG_tables[tablename].mean_formula) * 100)/10;//for mm
                    //here p5 and p95 are -2SD and +2SD respectively (not percentiles)
                    data.p5.push({x: GA, y: mean - 2 * this.GG_tables[tablename].SD});
                    data.p50.push({x: GA, y: mean});
                    data.p95.push({x: GA, y: mean + 2 * this.GG_tables[tablename].SD});
                }
            }
            else
                for(var i=0; i< this.GG_tables[tablename].data.length; i++){
                    data.p5.push(
                        { x: this.GG_tables[tablename].data[i][0], y: (sd ? this.GG_tables[tablename].data[i][1]/10 - 2*this.GG_tables[tablename].data[i][2]/10 :  this.GG_tables[tablename].data[i][1]/10) }
                    );
                    data.p50.push(
                        { x: this.GG_tables[tablename].data[i][0], y: sd ? this.GG_tables[tablename].data[i][1]/10 : this.GG_tables[tablename].data[i][2]/10 }
                    );
                    data.p95.push(
                        { x: this.GG_tables[tablename].data[i][0], y: (sd ? this.GG_tables[tablename].data[i][1]/10 + 2*this.GG_tables[tablename].data[i][2]/10 : this.GG_tables[tablename].data[i][3]/10) }
                    );
                }
        }
        
        chartData = [
            //myValues,
            points,
            { data: data.p5, label: sd?'-2SD' :'5%',showLine:true ,pointRadius: 0,fill: false,borderColor: '#CD5164' ,  backgroundColor:'#CD5164',borderWidth: 2},
            { data: data.p50, label: sd ? 'mean': '50%' ,showLine:true,pointRadius: 0,fill: false,borderColor: '#CFE4A5', backgroundColor: '#CFE4A5',borderWidth: 2 },
            { data: data.p95, label: sd ? '+2SD' :'95%',showLine:true,pointRadius: 0,fill: false,borderColor: '#CD5164',backgroundColor:'#CD5164' ,borderWidth: 2},
        ];
        return chartData;
          
    }

    calcGATable(tablename: string = this.GAfig): void{
        if(tablename == null || tablename == "none" || ! this.GA_tables){
            this.GA=null;
            return;
        }
        let min = this.GA_tables[tablename].data[0][0]/10;
        let max = this.GA_tables[tablename].data[this.GA_tables[tablename].data.length -1][0]/10;
        if(this.value < min || this.value>max){
            this.GA = null;
            return;
        }
        var i =0;
         var m = 0;
        var table = this.GA_tables[tablename].data; 
        var isdecimal = this.GA_tables[tablename].hasOwnProperty("weeks") && this.GA_tables[tablename].weeks=="decimal";

        var value: number  = this.value * 10;
        for (; i < table.length ; i++)
        {
            if( value <= table[i][0]){
                //logger.writeLog("need to break at "+ i);
                m=i;
                break; 
            }
            //else
                //logger.writeLog(meas+ "is less than " + currentGA[i][0]);
        }
        if (m == 0){
            //logger.writeLog("m=0 tring "+ name + " with m:"+meas +" cga" + currentGA[m][0]+","+currentGA[m][1]);
            this.GA = Measure.CalcRelative(value, table[m][0], null, table[m][1], null, isdecimal);
        }
        if(i = table.length)
            i--;
        //logger.writeLog(m+ " i is tring "+ name + " with m:"+meas +" cga" + currentGA[m][0]+","+ currentGA[m-1][0]+","+currentGA[m][1]+","+currentGA[m-1][1]);
        try{this.GA= Measure.CalcRelative(value, table[m][0], table[m-1][0], table[m][1], table[m-1][1], isdecimal)}
        catch{}
    }

    static DaysToWeeks(f: number):string
    {
        let days = Math.round(f)
        let wV = Math.floor(days / 7);
        let d = days - (wV*7);
        if(wV >= 44)
            return "44w+"
        return wV+"w"+d+"d"
    }

    static CalcRelative(meas, val, valP, week, weekP, isdeci: boolean): string
    {
        if ((valP == null)&&(weekP == null))
            return week;
        var ww: number;
        var wwP: number;
        let r = (meas - valP)/(val - valP)
        if(! isdeci){
            let ww = Measure.WeekToDays(week)
            let wwP = Measure.WeekToDays(weekP)
            return Measure.DaysToWeeks(wwP + (r * (ww - wwP)))
        }
        else{
            return Measure.DaysToWeeks(7*(weekP + (r * (week - weekP))))
        }
        
       
        
    }

    static WeekToDays(str: string): number
    {
        try{ 
            str = str.replace(" ","");
            let n = str.split("w")
            let wn = parseInt(n[0]) * 7
            let d = parseInt(n[1].charAt(0))
            return wn + d 
        }
        catch(e){
            return 0;
        }
    }

    getMain():string{
        return "measNormal"
    }

    static normal_distribution(z_num)
    {
        var Nd = new Array() ;
        Nd[0] = new Array(0.5000,0.5040,0.5080,0.5120,0.5160,0.5199,0.5239,0.5279,0.5319,0.5359);
        Nd[1] = new Array(0.5398,0.5438,0.5478,0.5517,0.5557,0.5596,0.5636,0.5675,0.5714,0.5753);
        Nd[2] = new Array(0.5793,0.5832,0.5871,0.5910,0.5948,0.5987,0.6026,0.6064,0.6103,0.6141);
        Nd[3] = new Array(0.6179,0.6217,0.6255,0.6293,0.6331,0.6368,0.6406,0.6443,0.6480,0.6517);
        Nd[4] = new Array(0.6554,0.6591,0.6628,0.6664,0.6700,0.6736,0.6772,0.6808,0.6844,0.6879);
        Nd[5] = new Array(0.6915,0.6950,0.6985,0.7019,0.7054,0.7088,0.7123,0.7157,0.7190,0.7224);
        Nd[6] = new Array(0.7257,0.7291,0.7324,0.7357,0.7389,0.7422,0.7454,0.7486,0.7517,0.7549);
        Nd[7] = new Array(0.7580,0.7611,0.7642,0.7673,0.7704,0.7734,0.7764,0.7794,0.7823,0.7852);
        Nd[8] = new Array(0.7881,0.7910,0.7939,0.7967,0.7995,0.8023,0.8051,0.8078,0.8106,0.8133);
        Nd[9] = new Array(0.8159,0.8186,0.8212,0.8238,0.8264,0.8289,0.8315,0.8340,0.8365,0.8389);
        Nd[10] = new Array(0.8413,0.8438,0.8461,0.8485,0.8508,0.8531,0.8554,0.8577,0.8599,0.8621);
        Nd[11] = new Array(0.8643,0.8665,0.8686,0.8708,0.8729,0.8749,0.8770,0.8790,0.8810,0.8830);
        Nd[12] = new Array(0.8849,0.8869,0.8888,0.8907,0.8925,0.8944,0.8962,0.8980,0.8997,0.9015);
        Nd[13] = new Array(0.9032,0.9049,0.9066,0.9082,0.9099,0.9115,0.9131,0.9147,0.9162,0.9177);
        Nd[14] = new Array(0.9192,0.9207,0.9222,0.9236,0.9251,0.9265,0.9279,0.9292,0.9306,0.9319);
        Nd[15] = new Array(0.9332,0.9345,0.9357,0.9370,0.9382,0.9394,0.9406,0.9418,0.9429,0.9441);
        Nd[16] = new Array(0.9452,0.9463,0.9474,0.9484,0.9495,0.9505,0.9515,0.9525,0.9535,0.9545);
        Nd[17] = new Array(0.9554,0.9564,0.9573,0.9582,0.9591,0.9599,0.9608,0.9616,0.9625,0.9633);
        Nd[18] = new Array(0.9641,0.9649,0.9656,0.9664,0.9671,0.9678,0.9686,0.9693,0.9699,0.9706);
        Nd[19] = new Array(0.9713,0.9719,0.9726,0.9732,0.9738,0.9744,0.9750,0.9756,0.9761,0.9767);
        Nd[20] = new Array(0.9772,0.9778,0.9783,0.9788,0.9793,0.9798,0.9803,0.9808,0.9812,0.9817);
        Nd[21] = new Array(0.9821,0.9826,0.9830,0.9834,0.9838,0.9842,0.9846,0.9850,0.9854,0.9857);
        Nd[22] = new Array(0.9861,0.9864,0.9868,0.9871,0.9875,0.9878,0.9881,0.9884,0.9887,0.9890);
        Nd[23] = new Array(0.9893,0.9896,0.9898,0.9901,0.9904,0.9906,0.9909,0.9911,0.9913,0.9916);
        Nd[24] = new Array(0.9918,0.9920,0.9922,0.9925,0.9927,0.9929,0.9931,0.9932,0.9934,0.9936);
        Nd[25] = new Array(0.9938,0.9940,0.9941,0.9943,0.9945,0.9946,0.9948,0.9949,0.9951,0.9952);
        Nd[26] = new Array(0.9953,0.9955,0.9956,0.9957,0.9959,0.9960,0.9961,0.9962,0.9963,0.9964);
        Nd[27] = new Array(0.9965,0.9966,0.9967,0.9968,0.9969,0.9970,0.9971,0.9972,0.9973,0.9974);
        Nd[28] = new Array(0.9974,0.9975,0.9976,0.9977,0.9977,0.9978,0.9979,0.9979,0.9980,0.9981);
        Nd[29] = new Array(0.9981,0.9982,0.9982,0.9983,0.9984,0.9984,0.9985,0.9985,0.9986,0.9986);
        
        var j0 = (Math.floor(z_num * 10 )) % 10 ;
        var j1 = Math.floor(z_num) ;
        var j = j0 + j1 * 10 ;
        
        if (j > 29)
            return 0.999;
        if(j < -29)
            return 0.001;

        var i = (Math.floor(z_num * 100)) % 10 ;
        if(j < 0){
            return 1- Nd[Math.abs(j)][Math.abs(i)];
        }
        return Nd[j][i] ;
    }

    static GetZPercent(z: number) 
    {
      //z == number of standard deviations from the mean
  
      //if z is greater than 6.5 standard deviations from the mean
      //the number of significant digits will be outside of a reasonable 
      //range
      if ( z < -6.5)
        return 0.0;
      if( z > 6.5) 
        return 1.0;
  
      var factK = 1;
      var sum = 0;
      var term = 1;
      var k = 0;
      var loopStop = Math.exp(-23);
      while(Math.abs(term) > loopStop) 
      {
        term = .3989422804 * Math.pow(-1,k) * Math.pow(z,k) / (2 * k + 1) / Math.pow(2,k) * Math.pow(z,k+1) / factK;
        sum += term;
        k++;
        factK *= k;
  
      }
      sum += 0.5;
  
      return sum;
    }

    static percentile(z_val: number):number
    {
        let abs_val = Math.abs(z_val)
        let ret = Measure.GetZPercent(abs_val) * 100.0;

        if (z_val < 0)
            ret = 100 - ret;

        
        ret =  <number> <unknown>ret.toFixed(4)
        return ret
    }

    calcGGTable(ga:string, figure : string = this.GGfig){
        //if ((lmp < 12) || (lmp > 40))
          //  return ""
        if(! this.value || ! figure ||figure == "none"){
            this.GG = null;
            return;
        }
        if(! this.GG_tables){
            this.lastGA=ga;
            return;
        } 
        if(this.GG_tables[figure].hasOwnProperty("calculation")&& this.GG_tables[figure].calculation == "SD"){
            if(this.GG_tables[figure].hasOwnProperty("mean_formula"))
                this.calcGGSDformula(ga,figure);
            else
                this.calcGGSD(ga,figure);
            return;
        }
        let meas = this.value * 10;
        let days = Measure.WeekToDays(ga)
        var i=0;
        var tmpDays:number;
        for (; i < this.GG_tables[figure].data.length; i++)
        {
            tmpDays = Measure.WeekToDays( this.GG_tables[figure].data[i][0])
            if (tmpDays >= days)
                break
        }
        if(i == this.GG_tables[figure].data.length)
            i--;
        if (i == 0){
            this.GG = Measure.CalcRelativeGG(days, meas, this.GG_tables[figure].data[i], null);
            return;
        }
        this.GG= Measure.CalcRelativeGG(days, meas, this.GG_tables[figure].data[i], tmpDays == days ? null : this.GG_tables[figure].data[i-1]);
        
    }
 
    inGGRange(ga:string){
        if(! this.GGfig || this.GGfig == 'none' || !this.GG_tables[this.GGfig])
            return true;
        let days = Measure.WeekToDays(ga)
        var tdays,enddays;
        if(this.GG_tables[this.GGfig]['min_weeks']){
            tdays = this.GG_tables[this.GGfig]['min_weeks'];
            enddays = this.GG_tables[this.GGfig]['max_weeks'] * 7
        }
        else{
            if(this.GG_tables[this.GGfig]['weeks'] == 'decimal'){
                tdays = this.GG_tables[this.GGfig].data[0][0] * 7;
                enddays = this.GG_tables[this.GGfig].data[this.GG_tables[this.GGfig].data.length -1][0] * 7
            }
            else{
                tdays = Measure.WeekToDays( this.GG_tables[this.GGfig].data[0][0]);
                enddays = Measure.WeekToDays( this.GG_tables[this.GGfig].data[this.GG_tables[this.GGfig].data.length -1][0]);
            }
        }
        if(tdays > days || days > enddays)
            return false;
        return true;
    }

    getRange(ga:string, figure : string = this.GGfig,round = false):string{
        if(! this.GG_tables || ! this.GG_tables.hasOwnProperty(figure))
            return "";
        let days = Measure.WeekToDays(ga)
        if(Measure.WeekToDays( this.GG_tables[figure].data[0][0]) > days || 
        days > Measure.WeekToDays( this.GG_tables[figure].data[this.GG_tables[figure].data.length -1][0]))
            return "GA is outside of this table";
        let i=0;
        for(;i < this.GG_tables[figure].data.length; i++)
        {
            let tmpDays = Measure.WeekToDays( this.GG_tables[figure].data[i][0])
            if (tmpDays >= days)
                break;
        }
        let start:number;
        let end: number;
        if( i > 0){
            let ww = Measure.WeekToDays(this.GG_tables[figure].data[i][0]);
            let wwP = Measure.WeekToDays(this.GG_tables[figure].data[i-1][0]);
            let r = (days - wwP)/(ww - wwP);
            start = Math.round((this.GG_tables[figure].data[i-1][1] + r * (this.GG_tables[figure].data[i][1] - this.GG_tables[figure].data[i-1][1]))*100) /1000 ;
            end = Math.round((this.GG_tables[figure].data[i-1][3] + r * (this.GG_tables[figure].data[i][3] - this.GG_tables[figure].data[i-1][3]))*100) /1000 ;
            
            
        }
        else{
            start = this.GG_tables[figure].data[i][1]/10;
            end = this.GG_tables[figure].data[i][3]/10
        }
        if(round){
            if(start > 10)
                start = Math.round(start);
            else
                start = Math.round(start * 100) / 100;
            if(end > 10)
                end = Math.round(end);
            else
                end = Math.round(end * 100) / 100;
        }
        return start+" to "+end;
        
    }

    calcGGSDformula(ga,figure){
        let GA = Measure.WeekToDays(ga)/7;
        let mean = eval(this.GG_tables[figure].mean_formula) * 10;//for mm
        let z = (this.value - mean)/(this.GG_tables[figure].SD);
        let percent = Measure.percentile(z);
        this.GG = Math.round(percent * 100) / 100;
    }

    calcGGSD(ga: string, figure: string){
        try{
            let meas = this.value * 10;
            let days = Measure.WeekToDays(ga)
            var i=0;
            var tmpDays = 0;
            var isdecimal = this.GG_tables[figure].hasOwnProperty("weeks") && this.GG_tables[figure].weeks=="decimal";
            for (; i < this.GG_tables[figure].data.length; i++)
            {
                if(isdecimal)
                    tmpDays =  this.GG_tables[figure].data[i][0] * 7
                else
                    tmpDays = Measure.WeekToDays( this.GG_tables[figure].data[i][0])
                if (tmpDays >= days)
                    break
            }
            //calc mean and sd
            var mean: number;
            var sd: number;
            if(tmpDays > days){
                //day variance x mean variance + mean
                var pdays = isdecimal ? this.GG_tables[figure].data[i-1][0] * 7 : Measure.WeekToDays( this.GG_tables[figure].data[i-1][0]);

                let daysf = (days - pdays)/(tmpDays - pdays); 
                mean = this.GG_tables[figure].data[i-1][1]+ daysf  * (this.GG_tables[figure].data[i][1] - this.GG_tables[figure].data[i-1][1]);
                sd = this.GG_tables[figure].data[i-1][2] + daysf * (this.GG_tables[figure].data[i][2] - this.GG_tables[figure].data[i-1][2]);
            }
            else{//when exactly on the day
                mean = this.GG_tables[figure].data[i][1];
                sd = this.GG_tables[figure].data[i][2];
            }

            let z = (meas - mean)/sd;
            let percent = Measure.percentile(z);
            if(percent > 2.23 || figure != 'Chervenak'){
                this.GG = Math.round(percent * 100) / 100;
                this.showSD = false
            }
            else{
                this.GG = Math.round(z * 1000) / 1000;
                this.showSD = true;
            }
        }
        catch(e){
            console.log('error - probably out of bounds in calc gg for '+ figure + e);
        }
    }       

    static CalcRelativeGG(lmpd: number, meas: number, a, aP,t90: boolean = false)
    {
        meas  = Math.round(meas * 100) / 100;//round because of floating point offset
        let ret = null
        let r =0;
        let n1 = 0;
        let n2 = 0;
        let n3 = 0
        let n4=0
        let n5 = 0

        if (aP == null)
        {
            n1 = a[1]
            n2 = a[2]
            n3 = a[3]
        }
        else
        {
            let ww = Measure.WeekToDays(a[0]);
            let wwP = Measure.WeekToDays(aP[0]);
        
            r = (lmpd - wwP)/(ww - wwP)
            n1 = aP[1] + r *(a[1] - aP[1] )
            n2 = aP[2] + r *(a[2] - aP[2] )
            n3 = aP[3] + r *(a[3] - aP[3] )
        }

        if(a.length > 5){//for the 3% 10% 50% 90% 97% tables
            if(aP == null){
                n4 = a[4]
                n5 =a[5]
            }else{
                n4 = aP[4] + r *(a[4] - aP[4] )
                n5 = aP[5] + r *(a[5] - aP[5] )
            }
            if (meas < n1)
            return 1;
        
            if ((meas >= n1)&&(meas <= n2))
                return 3 + Math.round( (meas - n1)/(n2 - n1) * 7 )

            if ((meas >= n2)&&(meas <= n3))
                return 10 + Math.round( (meas - n2)/(n3 - n2) * 40 )
            if ((meas >= n3)&&(meas <= n4))
                return 50 + Math.round( (meas - n3)/(n4 - n3) * 40 )
            if ((meas >= n4)&&(meas <= n5))
                return 90 + Math.round( (meas - n4)/(n5 - n4) * 7 )

            else //(meas > n3)
                return 99;
        }
        
        if(t90){
            if (meas < n1)
            ret = 1;
        
            else if ((meas >= n1)&&(meas <= n2))
                ret = 10 + Math.round( (meas - n1)/(n2 - n1) * 40 )

            else if ((meas >= n2)&&(meas <= n3))
                ret = 50 + Math.round( (meas - n2)/(n3 - n2) * 40 )

            else //(meas > n3)
                ret = 99;
        }
        else{
            if (meas < n1)
                ret = 1;

            else if ((meas >= n1)&&(meas <= n2))
                ret = 5 + Math.round( (meas - n1)/(n2 - n1) * 45 )

            else if ((meas >= n2)&&(meas <= n3))
                ret = 50 + Math.round( (meas - n2)/(n3 - n2) * 45 )

            else //(meas > n3)
                ret = 99;
        }
        return ret;
    }

  }