	<!-- ==================================== POPUP : GRAPHS (STARTS) ============================================== -->

	<div class="t-popup popup-graphs" style='display: block;' *ngIf="loaded">

            <div class="popup-close"></div>
            
            <div class="popup-frame">
                <div class="popup-body">
                    <h2>Graphs</h2>
                    <div class="graphs-container">
                        <ng-container *ngFor="let m of chartlist">
                            <div class="graphs-item-wrapper">
                                <div class="graphs-item">
                                    <div class="graphs-item-title">{{getName(m)}} <ng-container *ngIf="m.indexOf('EFW')==-1">({{ms.measref[m].GGfig}})</ng-container></div>
                                    <canvas baseChart [datasets]="chartData[m]"
                                        [options]="m.indexOf('EFW') > -1 ? weightChartOptions:lineChartOptions"
                                        [legend]="true"
                                        [chartType]="'scatter'"
                                    ></canvas>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <button type="button" class="popup-close-button" (click)="closed.emit()">Close Graphs Popup</button>
                </div>
            </div>
    
        </div>
    
        <div class="second-layer-popup">
            <div class="second-layer-popup-frame">
                <div class="second-layer-popup-body"></div>
            </div>
        </div>
    
        <!-- ===================================== POPUP : GRAPHS (END)  =============================================== -->
