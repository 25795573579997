<div class="m-box summary" [ngClass]="getClass(name)" style="height: 100%;direction: ltr;" [formGroup]="gfs.Form" (dblclick)="gfs.Form.get(name).setValue('')">

    <h2 style="width: max-content;direction: rtl;">{{displayName}}</h2>

    <div class="anatomy-twin-radios" style="margin-top: 0;">

        <label class="observe">

            <input type="radio" class="custom-check customInput" [formControlName]="type"
                [value]="0"><i class="customInput-display"></i>

            <span>
                כן
            </span>
        </label>

        <label class="not-observed">

            <input type="radio" class="custom-check customInput" [formControlName]="type"
                [value]="1"><i class="customInput-display"></i>

            <span>
                לא
            </span>

        </label>
    </div>
    <span class="anatomy-show-more" style="bottom: auto;right: auto;">
        <!--form elemnts not working when empty so using hack-->

        <div class="on-hoverPanel">

            <div class="on-hoverPanel-body">

                <div class="anatomy-item-details">

                    <h3 class="anatomy-item-details-title">
                        {{displayName}}
                    </h3>

                    <div class="anatomy-items-desc">

                        <div class="anatomy-items-edited scroller mCustomScrollbar _mCS_2 mCS_no_scrollbar"
                            contenteditable="true">
                            <div id="mCSB_2" class="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside" tabindex="0"
                                style="max-height: none;">
                                <div id="mCSB_2_container" class="mCSB_container mCS_y_hidden mCS_no_scrollbar_y"
                                    style="position:relative; top:0; left:0;" dir="ltr"> </div>
                                <div id="mCSB_2_scrollbar_vertical"
                                    class="mCSB_scrollTools mCSB_2_scrollbar mCS-light mCSB_scrollTools_vertical"
                                    style="display: none;">
                                    <div class="mCSB_draggerContainer">
                                        <div id="mCSB_2_dragger_vertical" class="mCSB_dragger"
                                            style="position: absolute; min-height: 30px; top: 0px; display: block; height: 12px; max-height: 106.938px;">
                                            <div class="mCSB_dragger_bar" style="line-height: 30px;"></div>
                                        </div>
                                        <div class="mCSB_draggerRail"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <textarea class="anatomy-items-edit" [name]="type+'Note'"
                            [formControlName]="type+'Note'"></textarea>


                    </div>

                </div>

                <!--<button class="anatomy-details-save">Save Comments</button> -->

            </div>

        </div>

    </span>
</div>