<ng-container [formGroup]="GenForm">

    <div class="h100 l-flex-w t-boxMargin--spacebyPadding summary">

        <!-- cell : Anamnesis -->

        <div class="w50 summary-anamnesis" style="height: 50%;" *ngIf="es.ExamType != 'Stress'">

            <section class="m-box">

                <h2>
                    Medical History
                </h2>

                <textarea class="scroller" formControlName="MedicalHistory">

                </textarea><!-- /scroller -->
                <button class="t-btn-edit" *ngIf="! es.isClosed && lastMed" (click)="addLastMed()"
                    style="margin-right: 6em">Previous Medical History</button>
                <button class="t-btn-edit" *ngIf="! es.isClosed"
                    (click)="atS.showAuto(GenForm.get('MedicalHistory'),'Medical History',1)">Auto Text</button>

            </section>

        </div>
        <!-- endcell : Anamnesis -->

        <!-- cell : Reason for Examinanion -->

        <div class="w50 summary-recommendations" style="height: 50%;">

            <section class="m-box">

                <h2>
                    Reason for Examination
                </h2>

                <textarea class="scroller" formControlName="Purpose">

                </textarea><!-- /scroller -->

                <button class="t-btn-edit" *ngIf="! es.isClosed"
                    (click)="atS.showAuto(GenForm.get('Purpose'),'Reason for Examinanion',92)">Auto Text</button>

            </section>

        </div>

        <!-- endcell : Reason for Examinanion -->
        <!-- cell : Risk Factors -->

        <div class="w50 summary-recommendations" style="height: 50%;" *ngIf="es.ExamType == 'Stress'">

            <section class="m-box">

                <h2>
                    Medical Hx  and risk factors 
                </h2>

                <textarea class="scroller" formControlName="Risk">

                </textarea><!-- /scroller -->

                <button class="t-btn-edit" *ngIf="! es.isClosed"
                    (click)="atS.showAuto(GenForm.get('Risk'),'Risk Factors ',501)">Auto Text</button>

            </section>

        </div>
             <!-- cell : Medications -->
        <!-- <div class="h100 l-flex-w t-boxMargin--spacebyPadding summary" [ngClass]="es.ExamType == 'Stress' ? 'w50' : 'w100'"> -->

            <div class="summary-recommendations w50" *ngIf="es.ExamType == 'Stress'">

                <section class="m-box" style="height: 100%;">

                    <h2>Medications</h2>

                    <textarea class="scroller" formControlName="Medications" style="height: 100%;">

                    </textarea><!-- /scroller -->

                    <button class="t-btn-edit" *ngIf="! es.isClosed"
                    (click)="atS.showAuto(GenForm.get('Medications'),'Medications ',502)">Auto Text</button>

                </section>

            </div>

        <!-- </div> -->

        <!-- endcell : Medications -->
                <!-- cell : Imaging Quality && HMO -->
        <!-- <div class="h100 l-flex-w t-boxMargin--spacebyPadding summary" [ngClass]="es.ExamType == 'Stress' ? 'w50' : 'w100'"> -->
            <div class="summary-recommendations" [ngClass]="es.ExamType == 'Stress' ? 'w50' : 'w100 l-flex'">
                <div class="m-box" *ngIf="es.ExamType != 'Stress'"  [ngClass]="es.ExamType == 'Stress' ? '' : 'w50 w50-marg'" (dblclick)="GenForm.get('Quality').setValue(-1)">

                    <h2>
                        Imaging Quality
                    </h2>

                    <div class="l-flex-c l-flex-w indicative-fieldset h100" style="justify-content: space-evenly">
                        <label class="t-label-top">

                            <input type="radio" formControlName="Quality" value="Poor" class="custom-check -sq" />
                            <i class="customInput-display -sq"></i>
                            <span>
                                Poor
                            </span>

                        </label>

                        <label class="t-label-top">
                            <input type="radio" formControlName="Quality" value="Fair" class="custom-check -sq" />
                            <i class="customInput-display -sq"></i>
                            <span>
                                Fair
                            </span>
                        </label>

                        <label class="t-label-top">
                            <input type="radio" formControlName="Quality" value="Good" class="custom-check -sq" />
                            <i class="customInput-display -sq"></i>
                            <span>
                                Good
                            </span>
                        </label>
                    </div>

                </div>
                <section class="m-box" [ngClass]="es.ExamType == 'Stress' ? '' : 'w50'"  (dblclick)="GenForm.get('HMO').setValue(-1)">
        
                    <h2>
                        HMO
                    </h2>
                    <div class="l-flex-c l-flex-w indicative-fieldset h100" style="justify-content: space-evenly">
                        <label class="t-label-top">
                            <input type="radio" formControlName="HMO" value="Clalit" class="custom-check -sq" />
                            <i class="customInput-display -sq"></i>
                            <span>
                                Clalit
                            </span>
                        </label>
                        <label class="t-label-top">
                            <input type="radio" formControlName="HMO" value="Macabi" class="custom-check -sq" />
                            <i class="customInput-display -sq"></i>
                            <span>
                                Macabi
                            </span>
                        </label>

                        <label class="t-label-top">
                            <input type="radio" formControlName="HMO" value="Meuhedet" class="custom-check -sq" />
                            <i class="customInput-display -sq"></i>
                            <span>
                                Meuhedet
                            </span>
                        </label>
                        <label class="t-label-top">

                            <input type="radio" formControlName="HMO" value="Leumit" class="custom-check -sq" />
                            <i class="customInput-display -sq"></i>
                            <span>
                                Leumit
                            </span>

                        </label>

                        <label class="t-label-top">
                            <input type="radio" formControlName="HMO" value="Private" class="custom-check -sq" />
                            <i class="customInput-display -sq"></i>
                            <span>
                                Private
                            </span>
                        </label>
                        <label class="t-label-top">
                            <input type="radio" formControlName="HMO" value="IDF" class="custom-check -sq" />
                            <i class="customInput-display -sq"></i>
                            <span>
                                IDF
                            </span>
                        </label>


                    </div>
                </section>
            </div>
            <!-- <div class="w50 summary-recommendations">


            </div> -->
    <!-- </div> -->
        <!-- endcell : Imaging Quality && HMO  -->

        <!-- endcell : Risk Factors -->

    </div>
    <!-- box (start) -->
    <section class="m-box" style="margin-top: 2em;" *ngIf="es.ExamType == 'TTE Adults'">

        <h2>
            Study
        </h2>

        <div class="w100 placenta-ammiotic">

            <!-- row (start) -->
            <div class="l-flex-c l-flex-w l-justify h50 placenta-row">
                <label class="t-label-top" *ngIf="es.ExamType =='TTE Adults'">
                    <app-geninput [form]="GenForm.get('ECG')" title="Resting ECG"></app-geninput>
                </label>

                <label class="t-label-top">
                    <span>
                        Heart Rate
                    </span>
                    <span class="t-field t-field--number">
                        <input class="t-field-text" type="number" min="0" formControlName="HeartRate" />
                        <span class="t-field-desc">
                            bpm
                        </span>
                    </span>
                </label>

                <label class="t-label-top">
                    <span>
                        Blood Pressure
                    </span>
                    <span class="t-field t-field--number" style="max-width: fit-content">
                        <input class="t-field-text" type="number" min="0" formControlName="bpSystolic"
                            style='width: 4em' />/<input class="t-field-text" type="number" min="0"
                            formControlName="bpDiastolic" style='width: 4em'>
                        <span class="t-field-desc">
                            mmHg
                        </span>
                    </span>
                </label>


            </div>
            <!-- row (end) -->


        </div>

    </section>
    <!-- box (end) -->
    <div class="h100 l-flex-w t-boxMargin--spacebyPadding summary " style='padding-bottom: 1em;margin-top: 1em;'>
        <div class="w50">
            <div class="m-box">

                <h2>
                    Referring Doctor
                </h2>

                <textarea class="fulltext t-field" style="width: 100%; padding-left: 0.5em;height: 2em" formControlName="ReferingDoc"></textarea>
                <button class="t-btn-edit" *ngIf="! es.isClosed"
                    (click)="atS.showAuto(GenForm.get('ReferingDoc'),'Referring Doctor',3)">Auto Text</button>

                    <div class="l-flex-c h100" style="direction: rtl;display: block;">
                        <div class="l-fg1 repeatExamination-text w100" style="font-size: 1.05em;margin-bottom: 1em">
                            מספר הפניה
                            <input class="t-field" formControlName="ReferralNum" style='width: 8em;padding-left:0.5em'>
                        </div>
                        <div class="l-fg1 repeatExamination-text w100" style="font-size: 1.05em;margin-bottom: 1em">
                            ת.ז רופא מפנה
    
                            <input type="text" class="t-field" style='width: 8em;padding-left:0.5em' formControlName="RefererringDocID">
                       
                        </div>

                    </div>
            </div>
        </div>
        <div class="w50">
            <section class="m-box">

                <div class="l-flex-c h100">

                    <div class="l-fg1 repeatExamination-text" style="font-size: 1.05em;">
                        Schedule Followup
                        <select class="t-select t-field" formControlName="FollowUpExam">
                            <option value="TTE Adults">TTE Adults</option>
                            <option value="Stress-Echo">Stress-Echo</option>
                            <option value="Duplex Carotid">Duplex Carotid</option>
                            <option value="Stress">Stress</option>
                        </select>
                        in

                        <span class="t-highlight-glowingCircle">
                            <input type="number" min="1" max="40"
                                style='width: 2em; background: none; border: none; color: white; font-size: 1.1em;margin-left: .7em;'
                                formControlName="Repeat1">
                        </span>

                        <select class="t-select t-field" formControlName="Repeat2">
                            <option></option>
                            <option value="days">Days</option>
                            <option value="weeks">Weeks</option>
                            <option value="months">Months</option>
                        </select>
                    </div>
                </div>

            </section>
        </div>
    </div>

</ng-container>