<div class="t-popup popup-patientDetails" *ngIf="ce.ErrorType!=''" style='display: block'>

    <div class="t-popup-close"></div>

    <div class="t-popup-frame error-w">
        <div class="t-popup-body">

            <button class="t-btn-popup-close" aria-label="close popup" (click)="close()"></button>

            <h2 style='color: #D7A95B;  text-align: center;'>
                {{ce.ErrorType}} {{ce.calendarForm || ce.alreadyHasExam || ce.sessionExpiration ? '' : ('GUI.errorMessages.Error' | translate)}}
            </h2>

            <div style="display: flex; justify-content: center;" class="l-flex l-flex-w t-fieldmargin patient-details" style="display: flex; justify-content: center;">
                <p style='text-align: center;'>
                    {{ce.calendarForm ? '' : ce.Error}}
                </p>
                <button class="save_patieont" (click)="login()" *ngIf="ce.ErrorType === 'Session'">
                    <!-- Login -->
                    {{'GUI.Buttons.Login' | translate}}
                </button>
                <button class="save_patieont" (click)="refreshSession()" *ngIf="ce.ErrorType === 'Session About To Expire'">
                    <!-- Stay Connected -->
                    {{'GUI.Buttons.Stay Connected' | translate}}
                </button>
                <button class="save_patieont" (click)="login(true)" *ngIf="ce.logError">
                    <!-- Back To Login -->
                    {{'GUI.Buttons.Back To Login' | translate}}
                </button>
            </div>
            <div *ngIf="ce.calendarForm" style="display:flex; align-items: center; justify-content: space-around; align-items: center; justify-content: space-between; margin-top: 1em;">
                <button class="save_patieont" style="min-width:7.9em;" (click)="close(true)">
                    <!-- Save -->
                    {{'GUI.Buttons.Save' | translate}}
                </button>
                <button class="delete_patieont" style="min-width:7.9em; margin: 0;" (click)="close(false)">
                    <!-- Cancel -->
                    {{'GUI.Buttons.Cancel' | translate}}
                </button>
            </div>

            <div *ngIf="ce.alreadyHasExam"
                style="display:flex; align-items: center; justify-content: space-around; align-items: center; justify-content: space-between; margin-top: 1em;">
                <button class="cancel" style="min-width:7.9em; margin: 0;" (click)="close(false)">
                    <!-- No -->
                    {{'GUI.Buttons.No' | translate}}
                </button>
                <button class="accept" style="min-width:7.9em;" (click)="close(true)">
                    <!-- Yes -->
                    {{'GUI.Buttons.Yes' | translate}}
                </button>
            </div>

        </div>
    </div>

</div>