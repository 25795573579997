import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ExamsService } from '../exams.service';
import { FetalechoService } from '../fetalecho.service';
import { MeasgrowthService } from '../measgrowth.service';
import { Measure } from '../measurements/meaure';

@Component({
  selector: 'app-fetalmeasure',
  templateUrl: './fetalmeasure.component.html',
  styleUrls: ['./fetalmeasure.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FetalmeasureComponent implements OnInit {
  @Input('name') name:string;
  @Input('first') first:any
  @Input('second') second:any
  @Input('type') type:any
  @Input('dbName') dbName:any;



  constructor(public fes:FetalechoService, private es:ExamsService, private measService:MeasgrowthService) { 
  }

  ngOnInit() {
    this.fes.resetMeas();
    this.es.fillForm(this.fes.fetalMeasureForm, "meas", true, false);
  }
}
