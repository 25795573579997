import { Component, OnInit, OnDestroy } from '@angular/core';
import {ExamsService} from '../exams.service';
import {MeasgrowthService} from '../measgrowth.service';
import {PatientsService} from '../patients.service';
import {PregnancyService} from '../pregnancy.service';
import {NtService} from '../nt.service';
import { UntypedFormBuilder,FormGroup,FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import {AutotextService} from '../autotext.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-nt',
  templateUrl: './nt.component.html',
  styleUrls: ['./nt.component.scss']
})
export class NTComponent implements OnInit, OnDestroy {

  
  riskAge: number;
  riskCRL:number;
  Conception: string;


  fetusSub:Subscription;
  mediaSub: Subscription;

  atSTitle1stTrimester = this.translate.instant('GUI.Auto Text.Titles.1st Trimester');
  atSTitleNT = this.translate.instant('GUI.Auto Text.Titles.NT');
  atSTitleComments = this.translate.instant('GUI.Auto Text.Titles.Comments');


  constructor(
    public es: ExamsService,
    private ms: MeasgrowthService,
    public pats: PatientsService,
    private pregS: PregnancyService,
    public nt: NtService,
    private fb: UntypedFormBuilder,
    public atS: AutotextService,
    private translate: TranslateService,
  ) {
    this.ms.setGA();
    this.nt.loaded = false;
   }

  ngOnInit() {
    this.loadData();
    this.fetusSub = this.es.fetusChange$.subscribe(()=> {
      this.riskAge=null;
      this.riskCRL=null;
      // this.nt.resetForm()
      this.loadData();
    });
    this.es.getSRs();
    if(!this.es.isClosed)
      this.mediaSub =  this.es.mediaArrived$.subscribe((mediatype)=>{
        if(mediatype == 'html')
          this.loadData();
      });
    
  }

  ngOnDestroy(): void {
    this.fetusSub.unsubscribe();
    if(this.mediaSub)
      this.mediaSub.unsubscribe();
  }

  loadData(){
      // this.nt.resetForm()
      // this.nt.NTForm.reset(false,{emitEvent:false});
    this.nt.resetForm()
    this.ms.clearMeas('NT');
    this.ms.clearMeas('CRL');
    this.ms.clearMeas('GS');
    this.nt.fillForm();
    if(this.es.ps.pregForm.get('NaturalPregnancy').value == 1){
      this.setConcep('Natural')
    }
   
  }

  

  getRiskAge(){
    if(!this.nt.NTForm.get('Conception').value)
      return null;
    let age= this.nt.getAge();
    if(age){
      this.riskAge = this.nt.calcRiskAge(age);
      return this.riskAge;
    }
    this.riskAge=null;
    return null;
  }

  checkRiskCRL(){
    if(this.riskAge && this.ms.getMeas("CRL").value && this.ms.getMeas("NT").value)
      return this.nt.calcRiskCRL(this.riskAge,this.ms.getMeas("CRL").value,this.ms.getMeas("NT").value);
    return "";
  }

  setConcep(method: string){
    if(this.nt.NTForm.get('EggDonationDate').value && this.Conception!=method)
      this.nt.NTForm.get('EggDonationDate').reset()
    this.Conception=method;
    this.nt.NTForm.get('Conception').setValue(method);
  }
  getAgeOver(age){
    if(age){
      var year = parseInt(age.split("y")[0])
      if(year >= 46)
        return true;
      else
        return false;
    }
  }

}
